import { Button, Card, Grid } from "@mui/material";
import { CustomField, getErrorKey, getErrorText } from "components";
import CustomMap from "components/CustomMap";
import Header from "components/HeaderV2";
import { useHasWriteAccess } from "hooks";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BookingActions from "./BookingActions";
import { BookingActionsSummary } from "./BookingActionsSummary";
import {
  BOOKING_DETAIL_FIELDS,
  CUSTOMER_DETAIL_FIELDS,
  PROVIDER_DETAIL_FIELDS,
} from "./constants";
import RenderCharges from "./RenderCharges";
import RenderNodeItems from "./RenderNodeItems";
import { getBookingDetailsRequest } from "./utils";
import ProfilePic from "components/profilePic";
import RenderUserSpecificTickets from "screens/CustomerSupport/components/RenderUserSpecificTickets";
import RenderUserSpecificPayments from "screens/CustomerSupport/components/RenderUserSpecificPayments";
import { formatJobScheduledTime } from "utils";
import { Visibility } from "@mui/icons-material";
import RelatedBookingsModal from "./components/RelatedBookingsModal";
import RenderProviderAttendanceModal from "screens/Jobs/components/RenderProviderAttendanceModal";
import { JOB_FREQUENCY_TYPE } from "./JobDurationSelector";
import JobScheduleModal from "screens/Jobs/components/JobScheduleModal";
import moment from "moment/moment";

const MODAL_TYPES = {
  REALTED: "realted",
  ATTENDANCE: "attendance",
  SCHEDULE: "schedule",
};

export default function BookingDetails() {
  const [data, setData] = useState({});
  const hasWriteAccess = useHasWriteAccess();
  const { nodes, charges, totalAmount, invoicePDF } = data;
  const { jobId, bookingId } = useParams();
  const id = jobId || bookingId;
  const isBooking = !!bookingId;
  const [modalData, setModalData] = useState({ type: null });
  const closeModal = () => setModalData({ type: null });

  const fetchData = useCallback(
    () =>
      getBookingDetailsRequest({
        id,
        onSuccess: (data) =>
          setData({
            ...data,
            jobScheduledTime: formatJobScheduledTime(data.jobScheduledTime),
          }),
      }),
    [id]
  );

  const getButtons = () => {
    const buttons = [];

    if (!isBooking && data.jobFrequency === JOB_FREQUENCY_TYPE.monthly.value) {
      buttons.push({
        title: (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              cursor: "pointer",
            }}
          >
            <Visibility />
            <span>Provider Attendance</span>
          </span>
        ),
        variant: "outlined",
        onClick: () => setModalData({ type: MODAL_TYPES.ATTENDANCE }),
      });
    }

    if (data.duration && data.perDayDuration) {
      buttons.push({
        title: (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              cursor: "pointer",
            }}
          >
            <Visibility />
            <span>View Schedule</span>
          </span>
        ),
        variant: "outlined",
        onClick: () => setModalData({ type: MODAL_TYPES.SCHEDULE }),
      });
    }

    return buttons;
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id, fetchData]);

  const onlyForMN = [
    "jobFrequencyCounter",
    "serviceFromDate",
    "serviceUptoDate",
  ];

  return (
    <>
      {!!hasWriteAccess && (
        <BookingActions
          isBooking={isBooking}
          fetchData={fetchData}
          serviceId={data?.service?.serviceId}
          data={data}
        />
      )}
      <Card
        sx={{
          mt: "12px",
          py: 3,
          px: 2,
          rowGap: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header
          title={bookingId ? "Booking Details" : "Job Details"}
          buttons={getButtons()}
        />
        <Grid container rowSpacing={2} spacing={1}>
          {BOOKING_DETAIL_FIELDS.map((booking, index) => {
            const { _key, md, renderField } = booking;

            if (!_key) return null;

            if (
              data.jobFrequency !== JOB_FREQUENCY_TYPE.monthly.value &&
              onlyForMN.includes(_key)
            ) {
              return null;
            }

            return (
              <Grid key={_key + index} item md={md ? md : 4} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={booking}
                  itemData={data}
                  errorText={data[getErrorText(_key)]}
                  error={data[getErrorKey(_key)]}
                  value={
                    renderField
                      ? renderField(data)
                      : _key === "service"
                      ? data[_key]?.name
                      : data[_key]
                  }
                  disabled
                />
              </Grid>
            );
          })}

          {data.jobFrequency === JOB_FREQUENCY_TYPE.monthly.value && (
            <Grid item md={2.3} sm={12} xs={12}>
              <Button
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                  gap: 0.5,
                }}
                variant="outlined"
                onClick={() => setModalData({ type: MODAL_TYPES.REALTED })}
              >
                <Visibility />
                <span>Related bookings</span>
              </Button>
            </Grid>
          )}
        </Grid>

        {!!data.provider && jobId && <ProviderDetails data={data.provider} />}

        {!!data.address && <CustomerDetails data={data.address} />}

        {!!data.provider && bookingId && (
          <ProviderDetails data={data.provider} />
        )}

        {!!nodes?.length && (
          <Grid container gap={4}>
            <Grid item sm={12} xs={12} md={7}>
              <Header skip title="Customer Selections" />
              <RenderNodeItems data={nodes} />
            </Grid>
            <Grid item sm={12} xs={12} md={4}>
              <Header skip title="Price Breakup" />
              <RenderCharges
                invoicePDF={invoicePDF}
                totalAmount={totalAmount}
                data={charges}
              />
            </Grid>
          </Grid>
        )}
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5px",
          }}
        >
          {!!data?.actions?.length && (
            <Grid item sm={12} xs={12} md={6}>
              <Header skip title="Actions" />
              <BookingActionsSummary actions={data.actions} />
            </Grid>
          )}
          <Grid item sm={12} xs={12} md={5}>
            <Header skip title={"Location"} />
            <CustomMap data={data.address} />
          </Grid>
        </Grid>
        <RenderUserSpecificPayments
          bookingId={bookingId}
          customerId={data.customerId}
        />
        <RenderUserSpecificTickets
          id={bookingId || jobId}
          userData={{
            name: bookingId
              ? data.address?.name
              : `${data?.provider?.firstName || ""} ${
                  data?.provider?.lastName || ""
                }`,
            id: bookingId ? data.customerId : data?.provider?._id,
          }}
          type={bookingId ? "booking" : "job"}
        />
      </Card>

      {modalData.type === MODAL_TYPES.ATTENDANCE && (
        <RenderProviderAttendanceModal
          jobId={data?.providerJobId}
          jobScheduledDate={data?.jobScheduledDate}
          closeModal={closeModal}
        />
      )}

      {modalData.type === MODAL_TYPES.REALTED && (
        <RelatedBookingsModal bookingId={bookingId} closeModal={closeModal} />
      )}

      {modalData.type === MODAL_TYPES.SCHEDULE && (
        <JobScheduleModal
          jobScheduledTime={
            data.jobScheduledTime ||
            moment(new Date(data.jobScheduledDate))
              .utc()
              .set({
                hours: 0,
                minutes: 0,
                seconds: 0,
              })
              .format("DD MMM YYYY hh:mm a")
          }
          endTime={data.endTime}
          jobFrequency={data.jobFrequency}
          duration={data.duration}
          serviceFromDate={data.serviceFromDate}
          perDayDuration={data.perDayDuration}
          closeModal={closeModal}
        />
      )}
    </>
  );
}

const CustomerDetails = ({ data }) => {
  return (
    <>
      <Header skip title="Customer Details" />
      <Grid container rowSpacing={2} spacing={1}>
        {CUSTOMER_DETAIL_FIELDS.map((customer, index) => {
          const { _key, md } = customer;

          if (!_key) return null;

          return (
            <Grid key={_key + index} item md={md ? md : 4} sm={12} xs={12}>
              <CustomField
                color={"primary"}
                item={customer}
                value={data[_key]}
                disabled
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

const ProviderDetails = ({ data }) => {
  return (
    <>
      <Header skip title="Provider Details" />
      <Grid container rowSpacing={2} spacing={1}>
        {data?.profilePic && (
          <Grid item sx={{ margin: "auto" }}>
            <ProfilePic uri={data?.profilePic} size={120} />
          </Grid>
        )}
        <Grid item lg={10} md={12} sm={12} xs={12} sx={{ margin: "auto" }}>
          <Grid container rowSpacing={2} spacing={1}>
            {PROVIDER_DETAIL_FIELDS.map((item, index) => {
              const { _key, md } = item;

              if (!_key) return null;

              return (
                <Grid key={_key + index} item md={md ? md : 4} sm={12} xs={12}>
                  <CustomField
                    color={"primary"}
                    item={item}
                    value={data[_key]}
                    disabled
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
