import { INPUT_TYPES } from "../../../../components/CustomField";

export const FAQ_FIELDS = [
  {
    md: 6,
    inputType: INPUT_TYPES.INPUT,
    _key: "faqsQuestion",
    placeholder: "Name",
  },
  {
    md: 6,
    inputType: INPUT_TYPES.TEXT_AREA,
    extraProps: { minRows: 3 },
    _key: "faqsAnswer",
    placeholder: "FAQ Answer",
  },
];
