import { Box, Card, CardContent } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CustomFilter from "components/CustomFilter";
import { queryString } from "utils";
import CustomDataGrid from "components/DataGrid";
import { fetchTicketTypesRequest, getCsTicketsList } from "./utils";
import { CS_TICKETS_FILTER, getCsTicketsColumns } from "./constants";
import qs from "query-string";

export default function CsTickets() {
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const { userType = "" } = useParams();
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState({});
  const [ticketTypes, setTicketTypes] = useState();
  const navigate = useNavigate();

  const onViewPress = (row) => {
    navigate(`/support/ticket/${row._id}`);
  };

  const onPageChange = (pNo) => setFiltersData({ ...filters, pageNo: pNo + 1 });

  const onPageSizeChange = (pSize) =>
    setFiltersData({ ...filters, pageSize: pSize });

  const setFiltersData = (_state) => {
    navigate(window.location.pathname + "?" + queryString(_state), {
      replace: true,
    });
    setFilters(_state);
  };

  useEffect(() => {
    const params = qs.parse(searchParams.toString());
    if (params.status) {
      params.status = params.status
        .split(",")
        .map((value) => ({ label: value, value }));
    }
    setFilters({ ...params });

    fetchTicketTypesRequest({
      onSuccess: (data) =>
        setTicketTypes(
          data.map((item) => ({ label: item.name, value: item._id }))
        ),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userType) {
      let filter = { ...filters, raisedFor: userType };
      delete filter.serviceLabel;

      getCsTicketsList({
        query: queryString(filter),
        onSuccess: (data) => {
          setData(data.items);
          setTotalRecords(data.totalRecords);
        },
      });
    }
  }, [filters, userType]);

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header title="Tickets" />
          <CustomFilter
            onChange={setFiltersData}
            filtersData={filters}
            fields={CS_TICKETS_FILTER.map((item) =>
              item._key === "typeId"
                ? { ...item, extraData: ticketTypes }
                : item
            )}
          />
          <CustomDataGrid
            rows={data}
            paginationMode="server"
            onPageChange={onPageChange}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pageSize={Number(filters?.pageSize || 10)}
            page={(filters?.pageNo || 1) - 1}
            onPageSizeChange={onPageSizeChange}
            rowCount={totalRecords}
            columns={getCsTicketsColumns({ onViewPress })}
          />
        </CardContent>
      </Card>
    </Box>
  );
}
