import { Circle } from "@mui/icons-material";
import { Typography } from "@mui/material";
import moment from "moment";
import React from "react";

export const BookingActionsSummary = ({ actions = [] }) => {
  return (
    <div>
      {actions.reverse().map(({ action, actionBy, createdAt }, index) => {
        return (
          <div
            key={index}
            style={{
              display: "flex",
              cursor: "pointer",
              width: "100%",
              padding: `4px`,
              marginBottom: "8px",
              position: "relative",
              gap: 6,
            }}
          >
            <Circle
              color={"primary"}
              sx={{ fontSize: "16px", marginTop: "4px", zIndex: "22" }}
            />
            <Typography
              component={"div"}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography
                component={"p"}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  fontSize: "16px",
                }}
              >
                <b>{action}</b> by <b>{actionBy}</b>
              </Typography>
              <Typography component={"span"} sx={{ fontSize: "13px" }}>
                {moment(createdAt).format("DD MMM YYYY hh:mm a")}
              </Typography>
            </Typography>
            {actions.length !== index + 1 && (
              <Typography
                component={"span"}
                sx={{
                  zIndex: "11",
                  position: "absolute",
                  top: "18px",
                  left: "11.5px",
                  borderLeft: "1.5px solid grey",
                  height: "100%",
                }}
              ></Typography>
            )}
          </div>
        );
      })}
    </div>
  );
};
