import React, { useState } from "react";
import { CustomField, getErrorKey, getErrorText } from "components";
import ModalWrapper from "components/ModalWrapper";
import { Grid } from "@mui/material";
import { saveContactUsRequest, updateContactUsRequest } from "../utils";
import { CONTACT_US_FIELDS } from "../constants";
import { validateFields } from "utils";

export const AddEditContactDetails = ({ closeModal, modalData }) => {
  const [state, setState] = useState(modalData.data);
  const isEdit = modalData.isEdit;
  const isMobileNumber = modalData.isMobileNumber;
  const customFields = isEdit
    ? CONTACT_US_FIELDS.filter((item) =>
        isMobileNumber
          ? item._key === "mobileNumber"
          : item._key !== "mobileNumber"
      )
    : CONTACT_US_FIELDS;

  const validateData = () => {
    const { _state, isValid } = validateFields({
      _state: state,
      fields: customFields,
    });
    setState({ ..._state });
    return isValid;
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    const { _id, mobileNumber, email } = state;

    const payload = isEdit
      ? isMobileNumber
        ? {
            mobileNumber,
          }
        : { email }
      : { mobileNumber, email, appType: modalData.appType };

    const caller = isEdit ? updateContactUsRequest : saveContactUsRequest;

    caller({
      id: _id,
      payload: payload,
      onSuccess: modalData?.onSuccess,
    });
  };

  const onChange = ({ value, _key }) => {
    setState((_state) => ({
      ..._state,
      [_key]: value,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
    }));
  };

  return (
    <ModalWrapper
      footerButtons={[{ title: isEdit ? "Update" : "Save", onClick: onSubmitPress }]}
      closeModal={closeModal}
      title={
        isEdit
          ? `Edit ${isMobileNumber ? "Mobile Number" : "Email Address"}`
          : "Add Contact Details"
      }
      sx={{ width: "35%" }}
      childSx={{ height: "25vh", p: 2, overflow: "auto" }}
    >
      <Grid container rowSpacing={2} spacing={1}>
        {customFields.map((item) => {
          const { _key } = item;

          if (!_key) return null;

          return (
            <Grid key={_key} item md={12} sm={12} xs={12}>
              <CustomField
                color={"primary"}
                item={item}
                itemData={state}
                errorText={state[getErrorText(_key)]}
                error={state[getErrorKey(_key)]}
                value={state[_key]}
                onChange={onChange}
              />
            </Grid>
          );
        })}
      </Grid>
    </ModalWrapper>
  );
};
