import { Box, Card, CardContent } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useCallback, useEffect, useState } from "react";
import CustomDataGrid from "components/DataGrid";
import { getCommunicationConfigRequest } from "./utils";
import { getCommunicationConfigColumns } from "./constants";
import { AddEditCommunicationConfig } from "./components/AddEditCommunicationConfig";
import { fetchTemplatesRequest } from "screens/Broadcast/utils";

export default function CommunicationConfig() {
  const [data, setData] = useState({});
  const [modalData, setModalData] = useState(null);
  const [templates, setTemplates] = useState([]);

  const closeModal = () => setModalData(null);

  const onEditPress = (row) =>
    setModalData({
      data: row,
      responseData: data,
    });

  const onSuccessCallback = () => {
    closeModal();
    fetchConfig();
  };

  const fetchConfig = useCallback(() => {
    getCommunicationConfigRequest({
      onSuccess: setData,
    });
  }, []);

  useEffect(() => {
    fetchConfig();
    fetchTemplatesRequest({
      onSuccess: (templateData) =>
        setTemplates(
          templateData.map((template) => ({
            label: template.templateName,
            value: template.templateId,
          }))
        ),
    });
  }, [fetchConfig]);

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header title="Communication Config" />

          {!!data.config && (
            <CustomDataGrid
              rows={Object.keys(data.config).map((_key, index) => ({
                name: _key,
                id: index,
                index: index + 1,
                ...data.config[_key],
              }))}
              columns={getCommunicationConfigColumns({
                onEditPress,
                templates,
              })}
            />
          )}
        </CardContent>
      </Card>

      {!!modalData && (
        <AddEditCommunicationConfig
          modalData={modalData}
          closeModal={closeModal}
          onSuccessCallback={onSuccessCallback}
          templates={templates}
        />
      )}
    </Box>
  );
}
