import { store } from "redux/store";
import { PAGE_TYPE_CONSTANTS } from "../constants";
import {
  ABOUT_FIELDS,
  CANCELLATION_POLICY_FIELDS,
  getAboutColumns,
  getPrivacyColumns,
  getTermsColumns,
  PRIVACY_FIELDS,
  REFUND_POLICY_FIELDS,
  TERMS_FIELDS,
} from "./constants";
import {
  useGetAboutData,
  useGetCancellationPolicyData,
  useGetPrivacyData,
  useGetRefundPolicyData,
  useGetTermsData,
} from "./hooks/useLanguageData";
import {
  updateCurrentAboutData,
  updateCurrentCancellationPolicyData,
  updateCurrentPrivacyData,
  updateCurrentRefundPolicyData,
  updateCurrentTermsData,
} from "redux/actions/appConfiguration";
const { setLoading } = require("redux/actions");
const { APP_CONFIGURATION_SERVICES, queryString } = require("utils");

export const formatTermsData = ({ item }) => {
  const { languageId } = item;
  const { remarks, terms } = item.stateData;

  return {
    remarks,
    terms,
    languageId,
  };
};

export const formatAboutData = ({ item }) => {
  const { languageId } = item;
  const { remarks, about } = item.stateData;

  return {
    remarks,
    about,
    languageId: languageId + "",
  };
};

export const formatPrivacyData = ({ item }) => {
  const { languageId } = item;
  const { remarks, policy } = item.stateData;

  return {
    remarks,
    policy,
    languageId,
  };
};

// terms and conditions

export const getTermsAndConditonsRequest = ({ query }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_TERMS_AND_CONDITIONS(query)
    .then(({ data, isSuccess }) => {
      isSuccess && dispatch(updateCurrentTermsData(data));
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createTermsRequest = ({ data, appType, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));

  const payload = {
    appType,
    data: data.map((item) => formatTermsData({ item })),
  };
  APP_CONFIGURATION_SERVICES.CREATE_A_TERMS_CONDITION(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        const query = queryString({ appType, languageId: 1 });
        getTermsAndConditonsRequest({ query });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const addTermsLanguageRequest = ({
  data,
  version,
  appType,
  languageId,
  onSuccess,
}) => {
  const dispatch = store.dispatch;

  const { terms, remarks } = data;

  const payload = {
    version,
    terms,
    languageId,
    appType,
    remarks,
  };

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.ADD_NEW_TERMS_LANGUAGE(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        const query = queryString({
          appType: payload.appType,
          version: payload.version,
        });

        getTermsAndConditonsRequest({ query });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getTermsByIdRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_TERMS_BY_ID(id)
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const editTermsRequest = ({ id, data, version, appType, onSuccess }) => {
  const dispatch = store.dispatch;

  const payload = {
    terms: data.terms,
    remarks: data.remarks,
  };

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.EDIT_TERMS(id, payload)
    .then(({ isSuccess }) => {
      const query = queryString({ appType, version });
      if (isSuccess) {
        onSuccess && onSuccess();
        getTermsAndConditonsRequest({ query });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const changeTermsStatus = ({ appType, version, isActive }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  const payload = {
    version,
    status: isActive ? "inactive" : "active",
  };

  APP_CONFIGURATION_SERVICES.CHANGE_TERMS_STATUS(payload)
    .then(({ isSuccess }) => {
      const query = queryString({ appType, languageId: 1 });
      isSuccess && getTermsAndConditonsRequest({ query });
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

// privacy and policy

export const getPrivacyAndPolicyRequest = ({ query }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_PRIVACY_POLICIES(query)
    .then(({ data, isSuccess }) => {
      isSuccess && dispatch(updateCurrentPrivacyData(data));
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createPrivacyRequest = ({ data, appType, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));

  const payload = {
    appType,
    data: data.map((item) => formatPrivacyData({ item })),
  };
  APP_CONFIGURATION_SERVICES.CREATE_A_PRIVACY_POLICY(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        const query = queryString({ appType, languageId: 1 });
        getPrivacyAndPolicyRequest({ query });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const editPrivacyRequest = ({
  id,
  data,
  version,
  appType,
  onSuccess,
}) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.EDIT_PRIVACY(id, data)
    .then(({ isSuccess }) => {
      const query = queryString({ appType, version });
      if (isSuccess) {
        onSuccess && onSuccess();
        getPrivacyAndPolicyRequest({ query });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getPrivacyByIdRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_PRIVACY_BY_ID(id)
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const addPrivacyLanguageRequest = ({
  data,
  version,
  appType,
  languageId,
  onSuccess,
}) => {
  const dispatch = store.dispatch;

  const { policy, remarks } = data;

  const payload = {
    version,
    policy,
    languageId,
    appType,
    remarks,
  };

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.ADD_NEW_PRIVACY_LANGUAGE(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        const query = queryString({
          appType: payload.appType,
          version: payload.version,
        });

        getPrivacyAndPolicyRequest({ query });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const changePrivacyStatus = ({ appType, version, isActive }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  const payload = {
    version,
    status: isActive ? "inactive" : "active",
  };

  APP_CONFIGURATION_SERVICES.CHANGE_PRIVACY_STATUS(payload)
    .then(({ isSuccess }) => {
      const query = queryString({ appType, languageId: 1 });
      isSuccess && getPrivacyAndPolicyRequest({ query });
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

// about us

export const getAboutUsRequest = ({ query }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_ABOUT_US(query)
    .then(({ data, isSuccess }) => {
      isSuccess && dispatch(updateCurrentAboutData(data));
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createAboutRequest = ({ data, appType, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));

  const payload = {
    appType,
    data: data.map((item) => formatAboutData({ item })),
  };

  APP_CONFIGURATION_SERVICES.CREATE_A_ABOUT_US(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        const query = queryString({ appType, languageId: 1 });
        getAboutUsRequest({ query });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const editAboutRequest = ({ id, data, version, appType, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.EDIT_ABOUT(id, data)
    .then(({ isSuccess }) => {
      const query = queryString({ appType, version });
      if (isSuccess) {
        onSuccess && onSuccess();
        getAboutUsRequest({ query });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const addAboutLanguageRequest = ({
  data,
  version,
  appType,
  languageId,
  onSuccess,
}) => {
  const dispatch = store.dispatch;

  const { about, remarks } = data;

  const payload = {
    version,
    about,
    languageId,
    appType,
    remarks,
  };

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.ADD_NEW_ABOUT_LANGUAGE(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        const query = queryString({
          appType: payload.appType,
          version: payload.version,
        });

        getAboutUsRequest({ query });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getAboutByIdRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_ABOUT_BY_ID(id)
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const changeAboutStatus = ({ appType, version, isActive }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  const payload = {
    version,
    status: isActive ? "inactive" : "active",
  };

  APP_CONFIGURATION_SERVICES.CHANGE_ABOUT_STATUS(payload)
    .then(({ isSuccess }) => {
      const query = queryString({ appType, languageId: 1 });
      isSuccess && getAboutUsRequest({ query });
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

// Cancellation policy

export const getCancellationPolicyRequest = ({ query }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_CANCELLATION_POLICIES(query)
    .then(({ data, isSuccess }) => {
      isSuccess && dispatch(updateCurrentCancellationPolicyData(data));
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createCancellationPolicyRequest = ({
  data,
  appType,
  onSuccess,
}) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));

  const payload = {
    appType,
    data: data.map((item) => formatPrivacyData({ item })),
  };
  APP_CONFIGURATION_SERVICES.CREATE_A_CANCELLATION_POLICY(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        const query = queryString({ appType, languageId: 1 });
        getCancellationPolicyRequest({ query });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const editCancellationPolicyRequest = ({
  id,
  data,
  version,
  appType,
  onSuccess,
}) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.EDIT_CANCELLATION(id, data)
    .then(({ isSuccess }) => {
      const query = queryString({ appType, version });
      if (isSuccess) {
        onSuccess && onSuccess();
        getCancellationPolicyRequest({ query });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getCancellationPolicyByIdRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_CANCELLATION_BY_ID(id)
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const addCancellationPolicyLanguageRequest = ({
  data,
  version,
  appType,
  languageId,
  onSuccess,
}) => {
  const dispatch = store.dispatch;

  const { policy, remarks } = data;

  const payload = {
    version,
    policy,
    languageId,
    appType,
    remarks,
  };

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.ADD_NEW_CANCELLATION_LANGUAGE(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        const query = queryString({
          appType: payload.appType,
          version: payload.version,
        });

        getCancellationPolicyRequest({ query });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const changeCancellationPolicyStatus = ({
  appType,
  version,
  isActive,
}) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  const payload = {
    version,
    status: isActive ? "inactive" : "active",
  };

  APP_CONFIGURATION_SERVICES.CHANGE_CANCELLATION_STATUS(payload)
    .then(({ isSuccess }) => {
      const query = queryString({ appType, languageId: 1 });
      isSuccess && getCancellationPolicyRequest({ query });
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

// Refund policy

export const getRefundPolicyRequest = ({ query }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_REFUND_POLICIES(query)
    .then(({ data, isSuccess }) => {
      isSuccess && dispatch(updateCurrentRefundPolicyData(data));
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createRefundPolicyRequest = ({ data, appType, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));

  const payload = {
    appType,
    data: data.map((item) => formatPrivacyData({ item })),
  };
  APP_CONFIGURATION_SERVICES.CREATE_A_REFUND_POLICY(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        const query = queryString({ appType, languageId: 1 });
        getRefundPolicyRequest({ query });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const editRefundPolicyRequest = ({
  id,
  data,
  version,
  appType,
  onSuccess,
}) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.EDIT_REFUND(id, data)
    .then(({ isSuccess }) => {
      const query = queryString({ appType, version });
      if (isSuccess) {
        onSuccess && onSuccess();
        getRefundPolicyRequest({ query });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getRefundPolicyByIdRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_REFUND_BY_ID(id)
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const addRefundPolicyLanguageRequest = ({
  data,
  version,
  appType,
  languageId,
  onSuccess,
}) => {
  const dispatch = store.dispatch;

  const { policy, remarks } = data;

  const payload = {
    version,
    policy,
    languageId,
    appType,
    remarks,
  };

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.ADD_NEW_REFUND_LANGUAGE(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        const query = queryString({
          appType: payload.appType,
          version: payload.version,
        });

        getRefundPolicyRequest({ query });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const changeRefundPolicyStatus = ({ appType, version, isActive }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  const payload = {
    version,
    status: isActive ? "inactive" : "active",
  };

  APP_CONFIGURATION_SERVICES.CHANGE_REFUND_STATUS(payload)
    .then(({ isSuccess }) => {
      const query = queryString({ appType, languageId: 1 });
      isSuccess && getRefundPolicyRequest({ query });
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getPageData = ({ pageType, version }) => {
  const state = store.getState();
  const { appConfiguration } = state;

  switch (pageType) {
    case PAGE_TYPE_CONSTANTS.TERMS:
      return {
        modalTitle: `Add New T&C`,
        headerTitle: `Terms and Conditions List ${
          version ? `(v${version})` : ""
        }`,
        headerBtnTitle: `Add ${version ? "Language" : "T&C"}`,
        requestCaller: getTermsAndConditonsRequest,
        requestByIdCaller: getTermsByIdRequest,
        createVersionRequest: createTermsRequest,
        addLanguageRequest: addTermsLanguageRequest,
        editRequest: editTermsRequest,
        languageCaller: useGetTermsData,
        changeStatusRequest: changeTermsStatus,
        getColumns: getTermsColumns,
        customFields: TERMS_FIELDS,
        key: "terms",
        data: appConfiguration.terms.map((item, index) => ({
          ...item,
          id: index + 1,
        })),
      };

    case PAGE_TYPE_CONSTANTS.PRIVACY:
      return {
        modalTitle: "Add New Policy",
        headerTitle: `Privacy and Policy List ${
          version ? `(v${version})` : ""
        }`,
        headerBtnTitle: `Add ${version ? "Language" : "Privacy Policy"}`,
        requestCaller: getPrivacyAndPolicyRequest,
        requestByIdCaller: getPrivacyByIdRequest,
        createVersionRequest: createPrivacyRequest,
        addLanguageRequest: addPrivacyLanguageRequest,
        editRequest: editPrivacyRequest,
        languageCaller: useGetPrivacyData,
        changeStatusRequest: changePrivacyStatus,
        getColumns: getPrivacyColumns,
        customFields: PRIVACY_FIELDS,
        key: "policy",
        data: appConfiguration.privacy.map((item, index) => ({
          ...item,
          id: index + 1,
        })),
      };

    case PAGE_TYPE_CONSTANTS.ABOUT:
      return {
        modalTitle: "Add New About",
        headerTitle: `About Us List ${version ? `(v${version})` : ""}`,
        headerBtnTitle: `Add ${version ? "Language" : "About"}`,
        requestCaller: getAboutUsRequest,
        requestByIdCaller: getAboutByIdRequest,
        createVersionRequest: createAboutRequest,
        addLanguageRequest: addAboutLanguageRequest,
        editRequest: editAboutRequest,
        languageCaller: useGetAboutData,
        changeStatusRequest: changeAboutStatus,
        getColumns: getAboutColumns,
        customFields: ABOUT_FIELDS,
        key: "about",
        data: appConfiguration.about.map((item, index) => ({
          ...item,
          id: index + 1,
        })),
      };

    case PAGE_TYPE_CONSTANTS.CANCELLATION:
      return {
        modalTitle: "Add Cancellation Policy",
        headerTitle: `Cancellation Policies List ${
          version ? `(v${version})` : ""
        }`,
        headerBtnTitle: `Add ${version ? "Language" : "Cancellation Policy"}`,
        requestCaller: getCancellationPolicyRequest,
        requestByIdCaller: getCancellationPolicyByIdRequest,
        createVersionRequest: createCancellationPolicyRequest,
        addLanguageRequest: addCancellationPolicyLanguageRequest,
        editRequest: editCancellationPolicyRequest,
        languageCaller: useGetCancellationPolicyData,
        changeStatusRequest: changeCancellationPolicyStatus,
        getColumns: getPrivacyColumns,
        customFields: CANCELLATION_POLICY_FIELDS,
        key: "policy",
        data: appConfiguration.cancellationPolicy.map((item, index) => ({
          ...item,
          id: index + 1,
        })),
      };

    case PAGE_TYPE_CONSTANTS.REFUND:
      return {
        modalTitle: "Add Refund Policy",
        headerTitle: `Refund Policies List ${version ? `(v${version})` : ""}`,
        headerBtnTitle: `Add ${version ? "Language" : "Refund Policy"}`,
        requestCaller: getRefundPolicyRequest,
        requestByIdCaller: getRefundPolicyByIdRequest,
        createVersionRequest: createRefundPolicyRequest,
        addLanguageRequest: addRefundPolicyLanguageRequest,
        editRequest: editRefundPolicyRequest,
        languageCaller: useGetRefundPolicyData,
        changeStatusRequest: changeRefundPolicyStatus,
        getColumns: getPrivacyColumns,
        customFields: REFUND_POLICY_FIELDS,
        key: "policy",
        data: appConfiguration.refundPolicy.map((item, index) => ({
          ...item,
          id: index + 1,
        })),
      };

    default:
      return {};
  }
};
