import { setLoading } from "../../../../redux/actions";
import { store } from "../../../../redux/store";
import { HTTP_SERVICE } from "../../../../utils/apiServices";

export const addServicePriceRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.ADD_SERVICE_PRICE({ payload })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getServicePriceRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_SERVICE_PRICE(id)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess(data?.items || []);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const deleteServicePriceRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.DELETE_SERVICE_PRICE(id)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess(data?.items || []);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateServicePriceRequest = ({ id, payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.UPDATE_SERVICE_PRICE({ payload, id })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
