import { Box, Button, Card } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import RenderFillers from "./RenderFillers";
import RenderLanguageSpecificTemplate from "./RenderLanguageSpecificTemplate";
import RenderBasicInfo from "./RenderBasicInfo";
import { successToast, validateFields } from "utils";
import {
  TEMPLATE_INFO_FIELDS,
  TEMPLATE_TYPE,
} from "./constants";
import { getErrorKey, getErrorText } from "components";
import {
  createTemplatesRequest,
  getTemplateByIdRequest,
  updateTemplatesRequest,
} from "screens/Broadcast/utils";

const initState = ({ activeLanguages, _data = {} }) => {
  const state = {
    ..._data,
    fillers: _data.fillers?.map((filler) => ({ key: filler })) || [{}],
    sms: activeLanguages.map((item) => ({
      ...item,
      ...((_data?.sms || {})[item.languageId] || {}),
    })),
    email: activeLanguages.map((item) => ({
      ...item,
      ...((_data?.email || {})[item.languageId] || {}),
    })),
    whatsapp: activeLanguages.map((item) => ({
      ...item,
      ...((_data?.whatsapp || {})[item.languageId] || {}),
    })),
    pushnotification: activeLanguages.map((item) => ({
      ...item,
      ...((_data?.pushnotification || {})[item.languageId] || {}),
    })),
  };
  return state;
};

const validateData = ({ state }) => {
  let isValid = true;
  const validator1 = validateFields({
    _state: state,
    fields: TEMPLATE_INFO_FIELDS,
  });
  if (!validator1.isValid) {
    isValid = false;
    state = { ...state, ...validator1._state };
  }

  state.fillers.forEach((item, index) => {
    if (!item.key?.trim()) {
      isValid = false;
      state.fillers[index][getErrorKey("key")] = true;
      state.fillers[index][getErrorText("key")] = "Please enter a valid value";
    }
  });
  TEMPLATE_TYPE.forEach(({ value: type, fields }) => {
    (state[type] || []).forEach((item, index) => {
      const validator2 = validateFields({
        _state: item,
        fields,
      });

      if (!validator2.isValid) {
        isValid = false;
        state[type][index] = validator2._state;
      }
    });
  });

  return { isValid, state };
};

const createPayload = (_state) => {
  let state = {};
  state.fillers = _state.fillers.map((item) => item.key);
  state.templateName = _state.templateName;
  TEMPLATE_TYPE.forEach(({ value: type, fields }) => {
    let typeState = {};
    (_state[type] || []).forEach((item) => {
      const tempItem = {};
      fields.forEach(({ _key }) => {
        tempItem[_key] = item[_key];
      });

      if (item.languageId) {
        typeState[item.languageId] = tempItem;
      }
    });
    state[type] = typeState;
  });

  return state;
};

export default function AddEditTemplate() {
  const { type = "", id } = useParams();
  const navigate = useNavigate();
  const activeLanguages = useSelector(
    ({ common }) => common.allActiveLanguages
  );
  const [template, setTemplateData] = useState(initState({ activeLanguages }));

  useEffect(() => {
    if (id) {
      getTemplateByIdRequest({
        id,
        onSuccess: (data) => {
          setTemplateData(initState({ activeLanguages, _data: data }));
        },
      });
    }
  }, [activeLanguages, id]);

  const onSubmitPress = () => {
    const { isValid, state } = validateData({ state: template });

    if (!isValid) {
      setTemplateData({ ...state });
      return;
    }
    const payload = createPayload(template);
    const caller = id ? updateTemplatesRequest : createTemplatesRequest;

    caller({
      payload,
      id: state._id,
      onSuccess: () => {
        navigate("/template", { replace: true });
        successToast(`Template ${id ? "updated" : "created"} successfully!`);
      },
    });
  };

  return (
    <Box sx={{ my: 2 }}>
      <Card sx={{ px: 2, py: 1 }}>
        <Header
          variant="h5"
          title={`${type[0].toLocaleUpperCase()}${type.slice(1)} Template`}
        />
        <RenderBasicInfo data={template} setTemplateData={setTemplateData} />
        <RenderFillers
          fillers={template.fillers}
          setTemplateData={setTemplateData}
        />

        {TEMPLATE_TYPE.map((templateType, index) => {
          return (
            <div
              key={index}
              style={{
                border: "1px solid",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingBottom: "10px",
                borderRadius: "8px",
                marginTop: index !== 0 && "10px",
              }}
            >
              <Header skip title={templateType.label} style={{ py: 0 }} />

              <RenderLanguageSpecificTemplate
                templateType={templateType.value}
                fields={templateType.fields}
                template={template[templateType.value]}
                setTemplateData={setTemplateData}
              />
            </div>
          );
        })}

        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
        >
          <Button
            size="large"
            variant="contained"
            onClick={onSubmitPress}
            style={{ whiteSpace: "nowrap" }}
          >
            {id ? "Update" : "Create"}
          </Button>
        </div>
      </Card>
    </Box>
  );
}
