import { Box, Card, CardContent } from "@mui/material";
import CustomFilter from "components/CustomFilter";
import CustomDataGrid from "components/DataGrid";
import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import qs from "query-string";
import { JOB_FILTERS, getJobsListColumns } from "./constants";
import { getJobsRequest } from "./utils";
import { queryString } from "utils";

export default function Jobs() {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState();
  const [totalCount, setTotalCount] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const params = qs.parse(searchParams.toString());
    if (params.status) {
      params.status = params.status
        .split(",")
        .map((value) => ({ label: value, value }));
    }
    setFilters({ ...params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !!filters &&
      getJobsRequest({
        query: queryString(filters),
        onSuccess: ({ totalRecords, response }) => {
          setData(response);
          setTotalCount(totalRecords);
        },
      });
  }, [filters]);

  const onPageChange = (pNo) => setFiltersData({ ...filters, pageNo: pNo + 1 });

  const onPageSizeChange = (pSize) =>
    setFiltersData({ ...filters, pageSize: pSize });

  const onViewPress = ({ booking }) => {
    navigate("/jobs/" + booking._id);
  };

  const setFiltersData = (_state) => {
    navigate("/jobs?" + queryString(_state), { replace: true });
    setFilters(_state);
  };

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header title={"Jobs"} />
          <CustomFilter
            onChange={setFiltersData}
            filtersData={filters}
            fields={JOB_FILTERS}
          />
          <CustomDataGrid
            rows={data}
            onPageChange={onPageChange}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pageSize={Number(filters?.pageSize || 10)}
            page={(filters?.pageNo || 1) - 1}
            paginationMode="server"
            rowCount={totalCount}
            onPageSizeChange={onPageSizeChange}
            columns={getJobsListColumns({ onViewPress })}
          />
        </CardContent>
      </Card>
    </Box>
  );
}
