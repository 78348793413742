import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { INPUT_TYPES } from "components";

export const CUSTOMER_FIELDS = [
  {
    _key: "name",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Name",
  },
  {
    _key: "email",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Email",
  },
  {
    _key: "phoneNumber",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Phone Number",
  },
  {
    _key: "balance",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Balance",
  },
];

export const getAddressColumns = ({ onViewPress }) => {
  return [
    {
      field: "index",
      headerName: "S. No.",
      width: 90,
      sortable: false,
    },
    {
      field: "label",
      headerName: "Label",
      width: 120,
      sortable: false,
    },
    {
      field: "landmark",
      headerName: "Landmark",
      width: 150,
      sortable: false,
    },
    {
      field: "address",
      headerName: "Address Line 1",
      width: 250,
      sortable: false,
    },
    {
      field: "addressline2",
      headerName: "Address Line 2",
      width: 150,
      sortable: false,
    },
    {
      field: "pincode",
      headerName: "Pincode",
      width: 100,
      sortable: false,
    },
    {
      field: "s",
      headerName: "View",
      width: 100,
      sortable: false,
      renderCell: ({ row }) => {
        const onView = (e) => {
          e.stopPropagation();
          onViewPress(row);
        };

        return (
          <IconButton onClick={onView}>
            <Visibility color="primary" />
          </IconButton>
        );
      },
    },
  ];
};
