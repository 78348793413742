import { MainWrapper } from "./mainWrapper.jsx";

// mui Css
import "./styles/index.css";
import "./styles/mui.css";
import {
  StyledEngineProvider,
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import { Routes, Navigate, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { Login } from "./screens/Login";
import { AUTH_SERVICES, HTTP_SERVICE } from "./utils/apiServices.js";
import { useDispatch, useSelector } from "react-redux";
import { setLanguagesData, setProfileData } from "./redux/actions/";
import { setAuthToken } from "utils/Services.js";
import StandardErrorBoundary from "StandardErrorBoundary.js";
import Dlogger from "screens/AppConfiguration/Dlogger/index.js";

function App() {
  const authToken = useSelector(({ common }) => common.authToken);
  setAuthToken(authToken);
  const dispatch = useDispatch();
  setAuthToken(authToken);
  useEffect(() => {
    if (authToken) {
      AUTH_SERVICES.PROFILE().then(({ data }) =>
        dispatch(setProfileData(data[0] || {}))
      );
      HTTP_SERVICE.GET_LANGUAGES_LIST().then((res) =>
        dispatch(setLanguagesData(res?.data || []))
      );
    }
  }, [dispatch, authToken]);

  const theme = createTheme({
    palette: {
      primary: { main: "#1b5e20" },
      secondary: { main: "#075985" },
      neutral: { main: "#64748B", contrastText: "#888" },
      danger: { main: "#f44336" },
      white: { main: "#fff" },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <StandardErrorBoundary>
            <Routes>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/logger" element={<Dlogger />}></Route>
              <Route
                path="/*"
                element={
                  authToken ? <MainWrapper /> : <Navigate to="/login" replace />
                }
              />
            </Routes>
            <Toaster
              position="top-right"
              reverseOrder={false}
              gutter={8}
              toastOptions={{
                duration: 3000,
                style: {
                  background: "#363636",
                  color: "#fff",
                },
                success: {
                  duration: 3000,
                  theme: {
                    primary: "blue",
                    secondary: "black",
                  },
                },
              }}
            />
          </StandardErrorBoundary>
        </StyledEngineProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
