import { Box, Card, CardContent, Grid } from "@mui/material";
import { CustomField } from "components";
import Header from "components/HeaderV2";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { CUSTOMER_FIELDS } from "./constants";

import ProfilePic from "components/profilePic";
import RenderUserSpecificJobsBookings from "screens/Provider/ProviderList/components/RenderUserSpecificJobsBookings";
import RenderAddresses from "./components/RenderAddresses";
import RenderUserSpecificTickets from "screens/CustomerSupport/components/RenderUserSpecificTickets";
import { useParams } from "react-router-dom";
import { getCustomerInfoRequest } from "./utils";
import RenderUserSpecificPayments from "screens/CustomerSupport/components/RenderUserSpecificPayments";
import { getAccountInfoRequest } from "screens/Provider/ProviderList/utils";

const CustomerDetails = ({ _data }) => {
  const { id } = useParams();
  const [data, setData] = useState(_data || {});
  const _id = _data._id;

  useEffect(() => {
    _id &&
      getAccountInfoRequest({
        query: `ledgerObjectId=${_id}`,
        onSuccess: (response) => {
          delete response._id;
          setData((_state) => ({ ..._state, ...response }));
        },
      });
  }, [_id]);

  useEffect(() => {
    if (id) {
      getCustomerInfoRequest({
        customerId: id,
        onSuccess: (data) =>
          setData((_state) => ({
            ..._state,
            ...data,
            addresses: data.addresses.map((item, index) => ({
              ...item,
              id: item._id,
              index: index + 1,
            })),
          })),
      });
    }
  }, [id]);

  return (
    <Box sx={{ my: 2 }}>
      <Card>
        <CardContent>
          <Header
            title={"Basic Details"}
            style={{ flexDirection: { xs: "column", sm: "row" } }}
          />
          <Grid container rowGap={2} sx={{ py: "15px" }}>
            {data?.profilePic && (
              <Grid item sx={{ margin: "auto" }}>
                <ProfilePic uri={data?.profilePic} size={150} />
              </Grid>
            )}
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "auto" }}>
              <Grid container rowSpacing={2} spacing={1}>
                {CUSTOMER_FIELDS.map((item) => {
                  const { md = 3, _key } = item;
                  if (!_key) {
                    return null;
                  }

                  return (
                    <Grid key={_key} item md={md} sm={12} xs={12}>
                      <CustomField
                        item={item}
                        disabled
                        itemData={[]}
                        value={get(data, _key, "")}
                        onChange={() => {}}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>

          {!!data?.addresses?.length && (
            <RenderAddresses data={data.addresses} />
          )}

          <RenderUserSpecificJobsBookings
            id={data?._id || id}
            userType="customer"
          />
          <RenderUserSpecificPayments customerId={data?._id || id} />
          <RenderUserSpecificTickets
            id={data?._id || id}
            userData={{ name: data.name, id: data?._id || id }}
            type="customer"
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default CustomerDetails;
