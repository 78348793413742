import axios from "axios";
import {
  setAuthToken as setToken,
  setProfileData,
  setLoading,
} from "redux/actions";
import { store } from "redux/store";
import { errorToast } from "./helper";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const setAuthToken = (token) => {
  instance.defaults.headers = {
    ...instance.defaults.headers,
    ...{
      authorization: "Bearer " + token,
    },
  };
};

const responseBody = (response) => response.data;
const onError = (error) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: false }));

  if (error?.response?.status !== 200) {
    errorToast(
      error.response?.data?.error ??
        error.response?.data?.message ??
        error.response?.statusText
    );
  }
  if (error?.response?.status === 401) {
    const dispatch = store.dispatch;
    dispatch(setProfileData({}));
    dispatch(setToken(""));
  }
  return Promise.reject(error);
};

export const requests = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody).catch(onError),

  post: (url, body) =>
    instance.post(url, body).then(responseBody).catch(onError),

  put: (url, body, headers) =>
    instance.put(url, body, headers).then(responseBody).catch(onError),

  patch: (url, body) =>
    instance.patch(url, body).then(responseBody).catch(onError),

  delete: (url) => instance.delete(url).then(responseBody).catch(onError),
};
