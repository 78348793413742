import Header from "components/HeaderV2";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomFilter from "components/CustomFilter";
import { queryString } from "utils";
import CustomDataGrid from "components/DataGrid";
import qs from "query-string";
import { getReasonsListRequest, toggleReasonRequest } from "./utils";
import { getReasonsColumns, REASONS_FILTER } from "./constants";
import { useGetLangData } from "../hooks/useLanguagesData";

export default function Reasons({ serviceId }) {
  const [data, setData] = useState([]);
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState(1);
  const navigate = useNavigate();
  const { availableLanguages } = useGetLangData({ data });

  const fetchReasonsList = useCallback(
    () =>
      getReasonsListRequest({
        query: queryString({
          ...filters,
          serviceId,
          serviceLabel: undefined,
          active: undefined,
        }),
        onSuccess: setData,
      }),
    [filters, serviceId]
  );

  const onEditPress = (row) => navigate(`/reasons/edit/${row.reasonId}`);
  const onCreatePress = () => navigate("/reasons/create");

  const setFiltersData = (_state) => {
    navigate(window.location.pathname + "?" + queryString(_state), {
      replace: true,
    });
    setFilters(_state);
  };

  useEffect(() => {
    const params = qs.parse(searchParams.toString());
    if (params.status) {
      params.status = params.status
        .split(",")
        .map((value) => ({ label: value, value }));
    }

    setFilters({ ...params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    serviceId && fetchReasonsList();
  }, [serviceId, fetchReasonsList]);

  const handleStatusChange = ({ reasonId }) => {
    toggleReasonRequest({ id: reasonId, onSuccess: fetchReasonsList });
  };

  return (
    <>
      <Header
        title=""
        skip
        buttons={[{ title: "Create Reason", onClick: onCreatePress }]}
        dropDown={{
          value: selectedLanguage,
          onChange: setSelectedLanguage,
          data: availableLanguages,
        }}
      />
      <CustomFilter
        onChange={setFiltersData}
        filtersData={filters}
        fields={REASONS_FILTER}
      />

      <CustomDataGrid
        rows={data
          .filter(({ languageId }) => languageId === selectedLanguage)
          .map((item, index) => ({
            ...item,
            id: item._id,
            index: index + 1,
          }))}
        columns={getReasonsColumns({ onEditPress, handleStatusChange })}
      />
    </>
  );
}
