import { toast } from "react-hot-toast";
import CryptoJS from "crypto-js";
import isArray from "lodash/isArray";
import { useSelector } from "react-redux";
import moment from "moment";

export const successToast = (message) => !!message && toast.success(message);
export const errorToast = (message) => {
  toast.dismiss();
  toast.error(message || "Something went wrong! Please try again later");
};
export const blue_500 = "#3b82f6";

export const getErrorKey = (_key) => `er_${_key}`;
export const getErrorText = (_key) => `er_txt_${_key}`;

export const checkDataExist = ({ data, key, value }) => {
  return !data?.find((obj) => obj?.[key] === value);
};

export const filterUniqueValues = (arrayData, field, lang) => {
  const filteredArray = [];
  for (let i = 0; i < arrayData?.length; i++) {
    if (
      (arrayData[i][lang]?.toLowerCase() === "english" ||
        arrayData[i][lang] === 1) &&
      filteredArray?.indexOf(field) < 0
    ) {
      filteredArray.push(arrayData[i]);
    }
  }
  return filteredArray;
};

export const checkAllRequiredData = (data, activeLanguages, key) => {
  return activeLanguages?.every(({ languageName }) => {
    if (data[`${key}${languageName}`]?.length > 0) {
      return true;
    }
    return false;
  });
};

export const getDataForUpdate = (id, dataList, flag) => {
  const filteredData = dataList?.reduce((prev, cur) => {
    const { categoryId, categoryName, categoryLanguage } = cur;
    if (categoryId === id) {
      prev[`${flag}${categoryLanguage}`] = categoryName;
    }
    return prev;
  }, {});
  return filteredData;
};

export const validateFields = ({ _state, fields = [] }) => {
  let isValid = true;
  fields.forEach(({ isOptional, regex, _key, inputType }) => {
    if (isOptional) {
      return;
    }
    if (_state[_key] === undefined) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "This field can't be empty";
      return;
    }
    if (!((_state[_key] ?? "") + "")?.trim()?.length) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "Please enter a valid value";
      return;
    }
    if (regex && !regex.test(_state[_key])) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "Please enter a valid value";
      return;
    }

    if (inputType !== "FILTER_OPTIONS" && inputType !== "NORMAL_FILE") {
      _state[_key] = ((_state[_key] ?? "") + "")?.trim();
    }

    _state[getErrorKey(_key)] = false;
    _state[getErrorText(_key)] = "";
  });
  return { isValid, _state };
};

export const decryptString = (value) => {
  if (!value) {
    return "";
  }

  const bytes = CryptoJS.AES.decrypt(value, "A!S@D#F$F$G%H^");

  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const queryString = (obj) => {
  var str = [];
  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      if (isArray(obj[p])) {
        const val = obj[p].map((item) => item.label || item);
        val.length &&
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(val));
        continue;
      }
      obj[p] !== undefined &&
        obj[p] !== "" &&
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }
  return str.join("&");
};

export const useGetLanguageData = ({ data }) => {
  const allLanguages = useSelector(({ common }) => common.allLanguages) || [];

  const availableLanguages = allLanguages
    .filter((item) =>
      data.find((_node) => +_node.languageId === item.languageId)
    )
    .map((lang) => ({
      ...lang,
      label: lang.languageName,
      value: lang.languageId,
    }));

  const unavailableLanguages = allLanguages
    .filter(
      (item) => !data.find((_node) => +_node.languageId === item.languageId)
    )
    .map((lang) => ({
      ...lang,
      label: lang.languageName,
      value: lang.languageId,
    }));

  if (!availableLanguages.length) {
    const defaultLang = allLanguages[0] || {};
    availableLanguages.push({
      ...defaultLang,
      label: defaultLang.languageName,
      value: defaultLang.languageId,
    });
  }

  const languagesKeyValPair = {};
  allLanguages.forEach(({ languageId, languageName }) => {
    return (languagesKeyValPair[languageId] = languageName);
  });

  return {
    availableLanguages,
    unavailableLanguages,
    defaultLanguageId: availableLanguages[0]?.languageId,
    allLanguages: allLanguages
      .filter((item) => item.isActive)
      .map(({ languageName, languageId }) => ({
        label: languageName,
        value: languageId,
      })),

    languagesKeyValPair,
  };
};

export const customSortComparatorForNums = (v1, v2) => v1 - v2;

export const formatJobScheduledTime = (jobScheduledTime) => {
  return jobScheduledTime
    ? moment(jobScheduledTime).utc().format("DD MMM YYYY hh:mm a")
    : "";
};

Object.defineProperty(Object.prototype, "hasOwnAllProperties", {
  value: function (properties) {
    return properties.every((prop) => this.hasOwnProperty(prop));
  },
});

Object.defineProperty(Object.prototype, "getMissingKeys", {
  value: function (properties) {
    return properties.filter((prop) => !this.hasOwnProperty(prop));
  },
});

Object.defineProperty(Array.prototype, "union", {
  value: function (elements) {
    const _union = new Set(this);
    for (const elem of elements) {
      _union.add(elem);
    }

    return [..._union];
  },
});
