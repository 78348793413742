import React, { useState } from "react";
import ModalWrapper from "components/ModalWrapper";
import { CATEGORY_FIELDS } from "../constants";
import { CustomField, getErrorKey, getErrorText } from "components";
import { Grid } from "@mui/material";
import { createCategoryRequest } from "../utils";
import { successToast, validateFields } from "utils";

export const CreateCategoryModal = ({ loadCategoryList, closeModal }) => {
  const [state, setState] = useState({});

  const validateData = () => {
    let isValidData = true;
    const { isValid, _state } = validateFields({
      _state: state,
      fields: CATEGORY_FIELDS,
    });
    setState({ ..._state });

    if (!isValid) {
      isValidData = false;
    }

    return isValidData;
  };

  const onChange = ({ _key, value }) => {
    setState((_state) => ({
      ...state,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
      [_key]: value,
    }));
  };

  const onSuccess = () => {
    successToast("Category created successfully!");
    loadCategoryList();
    closeModal();
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    let payload = {
      categoryLanguage: 1,
    };

    CATEGORY_FIELDS.forEach(({ _key }) => {
      if (state[_key] && typeof state[_key] === "string") {
        payload[_key] = state[_key].trim();
      }
    });

    createCategoryRequest({
      payload,
      onSuccess: onSuccess,
    });
  };

  return (
    <ModalWrapper
      footerButtons={[
        {
          title: "Create",
          onClick: onSubmitPress,
        },
      ]}
      title={"Create New Category"}
      closeModal={closeModal}
      sx={{ width: "50%" }}
      childSx={{ height: "50vh", p: 2 }}
    >
      <div
        style={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <Grid container rowSpacing={2} spacing={1}>
          {CATEGORY_FIELDS.map((category) => {
            const { _key } = category;

            if (!_key) {
              return null;
            }

            return (
              !category.showOnlyOnUpdate && (
                <Grid key={_key} item md={12} sm={12} xs={12}>
                  <CustomField
                    color={"primary"}
                    item={category}
                    errorText={state[getErrorText(_key)]}
                    error={state[getErrorKey(_key)]}
                    value={state[_key]}
                    onChange={onChange}
                  />
                </Grid>
              )
            );
          })}
        </Grid>
      </div>
    </ModalWrapper>
  );
};
