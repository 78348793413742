import { cloneDeep } from "lodash";
import React, { useState } from "react";
import {
  CustomField,
  getErrorKey,
  getErrorText,
  INPUT_TYPES,
} from "components";
import ModalWrapper from "components/ModalWrapper";
import { Grid } from "@mui/material";
import { getPageData } from "../utils";
import { validateFields } from "utils";

export const AddLanguageModal = ({
  unavailableLanguages,
  closeModal,
  pageType,
  version,
  appType,
}) => {
  const [state, setState] = useState(cloneDeep({}));
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const { customFields } = getPageData({ pageType, version });

  const validateData = () => {
    const { _state, isValid } = validateFields({
      _state: state,
      fields: customFields,
    });
    setState({ ..._state });
    return isValid;
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    const caller = getPageData({ pageType }).addLanguageRequest;

    if (caller) {
      caller({
        data: state,
        languageId: selectedLanguage + "",
        version,
        appType,
        onSuccess: closeModal,
      });
    }
  };

  const onChange = ({ value, _key }) => {
    setState((_state) => ({
      ..._state,
      [_key]: value,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
    }));
  };

  return (
    <ModalWrapper
      footerButtons={[{ title: "Create", onClick: onSubmitPress }]}
      closeModal={closeModal}
      title="Add New Language"
      sx={{ width: "50%" }}
      childSx={{ height: "50vh", p: 2 }}
    >
      <div style={{ marginBottom: 16 }}>
        <CustomField
          value={selectedLanguage}
          error={!selectedLanguage}
          onChange={({ value }) => setSelectedLanguage(value)}
          item={{
            inputType: INPUT_TYPES.DROPDOWN,
            placeholder: "Select Language",
            extraData: unavailableLanguages,
          }}
        />
      </div>
      {!!selectedLanguage && (
        <Grid container rowSpacing={2} spacing={1}>
          {customFields.map((category) => {
            const { _key } = category;

            if (!_key) return null;

            return (
              !category.showOnlyOnUpdate && (
                <Grid key={_key} item md={12} sm={12} xs={12}>
                  <CustomField
                    color={"primary"}
                    item={category}
                    itemData={state}
                    errorText={state[getErrorText(_key)]}
                    error={state[getErrorKey(_key)]}
                    value={state[_key]}
                    onChange={onChange}
                  />
                </Grid>
              )
            );
          })}
        </Grid>
      )}
    </ModalWrapper>
  );
};
