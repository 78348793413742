import { Edit, Visibility } from "@mui/icons-material";
import { IconButton, Switch } from "@mui/material";
import { INPUT_TYPES } from "components";
import moment from "moment/moment";
import { TRUE_FALSE } from "screens/Services/ServiceDetails/ServiceNode/constants";
import { COUNTRIES } from "shared/constants";

const ALL = "ALL";

export const BANNER_FIELDS = [
  {
    _key: "name",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Name",
  },
  {
    _key: "validFrom",
    inputType: INPUT_TYPES.DATE,
    placeholder: "Valid From",
    overRideValues: { validTill: undefined },
    extraProps: { minDate: new Date() },
  },
  {
    _key: "validTill",
    inputType: INPUT_TYPES.DATE,
    placeholder: "Valid Till",
    extraProps: { minDate: new Date() },
  },
  {
    _key: "country",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Country",
    extraData: COUNTRIES,
    overRideValues: { state: ALL },
  },
  {
    _key: "state",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "State",
    dependentKey: "country",
    hideForParentValue: ALL,
    dataKey: "states",
    isOptional: true,
    overRideValues: { district: ALL },
  },
  {
    _key: "district",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "District",
    dependentKey: "state",
    dataKey: "districts",
    hideForParentValue: ALL,
    isOptional: true,
  },
  {
    _key: "categoryId",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Category",
    dataKey: "categories",
    overRideValues: { serviceId: ALL, couponId: "" },
  },
  {
    _key: "serviceId",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Service",
    dataKey: "services",
    dependentKey: "categoryId",
    isOptional: true,
    hideForParentValue: ALL,
    overRideValues: { couponId: "" },
  },
  {
    _key: "couponId",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Coupon",
    dataKey: "coupons",
    dependentKey: "serviceId",
    isOptional: true,
    hideForParentValue: ALL,
  },
];

export const BANNER_FIELDS_LANG_SPECIFIC = [
  {
    _key: "bannerUrl",
    inputType: INPUT_TYPES.NORMAL_FILE,
    placeholder: "Upload Banner",
    extraProps: {
      inputProps: {
        accept: "image/*",
      },
    },
  },
];

export const BANNER_FILTER_FIELDS = [
  {
    _key: "isActive",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Active",
    extraData: TRUE_FALSE,
  },
  {
    _key: "categoryId",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Category",
    dataKey: "categories",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "serviceId",
    placeholder: "Service",
    dataKey: "services",
    deleteValues: { serviceLabel: "" },
    dependentKey: "categoryId",
    hideForParentValue: "ALL",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "country",
    placeholder: "Country",
    extraData: COUNTRIES,
    overRideValues: { state: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "state",
    placeholder: "State",
    dataKey: "states",
    dependentKey: "country",
    hideForParentValue: "ALL",
    overRideValues: { district: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "district",
    placeholder: "District",
    dataKey: "districts",
    dependentKey: "state",
    hideForParentValue: "ALL",
    overRideValues: { pincode: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "pincode",
    placeholder: "Pincode",
    dataKey: "pincodes",
    dependentKey: "district",
    hideForParentValue: "ALL",
  },
];

export const getBannersColumns = ({
  onViewPress,
  onEditPress,
  handleStatusChange,
}) => [
  { field: "id", headerName: "ID", width: 90, hide: true },
  {
    field: "index",
    headerName: "S.No.",
    width: 100,
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    renderCell: ({ row }) => row.name || "-",
  },
  {
    field: "validFrom",
    headerName: "Valid From",
    width: 120,
    renderCell: ({ row }) =>
      moment(new Date(row.validFrom)).format("DD MMM YYYY"),
  },
  {
    field: "validTill",
    headerName: "Valid Till",
    width: 120,
    renderCell: ({ row }) =>
      moment(new Date(row.validTill)).format("DD MMM YYYY"),
  },
  {
    field: "isActive",
    headerName: "Active",
    width: 100,
    renderCell: ({ row }) => {
      const onToggle = (e) => {
        e.stopPropagation();
        handleStatusChange(row);
      };
      return (
        <IconButton onClick={onToggle}>
          <Switch checked={row.isActive} color="primary" />
        </IconButton>
      );
    },
  },
  {
    field: "view",
    headerName: "View",
    width: 100,
    renderCell: ({ row }) => {
      const onView = (e) => {
        e.stopPropagation();
        onViewPress(row);
      };
      return (
        <IconButton onClick={onView}>
          <Visibility color="primary" />
        </IconButton>
      );
    },
  },
  {
    field: "edit",
    headerName: "Edit",
    width: 100,
    renderCell: ({ row }) => {
      const onEdit = (e) => {
        e.stopPropagation();
        onEditPress(row);
      };
      return (
        <IconButton onClick={onEdit}>
          <Edit color="primary" />
        </IconButton>
      );
    },
  },
];
