import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import React from "react";
import { ImageUploader } from "screens/Services/components/ImageUploader";
import CustomDatePicker from "./CustomDatePicker";
import CustomTimePicker from "./CustomTimePicker";
import FilterOptions from "./FilterOptions";
import PasswordField from "./PasswordField";
import CustomDateTimePicker from "./CustomDateTimePicker";

export const REGEX = {
  NUMBERS: /^\d+$/,
  PHONE: /^[6-9]\d{9}$/,
  EMAIL:
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  DECIMALS: /^\d+(\.\d{1,2})?$/,
};

export const INPUT_TYPES = {
  DATE: "DATE",
  DATE_TIME: "DATE_TIME",
  TIME: "TIME",
  DROPDOWN: "DROPDOWN",
  FILE: "FILE",
  FILTER_OPTIONS: "FILTER_OPTIONS",
  INPUT: "INPUT",
  PASSWORD: "PASSWORD",
  TEXT_AREA: "TEXT_AREA",
  NORMAL_FILE: "NORMAL_FILE",
};

export const getErrorKey = (_key) => `er_${_key}`;
export const getErrorText = (_key) => `er_txt_${_key}`;

export const CustomField = ({
  item = {},
  error,
  errorText,
  color = "primary",
  hardDisable,
  disabled: isDisable,
  value,
  itemData,
  onChange,
}) => {
  const {
    placeholder: _placeholder,
    isOptional,
    _key,
    shouldBeSame,
    inputType,
    overRideValues = {},
    maxLength,
    extraProps = {},
    extraData = [],
    isSingle,
  } = item;

  const disabled = isDisable ? isDisable : hardDisable ? shouldBeSame : false;
  const onChangeValue = ({ target }) => {
    onChange({
      overRideValues,
      maxLength,
      value: target.value,
      _key,
      target,
    });
  };
  const placeholder = _placeholder && _placeholder + (isOptional ? "" : "*");
  switch (inputType) {
    case INPUT_TYPES.INPUT:
      return (
        <TextField
          fullWidth
          color={color}
          size="small"
          error={error}
          helperText={errorText}
          label={placeholder}
          name={_key}
          disabled={disabled}
          variant="outlined"
          value={value ?? ""}
          onChange={onChangeValue}
          {...extraProps}
        />
      );
    case INPUT_TYPES.NORMAL_FILE:
      return (
        <TextField
          fullWidth
          color={color}
          type={"file"}
          InputLabelProps={{ shrink: true }}
          size="small"
          error={error}
          helperText={errorText}
          label={placeholder}
          name={_key}
          disabled={disabled}
          variant="outlined"
          value={value ?? ""}
          onChange={onChangeValue}
          {...extraProps}
        />
      );
    case INPUT_TYPES.FILE:
      return (
        <ImageUploader
          _key={_key}
          color={color}
          data={itemData}
          placeholder={placeholder}
          {...extraProps}
        />
      );

    case INPUT_TYPES.TIME:
      return (
        <CustomTimePicker
          color={color}
          disabled={disabled}
          error={error}
          _key={_key}
          errorText={errorText}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          extraProps={extraProps}
        />
      );

    case INPUT_TYPES.DATE:
      return (
        <CustomDatePicker
          color={color}
          disabled={disabled}
          error={error}
          _key={_key}
          errorText={errorText}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          extraProps={extraProps}
        />
      );

    case INPUT_TYPES.DATE_TIME:
      return (
        <CustomDateTimePicker
          color={color}
          disabled={disabled}
          error={error}
          _key={_key}
          errorText={errorText}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          extraProps={extraProps}
        />
      );

    case INPUT_TYPES.PASSWORD:
      return (
        <PasswordField
          color={color}
          disabled={disabled}
          error={error}
          _key={_key}
          errorText={errorText}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          extraProps={extraProps}
        />
      );
    case INPUT_TYPES.FILTER_OPTIONS:
      return (
        <FilterOptions
          color={color}
          disabled={disabled}
          error={error}
          _key={_key}
          errorText={errorText}
          overRideValues={overRideValues}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          extraData={extraData}
          extraProps={extraProps}
          isSingle={isSingle}
        />
      );

    case INPUT_TYPES.DROPDOWN:
      return (
        <FormControl
          disabled={disabled}
          error={error}
          color={color}
          fullWidth
          {...extraProps}
        >
          <InputLabel size="small" id={_key}>
            {placeholder}
          </InputLabel>
          <Select
            labelId={_key}
            size="small"
            disabled={disabled}
            name={_key}
            value={value ?? ""}
            label={placeholder}
            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
            onChange={onChangeValue}
          >
            {extraData?.map((_item, index) => (
              <MenuItem key={index} value={_item.value || _item}>
                {_item?.label || _item}
              </MenuItem>
            ))}
          </Select>
          {!!errorText && (
            <FormHelperText error={error}>{errorText}</FormHelperText>
          )}
        </FormControl>
      );

    case INPUT_TYPES.TEXT_AREA:
      return (
        <TextField
          fullWidth
          size="small"
          multiline={!disabled}
          minRows={1}
          maxRows={10}
          error={error}
          color={color}
          helperText={errorText}
          label={placeholder}
          name={_key}
          variant="outlined"
          value={value ?? ""}
          disabled={disabled}
          onChange={({ target }) => onChange({ value: target.value, _key })}
          {...extraProps}
        />
      );

    default:
      return null;
  }
};
