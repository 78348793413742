import React, { useEffect, useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MuiDrawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  useMediaQuery,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import CustomAppBar from "./CustomAppBar";
import Logo from "../../assets/image/logo.png";

import { useSelector } from "react-redux";
import { DATA } from "./constants";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `0px`,

  [theme.breakpoints.up("sm")]: { width: `0px` },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const getBasicStyle = ({ isActive, ...rest } = {}) => {
  return {
    display: "flex",
    px: 2.5,
    color: isActive ? "#1b5e20" : "#064e3b",
    background: isActive ? "#a5d6a7" : "",
    borderRadius: "5px",
    textDecoration: "none !important",
    margin: "0px 2px",
    ...rest,
  };
};

const RenderListItem = ({
  onClick = () => {},
  sx,
  data: { rightIcon, title, icon },
}) => (
  <ListItemButton sx={sx} key={title} onClick={onClick}>
    {!!icon && (
      <ListItemIcon sx={{ minWidth: 0, mr: 3, justifyContent: "center" }}>
        {icon}
      </ListItemIcon>
    )}
    <ListItemText sx={{ textWrap: "wrap" }} primary={title} />
    {!!rightIcon && (
      <ListItemIcon sx={{ minWidth: 0, ml: 3, justifyContent: "center" }}>
        {rightIcon}
      </ListItemIcon>
    )}
  </ListItemButton>
);

const RenderNavLink = ({ closeDrawer, sx, data = {} }) => (
  <NavLink
    end
    style={({ isActive }) => getBasicStyle({ isActive, ...sx })}
    className="navlink-decoration"
    to={data.href}
    onClick={closeDrawer}
  >
    <RenderListItem data={data} />
  </NavLink>
);

const RenderItem = ({ closeDrawer, sx, data = {} }) => {
  const [showChildItems, toggleChildItems] = useState(false);
  const userRole = useSelector(({ common }) => common.userData?.userRole);
  const userPrivilege = useSelector(
    ({ common }) => common.userData?.authorityType
  );

  if (data.checkPrivilege && !data.checkPrivilege[userPrivilege]) {
    return null;
  }

  if (data.enabled && !data.enabled[userRole]) {
    return null;
  }

  if (data.href) {
    return <RenderNavLink closeDrawer={closeDrawer} sx={sx} data={data} />;
  }
  if (data.child?.length) {
    return (
      <div>
        <div style={getBasicStyle({ ...sx })}>
          <RenderListItem
            onClick={() => toggleChildItems(!showChildItems)}
            data={{
              rightIcon: showChildItems ? <ExpandLess /> : <ExpandMore />,
              title: data.title,
              icon: data.icon,
            }}
          />
        </div>
        <Collapse sx={sx} in={showChildItems} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {data.child.map((item, index) => (
              <RenderItem
                sx={{ paddingLeft: "16px" }}
                data={item}
                closeDrawer={closeDrawer}
                key={`${item.title}---${index}`}
              />
            ))}
          </List>
        </Collapse>
      </div>
    );
  }
  return null;
};

export default function CustomDrawer() {
  const [open, setOpen] = useState(true);
  const theme2 = useTheme();
  const SizeLessThenMd = useMediaQuery(theme2.breakpoints.down("md"));

  useEffect(() => {
    setOpen(!SizeLessThenMd);
  }, [SizeLessThenMd]);

  const closeDrawer = () => SizeLessThenMd && setOpen(false);
  return (
    <div style={{ height: "100%" }}>
      <CustomAppBar open={open} toggleDrawer={() => setOpen(!open)} />
      {SizeLessThenMd ? (
        <SwipeableDrawer
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          <img
            src={Logo}
            alt=""
            style={{ width: "50%", height: "auto", margin: "0px auto" }}
          />
          {DATA.map((item, index) => (
            <RenderItem
              closeDrawer={closeDrawer}
              data={item}
              key={`${item.title}---${index}`}
            />
          ))}
        </SwipeableDrawer>
      ) : (
        <Drawer
          sx={{
            height: "100%",
            background: "white",
            zIndex: "1111",
          }}
          variant="permanent"
          open={open}
        >
          <img
            src={Logo}
            alt=""
            style={{ width: "50%", height: "auto", margin: "0px auto" }}
          />
          {DATA.map((item, index) => (
            <RenderItem
              closeDrawer={closeDrawer}
              data={item}
              key={`${item.title}---${index}`}
            />
          ))}
        </Drawer>
      )}
    </div>
  );
}
