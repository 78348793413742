import { INPUT_TYPES } from "components";
import { customSortComparatorForNums } from "utils";
import { Edit, Visibility } from "@mui/icons-material";
import { IconButton, Switch } from "@mui/material";

export const APPLIANCE_FIELDS = [
  {
    _key: "languageId",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Language",
  },
  {
    _key: "appliance",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Appliance",
  },
];

export const CATEGORY_FIELDS = [
  {
    _key: "name",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Category Name",
  },
];

export const SPARE_PART_FIELDS = [
  {
    _key: "name",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Spare Part Name",
  },
  {
    _key: "charges",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Spare Part Charge",
  },
];

export const getApplianceColumns = ({ onViewPress, onEditPress }) => {
  return [
    {
      field: "id",
      headerName: "Id",
      width: 60,
      hide: true,
    },
    {
      field: "index",
      headerName: "S. No.",
      width: 80,
      sortComparator: customSortComparatorForNums,
    },
    {
      field: "appliance",
      headerName: "Appliance Name",
      width: 250,
    },
    {
      field: "view",
      headerName: "View",
      width: 80,
      renderCell: ({ row }) => {
        const onView = (e) => {
          e?.stopPropagation?.();
          onViewPress(row);
        };

        return (
          <IconButton onClick={onView}>
            <Visibility color="primary" />
          </IconButton>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 80,
      renderCell: ({ row }) => {
        const onEdit = (e) => {
          e?.stopPropagation?.();
          onEditPress(row);
        };

        return (
          <IconButton onClick={onEdit}>
            <Edit color="primary" />
          </IconButton>
        );
      },
    },
  ];
};
export const getSparePartCategoryColumns = () => {
  return [
    {
      field: "id",
      headerName: "Id",
      width: 60,
      hide: true,
    },
    {
      field: "index",
      headerName: "S. No.",
      width: 80,
      sortComparator: customSortComparatorForNums,
    },
    {
      field: "name",
      headerName: "Spare Part",
      width: 250,
    },
    {
      field: "charges",
      headerName: "Charge",
      width: 180,
    },
  ];
};
