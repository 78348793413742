import { Button, Typography } from "@mui/material";
import { CustomField, INPUT_TYPES } from "components";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function RenderLanguageSpecificData({ data }) {
  const navigate = useNavigate();
  if (!data) {
    return null;
  }
  const { details, fillers = [] } = data;

  if (details.filter((item) => !item.title).length) {
    const onPressAddNow = () => {
      navigate(`/template/edit/${data[0]?.templateId || ""}`);
    };
    return (
      <div
        style={{
          padding: "24px",
          margin: "24px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          backgroundColor: "#e3c6c6",
        }}
      >
        <Typography color="error">
          Please add the translation of all the languages before using this
          template
        </Typography>
        <Button
          sx={{ marginTop: "12px" }}
          size="small"
          variant="contained"
          color="error"
          onClick={onPressAddNow}
        >
          Add Now
        </Button>
      </div>
    );
  }

  return (
    <div>
      {details.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              padding: "16px",
              borderRadius: "8px",
              margin: "16px",
              border: "1px solid",
            }}
          >
            <Typography variant="h6">{item.languageName}</Typography>
            {fillers.map((filler, _ind) => (
              <div
                style={{
                  display: "flex",
                  marginTop: "8px",
                  alignItems: "center",
                }}
                key={_ind}
              >
                <label style={{ minWidth: "200px" }}>{`${filler.key}`}</label>
                <CustomField
                  item={{
                    _key: "value",
                    inputType: INPUT_TYPES.INPUT,
                    placeholder: "Filler value",
                    extraProps: { sx: { marginLeft: "16px" } },
                  }}
                />
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}
