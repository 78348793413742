import { Edit } from "@mui/icons-material";
import { IconButton, Switch } from "@mui/material";
import { INPUT_TYPES } from "components";

const APP_TYPES = ["provider", "customer", "both"];
export const REASON_TYPES = {
  cancellation: "cancellation",
  reschedule: "reschedule",
  suspend: "suspend",
  all: "all",
};

export const REASON_FIELDS = [
  {
    md: 4,
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "serviceId",
    placeholder: "Service",
    dataKey: "services",
    extraProps: { sx: { marginBottom: "16px" } },
  },
  {
    md: 4,
    _key: "reasonApp",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "App Type",
    extraData: APP_TYPES,
    extraProps: { sx: { marginBottom: "16px" } },
  },
  {
    md: 4,
    _key: "type",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Reason Type",
    extraData: Object.values(REASON_TYPES),
    extraProps: { sx: { marginBottom: "16px" } },
  },
];
export const REASONS_FILTER = [
  {
    _key: "reasonApp",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "App Type",
    extraData: APP_TYPES,
  },
  {
    _key: "type",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Reason Type",
    extraData: Object.values(REASON_TYPES),
  },
];

export const getReasonsColumns = ({ onEditPress, handleStatusChange }) => [
  { field: "id", headerName: "ID", hide: true },
  {
    field: "index",
    headerName: "S. No.",
    width: 80,
  },
  {
    field: "reason",
    headerName: "Title",
    width: 200,
  },
  {
    field: "reasonApp",
    headerName: "App Type",
    width: 100,
  },
  {
    field: "type",
    headerName: "Reason Type",
    width: 150,
  },
  // {
  //   field: "service",
  //   headerName: "Service",
  //   width: 144,
  //   renderCell: ({ row }) => row.service?.name || "-",
  // },
  {
    field: "isActive",
    headerName: "Active",
    width: 100,
    renderCell: ({ row }) => {
      const onToggle = (e) => {
        e.stopPropagation();
        handleStatusChange(row);
      };
      return (
        <IconButton onClick={onToggle}>
          <Switch checked={row.isActive} color="primary" />
        </IconButton>
      );
    },
  },
  {
    field: "edit",
    headerName: "Edit",
    width: 100,
    renderCell: ({ row }) => {
      const onEdit = (e) => {
        e.stopPropagation();
        onEditPress(row);
      };
      return (
        <IconButton onClick={onEdit}>
          <Edit color="primary" />
        </IconButton>
      );
    },
  },
];
