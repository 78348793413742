import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { CHARGE_SERVICES } from "utils";

export const getChargesForServiceRequest = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  CHARGE_SERVICES.FETCH_CHARGES_BY_SERVICE_ID(query)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const addServiceChargeConfigRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  CHARGE_SERVICES.CREATE_SERVICE_CHARGE_CONFIG(payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateServiceChargeConfigRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  CHARGE_SERVICES.UPDATE_SERVICE_CHARGE_CONFIG(payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
