import React, { useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Typography } from "@mui/material";
import { getEnabledLocationsTreeRequest } from "../ServiceDetails/ServiceLocation/utils";
import RenderNodeItems from "../ServiceDetails/ServiceLocation/components/RenderNodeItems";

const LeftIcons = ({ active, color }) => {
  if (active) {
    return <IndeterminateCheckBoxIcon color={color} />;
  }
  return <AddBoxIcon color={color} />;
};

export const Item = ({ data = {}, isPinCode }) => {
  const [showChildrenNodes, toggleChildrenNodes] = useState(false);
  const [localities, setLocalities] = useState();
  const { serviceId, name } = data;
  const color = "primary";

  const getLocalities = () => {
    getEnabledLocationsTreeRequest({ id: serviceId, onSuccess: setLocalities });
  };

  return (
    <div
      style={{
        marginLeft: 15,
      }}
    >
      <div
        style={{
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
          width: "100%",
          padding: `8px 4px`,
          marginBottom: "8px",
          position: "relative",
          background: "#f0fdf4",
        }}
        onClick={() => {
          if (!showChildrenNodes) getLocalities();
          toggleChildrenNodes(!showChildrenNodes);
        }}
      >
        <LeftIcons color={color} active={showChildrenNodes} />

        <div
          style={{
            width: "100%",
            paddingLeft: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: "500" }} color={color}>
            {name}
          </Typography>
        </div>
      </div>
      {!!(showChildrenNodes && localities) &&
        (localities.length ? (
          <div style={{ display: "flex", width: "100%" }}>
            {showChildrenNodes && (
              <div
                style={{
                  borderLeft: "1.5px dashed grey",
                  marginLeft: "13px",
                  marginTop: "-8px",
                }}
              />
            )}

            {showChildrenNodes && (
              <div style={{ width: "100%" }}>
                {localities?.map((item, _index) => {
                  return (
                    <RenderNodeItems
                      index={_index}
                      data={item}
                      key={item._id}
                    />
                  );
                })}
              </div>
            )}
          </div>
        ) : (
          <Typography
            sx={{
              fontWeight: "500",
              marginBottom: "8px",
              marginLeft: "24px",
              backgroundColor: "#fecdd3",
              padding: "8px",
              borderRadius: "4px",
            }}
            fontSize={12}
            color={"error"}
          >
            Not currently serviceable in your area
          </Typography>
        ))}
    </div>
  );
};

export default Item;
