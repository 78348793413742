import React, { useCallback, useEffect, useState } from "react";
import { fetchChargeTypeRequest } from "./utils";
import { Box, Card, CardContent } from "@mui/material";
import Header from "components/HeaderV2";
import { queryString, useGetLanguageData } from "utils";
import CustomDataGrid from "components/DataGrid";
import { getChargesListColumns } from "./constants";
import { AddEditChargeTypeModal } from "./components/AddEditChargeTypeModal";

export default function ChargesList() {
  const [data, setData] = useState([]);

  const { allLanguages, languagesKeyValPair } = useGetLanguageData({
    data,
  });
  const selectedLanguage = 1;
  const [modalData, setModalData] = useState(null);

  const closeModal = () => setModalData(null);

  const onAddPress = () => setModalData({ data: null });

  const onEditPress = (row) => {
    setModalData({
      data: data.filter(
        ({ chargeTypeId }) => row.chargeTypeId === chargeTypeId
      ),
    });
  };

  const fetchChargeTypes = useCallback(() => {
    fetchChargeTypeRequest({
      query: queryString({}),
      onSuccess: (data) =>
        setData(
          data.map((item) => ({
            ...item,
            id: item._id,
            languageName: languagesKeyValPair[item.languageId],
          }))
        ),
    });
  }, []);

  const languageData = data
    .filter((item) => item.languageId === selectedLanguage)
    .map((item, index) => ({
      ...item,
      index: index + 1,
    }));

  const onSuccessCallback = () => {
    fetchChargeTypes();
    closeModal();
  };

  useEffect(() => {
    fetchChargeTypes();
  }, [fetchChargeTypes]);

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header
            title={"Charges Type"}
            buttons={[
              {
                title: "Add Charge Type",
                onClick: onAddPress,
              },
            ]}
          />

          <CustomDataGrid
            rows={languageData}
            columns={getChargesListColumns({ onEditPress })}
          />

          {!!modalData && (
            <AddEditChargeTypeModal
              data={modalData?.data}
              languages={allLanguages}
              onSuccess={onSuccessCallback}
              closeModal={closeModal}
            />
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
