import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/image/logo.png";
import { Box, Paper, Button, Typography, Grid } from "@mui/material";
import { useState } from "react";
import { loginRequest } from "./utils";
import PublicWrapper from "components/PublicWrapper";
import { CustomField, getErrorKey, getErrorText } from "components";
import { LOGIN_FIELDS } from "./constants";
import { validateFields } from "utils";

export const Login = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const handleInputChange = ({ _key, value }) => {
    setData({ ...data, [_key]: value });
  };

  const onLogin = () => {
    loginRequest({
      payload: data,
      onSuccess: () => navigate("/", { replace: true }),
    });
  };

  const validateData = () => {
    const { isValid, _state } = validateFields({
      _state: data,
      fields: LOGIN_FIELDS,
    });

    setData({ ..._state });
    return { isValid };
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const { isValid } = validateData();
    if (!isValid) return;

    onLogin();
  };

  return (
    <PublicWrapper>
      <Typography
        component={"div"}
        sx={{ display: { xs: "flex", sm: "none" }, alignItems: "center", p: 2 }}
      >
        <Typography
          component={"img"}
          alt=""
          sx={{ width: { xs: "150px", sm: "200px" } }}
          src={logo}
        />
      </Typography>
      <Typography
        component={"form"}
        onSubmit={onSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: { xs: "80vh", sm: "100vh" },
          justifyContent: "center",
          backgroundColor: { xs: "white", sm: "#c8e6c9" },
        }}
      >
        <Box className="login-card">
          <Typography
            variant="h4"
            sx={{
              mb: 2,
              fontSize: { xs: "30px", sm: "40px" },
              fontWeight: { xs: 500, sm: "bold" },
              color: "#388e3c",
            }}
            component="h1"
          >
            Log in
          </Typography>
          <Paper
            sx={{
              height: "100%",
              alignItems: "center",
              display: "flex",
              boxShadow: {
                xs: "none",
                sm: "0px 0px 3px rgb(0 0 0 / 70%)",
              },
            }}
          >
            <Grid container sx={{ p: { sm: "8px", md: "24px" } }}>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <img
                  alt="logo"
                  style={{ width: "200px", margin: "auto" }}
                  src={logo}
                />
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {LOGIN_FIELDS.map((item) => {
                  const { _key } = item;

                  if (!_key) return null;

                  return (
                    <Typography
                      component={"div"}
                      key={_key}
                      sx={{ my: { xs: 1.5, sm: 1 } }}
                    >
                      <CustomField
                        item={item}
                        value={data[_key]}
                        onChange={handleInputChange}
                        error={data[getErrorKey(_key)]}
                        errorText={data[getErrorText(_key)]}
                      />
                    </Typography>
                  );
                })}
                <Button
                  sx={{ mb: { xs: 1.5, sm: 1 }, mt: 4 }}
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Typography>
    </PublicWrapper>
  );
};
