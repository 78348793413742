import React, { useState } from "react";
import ModalWrapper from "components/ModalWrapper";
import { CustomField, getErrorKey, getErrorText } from "components";
import { Grid } from "@mui/material";
import { validateFields } from "utils";
import { PAYORDER_FIELDS } from "../constants";
import { createPayOrderRequest } from "../utils";
import moment from "moment/moment";

export const CreatePayOrderModal = ({
  data,
  onSuccessCallback = () => {},
  closeModal,
}) => {
  const [state, setState] = useState(data || {});

  const validateData = () => {
    const { isValid, _state } = validateFields({
      _state: state,
      fields: PAYORDER_FIELDS.slice(0, 7),
    });

    setState({ ..._state });

    return { isValid };
  };

  const onChange = ({ _key, value }) => {
    setState((_state) => ({ ..._state, [_key]: value }));
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    // "providerId":"63e3ad17fad4a3904a0d5077",
    // "paid":true,
    // "description":"test ",
    // "orderType":"Commission",
    // "dateFrom":"2024-03-24",
    // "dateTo":"2024-03-26",
    // "languageId":1,
    // "amount":200,
    // "jobs":[{"jobId":"63e3ad17fad4a3904a0d5077"}]

    const payload = {
      providerId: state.providerId,
      jobs: state.jobs.map((jobId) => ({ jobId })),
      description: state.description,
      orderType: state.orderType,
      amount: +state.amount,
      dateFrom: moment(state.dateFrom).format().split("T")[0],
      dateTo: moment(state.dateTo).format().split("T")[0],
    };

    createPayOrderRequest({
      payload,
      onSuccess: () => {
        onSuccessCallback();
      },
    });
    closeModal();
  };

  return (
    <ModalWrapper
      footerButtons={[
        {
          title: "Submit",
          onClick: onSubmitPress,
        },
      ]}
      title={`Create Payorder`}
      closeModal={closeModal}
      sx={{ width: "60%" }}
      childSx={{ height: "60vh", p: 2, overflow: "auto" }}
    >
      <div
        style={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <Grid container rowSpacing={2} spacing={1}>
          {PAYORDER_FIELDS.slice(0, 7).map((item) => {
            const { _key, dependentKey } = item;

            if (!_key) {
              return null;
            }

            if (
              dependentKey &&
              (!state[dependentKey] || state[dependentKey] === "false")
            ) {
              return null;
            }

            return (
              <Grid key={_key} item md={6} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={item}
                  disabled={item.disabled}
                  errorText={state[getErrorText(_key)]}
                  error={state[getErrorKey(_key)]}
                  value={state[_key]}
                  onChange={onChange}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </ModalWrapper>
  );
};
