import { Typography, Grid, IconButton } from "@mui/material";
import { CustomField } from "components";
import { getErrorKey, getErrorText } from "utils";
import { SPARE_PART_FIELDS } from "../constants";
import Delete from "@mui/icons-material/Delete";

export const RenderSpareParts = ({
  hideActions,
  spareParts,
  applianceIndex,
  catIndex,
  setApplianceData,
}) => {
  const onChange = ({ _key, value, index }) => {
    setApplianceData((_state) => {
      _state[applianceIndex].categories[catIndex].spareParts[index][_key] =
        value;
      delete _state[applianceIndex].categories[catIndex].spareParts[index][
        getErrorKey(_key)
      ];
      delete _state[applianceIndex].categories[catIndex].spareParts[index][
        getErrorText(_key)
      ];

      return [..._state];
    });
  };

  const onDeletePress = ({ index }) => {
    setApplianceData((_state) => {
      return [
        ..._state.map((applianceItem) => {
          return {
            ...applianceItem,
            categories: [
              ...applianceItem.categories.map((categoryItem) => {
                categoryItem.spareParts.splice(index, 1);

                return categoryItem;
              }),
            ],
          };
        }),
      ];
    });
  };

  return spareParts?.map((sparePart, index) => {
    return (
      <Typography key={index} component={"div"}>
        {/* <Header
          skip
          title={`Spare Part ${index + 1}`}
          titleStyle={{ fontSize: "15px" }}
        /> */}

        <Grid
          container
          rowSpacing={1}
          columnGap={1}
          sx={{
            mt: index === 0 && 2,
            display: "flex",
            alignItems: "center",
            borderTop: index === 0 && "1px solid gray",
          }}
        >
          {SPARE_PART_FIELDS.map((item) => {
            const { _key, md } = item;

            if (!_key) return null;

            return (
              <Grid key={_key} item md={md ? md : 5.5} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={item}
                  itemData={sparePart}
                  errorText={sparePart[getErrorText(_key)]}
                  error={sparePart[getErrorKey(_key)]}
                  value={sparePart[_key]}
                  onChange={({ _key, value }) =>
                    onChange({ _key, value, index })
                  }
                />
              </Grid>
            );
          })}

          {!hideActions && spareParts.length > 1 && (
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "flex-end",
                // paddingTop: "16px",
              }}
            >
              <IconButton onClick={() => onDeletePress({ index })}>
                <Delete color="error" />
              </IconButton>
            </div>
          )}
        </Grid>
      </Typography>
    );
  });
};
