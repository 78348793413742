import React, { useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { RenderNodeData } from "../ServiceNode/components/RenderNodeData";
import { Typography } from "@mui/material";
import { NODE_FIELDS } from "./constants";
import {
  CustomField,
  getErrorKey,
  getErrorText,
  INPUT_TYPES,
} from "../../../../components/CustomField";

const LeftIcons = ({ active, color }) => {
  if (active) {
    return <IndeterminateCheckBoxIcon color={color} />;
  }
  return <AddBoxIcon color={color} />;
};

export const RenderNodeItems = ({
  itemsLength,
  index,
  disabled,
  serviceId,
  hardDisable,
  availableLanguages,
  onUpdateData,
  ignoreMargin,
  isNode,
  data = {},
}) => {
  const [showChildrenNodes, toggleChildrenNodes] = useState(
    isNode ? false : true
  );
  const { isActive = false, title, children = [], items = [] } = data;

  const onChange = ({ value, _key }) => {
    if (!onUpdateData) {
      return;
    }
    onUpdateData(
      {
        ...data,
        [_key]: value,
        [getErrorKey(_key)]: false,
        [getErrorText(_key)]: "",
      },
      index
    );
  };

  const onUpdateChild = (childData, _ind) => {
    if (!onUpdateData) {
      return;
    }
    if (data.children?.length) {
      data.children[_ind] = childData;
    }
    if (data.items?.length) {
      data.items[_ind] = childData;
    }
    onUpdateData({ ...data }, index);
  };

  const color = isNode ? "primary" : "secondary";
  const innerItems = children.length ? children : items;
  const hasInnerItems = !!innerItems.length;

  return (
    <div
      style={{
        opacity: isActive || !disabled ? 1 : 0.5,
        marginLeft: ignoreMargin ? 0 : 15,
      }}
    >
      <div
        style={{
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
          width: "100%",
          padding: `${disabled ? 0 : 8}px 4px`,
          marginBottom: "8px",
          position: "relative",
          background: isNode ? "#f0fdf4" : "#eff6ff",
        }}
        onClick={() => toggleChildrenNodes(!showChildrenNodes)}
      >
        {hasInnerItems && (
          <LeftIcons color={color} active={showChildrenNodes} />
        )}
        <div
          style={{
            width: "100%",
            paddingLeft: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: "500" }} color={color}>
            {title}
          </Typography>
          {!isNode && (
            <CustomField
              value={data.value}
              error={data[getErrorKey("value")]}
              errorText={data[getErrorText("value")]}
              onChange={onChange}
              item={{
                extraProps: { sx: { width: "200px" } },
                inputType: INPUT_TYPES.INPUT,
                _key: "value",
                placeholder: "Value",
              }}
            />
          )}
        </div>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        {showChildrenNodes && hasInnerItems && (
          <div
            style={{
              borderLeft: "1.5px dashed grey",
              marginLeft: "13px",
              marginTop: "-8px",
            }}
          />
        )}
        {showChildrenNodes && (
          <div style={{ width: "100%" }}>
            {!!isNode && (
              <RenderNodeData
                disabled={disabled}
                customFields={NODE_FIELDS}
                onChange={onChange}
                isNode={isNode}
                hardDisable={hardDisable}
                data={data}
              />
            )}
            {innerItems.map((item, _index) => {
              return (
                <RenderNodeItems
                  itemsLength={innerItems.length}
                  index={_index}
                  isNode={!isNode}
                  availableLanguages={availableLanguages}
                  hardDisable={hardDisable}
                  onUpdateData={onUpdateChild}
                  serviceId={serviceId}
                  disabled={disabled}
                  data={item}
                  key={item._id}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default RenderNodeItems;
