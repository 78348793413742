import React, { useCallback, useEffect, useState } from "react";
import DataGrid from "../../../../components/DataGrid";
import Header from "../../../../components/HeaderV2";
import { useGetNodesData } from "../hooks/useLanguagesData";
import { fetchServiceNodesData } from "../ServiceNode/utils";
import { AddPriceModal } from "./AddPriceModal";
import { getColumnsData } from "./constants";
import { deleteServicePriceRequest, getServicePriceRequest } from "./utils";

export default function ServicePrice({ serviceId }) {
  const [modalData, toggleModal] = useState(null);
  const [data, setData] = useState([]);
  const closeModal = () => toggleModal(null);
  const onAdPrice = () => toggleModal({});
  const { serviceNodes } = useGetNodesData();

  const getData = useCallback(
    () =>
      getServicePriceRequest({
        id: serviceId,
        onSuccess: (_data) =>
          setData(_data.map((item) => ({ ...item, id: item._id }))),
      }),
    [serviceId]
  );

  useEffect(() => {
    getData();
    fetchServiceNodesData(serviceId);
  }, [getData, serviceId]);
  const nodes = serviceNodes[0]?.nodes || [];

  const onEditPress = (row) => toggleModal({ ...row, isEdit: true });
  const onDeletePress = ({ id }) => {
    deleteServicePriceRequest({ id, onSuccess: getData });
  };

  return (
    <div>
      <Header
        skip
        title="Service Price"
        buttons={[{ onClick: onAdPrice, title: "Add Price" }]}
      />
      {!!modalData && (
        <AddPriceModal
          serviceId={serviceId}
          modalData={modalData}
          data={nodes}
          fetchData={getData}
          closeModal={closeModal}
        />
      )}
      <DataGrid
        rows={data}
        columns={getColumnsData({ onEditPress, onDeletePress })}
      />
    </div>
  );
}
