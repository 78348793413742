import CustomDataGrid from "components/DataGrid";
import { HeaderTitle } from "components/header";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getContactQueriesRequest, getContactUsRequest } from "./utils";
import { queryString } from "utils";
import ContactUsCard from "./components/Card";
import { AccountCircle, AlternateEmail } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { AddEditContactDetails } from "./components/AddEditContactDetails";
import { getQueryColumns } from "./constants";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setScreenTitle } from "redux/actions";

const ContactUs = () => {
  const pathName = window.location.pathname;
  const [userType] = pathName.slice(1, pathName.length).split("/");
  const navigate = useNavigate();
  const [contactUsData, setContactUsData] = useState(null);
  const [contactQueries, setContactQueries] = useState([]);
  const [modaData, setModalData] = useState(null);
  const dispatch = useDispatch();
  const pageTitle = "Contact US";

  const closeModal = () => setModalData(null);

  const fetchContactUsData = useCallback(() => {
    closeModal();
    getContactUsRequest({
      query: queryString({ appType: userType }),
      onSuccess: (data) => setContactUsData(data),
    });
  }, [userType]);

  useEffect(() => {
    dispatch(setScreenTitle(pageTitle));
    fetchContactUsData();
    getContactQueriesRequest({
      query: queryString({ appType: userType }),
      onSuccess: (data) => setContactQueries(data),
    });
  }, [userType, dispatch, fetchContactUsData]);

  return (
    <>
      <HeaderTitle
        heading={pageTitle}
        customCardStyle={{ background: "transparent", boxShadow: "none" }}
        btn={!contactUsData}
        btnTitle={"Add Contact Details"}
        btnAction={() =>
          setModalData({
            isEdit: false,
            appType: userType,
            data: [],
            onSuccess: fetchContactUsData,
          })
        }
      />
      {!!contactUsData && (
        <Typography
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 0, sm: 3 },
          }}
          component={"div"}
        >
          <ContactUsCard
            icon={<AccountCircle sx={{ fontSize: "40px" }} color={"primary"} />}
            content={contactUsData?.mobileNumber}
            btn
            btnAction={() =>
              setModalData({
                isEdit: true,
                data: contactUsData,
                isMobileNumber: true,
                onSuccess: fetchContactUsData,
              })
            }
          />
          <ContactUsCard
            icon={
              <AlternateEmail sx={{ fontSize: "40px" }} color={"primary"} />
            }
            content={contactUsData?.email}
            btn
            btnAction={() =>
              setModalData({
                isEdit: true,
                data: contactUsData,
                isMobileNumber: false,
                onSuccess: fetchContactUsData,
              })
            }
          />
        </Typography>
      )}
      {!!contactQueries?.length && (
        <>
          <HeaderTitle
            heading={"Contact Queries"}
            customCardStyle={{ background: "transparent", boxShadow: "none" }}
          />
          <CustomDataGrid
            rows={contactQueries}
            columns={getQueryColumns({
              onViewPress: (data) =>
                navigate(`/${userType}/query-detail`, {
                  state: {
                    data: {
                      ...data,
                      createdAt: moment(new Date(data.createdAt)).format(
                        "DD MMM YYYY, H:mm"
                      ),
                    },
                  },
                }),
            })}
          />
        </>
      )}

      {!!modaData && (
        <AddEditContactDetails modalData={modaData} closeModal={closeModal} />
      )}
    </>
  );
};

export default ContactUs;
