import { CheckBox, CheckBoxOutlineBlank, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { INPUT_TYPES } from "components";
import { TRUE_FALSE } from "shared/constants";

export const COMMUNICATION_CONFIG_FIELDS = [
  {
    _key: "name",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Name",
  },
  {
    _key: "templateId",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Template",
  },
  {
    _key: "sms",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "SMS",
    extraData: TRUE_FALSE,
  },
  {
    _key: "whatsapp",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "WhatsApp",
    extraData: TRUE_FALSE,
  },
  {
    _key: "email",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Email",
    extraData: TRUE_FALSE,
  },
  {
    _key: "push",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "push",
    extraData: TRUE_FALSE,
  },
];

export const getCommunicationConfigColumns = ({
  onEditPress,
  templates = [],
}) => [
  { field: "id", headerName: "ID", width: 90, hide: true },
  {
    field: "index",
    headerName: "S.No.",
    width: 80,
  },
  {
    field: "name",
    headerName: "Name",
    width: 240,
  },
  {
    field: "templateId",
    headerName: "Template",
    width: 200,
    renderCell: ({ row }) =>
      templates.find((template) => +template.value === +row.templateId)?.label || "",
  },
  {
    field: "sms",
    headerName: "SMS",
    width: 100,
    renderCell: ({ row }) =>
      row.sms ? (
        <CheckBox color="primary" />
      ) : (
        <CheckBoxOutlineBlank color="primary" />
      ),
  },
  {
    field: "whatsapp",
    headerName: "WhatsApp",
    width: 100,
    renderCell: ({ row }) =>
      row.whatsapp ? (
        <CheckBox color="primary" />
      ) : (
        <CheckBoxOutlineBlank color="primary" />
      ),
  },
  {
    field: "email",
    headerName: "Email",
    width: 100,
    renderCell: ({ row }) =>
      row.email ? (
        <CheckBox color="primary" />
      ) : (
        <CheckBoxOutlineBlank color="primary" />
      ),
  },
  {
    field: "push",
    headerName: "Push",
    width: 100,
    renderCell: ({ row }) =>
      row.push ? (
        <CheckBox color="primary" />
      ) : (
        <CheckBoxOutlineBlank color="primary" />
      ),
  },
  {
    field: "edit",
    headerName: "Edit",
    width: 80,
    renderCell: ({ row }) => {
      const onEdit = (e) => {
        e.stopPropagation();
        onEditPress(row);
      };
      return (
        <IconButton onClick={onEdit}>
          <Edit color="primary" />
        </IconButton>
      );
    },
  },
];
