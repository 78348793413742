import { Close } from "@mui/icons-material";
import { Button, IconButton, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { CustomField, INPUT_TYPES } from "./CustomField";

export const ConfirmationModal = ({ closeModal, modalData, color }) => {
  const [state, setState] = useState({});
  if (!modalData) {
    return;
  }

  const {
    title,
    description,
    remarks,
    footerButtons = [],
    reasons = [],
  } = modalData;

  const onClick = ({ isRequired, onClick }) => {
    if (isRequired && remarks) {
      if (!state.value?.trim().length) {
        setState((_state) => ({
          ..._state,
          error: true,
          errorText: "Please enter a valid value",
        }));
        return;
      }
    }

    if (isRequired && !!reasons?.length) {
      if (!state.reason) {
        setState((_state) => ({
          ..._state,
          reasonError: true,
          reasonErrorText: "Please enter a reason",
        }));
        return;
      }
    }

    onClick && onClick({ remarks: state.value, reason: state.reason });
  };

  const onChange = ({ value }) => {
    setState((_state) => ({ value, error: false, errorText: "" }));
  };

  return (
    <Modal
      open
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ outline: "none" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "40vw",
          width: { xs: "80%", sm: "30%" },
          maxHeight: "95vh",
          bgcolor: "background.paper",
          boxShadow: 4,
          borderRadius: "8px",
          p: 2,
          overflow: "auto",
        }}
      >
        <Typography
          //   color={color}
          id="modal-modal-title"
          component="div"
          sx={{
            display: "flex",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            alignItems: "center",
            justifyContent: "space-between",
            // borderBottom: "1.5px solid",
            // p: 2,
          }}
        >
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
          <IconButton onClick={closeModal}>
            <Close color={color} />
          </IconButton>
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{
            py: 3,
          }}
        >
          {description}
        </Typography>

        {!!reasons?.length && (
          <CustomField
            value={state.reason}
            error={state.reasonError}
            errorText={state.reasonErrorText}
            onChange={({ value }) =>
              setState((_state) => ({ ..._state, reason: value }))
            }
            item={{
              inputType: INPUT_TYPES.DROPDOWN,
              placeholder: "Reason",
              extraProps: { sx: { marginBottom: "16px" } },
              extraData: reasons,
            }}
          />
        )}

        {!!remarks && (
          <CustomField
            value={state.value}
            error={state.error}
            errorText={state.errorText}
            onChange={onChange}
            item={{
              inputType: INPUT_TYPES.TEXT_AREA,
              placeholder: remarks?.placeholder || "Remarks",
              extraProps: { sx: { marginBottom: "16px" } },
            }}
          />
        )}
        {!!footerButtons && (
          <Typography
            id="modal-modal-footer"
            component="div"
            color={color}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {footerButtons?.map((item, index) => (
              <Button
                key={index}
                color={color}
                variant="contained"
                sx={{ px: "20px" }}
                {...item}
                onClick={() => onClick(item)}
              >
                {item?.title}
              </Button>
            ))}
          </Typography>
        )}
      </Box>
    </Modal>
  );
};
