import React, { useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Typography } from "@mui/material";
import { fetchLocalitiesRequest } from "../utils";

const LeftIcons = ({ active, color }) => {
  if (active) {
    return <IndeterminateCheckBoxIcon color={color} />;
  }
  return <AddBoxIcon color={color} />;
};

export const RenderNodeItems = ({ data = {}, isPinCode }) => {
  const [showChildrenNodes, toggleChildrenNodes] = useState(false);
  const [localities, setLocalities] = useState([]);
  const { states, districts, pincodes, state, district, name } = data;

  const innerItems = states || districts || pincodes;
  const hasInnerItems = !!innerItems?.length;
  const color = "primary";

  const getLocalities = () => {
    fetchLocalitiesRequest({
      id: data,
      onSuccess: (data) => setLocalities(data[0]?.localities),
    });
  };

  return (
    <div
      style={{
        marginLeft: 15,
      }}
    >
      <div
        style={{
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
          width: "100%",
          padding: `8px 4px`,
          marginBottom: "8px",
          position: "relative",
          background: "#f0fdf4",
        }}
        onClick={() => {
          if (isPinCode && !showChildrenNodes) getLocalities();
          toggleChildrenNodes(!showChildrenNodes);
        }}
      >
        {(hasInnerItems || isPinCode) && (
          <LeftIcons color={color} active={showChildrenNodes} />
        )}
        <div
          style={{
            width: "100%",
            paddingLeft: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: "500" }} color={color}>
            {name || state || district || data || ""}
          </Typography>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        {showChildrenNodes && hasInnerItems && (
          <div
            style={{
              borderLeft: "1.5px dashed grey",
              marginLeft: "13px",
              marginTop: "-8px",
            }}
          ></div>
        )}

        {showChildrenNodes && (
          <div style={{ width: "100%" }}>
            {(innerItems ?? localities)?.map((item, _index) => {
              return (
                <RenderNodeItems
                  index={_index}
                  data={item}
                  key={item._id}
                  isPinCode={!!pincodes?.length}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default RenderNodeItems;
