import React, { useState } from "react";
import { Grid, IconButton } from "@mui/material";
import { CustomField, INPUT_TYPES } from "components";
import { getErrorKey, getErrorText, validateFields } from "utils";
import { updateAppConfigRequest } from "../utils";
import ModalWrapper from "components/ModalWrapper";
import { Delete } from "@mui/icons-material";
import { cloneDeep } from "lodash";

export const EditConfigModal = ({ data, closeModal, onSuccessCallback }) => {
  const [state, setState] = useState(cloneDeep(data || {}));
  const config = Object.keys(state.config) || [];

  const onChange = ({ _key, value }) => {
    setState((_state) => ({
      ..._state,
      config: { ..._state.config, [_key]: value },
    }));
  };

  const onDeletePress = ({ _key }) => {
    setState((_state) => {
      delete _state.config[_key];
      return { ..._state };
    });
  };

  const validateData = () => {
    const { isValid, _state } = validateFields({
      _state: { ...state.config },
      fields: config.map((_key) => {
        return {
          _key,
          inputType: INPUT_TYPES.INPUT,
          placeholder: _key,
        };
      }),
    });

    setState((_prev) => ({ ..._prev, errors: _state }));
    return isValid;
  };

  const onSubmit = () => {
    if (!validateData()) {
      return;
    }

    const payload = {
      id: state._id,
      config: state.config,
    };

    updateAppConfigRequest({
      payload,
      onSuccess: onSuccessCallback,
    });
  };

  return (
    <ModalWrapper
      title="Edit Field"
      closeModal={closeModal}
      footerButtons={[
        {
          title: "Submit",
          onClick: onSubmit,
        },
      ]}
    >
      <Grid container rowSpacing={2} spacing={1}>
        {config.length &&
          config.map((_key) => {
            return (
              <Grid
                key={_key}
                item
                md={12}
                sm={12}
                xs={12}
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <CustomField
                  color={"primary"}
                  item={{
                    _key,
                    inputType: INPUT_TYPES.INPUT,
                    placeholder: _key,
                  }}
                  errorText={state.errors && state.errors[getErrorText(_key)]}
                  error={state.errors && state.errors[getErrorKey(_key)]}
                  value={state.config && state.config[_key]}
                  onChange={onChange}
                />
                {config.length > 1 && (
                  <IconButton onClick={() => onDeletePress({ _key })}>
                    <Delete color="error" />
                  </IconButton>
                )}
              </Grid>
            );
          })}
      </Grid>
    </ModalWrapper>
  );
};
