import { Card, CardContent } from "@mui/material";
import { Box } from "@mui/system";
import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import { fetchServices } from "utils/calls";
import RenderNodeItems from "./Item";
export default function EnabledPinCodes() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetchServices({ onSuccess: setServices });
  }, []);
  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header title={"Search PIN Codes"} />
          {/* {services.map((service) => (
            <RenderNodeItems data={service} key={service._id} />
          ))} */}
        </CardContent>
      </Card>
    </Box>
  );
}
