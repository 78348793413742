import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { APP_CONFIGURATION_SERVICES } from "utils";

export const getFaqsRequest = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_FAQS(query)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const addTopicRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.CREATE_FAQ_TOPIC(payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const editTopicRequest = ({ id, payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.UPDATE_FAQ_TOPIC(id, payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateTopicOrderRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.UPDATE_FAQ_TOPIC_ORDER(payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const addFaqRequest = ({ topicId, payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.CREATE_FAQ(topicId, payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const editFaqRequest = ({ topicId, id, payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.UPDATE_FAQ(topicId, id, payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateFaqOrderRequest = ({ topicId, payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.UPDATE_FAQ_ORDER(topicId, payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
