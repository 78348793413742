import { CustomField, getErrorKey, getErrorText } from "components";
import Header from "components/HeaderV2";
import React from "react";
import { Grid } from "@mui/material";

export default function RenderLanguageSpecificTemplate({
  templateType,
  template = [],
  fields = [],
  setTemplateData,
}) {
  return (
    <div>
      {template.map((item, index) => {
        const onChange = ({ value, _key }) => {
          setTemplateData((_data) => {
            _data[templateType][index][_key] = value;
            _data[templateType][index][getErrorText(_key)] = "";
            _data[templateType][index][getErrorKey(_key)] = false;
            return { ..._data };
          });
        };
        return (
          <div
            key={index}
            style={{
              marginTop: index !== 0 && "10px",
              borderTop: index !== 0 && "1px dashed",
              position: "relative",
            }}
          >
            <Header
              skip
              title={item.languageName}
              style={{ py: 0, pb: "4px" }}
              titleStyle={{ fontSize: "16px" }}
            />
            <Grid container rowSpacing={1} spacing={1}>
              {fields.map((field) => {
                const { md } = field;

                return (
                  <Grid key={field._key} item md={md || 12} sm={12} xs={12}>
                    <CustomField
                      onChange={onChange}
                      value={item[field._key]}
                      errorText={item[getErrorText(field._key)]}
                      error={item[getErrorKey(field._key)]}
                      item={field}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        );
      })}
    </div>
  );
}
