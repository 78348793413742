import { INPUT_TYPES } from "../../../components/CustomField";

export const LAYOUT_TYPES = [{ value: "page", label: "Page" }];

export const ITEM_LAYOUT_TYPES = [
  { value: "row", label: "Row" },
  { value: "flow", label: "Flow" },
  { value: "grid", label: "Grid" },
];

export const ITEM_STYLE = [
  { value: "circle", label: "Circle" },
  { value: "rectangle", label: "Rectangle" },
];

export const ITEM_SELECTION_TYPE = [
  { value: "single", label: "Single" },
  { value: "multiple", label: "Multiple" },
];

export const NODE_FIELDS = [
  { inputType: INPUT_TYPES.INPUT, _key: "title", placeholder: "Title" },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "layout",
    shouldBeSame: true,
    placeholder: "Layout",
    extraData: LAYOUT_TYPES,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "itemSelectionType",
    shouldBeSame: true,
    placeholder: "Item Selection Type",
    extraData: ITEM_SELECTION_TYPE,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "itemLayout",
    shouldBeSame: true,
    placeholder: "Item Layout",
    extraData: ITEM_LAYOUT_TYPES,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "itemStyle",
    shouldBeSame: true,
    placeholder: "Item Style",
    extraData: ITEM_STYLE,
  },
  {
    md: 9,
    inputType: INPUT_TYPES.TEXT_AREA,
    _key: "description",
    isOptional: true,
    placeholder: "Description",
  },
];

export const ITEM_FIELDS = [
  { md: 6, inputType: INPUT_TYPES.INPUT, _key: "title", placeholder: "Title" },
  {
    md: 6,
    inputType: INPUT_TYPES.TEXT_AREA,
    _key: "description",
    isOptional: true,
    placeholder: "Description",
  },
];
