import { Box, Card, CardContent } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomFilter from "components/CustomFilter";
import { queryString } from "utils";
import qs from "query-string";
import CustomDataGrid from "components/DataGrid";
import { PAYORDER_FILTER_FIELDS, getPayOrdersColumns } from "./constants";
import { fetchPayOrdersRequest } from "./utils";

export default function PayOrders() {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();

  const onCreatePress = () => navigate(`/provider/payOrder/create`);

  const onViewPress = (row) => navigate(`/provider/payOrders/${row._id}`);

  const onPageChange = (pNo) => setFiltersData({ ...filters, pageNo: pNo + 1 });

  const onPageSizeChange = (pSize) =>
    setFiltersData({ ...filters, pageSize: pSize });

  const setFiltersData = (_state) => {
    navigate(`${window.location.pathname}?${queryString(_state)}`, {
      replace: true,
    });
    setFilters(_state);
  };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const params = qs.parse(searchParams.toString());
    if (params.status) {
      params.status = params.status
        .split(",")
        .map((value) => ({ label: value, value }));
    }
    setFilters({ ...params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !!filters &&
      fetchPayOrdersRequest({
        query: queryString(filters),
        onSuccess: ({ totalRecords, items }) => {
          setData(
            items.map((item, index) => ({
              ...item,
              id: index,
              index:
                ((filters.pageNo || 1) - 1) * (filters.pageSize || 10) +
                index +
                1,
            }))
          );
          setTotalCount(totalRecords);
        },
      });
  }, [filters]);

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header
            title="Pay Orders"
            buttons={[
              {
                title: "Create Pay Order",
                onClick: onCreatePress,
              },
            ]}
          />
          <CustomFilter
            onChange={setFilters}
            filtersData={filters}
            fields={PAYORDER_FILTER_FIELDS}
          />
          <CustomDataGrid
            rows={data}
            onPageChange={onPageChange}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pageSize={Number(filters?.pageSize || 10)}
            page={(filters?.pageNo || 1) - 1}
            paginationMode="server"
            rowCount={totalCount}
            onPageSizeChange={onPageSizeChange}
            columns={getPayOrdersColumns({ onViewPress })}
          />
        </CardContent>
      </Card>
    </Box>
  );
}
