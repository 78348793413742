import Header from "components/HeaderV2";
import React from "react";
import { TEMPLATE_INFO_FIELDS } from "./constants";
import { CustomField, getErrorKey, getErrorText } from "components";

export default function RenderBasicInfo({ data, setTemplateData }) {
  const onChange = ({ value, _key }) => {
    setTemplateData((_data) => {
      _data[_key] = value;
      _data[getErrorText(_key)] = "";
      _data[getErrorKey(_key)] = false;
      return { ..._data };
    });
  };
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* <Header skip title="Basic Info" /> */}

      {TEMPLATE_INFO_FIELDS.map((field) => (
        <CustomField
          onChange={onChange}
          key={field._key}
          errorText={data[getErrorText(field._key)]}
          error={data[getErrorKey(field._key)]}
          value={data[field._key]}
          item={field}
        />
      ))}
    </div>
  );
}
