import React, { useState } from "react";
import ModalWrapper from "components/ModalWrapper";
import { CustomField, getErrorKey, getErrorText } from "components";
import { Grid } from "@mui/material";
import { validateFields } from "utils";
import { COMMUNICATION_CONFIG_FIELDS } from "../constants";
import { updateCommunicationConfigRequest } from "../utils";

export const AddEditCommunicationConfig = ({
  closeModal,
  modalData,
  onSuccessCallback,
  templates = [],
}) => {
  const { data, responseData } = modalData;
  const isEdit = !!data;
  const [state, setState] = useState(data || {});

  const validateData = () => {
    const { isValid, _state } = validateFields({
      _state: state,
      fields: COMMUNICATION_CONFIG_FIELDS,
    });
    setState({ ..._state });

    return isValid;
  };

  const onChange = ({ _key, value, overRideValues }) => {
    setState((_state) => ({
      ...state,
      ...overRideValues,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
      [_key]: value,
    }));
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    const payload = {
      id: responseData._id,
      data: {
        ...responseData.config,
      },
    };

    ["sms", "whatsapp", "email", "push", "templateId"].forEach((_key) => {
      if (_key === "templateId") {
        payload.data[state.name][_key] = state[_key];
      } else {
        payload.data[state.name][_key] = state[_key] === "true";
      }
    });

    updateCommunicationConfigRequest({
      payload,
      onSuccess: onSuccessCallback,
    });
  };

  return (
    <ModalWrapper
      footerButtons={[
        {
          title: isEdit ? "Update" : "Create",
          onClick: onSubmitPress,
        },
      ]}
      title={`${isEdit ? "Edit" : "Add"} Config`}
      closeModal={closeModal}
      sx={{ width: "50vw" }}
      childSx={{ height: "70vh", p: 2, overflow: "auto" }}
    >
      <div
        style={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <Grid container rowSpacing={2} spacing={1}>
          {COMMUNICATION_CONFIG_FIELDS.map((item) => {
            const { _key } = item;

            if (!_key) {
              return null;
            }

            if (_key === "templateId") {
              item.extraData = templates;
            }

            let disabled = false;
            if (_key === "name") {
              disabled = true;
            }

            return (
              <Grid key={_key} item md={12} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={item}
                  errorText={state[getErrorText(_key)]}
                  error={state[getErrorKey(_key)]}
                  value={state[_key]}
                  onChange={onChange}
                  disabled={disabled}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </ModalWrapper>
  );
};
