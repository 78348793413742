import { useTheme } from "@emotion/react";
import { Button, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { CustomField, INPUT_TYPES } from "./CustomField";
import { useDispatch } from "react-redux";
import { setScreenTitle } from "redux/actions";

export default function Header({
  variant = "h6",
  style = {},
  skip,
  dropDown,
  title,
  children,
  buttons = [],
  titleStyle = {},
}) {
  const theme = useTheme();
  const SizeLessThenMd = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  useEffect(() => {
    !skip && title && dispatch(setScreenTitle(title));
  }, [skip, dispatch, title]);

  if (!skip && SizeLessThenMd && buttons.length === 0 && !dropDown) return null;

  return (
    <Typography
      className="hide-scrollbar"
      component={"div"}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        overflowX: "auto",
        // overflowY: "visible",
        gap: 8,
        py: "8px",
        ...style,
      }}
    >
      {!!title && (
        <Typography
          variant={variant}
          sx={{
            display: { xs: skip ? "inline-flex" : "none", md: "inline-flex" },
            ...titleStyle,
          }}
        >
          {title}
        </Typography>
      )}
      <Typography
        component={"div"}
        sx={{
          flex: 1,
          justifyContent: { xs: "space-between", sm: "flex-end" },
          display: "flex",
          gap: 2,
        }}
      >
        {children}
        {!!dropDown && (
          <Typography
            component={"div"}
            sx={{ width: { xs: "150px", sm: "200px" } }}
          >
            <CustomField
              value={dropDown.value}
              onChange={({ value }) => dropDown.onChange(value)}
              item={{
                inputType: INPUT_TYPES.DROPDOWN,
                placeholder: dropDown.placeholder || "Select Language",
                extraData: dropDown.data,
              }}
            />
          </Typography>
        )}
        {buttons.map((buttonItem, index) => {
          return (
            <Button
              key={index}
              size="small"
              variant="contained"
              {...buttonItem}
              style={{ whiteSpace: "nowrap" }}
            >
              {buttonItem.title}
            </Button>
          );
        })}
      </Typography>
    </Typography>
  );
}
