import { REHYDRATE } from "redux-persist";
import { RESET } from "../actions";
import {
  UPDATE_CURRENT_ENROLLMENT_DATA,
  UPDATE_CURRENT_SERVICE_DATA,
  UPDATE_CURRENT_SERVICE_NODES_DATA,
} from "../actions/services";

const initialState = {
  serviceNodes: [],
  serviceInfo: [],
  enrollmentNodes: [],
  serviceLanguages: [],
};

const ServicesReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_SERVICE_NODES_DATA:
      return {
        ...state,
        serviceNodes: action.payload || [],
      };
    case UPDATE_CURRENT_ENROLLMENT_DATA:
      return {
        ...state,
        enrollmentNodes: action.payload || [],
      };
    case UPDATE_CURRENT_SERVICE_DATA:
      const serviceInfo = action.payload || [];
      const serviceLanguages = [];
      serviceInfo.forEach((item) => serviceLanguages.push(item.languageId));
      return {
        ...state,
        serviceInfo,
        serviceLanguages,
      };

    case REHYDRATE:
      return {
        ...initialState,
        ...((action.payload || {}).common || {}),
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ServicesReducer;
