import { getServiceInfo } from "screens/Services/utils";
import { setLoading } from "../../../../redux/actions";
import { store } from "../../../../redux/store";
import { HTTP_SERVICE } from "../../../../utils/apiServices";
import { successToast } from "utils";

export const createServiceRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.ADD_A_SERVICE(payload)
    .then(({ isSuccess, data = {} }) => {
      isSuccess && onSuccess && onSuccess(data.serviceId);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateServiceRequest = ({ payload, serviceId, id }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.UPDATE_A_SERVICE(payload, id)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        successToast("Updated Successfully!");
        getServiceInfo(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createServiceByLangRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.ADD_SERVICE_BY_LANG(payload)
    .then(({ isSuccess, data = {} }) => {
      isSuccess && onSuccess && onSuccess(data.serviceId);
      isSuccess && getServiceInfo(data.serviceId);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
