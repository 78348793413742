import { Typography } from "@mui/material";
import CustomDataGrid from "components/DataGrid";
import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBookingsListColumns } from "screens/Bookings/constants";
import { getBookingsRequest } from "screens/Bookings/utils";
import { getJobsListColumns } from "screens/Jobs/constants";
import { getJobsRequest } from "screens/Jobs/utils";
import { queryString } from "utils";

export default function RenderUserSpecificJobsBookings({
  id,
  userType = "provider",
}) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [totalCount, setTotalCount] = useState(1);
  const isProvider = userType === "provider";
  const columns = isProvider ? getJobsListColumns : getBookingsListColumns;

  const fetchBookingsJobs = ({ query, isProvider }) => {
    const call = isProvider ? getJobsRequest : getBookingsRequest;
    call({
      query,
      onSuccess: ({ totalRecords, response }) => {
        setData(response);
        setTotalCount(totalRecords);
      },
    });
  };

  useEffect(() => {
    if (id) {
      fetchBookingsJobs({
        isProvider,
        query: queryString({ ...filters, [`${userType}Id`]: id }),
      });
    }
  }, [id, userType, isProvider, filters]);

  const onViewPress = ({ booking, _id }) => {
    navigate(isProvider ? "/jobs/" + booking._id : "/bookings/" + _id);
  };

  const onPageChange = (pNo) =>
    setFilters((_filters) => ({ ..._filters, pageNo: pNo + 1 }));

  const onPageSizeChange = (pSize) =>
    setFilters((_filters) => ({ ..._filters, pageSize: pSize }));

  return (
    <Typography component={"div"}>
      <Header skip title={isProvider ? "Jobs" : "Bookings"} />
      <CustomDataGrid
        rows={data}
        onPageChange={onPageChange}
        rowsPerPageOptions={[10, 25, 50, 100]}
        pageSize={Number(filters?.pageSize || 10)}
        page={(filters?.pageNo || 1) - 1}
        paginationMode="server"
        rowCount={totalCount}
        onPageSizeChange={onPageSizeChange}
        columns={columns({
          onViewPress,
          hideProvider: true,
          hideService: true,
        }).filter((item) => !item.hide)}
      />
    </Typography>
  );
}
