import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { APP_CONFIGURATION_SERVICES } from "utils";

export const getResponseMessagesRequest = ({ onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_RESPONSE_MESSAGES()
    .then(({ data, isSuccess }) => {
      isSuccess &&
        onSuccess(data.map((item, index) => ({ ...item, id: index + 1 })));
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const addResponseMessageRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.ADD_RESPONSE_MESSAGE(payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateResponseMessageRequest = ({ id, payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.UPDATE_RESPONSE_MESSAGE(id, payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
