import React from "react";
import ModalWrapper from "components/ModalWrapper";
import CustomMap from "components/CustomMap";

export const RenderLocationModal = ({ data, closeModal }) => {
  return (
    <ModalWrapper
      title={"Address"}
      closeModal={closeModal}
      sx={{ width: "50%" }}
      childSx={{ height: "50vh", p: 2 }}
    >
      <div
        style={{
          marginTop: "20px",
          marginBottom: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <CustomMap data={data} />
      </div>
    </ModalWrapper>
  );
};
