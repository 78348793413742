import React from "react";
import { Divider, Typography } from "@mui/material";
import {
  CustomField,
  INPUT_TYPES,
  getErrorKey,
  getErrorText,
} from "components";
import Header from "components/HeaderV2";
import moment from "moment";

export const JOB_FREQUENCY_TYPE = {
  oneTime: { label: "One Time", value: "OT" },
  multipleDays: { label: "Multiple Days", value: "MD" },
  monthly: { label: "Monthly", value: "MN" },
};

const MINUTES = ["0", "30"];

const ONE_TIME = [
  {
    _key: "jobFrequency",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Job Frequency",
    extraProps: { sx: { marginRight: "16px" } },
    overRideValues: { daysCount: "", hours: "", minutes: "", monthsCount: "" },
    extraData: Object.values(JOB_FREQUENCY_TYPE).map((item) => item),
  },
  {
    _key: "daysCount",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "No. of days",
    maxLength: 1,
    extraProps: { sx: { marginRight: "16px" }, disabled: true, value: 1 },
  },
  {
    _key: "hours",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Hours",
    maxLength: 2,
    extraProps: { sx: { marginRight: "16px" } },
    overRideValues: { minutes: "" },
  },
  {
    _key: "minutes",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Minutes",
    extraProps: { sx: { marginRight: "16px" } },
    extraData: MINUTES,
  },
];

const MULTIPLE_DAYS_FIELDS = [
  {
    _key: "jobFrequency",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Job Frequency",
    extraProps: { sx: { marginRight: "16px" } },
    overRideValues: { daysCount: "", hours: "", minutes: "", monthsCount: "" },
    extraData: Object.values(JOB_FREQUENCY_TYPE).map((item) => item),
  },
  {
    _key: "daysCount",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "No. of days",
    maxLength: 2,
    extraProps: { sx: { marginRight: "16px" } },
  },
  {
    _key: "hours",
    inputType: INPUT_TYPES.INPUT,
    maxLength: 2,
    placeholder: "Hours per day",
    extraProps: { sx: { marginRight: "16px" } },
    overRideValues: { minutes: "" },
  },
  {
    _key: "minutes",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Minutes",
    extraProps: { sx: { marginRight: "16px" } },
    extraData: MINUTES,
  },
];

const MONTHLY_FIELDS = [
  {
    _key: "jobFrequency",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Job Frequency",
    extraProps: { sx: { marginRight: "16px" } },
    overRideValues: { daysCount: "", hours: "", minutes: "", monthsCount: "" },
    extraData: Object.values(JOB_FREQUENCY_TYPE).map((item) => item),
  },
  {
    _key: "monthsCount",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "No. of months",
    maxLength: 2,
    extraProps: { sx: { marginRight: "16px" } },
  },
  {
    _key: "hours",
    inputType: INPUT_TYPES.INPUT,
    maxLength: 2,
    placeholder: "Hours per day",
    extraProps: { sx: { marginRight: "16px" } },
    overRideValues: { minutes: "" },
  },
  {
    _key: "minutes",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Minutes",
    extraProps: { sx: { marginRight: "16px" } },
    extraData: MINUTES,
  },
];

const RESCHEDULE_FIELDS = [
  {
    _key: "rescheduledTime",
    inputType: INPUT_TYPES.DATE_TIME,
    placeholder: "Rescheduled Date",
  },
];

export default function JobDurationSelector({
  jobScheduledTime,
  state,
  isReschedule,
  setState,
  isDisabled,
}) {
  const { jobFrequency } = state;
  const fields = isReschedule
    ? RESCHEDULE_FIELDS
    : jobFrequency === "MD"
    ? MULTIPLE_DAYS_FIELDS
    : jobFrequency === "MN"
    ? MONTHLY_FIELDS
    : ONE_TIME;

  const onChange = ({ overRideValues, maxLength, _key, value = "" }) => {
    let val =
      _key === "jobFrequency" || isReschedule
        ? value
        : (value.match(/\d+/g) || [])[0];

    if (
      maxLength &&
      val > (maxLength === 3 ? 365 : _key === "daysCount" ? 30 : 24)
    ) {
      return;
    }

    if ((_key === "hours" || _key === "minutes") && value.length > 4) {
      return;
    }

    // eslint-disable-next-line eqeqeq
    if (_key === "minutes" && state.hours == 24 && value == "30") {
      return;
    }

    setState((_state) => ({
      ..._state,
      ...overRideValues,
      [_key]: val,
      [getErrorText(_key)]: "",
      [getErrorKey(_key)]: false,
    }));
  };

  return (
    <div>
      <Header skip title={isReschedule ? "Reschedule Date" : "Job duration"} />
      <div style={{ display: "flex", alignItems: "center" }}>
        {fields.map((item, index) => {
          const { _key } = item;
          return (
            <CustomField
              key={`${_key}------${index}`}
              value={state[_key]}
              errorText={state[getErrorText(_key)]}
              error={state[getErrorKey(_key)]}
              onChange={onChange}
              item={item}
              disabled={!!isDisabled}
            />
          );
        })}
      </div>
      <Typography sx={{ py: 2 }}>
        <RenderTimeSlots jobScheduledTime={jobScheduledTime} state={state} />
      </Typography>
    </div>
  );
}

const borderColor = "rgba(0, 0, 0, 0.3)";
const background = "#f0fdf4";

const RenderTimeSlots = ({
  state: { jobFrequency, hours, minutes, daysCount, monthsCount, endTime } = {},
  jobScheduledTime,
}) => {
  const daily = jobFrequency === "MD";
  const monthly = jobFrequency === "MN";

  if (
    (daily && daysCount && hours) ||
    (monthly && monthsCount && hours) ||
    minutes ||
    hours
  ) {
    const array = [
      ...new Array(Number((daily ? daysCount : monthsCount) || 1)),
    ];
    return (
      <Typography
        sx={{
          border: `1px solid ${borderColor}`,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <Typography sx={{ p: 1.5, background }}>
          <Item
            counter={daily ? "Day" : monthly ? "Month" : ""}
            left="Job Start"
            right="Job End"
          />
        </Typography>

        <Divider orientation="horizontal" sx={{ bgcolor: borderColor }} />

        <Typography
          sx={{
            p: daily || monthly ? "" : 1.5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {daily ? (
            array.map((_, index) => {
              const isLastIndex = index === array.length - 1;

              return (
                <Typography
                  sx={{
                    p: 1.5,
                    borderTop: index > 0 ? `1px solid ${borderColor}` : "",
                    background: index % 2 ? background : "",
                  }}
                >
                  <Item
                    counter={index + 1}
                    left={moment(new Date(jobScheduledTime))
                      .add(index, "days")
                      .format("DD MMM YYYY, hh:mm A")}
                    right={
                      isLastIndex && endTime
                        ? moment(new Date(endTime))
                            .utc()
                            .format("DD MMM YYYY, hh:mm A")
                        : moment(new Date(jobScheduledTime))
                            .add(index, "days")
                            .add(hours || 0, "hours")
                            .format("DD MMM YYYY, hh:mm A")
                    }
                  />
                </Typography>
              );
            })
          ) : monthly ? (
            array.map((_, index) => {
              return (
                <Typography
                  sx={{
                    p: 1.5,
                    borderTop: index > 0 ? `1px solid ${borderColor}` : "",
                    background: index % 2 ? background : "",
                  }}
                >
                  <Item
                    counter={index + 1}
                    left={moment(new Date(jobScheduledTime))
                      .add(index, "month")
                      .format("DD MMM YYYY, hh:mm A")}
                    right={
                      // endTime
                      //   ? moment(new Date(endTime))
                      //       .utc()
                      //       .format("DD MMM YYYY, hh:mm A")
                      //   :
                      moment(new Date(jobScheduledTime))
                        .add(index + 1, "month")
                        .subtract(1, "day")
                        .add(hours, "hours")
                        .format("DD MMM YYYY, hh:mm A")
                    }
                  />
                </Typography>
              );
            })
          ) : (
            <Item
              left={moment(new Date(jobScheduledTime)).format(
                "DD MMM YYYY, hh:mm A"
              )}
              right={
                endTime
                  ? moment(new Date(endTime))
                      .utc()
                      .format("DD MMM YYYY, hh:mm A")
                  : moment(new Date(jobScheduledTime))
                      .add(hours || 0, "hours")
                      .add(minutes || 0, "minutes")
                      .format("DD MMM YYYY, hh:mm A")
              }
            />
          )}
        </Typography>
      </Typography>
    );
  }
  return null;
};

const Item = ({ counter, left, right }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {!!counter && <Typography sx={{ flex: 0.2 }}>{counter}</Typography>}
      <Typography sx={{ flex: 1 }}>{left}</Typography>
      <Typography sx={{ flex: 1 }}>{right}</Typography>
    </div>
  );
};
