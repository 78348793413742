import { APP_CONFIG_ROUTES, BROADCAST_ROUTES } from "./constants.appConfig";
import { OPERATION_ROUTES } from "./constants.operation";
import { SUPPORT_ROUTES } from "./constants.support";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import { ROLE_TYPES } from "shared/constants";
import CurrencyRupee from "@mui/icons-material/CurrencyRupee";
import { ACCOUNT_ROUTES } from "./constants.account";

export const DATA = [
  {
    href: "/",
    icon: <HomeIcon fontSize="small" />,
    title: "Home",
  },
  {
    href: "/languages",
    icon: <GTranslateIcon fontSize="small" />,
    title: "Languages",
    enabled: { [ROLE_TYPES["super-admin"].value]: true },
  },
  {
    href: "/users",
    icon: <SupervisorAccountIcon fontSize="small" />,
    title: "Users",
    enabled: { [ROLE_TYPES["super-admin"].value]: true },
  },
  {
    icon: <AccountTreeIcon fontSize="small" />,
    title: "Services",
    enabled: { [ROLE_TYPES["super-admin"].value]: true },
    child: [
      {
        href: "/categories",
        icon: <CategoryIcon fontSize="small" />,
        title: "Category List",
      },
      {
        href: "/services",
        icon: <RoomServiceIcon fontSize="small" />,
        title: "Service List",
      },
      {
        href: "/charges",
        icon: <CurrencyRupee fontSize="small" />,
        title: "Charges Type",
      },
      {
        href: "/enrollment-questions",
        icon: <QuestionAnswerIcon fontSize="small" />,
        title: "Enrollment Questions",
      },
    ],
  },
  ...OPERATION_ROUTES,
  ...SUPPORT_ROUTES,
  ...ACCOUNT_ROUTES,
  ...APP_CONFIG_ROUTES,
  ...BROADCAST_ROUTES,
];
