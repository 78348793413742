import React from "react";
import { MyRoutes } from "./Routes";
import { Box, CssBaseline } from "@mui/material";
import CustomDrawer from "./components/CustomDrawer";

export const MainWrapper = ({ theme }) => {
  return (
    <Box sx={{ display: "flex", overflow: "hidden" }}>
      <CssBaseline />
      <CustomDrawer />
      <Box
        component="main"
        sx={{
          width: "100%",
          flexGrow: 1,
          p: 1.5,
          marginTop: "48px",
          minHeight: "100vh",
          height: "100%",
          overflow: "auto",
          boxShadow: "inset rgb(0 0 0 / 7%) 0px 0px 21px 1px",
          background: "#a5d6a7 !important",
        }}
      >
        <MyRoutes />
      </Box>
    </Box>
  );
};
