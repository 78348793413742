import { INPUT_TYPES } from "components";
import moment from "moment/moment";

export const DLOGGER_FIELDS = [
  {
    _key: "appName",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "App Name",
    dataKey: "apps",
    md: 1.2,
  },
  {
    _key: "moduleName",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Module Name",
    dataKey: "modules",
    md: 1.5,
    isOptional: true,
  },
  {
    _key: "apiName",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Api Name",
    dataKey: "apis",
    md: 3.8,
    sm: 12,
    isOptional: true,
  },
  {
    _key: "logStartDate",
    inputType: INPUT_TYPES.DATE,
    placeholder: "Start Date",
    md: 1.2,
  },
  {
    _key: "logEndDate",
    inputType: INPUT_TYPES.DATE,
    placeholder: "End Date",
    md: 1.2,
  },
  {
    _key: "level",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Level",
    dataKey: "level",
    md: 0.8,
  },
  {
    _key: "duration",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Duration",
    isOptional: true,
  },
];

export const DLOGGER_LOG_CHIPS = [
  {
    _key: "moduleName",
    placeholder: "Module Name",
  },
  {
    _key: "apiName",
    placeholder: "Api Name",
  },
  {
    _key: "level",
    placeholder: "Level",
  },
  {
    _key: "duration",
    placeholder: "Duration",
  },
  {
    _key: "startTime",
    placeholder: "Log Start Time",
    render: (time) => moment(time).format("DD MMM YYYY hh:mm:ss A"),
  },
  {
    _key: "endTime",
    placeholder: "Log End Time",
    render: (time) => moment(time).format("DD MMM YYYY hh:mm:ss A"),
  },
];
