import React, { useState } from "react";

import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Button, IconButton, Switch, Tooltip, Typography } from "@mui/material";
import { Edit, ExpandCircleDown } from "@mui/icons-material";
import { AddEditFaqModal } from "./AddEditFaqModal";
import FaqItem from "./FaqItem";
import { editFaqRequest, updateFaqOrderRequest } from "../utils";

const LeftIcons = ({ active, color }) => {
  if (active) {
    return <IndeterminateCheckBoxIcon color={color} />;
  }
  return <AddBoxIcon color={color} />;
};
export default function FaqTopic({
  onEditPress,
  onPositionUpdate,
  index,
  data = {},
  onFaqChangeCallback,
  onChangeStatusCallback,
  itemsLength,
  color = "secondary",
}) {
  const [showChildrenNodes, toggleChildrenNodes] = useState(false);
  const { isActive, _id, questions, topic } = data;

  const [modalData, setModalData] = useState(null);
  const closeModal = () => setModalData(null);

  const onAddFaq = (e) => {
    e?.stopPropagation && e?.stopPropagation();
    setModalData({ data: null, topicData: data });
  };

  const onEdit = (e) => {
    e?.stopPropagation && e?.stopPropagation();
    onEditPress();
  };

  const onMoveDown = (e) => {
    e?.stopPropagation && e?.stopPropagation();
    onPositionUpdate({ type: "increment", id: _id });
  };

  const onMoveUp = (e) => {
    e?.stopPropagation && e?.stopPropagation();
    onPositionUpdate({ type: "decrement", id: _id });
  };

  const onToggleActiveStatus = (e) => {
    e?.stopPropagation && e?.stopPropagation();
    onChangeStatusCallback({ id: _id, isActive: !isActive });
  };

  const onSuccessCallback = (data) => {
    onFaqChangeCallback({ topicId: _id, topicData: data });
  };

  const onFaqStatusChange = ({ id, isActive }) => {
    editFaqRequest({
      topicId: _id,
      id,
      payload: { isActive },
      onSuccess: (data) =>
        onFaqChangeCallback({ topicId: _id, topicData: data }),
    });
  };

  const onFaqPositionUpdate = ({ type, id }) => {
    updateFaqOrderRequest({
      topicId: _id,
      payload: {
        questionId: id,
        step: type,
      },
      onSuccess: (data) => onFaqChangeCallback({ topicId: _id, topicData: data }),
    });
  };

  const onEditFaqPress = (item) =>
    setModalData({ data: item, topicData: data });

  return (
    <div
      style={{
        marginBottom: "8px",
      }}
    >
      <div
        className="hide-scrollbar"
        style={{
          background: "#f0fdf4",
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
          width: "100%",
          position: "relative",
          overflow: "auto",
        }}
        onClick={() => toggleChildrenNodes(!showChildrenNodes)}
      >
        <LeftIcons color={color} active={showChildrenNodes} />
        <div
          style={{
            width: "100%",
            paddingLeft: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "500", whiteSpace: "nowrap" }}
            color={color}
          >
            {index + 1}. {topic}
          </Typography>
          <Typography component={"div"} sx={{ display: "flex" }}>
            <IconButton
              onClick={onMoveUp}
              disabled={!index}
              sx={{ rotate: "180deg" }}
            >
              <Tooltip title="Move Up">
                <ExpandCircleDown color={index ? color : "disabled"} />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={onMoveDown}
              disabled={index === itemsLength - 1}
            >
              <Tooltip title="Move Down">
                <ExpandCircleDown
                  color={index === itemsLength - 1 ? "disabled" : color}
                />
              </Tooltip>
            </IconButton>
            <Tooltip title="Edit">
              <IconButton onClick={onEdit}>
                <Edit color={color} />
              </IconButton>
            </Tooltip>
            <Tooltip title={isActive ? "Disable" : "Enable"}>
              <Switch
                label="check"
                color={color}
                name="showParentTitle"
                checked={isActive}
                onClick={onToggleActiveStatus}
              />
            </Tooltip>
            {topic && (
              <Button variant="text" onClick={onAddFaq}>
                Add Faq
              </Button>
            )}
          </Typography>
        </div>
      </div>

      {showChildrenNodes && (
        <Typography sx={{ mt: "8px", pl: 2 }}>
          {questions.map((item, index) => {
            return (
              <FaqItem
                key={item._id}
                index={index}
                itemsLength={questions.length}
                onEditPress={(e) => {
                  e?.stopPropagation && e?.stopPropagation();
                  onEditFaqPress(item);
                }}
                onPositionUpdate={onFaqPositionUpdate}
                onChangeStatusCallback={onFaqStatusChange}
                color="secondary"
                data={item}
              />
            );
          })}
        </Typography>
      )}

      {!!modalData && (
        <AddEditFaqModal
          closeModal={closeModal}
          data={modalData}
          onSuccessCallback={onSuccessCallback}
        />
      )}
    </div>
  );
}
