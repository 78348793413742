import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { CHARGE_SERVICES } from "utils";

export const fetchChargeTypeRequest = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  CHARGE_SERVICES.FETCH_CHARGE_TYPES(query)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createChargesRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  CHARGE_SERVICES.CREATE_CHARGE_REQUEST(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateChargesRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  CHARGE_SERVICES.UPDATE_CHARGE_REQUEST(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
