import { Box, Card, CardContent } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useCallback, useEffect, useState } from "react";
import { CategoryItem } from "./components/CategoryItem";
import { useGetLangData } from "../hooks/useLanguagesData";
import { useParams } from "react-router-dom";
import { fetchSparePartsListRequest, sortByLanguageOrder } from "./utils";
import { queryString } from "utils";
import { AddEditAppliance } from "./components/AddEditAppliance";
import { cloneDeep } from "lodash";

export default function ApplianceDetail() {
  const { serviceId, applianceId } = useParams();
  const [state, setState] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState(0);
  const { defaultLanguageId, availableLanguages, unavailableLanguages } =
    useGetLangData({
      useAll: true,
      data: state,
    });
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [modalData, setModalData] = useState(null);
  const closeModal = () => setModalData(null);

  const onAddPress = () =>
    setModalData({
      languageId: selectedLanguage,
      serviceId,
      isAddNewLang: true,
      languages: unavailableLanguages,
      data: [
        {
          ...(state.find((item) => item.languageId === selectedLanguage) || {}),
          languageId: null,
        },
      ],
    });

  const onEditPress = () =>
    setModalData({
      languageId: selectedLanguage,
      serviceId,
      languages: availableLanguages,
      data: sortByLanguageOrder({
        languageOrder: availableLanguages.map(({ value }) => value),
        applianceData: cloneDeep(state),
      }),
      isEdit: true,
    });

  const onSuccessCallback = () => {
    closeModal();
    getApplianceDetail();
  };

  const getApplianceDetail = useCallback(() => {
    fetchSparePartsListRequest({
      query: queryString({
        serviceId,
        applianceId,
      }),
      onSuccess: setState,
    });
  }, [serviceId, applianceId]);

  useEffect(() => {
    applianceId && getApplianceDetail();
  }, [applianceId, getApplianceDetail]);

  useEffect(() => {
    defaultLanguageId && setSelectedLanguage(defaultLanguageId);
  }, [defaultLanguageId]);

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header
            title={state?.appliance}
            dropDown={{
              value: selectedLanguage,
              onChange: setSelectedLanguage,
              data: availableLanguages,
            }}
            buttons={[
              { title: "Add Language", disabled: !unavailableLanguages.length, onClick: onAddPress },
              { title: "Edit Appliance", onClick: onEditPress },
            ]}
            style={{ mb: "10px" }}
          />

          {state
            .find((item) => item.languageId === selectedLanguage)
            ?.categories?.map((category, index) => {
              return (
                <CategoryItem
                  key={index}
                  data={{ ...category, index }}
                  expandedCategory={expandedCategory}
                  setExpandedCategory={setExpandedCategory}
                />
              );
            })}
        </CardContent>
      </Card>

      {!!modalData && (
        <AddEditAppliance
          closeModal={closeModal}
          data={modalData}
          onSuccessCallback={onSuccessCallback}
        />
      )}
    </Box>
  );
}
