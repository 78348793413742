import { setLoading } from "../../redux/actions";
import { updateCurrentServiceData } from "../../redux/actions/services";
import { store } from "../../redux/store";
import { HTTP_SERVICE } from "../../utils/apiServices";
import { getErrorKey, getErrorText } from "../../components/CustomField";
import { SERVICE_FIELDS } from "./ServiceDetails/ServiceConfig/components/constants";

export const getServiceInfo = (serviceId) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_A_SERVICE(serviceId)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        dispatch(updateCurrentServiceData(data));
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createServiceRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.ADD_SERVICES(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        getServiceInfo(payload.serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getServiceFaqRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_SERVICE_FAQ(id)
    .then(({ data = [], isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess(data.filter((item) => item.faqs.length));
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const addServiceFaqRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.ADD_FAQS(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const toggleServiceFaqStatusRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.TOGGLE_FAQ_STATUS(id)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateServiceFaqPositionRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.UPDATE_FAQ_POSITION_STATUS(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const addServiceFaqLangRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.ADD_FAQ_LANG(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateServiceFaqRequest = ({ data, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.UPDATE_FAQS(data)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const changeServiceOrderRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.CHANGE_SERVICE_LIST_ORDER(payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const validateFields = ({ _state, fields = SERVICE_FIELDS }) => {
  let isValid = true;
  fields.forEach(({ isOptional, regex, _key }) => {
    if (isOptional) {
      return;
    }
    if (_state[_key] === undefined) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "This field can't be empty";
      return;
    }
    if (!((_state[_key] ?? "") + "")?.trim()?.length) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "Please enter a valid value";
      return;
    }
    if (regex && !regex.test(_state[_key])) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "Please enter a valid value";
      return;
    }
    _state[_key] = ((_state[_key] ?? "") + "")?.trim();
  });
  return { isValid, _state };
};
