import CustomDataGrid from "components/DataGrid";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getColumns } from "../constants";
import { useGetLanguageData } from "utils";
import Header from "components/HeaderV2";
import { Box, Card } from "@mui/material";
import { AddEditResponseMessage } from "./AddEditResponseMessage";
import { getResponseMessagesRequest } from "../utils";

const ApiResponseMessagesByKeyword = () => {
  const [modalData, setModalData] = useState(null);
  const { state } = useLocation();
  const { keyword } = useParams();
  const [data, setData] = useState(state.data || []);

  const activeLanguages = useSelector(({ common }) =>
    common.allLanguages?.map(({ languageId, languageName }) => ({
      label: languageName,
      value: languageId,
    }))
  );

  const { availableLanguages } = useGetLanguageData({ data });

  const closeModal = () => setModalData(null);

  const onSuccess = () => {
    getResponseMessages();
    closeModal();
  };

  const onAddPress = () => {
    setModalData({
      title: "Add New Response Message",
      data: { keyword },
      activeLanguages: activeLanguages,
      onSuccess,
    });
  };

  const onViewPress = (row) => {
    const { _id, languageId, keyword, message } = row;
    setModalData({
      viewOnly: true,
      title: "Response Message Details",
      data: { _id, languageId, keyword, message },
      activeLanguages: activeLanguages,
      onSuccess,
    });
  };

  const onEditPress = (row) => {
    const { _id, languageId, keyword, message } = row;
    setModalData({
      isEdit: true,
      title: "Edit Keyword And Message",
      data: { _id, languageId: languageId, keyword, message },
      activeLanguages: activeLanguages,
      onSuccess,
    });
  };

  const getResponseMessages = () => {
    getResponseMessagesRequest({
      onSuccess: (data) =>
        setData(
          data
            .filter((item) => item.keyword === keyword)
            .map((item, index) => ({ ...item, id: index }))
        ),
    });
  };

  return (
    <>
      <Box sx={{ my: 2 }}>
        <Card sx={{ px: 2, py: 1 }}>
          <Header
            title={`Response Messages (${keyword})`}
            buttons={[
              { title: "Add New Response Message", onClick: onAddPress },
            ]}
          />
        </Card>
      </Box>
      <CustomDataGrid
        rows={data}
        columns={getColumns({ availableLanguages, onViewPress, onEditPress })}
      />

      {!!modalData && (
        <AddEditResponseMessage closeModal={closeModal} modalData={modalData} />
      )}
    </>
  );
};

export default ApiResponseMessagesByKeyword;
