import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { APP_CONFIGURATION_SERVICES } from "utils";

export const getApplicationTagsRequest = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_APPLICATION_TAGS(query)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateApplicationTagsRequest = ({ id, payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.UPDATE_APPLICATION_TAGS(id, payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
