import { shallowEqual, useSelector } from "react-redux";

export const useGetLangData = ({ useAll = false, data = [] }) => {
  const allLanguages = useSelector(({ common }) => common.allLanguages) || [];
  const serviceLanguages =
    useSelector(({ services }) => services.serviceLanguages, shallowEqual) ||
    [];
  const languages = allLanguages.filter((item) =>
    serviceLanguages.includes(item.languageId)
  );
  const availableLanguages = (useAll ? allLanguages : languages)
    .filter((item) =>
      data.find((_node) => _node.languageId === item.languageId)
    )
    .map((lang) => ({
      ...lang,
      label: lang.languageName,
      value: lang.languageId,
    }));
  const unavailableLanguages = (useAll ? allLanguages : languages)
    .filter(
      (item) => item.isActive && !data.find((_node) => _node.languageId === item.languageId)
    )
    .map((lang) => ({
      ...lang,
      label: lang.languageName,
      value: lang.languageId,
    }));

  if (!availableLanguages.length) {
    const defaultLang = allLanguages[0] || {};
    availableLanguages.push({
      ...defaultLang,
      label: defaultLang.languageName,
      value: defaultLang.languageId,
    });
  }
  return {
    availableLanguages,
    defaultLanguageId: availableLanguages[0]?.languageId,
    unavailableLanguages,
    allLanguages: allLanguages.filter((item) => !!item.isActive).map((item) => ({
      label: item.languageName,
      value: item.languageId,
    })),
  };
};

export const useGetNodesData = () => {
  const serviceNodes =
    useSelector(({ services }) => services.serviceNodes) || [];
  const { defaultLanguageId, availableLanguages, unavailableLanguages } =
    useGetLangData({
      data: serviceNodes,
    });

  return {
    defaultLanguageId,
    availableLanguages,
    unavailableLanguages,
    serviceNodes,
  };
};

export const useGetServiceData = () => {
  const serviceInfo =
    useSelector(({ services }) => services.serviceInfo, shallowEqual) || [];
  const { defaultLanguageId, availableLanguages, unavailableLanguages } =
    useGetLangData({ useAll: true, data: serviceInfo });

  return {
    defaultLanguageId,
    availableLanguages,
    unavailableLanguages,
    serviceInfo,
  };
};
