import { Box, Card, CardContent, Typography } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchPayOrderInfo } from "./utils";
import { PAYORDER_FIELDS, getPayOrderJobsColumns } from "./constants";
import CustomDataGrid from "components/DataGrid";

export default function PayOrderDetail() {
  const [data, setData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  const onViewPress = (row) => navigate(`/jobs/${row._id}`);

  useEffect(() => {
    id &&
      fetchPayOrderInfo({
        id,
        onSuccess: setData,
      });
  }, [id]);

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header
            title={`Pay Order #${data._id}`}
            variant="h5"
            titleStyle={{ fontWeight: "medium" }}
          />
          {PAYORDER_FIELDS.map((item) => {
            const { _key, placeholder, renderCell } = item;

            if (!_key) return null;

            const value = renderCell ? renderCell(data[_key]) : data[_key];

            return (
              //   data[_key] && (
              <PayOrderDetailItem
                key={_key}
                data={{
                  _key,
                  label: placeholder,
                  value,
                }}
              />
              //   )
            );
          })}

          {data?.jobs?.length && (
            <>
              <Header
                title={`Jobs`}
                titleStyle={{ fontWeight: "medium" }}
                style={{
                  pb: "0px",
                  pt: "5px",
                  borderTop: "1px dashed",
                  mt: "5px",
                }}
              />
              <CustomDataGrid
                rows={data.jobs.map((item) => ({ ...item, id: item._id }))}
                columns={getPayOrderJobsColumns({ onViewPress })}
              />
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

const PayOrderDetailItem = ({ data }) => {
  const { label, value } = data;

  return (
    <Typography>
      <Typography
        component={"span"}
        sx={{ fontSize: "16px", fontWeight: "medium" }}
      >
        {label}:{" "}
      </Typography>
      <Typography
        component={"span"}
        sx={{
          cursor: "pointer",
          fontSize: "14px",
        }}
      >
        {value}
      </Typography>
    </Typography>
  );
};
