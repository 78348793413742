import { setLanguagesData, setLoading } from "redux/actions";
import { store } from "redux/store";
import { HTTP_SERVICE } from "../../utils";

export const getLanguagesRequest = ({ onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_LANGUAGE()
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess(
          data.map((item, index) => ({ ...item, id: item._id, index: index }))
        );
        dispatch(setLanguagesData(data));
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createLanguageRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.ADD_LANGUAGE({
    languageName: payload.languageName,
    languageCode: payload.languageCode,
  })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateLanguageRequest = ({ payload, id, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.UPDATE_LANGUAGE(
    { languageName: payload.languageName, languageCode: payload.languageCode },
    id
  )
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const changeLanguageOrderRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.CHANGE_LANGUAGE_LIST_ORDER(payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const handleStatusChange = ({ languageId, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.UPDATE_STATUS_LANGUAGE(languageId).then((res) => {
    if (res.isSuccess) {
      onSuccess();
    }
  });
};
