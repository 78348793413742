import { Button, Typography } from "@mui/material";
import { getErrorKey, getErrorText } from "components/CustomField";
import Header from "components/HeaderV2";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetServiceData } from "../hooks/useLanguagesData";
import { RenderNodeData } from "../ServiceNode/components/RenderNodeData";
import { AddLanguageModal } from "./components/AddLanguageModal";
import ConfigsSection from "./components/ConfigsSection";
import { SERVICE_FIELDS } from "./components/constants";
import KeywordsSection from "./components/KeywordsSection";
import { CONFIG_FIELDS } from "./constants";
import { createServiceRequest, updateServiceRequest } from "./utils";
import { validateFields } from "utils";

const validateData = (data) => {
  let isValidData = true;
  const payload = {
    serviceConfig: { parentType: "category" },
  };

  SERVICE_FIELDS.forEach(({ _key }) => {
    if (data[_key] && typeof data[_key] === "string") {
      payload[_key] = data[_key]?.trim();
    }
  });
  let _state = cloneDeep(data);
  const { isValid, _state: validatedState } = validateFields({
    _state,
    fields: SERVICE_FIELDS,
  });
  isValidData = isValid;
  _state = validatedState;

  CONFIG_FIELDS.forEach(
    ({ isOptional, regex, _key, dependentKey, hideOnDependentKeyValue }) => {
      if (isOptional) {
        return;
      }

      const value = _state.serviceConfig[_key];

      if (
        dependentKey &&
        (!_state.serviceConfig[dependentKey] ||
          _state.serviceConfig[dependentKey] === "false" ||
          _state.serviceConfig[dependentKey] === hideOnDependentKeyValue)
      ) {
        return;
      }
      if (value === undefined) {
        isValidData = false;
        _state.serviceConfig[getErrorKey(_key)] = true;
        _state.serviceConfig[getErrorText(_key)] = "This field can't be empty";
        return;
      }
      if (!((value ?? "") + "")?.trim()?.length) {
        isValidData = false;
        _state.serviceConfig[getErrorKey(_key)] = true;
        _state.serviceConfig[getErrorText(_key)] = "Please enter a valid value";
        return;
      }
      if (regex && !regex.test(value)) {
        isValidData = false;
        _state.serviceConfig[getErrorKey(_key)] = true;
        _state.serviceConfig[getErrorText(_key)] = "Please enter a valid value";
        return;
      }
      _state.serviceConfig[_key] = ((value ?? "") + "")?.trim();
      payload.serviceConfig[_key] = _state.serviceConfig[_key];
    }
  );
  return { isValidData, payload, data: _state };
};

export default function ServiceConfig({ serviceId }) {
  const {
    availableLanguages,
    serviceInfo,
    unavailableLanguages,
    defaultLanguageId,
  } = useGetServiceData();
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [showModal, toggleModal] = useState(false);
  const [state, setState] = useState({ serviceConfig: { searchKeyword: [] } });
  const navigate = useNavigate();

  useEffect(() => {
    defaultLanguageId && setSelectedLanguage(defaultLanguageId);
  }, [defaultLanguageId]);

  useEffect(() => {
    if (serviceInfo.length) {
      const data =
        serviceInfo.find((item) => item.languageId === selectedLanguage) || {};
      setState(data);
    }
  }, [serviceInfo, selectedLanguage]);

  const onChange = ({ value, _key }) => {
    setState((_state) => ({
      ..._state,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
      [_key]: value,
    }));
  };

  const onChangeConfig = ({ value, _key }) => {
    setState((_state) => {
      _state.serviceConfig = {
        ..._state.serviceConfig,
        [getErrorKey(_key)]: false,
        [getErrorText(_key)]: "",
        [_key]: value,
      };
      return { ..._state };
    });
  };
  const onSubmit = () => {
    const { payload, data, isValidData } = validateData(state);
    setState(data);
    if (!isValidData) {
      return;
    }
    const caller = serviceId ? updateServiceRequest : createServiceRequest;

    delete payload.thumbnail;

    payload.serviceConfig.searchKeyword =
      state.serviceConfig.searchKeyword || [];
    caller({
      payload: {
        ...payload,
        languageId: selectedLanguage,
      },
      serviceId,
      id: state._id,
      onSuccess: (id) => navigate(`/service-detail/${id}`),
    });
  };

  const onAddLanguagePress = () => toggleModal(true);
  const closeModal = () => toggleModal(false);

  return (
    <div
      style={{
        overflow: "auto",
        margin: "-16px",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        rowGap: 10,
      }}
    >
      {!!showModal && (
        <AddLanguageModal
          closeModal={closeModal}
          serviceId={serviceId}
          unavailableLanguages={unavailableLanguages}
        />
      )}
      <Header
        title="Basic Info"
        skip
        dropDown={{
          value: selectedLanguage,
          onChange: setSelectedLanguage,
          data: availableLanguages,
        }}
        buttons={
          serviceId
            ? [
                {
                  disabled: !unavailableLanguages.length,
                  title: "Add Language",
                  onClick: onAddLanguagePress,
                },
              ]
            : []
        }
      />
      <RenderNodeData
        onChange={onChange}
        id={serviceId}
        data={state}
        customMd={6}
        customFields={SERVICE_FIELDS}
        marginLeft="0px"
      />
      <KeywordsSection
        data={state.serviceConfig?.searchKeyword || []}
        onChange={onChangeConfig}
      />
      <ConfigsSection
        serviceId={serviceId}
        data={state.serviceConfig || {}}
        onChange={onChangeConfig}
      />
      <Typography
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Button variant="contained" sx={{ px: "20px" }} onClick={onSubmit}>
          {serviceId ? "Update" : "Create"}
        </Button>
      </Typography>
    </div>
  );
}
