import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { ConfirmationModal } from "components/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { setAuthToken, setProfileData } from "redux/actions";
import { ArrowBack, Home } from "@mui/icons-material";
const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,

  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function CustomAppBar({ toggleDrawer, open }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme2 = useTheme();
  const SizeLessThenMd = useMediaQuery(theme2.breakpoints.down("md"));
  const [modalData, setModalData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeModal = () => {
    setModalData(null);
  };

  const onLogoutPress = () => {
    setModalData({
      title: "Logout",
      description: "Are you sure, you want to logout?",
      footerButtons: [
        {
          onClick: () => setModalData(null),
          sx: { px: "20px", mx: "8px" },
          title: "Cancel",
          variant: "outlined",
        },
        {
          onClick: () => {
            setModalData(null);
            dispatch(setProfileData({}));
            dispatch(setAuthToken(""));
            navigate("/login", { replace: true });
          },
          sx: { px: "20px" },
          title: "Logout",
        },
      ],
    });
  };

  const isHome = window.location.pathname === "/";
  const { screenTitle } = useSelector(({ common }) => common);

  return (
    <AppBar
      elevation={2}
      sx={{
        // height: '48px',
        marginLeft: !SizeLessThenMd && open ? drawerWidth : "0",
        width:
          !SizeLessThenMd && open ? `calc(100% - ${drawerWidth}px)` : "100%",
        background: { xs: "#fff", md: "#66bb6a" },
        overflow: "hidden",
        zIndex: "999",
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          height: "auto",
          left: 0,
          p: 0,
          px: { xs: 1, sm: 3 },
          color: "black",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/*  hamburger menu icon  */}
        {SizeLessThenMd && isHome && (
          <IconButton
            onClick={toggleDrawer}
            sx={{
              // p: 0,
              display: {
                xs: "inline-flex",
              },
              color: "black",
            }}
          >
            <MenuIcon />
          </IconButton>
        )}

        {SizeLessThenMd && !isHome && (
          <IconButton
            onClick={() => navigate(-1)}
            sx={{
              display: {
                xs: "inline-flex",
              },
              color: "black",
            }}
          >
            <ArrowBack />
          </IconButton>
        )}

        {SizeLessThenMd && (
          <span style={{ fontWeight: 500 }}>{screenTitle}</span>
        )}

        {!SizeLessThenMd && <Box sx={{ flexGrow: 1 }} />}
        {!SizeLessThenMd || isHome ? (
          <Tooltip title="User Details">
            <IconButton onClick={handleMenu}>
              <Avatar
                sx={{ bgcolor: "#a5d6a7", color: "#1b5e20" }}
                color="#a5d6a7"
                alt="Remy Sharp"
              />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton sx={{ color: "#000" }} onClick={() => navigate("/")}>
            <Home />
          </IconButton>
        )}

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{ marginTop: "2.5rem", marginLeft: "-1rem" }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Link
              style={{
                color: "#000",
                textDecoration: "none !important",
              }}
              className="link"
              to="/profile"
            >
              <PersonIcon fontSize="small" sx={{ mr: 1 }} />
              Profile
            </Link>
          </MenuItem>
          <MenuItem onClick={() => console.log("change-password")}>
            <Link
              style={{
                color: "#000",
                textDecoration: "none !important",
              }}
              className="link"
              to="/change-password"
            >
              <LockOpenIcon fontSize="small" sx={{ mr: 1 }} />
              Change Password
            </Link>
          </MenuItem>
          <MenuItem onClick={onLogoutPress}>
            <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography>Logout</Typography>
          </MenuItem>

          {!!modalData && (
            <ConfirmationModal closeModal={closeModal} modalData={modalData} />
          )}
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
