import React, { useState } from "react";
import ModalWrapper from "components/ModalWrapper";
import { CustomField, getErrorKey, getErrorText } from "components";
import { Grid, Typography } from "@mui/material";
import { validateFields } from "utils";
import { CHARGES_FIELDS } from "../constants";
import { createChargesRequest, updateChargesRequest } from "../utils";

const initState = ({ languages, data }) => {
  const unavailableLanguages = languages.filter(
    (item) => !data.find(({ languageId }) => languageId === item.value)
  );

  const _state = unavailableLanguages.map((language) => {
    return { languageId: language.value, languageName: language.label };
  });

  return [...data, ..._state];
};

export const AddEditChargeTypeModal = ({
  data,
  languages,
  onSuccess = () => {},
  closeModal,
}) => {
  const isEdit = !!data;
  const [state, setState] = useState(initState({ languages, data: data || [] }));

  const validateData = () => {
    let isValidData = true;

    const tempState = languages.map((_, index) => {
      const { isValid, _state } = validateFields({
        _state: state[index] || {},
        fields: CHARGES_FIELDS,
      });

      if (!isValid) {
        isValidData = false;
      }
      return _state;
    });

    setState([...tempState]);
    return isValidData;
  };

  const onChange = ({ _key, value, languageId, index }) => {
    let temp = state;
    temp[index] = {
      ...temp[index],
      [_key]: value,
      languageId,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
    };
    setState([...temp]);
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    const payload = {
      data: state.map(({ _id, languageId, chargeName, description }) => ({
        _id,
        languageId,
        chargeName,
        description,
      })),
    };

    const caller = isEdit ? updateChargesRequest : createChargesRequest;

    caller({
      payload,
      onSuccess: onSuccess,
    });
  };

  return (
    <ModalWrapper
      footerButtons={[
        {
          title: "Submit",
          onClick: onSubmitPress,
        },
      ]}
      title={`${isEdit ? "Edit" : "Add"} Charges`}
      closeModal={closeModal}
      sx={{ width: "50%" }}
      childSx={{ height: "50vh", p: 2, overflow: "auto" }}
    >
      <Grid container rowSpacing={2} spacing={1}>
        {state.map(({ languageId, languageName }, index) => {
          return (
            <Grid key={languageId} item md={12} sm={12} xs={12}>
              <Typography component={"span"} sx={{ fontSize: "18px" }}>
                {languageName}
              </Typography>

              <Grid
                container
                rowSpacing={2}
                spacing={1}
                sx={{ mt: 0.5, mb: index > 0 ? 0.5 : 0 }}
              >
                {CHARGES_FIELDS.map((item) => {
                  const { _key } = item;

                  if (!_key) {
                    return null;
                  }

                  return (
                    <Grid key={_key} item md={12} sm={12} xs={12}>
                      <CustomField
                        color={"primary"}
                        item={item}
                        errorText={
                          state[index] && state[index][getErrorText(_key)]
                        }
                        error={state[index] && state[index][getErrorKey(_key)]}
                        value={state[index] && state[index][_key]}
                        onChange={({ _key, value }) =>
                          onChange({
                            _key,
                            value,
                            languageId,
                            index,
                          })
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </ModalWrapper>
  );
};
