import { REHYDRATE } from "redux-persist";
import { setAuthToken } from "utils/Services";
import {
  RESET,
  setScreenTitle,
  SET_AUTH_TOKEN,
  SET_LANGUAGES_DATA,
  SET_LOADING,
  SET_PROFILE_DATA,
  SET_SCREEN_TITLE,
} from "../actions";

const initialState = {
  authToken: "",
  languagesMap: {},
  allLanguages: [],
  loading: {},
  userData: {},
  allActiveLanguages: [],
  screenTitle: "",
};

const CommonReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_LANGUAGES_DATA:
      const allActiveLanguages = action.payload?.filter((obj) => obj.isActive);
      const languagesMap = {};
      action.payload.forEach((item) => (languagesMap[item.languageId] = item));
      return {
        ...state,
        languagesMap,
        allActiveLanguages,
        allLanguages: action.payload || [],
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_PROFILE_DATA:
      return {
        ...state,
        userData: {
          ...(action.payload || {}),
          canWrite: action.payload?.userAccessType === "write",
        },
      };
    case SET_AUTH_TOKEN:
      setAuthToken(action.payload);
      return {
        ...state,
        authToken: action.payload,
      };

    case SET_SCREEN_TITLE:
      setScreenTitle(action.payload);
      return {
        ...state,
        screenTitle: action.payload,
      };

    case REHYDRATE:
      return {
        ...initialState,
        ...((action.payload || {}).common || {}),
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default CommonReducer;
