const { INPUT_TYPES } = require("components");

export const TOPIC_FIELDS = [
  {
    _key: "languageId",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Language",
  },
  {
    _key: "topic",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Topic",
  },
];

export const FAQ_FIELDS = [
  {
    _key: "question",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Question",
  },
  {
    _key: "answer",
    inputType: INPUT_TYPES.TEXT_AREA,
    placeholder: "Answer",
    extraProps: { minRows: 3 },
  },
];
