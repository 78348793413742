import { useEffect } from "react";
import { useSelector } from "react-redux";
import { setAuthToken } from "utils/Services";

export const useIsLoggedIn = () => {
  const authToken = useSelector(({ common }) => common.authToken);
  useEffect(() => {
    authToken && setAuthToken(authToken);
  }, [authToken]);
  return !!authToken;
};

export const useHasWriteAccess = () => {
  const canWrite =
    useSelector(({ common }) => common.userData.canWrite) || false;

  return canWrite;
};
