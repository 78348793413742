import CustomDataGrid from "components/DataGrid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getResponseMessagesRequest } from "./utils";
import { getColumns } from "./constants";
import { useGetLanguageData } from "utils";
import Header from "components/HeaderV2";
import { Box, Card } from "@mui/material";
import { AddEditResponseMessage } from "./components/AddEditResponseMessage";

const ApiResponseMessages = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [modalData, setModalData] = useState();
  const closeModal = () => setModalData(null);

  const { availableLanguages, defaultLanguageId } =
    useGetLanguageData({ data }) || {};

  const onViewPress = (row) => {
    navigate(`/api-response-messages/${row.keyword}`, {
      state: {
        data: data
          .filter(({ keyword }) => row.keyword === keyword)
          .map((item, index) => ({ ...item, id: index + 1 })),
      },
    });
  };

  const onSuccess = () => {
    getResponseMessages();
    closeModal();
  };

  const onEditPress = (row) =>
    setModalData({
      title: "Edit Keyword",
      data: row,
      isEdit: true,
      activeLanguages: availableLanguages,
      onSuccess,
    });

  const onAddPress = () =>
    setModalData({
      title: "Add Keyword",
      data: null,
      activeLanguages: availableLanguages,
      onSuccess,
    });

  const getResponseMessages = () => {
    getResponseMessagesRequest({ onSuccess: setData });
  };

  useEffect(() => {
    getResponseMessages();
  }, []);

  useEffect(() => {
    defaultLanguageId && setSelectedLanguage(defaultLanguageId);
  }, [defaultLanguageId]);

  return (
    <>
      <Box sx={{ my: 2 }}>
        <Card sx={{ px: 2, py: 1 }}>
          <Header
            title="Response Messages"
            dropDown={{
              value: selectedLanguage,
              onChange: setSelectedLanguage,
              data: availableLanguages,
            }}
            buttons={[
              {
                title: "Add Keyword",
                onClick: onAddPress,
              },
            ]}
          />
        </Card>
      </Box>
      <CustomDataGrid
        rows={data
          .filter(({ languageId }) => languageId === selectedLanguage)
          .map((item, index) => ({ ...item, id: index + 1 }))}
        columns={getColumns({ availableLanguages, onViewPress, onEditPress })}
      />

      {!!modalData && (
        <AddEditResponseMessage
          closeModal={closeModal}
          modalData={modalData}
          isKeyword={true}
        />
      )}
    </>
  );
};

export default ApiResponseMessages;
