import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setScreenTitle } from "redux/actions";
import Chart from "react-apexcharts";

export const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenTitle("Home"));
  }, [dispatch]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <Chart
        options={{}}
        series={[44, 55, 41, 17, 15]}
        type="donut"
        width="380"
      />
    </div>
  );
};
