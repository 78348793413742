import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { CustomField, INPUT_TYPES } from "components";
import { getErrorKey, getErrorText, validateFields } from "utils";
import Header from "components/HeaderV2";
import { getAppConfigRequest, updateAppConfigRequest } from "./utils";
import { AddFieldModal } from "./components/AddFieldModal";
import { Close } from "@mui/icons-material";
import { EditConfigModal } from "./components/EditConfigModal";

const Config = () => {
  const [state, setState] = useState({});
  const [modalData, setModalData] = useState(null);

  const closeModal = () => setModalData(null);
  const onSuccessCallback = () => {
    getConfig();
    closeModal();
  };

  const onAddField = () => setModalData({ isEdit: false });
  const onEdit = () => setModalData({ isEdit: true });

  const getConfig = () => {
    getAppConfigRequest({
      onSuccess: setState,
    });
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <Box sx={{ my: 2 }}>
      <Card>
        <CardContent>
          <Header
            title={"App Config"}
            buttons={[
              {
                title: "Add New Field",
                onClick: onAddField,
              },
              {
                title: "Edit",
                onClick: onEdit,
              },
            ]}
          />
          <Grid sx={{ mt: 4 }} container rowSpacing={2} spacing={1}>
            {state.config &&
              Object.keys(state.config).map((_key) => {
                return (
                  <Grid key={_key} item md={4} sm={12} xs={12}>
                    <CustomField
                      color={"primary"}
                      item={{
                        _key,
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: _key,
                      }}
                      value={state.config && state.config[_key]}
                      disabled={true}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </CardContent>
      </Card>

      {!!modalData && !modalData.isEdit && (
        <AddFieldModal
          data={state}
          closeModal={closeModal}
          onSuccessCallback={onSuccessCallback}
        />
      )}

      {!!modalData && !!modalData.isEdit && (
        <EditConfigModal
          data={state}
          closeModal={closeModal}
          onSuccessCallback={onSuccessCallback}
        />
      )}
    </Box>
  );
};

export default Config;
