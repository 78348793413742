import ModalWrapper from "components/ModalWrapper";
import React from "react";
import JobDurationSelector from "screens/Bookings/JobDurationSelector";

export default function JobScheduleModal({
  endTime,
  jobScheduledTime,
  jobFrequency,
  perDayDuration,
  duration,
  closeModal,
}) {
  let state = {
    jobFrequency,
    hours: Math.floor(+perDayDuration / 60).toString(),
    minutes: Math.floor(+perDayDuration % 60).toString(),
    daysCount: 0,
    monthsCount: 0,
    endTime,
  };

  switch (jobFrequency) {
    case "MN":
      state.monthsCount = +duration;
      break;

    default:
      state.daysCount = +duration;
      break;
  }

  return (
    <ModalWrapper
      title={"Job Schedule"}
      closeModal={closeModal}
      sx={{ width: "70%" }}
      childSx={{ height: "82vh", mb: "0px !important" }}
    >
      <JobDurationSelector
        isDisabled={true}
        jobScheduledTime={jobScheduledTime}
        state={state}
      />
    </ModalWrapper>
  );
}
