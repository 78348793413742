import { Box, Card, CardContent, Typography } from "@mui/material";
import { CustomField, INPUT_TYPES } from "components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "./table.css";
import { useParams, useSearchParams } from "react-router-dom";
import qs from "query-string";
import { formatJobScheduledTime, queryString } from "utils";
import { getProviderCalendarRequest } from "../ProviderList/utils";
import { formatSlotsData, getWeek } from "./helper";

export default function ProviderCalendar() {
  const [searchParams] = useSearchParams();

  const [date, setDate] = useState(
    qs.parse(searchParams.toString())?.date || new Date()
  );
  const onChange = ({ value }) => setDate(value);
  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <CustomField
            value={date}
            onChange={onChange}
            item={{
              inputType: INPUT_TYPES.DATE,
              placeholder: "Date",
            }}
          />
          <Table date={date} />
        </CardContent>
      </Card>
    </Box>
  );
}

const DEFAULT_SLOTS = [...new Array(24)];
const DEFAULT_DAYS = [...new Array(7)];

const Table = ({ date }) => {
  const [week, setWeek] = useState([]);

  useEffect(() => {
    setWeek(getWeek(new Date(date)));
  }, [date]);

  return (
    <div className="table-container">
      <div style={{ position: "relative" }}>
        <div
          style={{
            width: "75px",
            height: "75px",
            backgroundColor: "#fff",
            position: "sticky",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
        {DEFAULT_SLOTS.map((item, index) => (
          <div
            key={index}
            style={{
              zIndex: 0,
              width: "10px",
              right: "-65px",
              height: "75px",
              borderTop: "1px solid #ddd",
              position: "relative",
            }}
          >
            <p
              style={{
                position: "absolute",
                top: "-30px",
                left: "-44px",
                whiteSpace: "nowrap",
              }}
            >
              {index ? `${index % 12} ${index > 11 ? "PM" : "AM"}` : ""}
            </p>
          </div>
        ))}
      </div>
      <table className="calendar-table">
        <thead style={{ zIndex: 2 }} className="calendar-thead">
          <tr>
            {week.map((item, index) => {
              const isSameDate = moment(item).isSame(new Date(), "date");
              return (
                <th
                  className="table-container-th"
                  key={index}
                  style={{
                    width: "124px",
                    height: "75px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ color: isSameDate ? "#1b5e20" : "black" }}>
                      {moment(item).format("ddd")}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "44px",
                        backgroundColor: isSameDate && "#1b5e20",
                        width: "44px",
                        height: "44px",
                      }}
                    >
                      <Typography
                        style={{ color: isSameDate ? "white" : "black" }}
                        variant="h6"
                      >
                        {moment(item).format("D")}
                      </Typography>
                    </div>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          <RenderSlots startDate={week[0]} endDate={week[6]} />
          {DEFAULT_SLOTS.map((row, index) => (
            <tr key={index}>
              {DEFAULT_DAYS.map((row, _index) => (
                <td
                  className="table-container-td"
                  key={_index}
                  style={{ width: "124px", height: "75px" }}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const RenderSlots = ({ startDate, endDate }) => {
  const { id } = useParams();
  const [slots, setSlots] = useState([]);

  useEffect(() => {
    if (id && startDate && endDate) {
      getProviderCalendarRequest({
        query: queryString({
          startDate: moment(startDate).toISOString(),
          endDate: moment(endDate)
            .set("hours", 24)
            .set("minutes", 0)
            .set("seconds", 0)
            .toISOString(),
          providerId: id,
        }),
        onSuccess: (result) => {
          setSlots(formatSlotsData({ startDate, slots: result }));
        },
      });
    }
  }, [id, startDate, endDate]);

  return (
    <div
      style={{
        display: "grid",
        zIndex: 0,
        position: "absolute",
        gridTemplateColumns: "repeat(7,124px)",
      }}
    >
      {slots.map((rows, _index) =>
        rows.map((slot, index) => (
          <RenderItem left={_index * 124} key={index} slot={slot} />
        ))
      )}
    </div>
  );
};

const RenderItem = ({ width, left, slot }) => {
  const { startDate: _start, endDate: _end, child = [] } = slot;

  const startDate = formatJobScheduledTime(_start);
  const endDate = formatJobScheduledTime(_end);

  const top =
    (moment(startDate).diff(
      moment(startDate)
        .set("hours", 0)
        .set("minutes", 0)
        .set("seconds", 0)
        .set("milliseconds", 0),
      "minutes"
    ) /
      60) *
    75;
  const finalWidth = width || 124 / (child.length + 1) - 1;
  const height = (moment(endDate).diff(moment(startDate), "minutes") / 60) * 75;
  return (
    <>
      <div
        style={{
          width: `${finalWidth}px`,
          borderRadius: "8px",
          border: "1px solid #fff",
          height: `${height}px`,
          top: `${top}px`,
          left: `${left}px`,
          backgroundColor: "#1b5e20",
          position: "absolute",
        }}
      />
      {child.map((item, index) => (
        <RenderItem
          key={index}
          left={left + (index + 1) + finalWidth * (index + 1)}
          width={finalWidth}
          slot={item}
        />
      ))}
    </>
  );
};
