import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { APP_CONFIGURATION_SERVICES, successToast } from "utils";

export const getAppConfigRequest = ({ onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_CONFIG()
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateAppConfigRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.UPDATE_CONFIG(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        successToast("Config updated successfully!");
        onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
