export const UPDATE_CURRENT_SERVICE_NODES_DATA =
  "UPDATE_CURRENT_SERVICE_NODES_DATA";
export const UPDATE_CURRENT_SERVICE_DATA = "UPDATE_CURRENT_SERVICE_DATA";
export const UPDATE_CURRENT_ENROLLMENT_DATA = "UPDATE_CURRENT_ENROLLMENT_DATA";

export const updateCurrentServiceNodesData = (payload) => ({
  type: UPDATE_CURRENT_SERVICE_NODES_DATA,
  payload,
});

export const updateCurrentServiceData = (payload) => ({
  type: UPDATE_CURRENT_SERVICE_DATA,
  payload,
});

export const updateCurrentEnrollmentData = (payload) => ({
  type: UPDATE_CURRENT_ENROLLMENT_DATA,
  payload,
});
