import { Close } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import {
  CustomField,
  getErrorKey,
  getErrorText,
  INPUT_TYPES,
} from "../../../../components/CustomField";
import RenderNodeItems from "./RenderNodesItems";
import {
  getDistrictsRequest,
  getPinCodesRequest,
  getStatesRequest,
} from "../ServiceLocation/utils";
import ModalWrapper from "../../../../components/ModalWrapper";
import { addServicePriceRequest, updateServicePriceRequest } from "./utils";
import { errorToast } from "utils";
import { REGEX } from "./constants";

const validateFields = ({ _state, isNode }) => {
  const fieldsData = isNode
    ? []
    : [
        {
          inputType: INPUT_TYPES.INPUT,
          _key: "value",
          shouldBeSame: true,
          regex: REGEX.DECIMALS,
          placeholder: "Value",
        },
      ];
  let isValid = true;
  fieldsData.forEach(({ isOptional, regex, _key }) => {
    if (isOptional) {
      return;
    }
    if (_state[_key] === undefined) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "This field can't be empty";
      return;
    }
    if (!((_state[_key] ?? "") + "")?.trim()?.length) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "Please enter a valid value";
      return;
    }
    if (regex && !regex.test(_state[_key])) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "Please enter a valid value";
      return;
    }
    _state[_key] = ((_state[_key] ?? "") + "")?.trim();
  });
  if (_state.children?.length) {
    _state.children.forEach((item, index) => {
      const { _state: newState, isValid: isValidData } = validateFields({
        _state: item,
        isNode: !isNode,
      });
      _state.children[index] = newState;
      if (!isValidData) {
        isValid = false;
      }
    });
  }
  if (_state.items?.length) {
    _state.items.forEach((item, index) => {
      const { _state: newState, isValid: isValidData } = validateFields({
        _state: item,
        isNode: !isNode,
      });
      _state.items[index] = newState;
      if (!isValidData) {
        isValid = false;
      }
    });
  }
  return { isValid, _state };
};

const initState = ({ isNode, data, values = [] }) => {
  const _data = cloneDeep(data);
  return _data.map((node) => {
    if (isNode) {
      if (node?.items?.length) {
        node.items = initState({ data: node.items, isNode: !isNode, values });
      }
    } else {
      node.defaultValue = node.value;
      const index = values.findIndex((_item) => _item.itemId === node.itemId);
      if (index >= 0) {
        node.value = values[index].value;
      }
      if (node?.children?.length) {
        node.children = initState({
          data: node.children,
          isNode: !isNode,
          values,
        });
      }
    }
    return { ...node };
  });
};

const generatePayload = (state) => {
  const finalItemsArray = [];
  state.forEach((node) => {
    node?.items?.forEach((item) => {
      if (Number(item.defaultValue) !== Number(item.value)) {
        finalItemsArray.push({
          itemId: item.itemId,
          value: Number(item.value),
        });
      }
      if (item?.children?.length) {
        finalItemsArray.push(...generatePayload(item?.children));
      }
    });
  });
  return finalItemsArray;
};

export const AddPriceModal = ({
  serviceId,
  closeModal,
  data,
  fetchData,
  modalData = {},
}) => {
  const { isEdit, _id: id, pincodes = [], values = [], name = "" } = modalData;
  const [state, setState] = useState(initState({ data, isNode: true, values }));
  const [title, setTitle] = useState(name);
  const [dropDownData, setDropDownData] = useState({});
  const [selectedPincodes, setSelectedPincodes] = useState(pincodes);

  useEffect(() => {
    getStatesRequest({
      onSuccess: (statesData) =>
        setDropDownData((_state) => ({ ..._state, statesData })),
    });
  }, []);

  const onUpdateData = (childData, ind) => {
    setState((_state) => {
      _state[ind] = childData;
      return [..._state];
    });
  };

  const onChangeState = ({ value }) => {
    setDropDownData((_state) => ({
      ..._state,
      state: value,
      districtsData: [],
      pinCodesData: [],
      district: "",
    }));
    getDistrictsRequest({
      state: value,
      onSuccess: (districtsData) =>
        setDropDownData((_state) => ({ ..._state, districtsData })),
    });
  };

  const onChangeDistrict = ({ value }) => {
    setDropDownData((_state) => ({
      ..._state,
      pinCodesData: [],
      district: value,
    }));
    getPinCodesRequest({
      state: dropDownData.state,
      district: value,
      onSuccess: (pinCodesData) =>
        setDropDownData((_state) => ({ ..._state, pinCodesData })),
    });
  };

  const validateData = () => {
    let isValidData = true;
    const clonedState = cloneDeep(state);
    clonedState.forEach((item, index) => {
      const { _state, isValid } = validateFields({
        _state: item,
        isNode: true,
      });
      clonedState[index] = _state;
      if (!isValid) {
        isValidData = false;
      }
    });
    setState(clonedState);
    return isValidData;
  };

  const onSubmitPress = () => {
    if (!selectedPincodes.length || !validateData() || !title.length) {
      if (!title.length) {
        errorToast("Please add Price Tag");
      }
      return;
    }
    const _values = generatePayload(state);
    if (!_values.length) {
      return;
    }
    const caller = id ? updateServicePriceRequest : addServicePriceRequest;
    caller({
      id,
      payload: {
        serviceId,
        name: title,
        pincodes: selectedPincodes,
        values: _values,
      },
      onSuccess: () => {
        closeModal();
        fetchData();
      },
    });
  };
  const hasState = !!dropDownData.state;
  const hasPinCode = !!dropDownData.pinCodesData?.length;
  return (
    <ModalWrapper
      footerButtons={[
        { title: isEdit ? "Update" : "Create", onClick: onSubmitPress },
      ]}
      closeModal={closeModal}
      title={isEdit ? "Edit Price" : "Add New Price"}
    >
      {!!selectedPincodes.length && (
        <>
          <Typography sx={{ marginBottom: "8px" }} variant="body1">
            Selected PIN Codes
          </Typography>
          <div
            style={{
              marginBottom: "16px",
              flexWrap: "wrap",
              display: "flex",
              gap: 12,
            }}
          >
            {selectedPincodes.map((item, index) => {
              const onRemovePress = () => {
                setSelectedPincodes((_state) => [
                  ..._state.slice(0, index),
                  ..._state.slice(index + 1),
                ]);
              };
              return (
                <div
                  key={item}
                  style={{
                    borderRadius: "8px",
                    display: "flex",
                    paddingLeft: "8px",
                    alignItems: "center",
                    background: "#f0fdf4",
                    gap: 4,
                  }}
                >
                  <Typography variant="subtitle2">{item}</Typography>
                  <Tooltip title={"Remove"}>
                    <IconButton onClick={onRemovePress}>
                      <Close fontSize="12" />
                    </IconButton>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        </>
      )}
      <Grid container sx={{ mb: "16px" }} spacing={1}>
        <Grid item sm={12} xs={12} md={hasPinCode ? 4 : hasState ? 6 : 12}>
          <CustomField
            value={dropDownData.state}
            onChange={onChangeState}
            item={{
              inputType: INPUT_TYPES.DROPDOWN,
              placeholder: "Select State",
              extraData: dropDownData.statesData,
            }}
          />
        </Grid>
        {hasState && (
          <Grid item sm={12} xs={12} md={hasPinCode ? 4 : 6}>
            <CustomField
              value={dropDownData.district}
              onChange={onChangeDistrict}
              item={{
                inputType: INPUT_TYPES.DROPDOWN,
                placeholder: "Select District",
                extraData: dropDownData.districtsData,
              }}
            />
          </Grid>
        )}
        {hasPinCode && (
          <Grid item sm={12} xs={12} md={4}>
            <CustomField
              value={dropDownData.district}
              onChange={({ value }) =>
                setSelectedPincodes((_state) => {
                  if (_state.includes(value)) {
                    return _state;
                  }
                  return [..._state, value];
                })
              }
              item={{
                inputType: INPUT_TYPES.DROPDOWN,
                placeholder: "Select PIN Code",
                extraData: dropDownData.pinCodesData,
              }}
            />
          </Grid>
        )}
      </Grid>
      {!!selectedPincodes.length && (
        <CustomField
          value={title}
          error={!title.length}
          onChange={({ value }) => setTitle(value)}
          item={{
            extraProps: { sx: { marginBottom: "16px" } },
            inputType: INPUT_TYPES.INPUT,
            placeholder: "Price Tag",
          }}
        />
      )}

      {!!selectedPincodes.length &&
        state.map((data, index) => (
          <RenderNodeItems
            itemsLength={state.length}
            index={index}
            onUpdateData={onUpdateData}
            ignoreMargin
            hardDisable
            isNode
            data={data}
            key={data._id}
          />
        ))}
    </ModalWrapper>
  );
};
