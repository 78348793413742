import React, { useEffect, useState } from "react";
import { HeaderTitle } from "../../components/header";
import { HTTP_SERVICE, customSortComparatorForNums } from "../../utils";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Switch from "@mui/material/Switch";
import {
  changeLanguageOrderRequest,
  getLanguagesRequest,
  handleStatusChange,
} from "./utils";
import { AddEditLanguageModal } from "./components/AddEditLanguageModal";
import CustomDataGrid from "components/DataGrid";
import { useDispatch } from "react-redux";
import { setLoading, setScreenTitle } from "redux/actions";
import moment from "moment";
import { ExpandCircleDown } from "@mui/icons-material";
const label = { inputProps: { "aria-label": "Category isActive" } };

export const LanguagesList = () => {
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const dispatch = useDispatch();

  const loadLanguages = () => {
    getLanguagesRequest({ onSuccess: setData });
  };

  useEffect(() => {
    loadLanguages();
    dispatch(setScreenTitle("Languages"));
  }, [dispatch]);

  const onAddLanguage = () => {
    setModalData({
      title: "Add New Language",
      isUpdating: false,
      data: {},
    });
  };

  const onUpdateLanguage = (selectedLanguage) => {
    setModalData({
      title: "Update Language",
      isUpdating: true,
      data: selectedLanguage,
    });
  };

  const moveLanguageUp = (languageId) => {
    changeLanguageOrderRequest({
      payload: {
        id: languageId,
        event: "decrement",
      },
      onSuccess: () => loadLanguages(),
    });
  };

  const moveLanguageDown = (languageId) => {
    changeLanguageOrderRequest({
      payload: {
        id: languageId,
        event: "increment",
      },
      onSuccess: () => loadLanguages(),
    });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: true, hide: true },
    {
      field: "languageId",
      headerName: "Language Id",
      width: 150,
      sortComparator: customSortComparatorForNums,
    },
    {
      field: "languageName",
      headerName: "Language Name",
      width: 250,
    },
    {
      field: "updatedAt",
      headerName: "Update At",
      width: 300,
      renderCell: ({ row }) =>
        moment(new Date(row.updatedAt)).format("DD MMM YYYY, H:mm"),
    },
    {
      field: "isActive",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <Switch
            {...label}
            disabled={+cellValues?.row?.languageId === 1}
            checked={cellValues?.row?.isActive}
            onChange={() =>
              handleStatusChange({
                languageId: cellValues?.row?.languageId,
                onSuccess: loadLanguages,
              })
            }
          />
        );
      },
    },
    {
      field: "Update",
      headerName: "Update",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <div>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              sx={{ mr: 1 }}
              onClick={() => onUpdateLanguage(row)}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </div>
        );
      },
    },
    {
      field: "changeOrder",
      headerName: "Order",
      width: 200,
      renderCell: ({ row }) => {
        const { index, _id } = row;
        const color = "primary";
        return (
          <Typography component={"div"} sx={{ display: "flex" }}>
            <IconButton
              onClick={() => moveLanguageUp(_id)}
              disabled={!index}
              style={{ rotate: "180deg" }}
            >
              <Tooltip title="Move Up">
                <ExpandCircleDown color={index ? color : "disabled"} />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={() => moveLanguageDown(_id)}
              disabled={index === data?.length - 1}
            >
              <Tooltip title="Move Down">
                <ExpandCircleDown
                  color={index === data?.length - 1 ? "disabled" : color}
                />
              </Tooltip>
            </IconButton>
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <HeaderTitle
        heading="Add Language"
        btn={true}
        btnTitle="Add Language"
        btnAction={onAddLanguage}
      />
      <CustomDataGrid rows={data} columns={columns} />
      {!!modalData && (
        <AddEditLanguageModal
          closeModal={() => setModalData(null)}
          modalData={modalData}
          loadLanguages={loadLanguages}
        />
      )}
    </>
  );
};
