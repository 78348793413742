import { Button, Typography } from "@mui/material";
import React from "react";

export default function ErrorPage({ onHomePress }) {
  return (
    <Typography
      sx={{
        background: "#a5d6a7",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      component={"div"}
    >
      <Typography
        sx={{
          maxWidth: 600,
          padding: "20px",
          background: "white",
          borderRadius: "8px",
          boxShadow: `0 2px 4px rgba(0, 0, 0, 0.1)`,
          textAlign: "center",
        }}
        component={"div"}
      >
        <h1 style={{ fontSize: "28px", mb: "20px" }}>
          Oops, Something Went Wrong
        </h1>
        <p style={{ fontSize: "18px", mb: "20px" }}>
          We apologize for the inconvenience. Our team has been notified and is
          working to fix the issue.
        </p>
        <p style={{ fontSize: "18px", mb: "20px" }}>
          An unexpected error occurred while processing your request. Please try
          again later.
        </p>
        <p style={{ fontSize: "18px", mb: "20px" }}>
          If you need further assistance, please contact support at{" "}
          <a href="mailto:support@example.com">support@example.com</a>.
        </p>
        <Button
          color="primary"
          variant="contained"
          onClick={onHomePress}
        >
          Return to Home
        </Button>
      </Typography>
    </Typography>
  );
}
