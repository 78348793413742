import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import { ScrollToTop } from "components/ScrollToTop";
import { getProtectedRoutes } from "./utils";
import { useSelector } from "react-redux";
import Logo from "../assets/image/logo.png";

export const MyRoutes = () => {
  const userRole = useSelector(({ common }) => common.userData?.userRole);
  const userPrivilege = useSelector(
    ({ common }) => common.userData?.authorityType
  );

  return (
    <Suspense
      fallback={
        <main className="flex items-center justify-center w-screen h-screen">
          <img
            src={Logo}
            style={{ height: "50px", width: "auto" }}
            alt="logo"
          />
        </main>
      }
    >
      <ScrollToTop>
        <Routes>
          {getProtectedRoutes({ userRole, userPrivilege }).map(
            ({ Component, path }) => (
              <Route key={path} path={path} element={<Component />} />
            )
          )}
          <Route
            path="/*"
            element={
              <main
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "80vh",
                }}
              >
                <p
                  style={{
                    fontSize: "100px",
                    lineHeight: "100px",
                    fontWeight: 600,
                    margin: 0,
                  }}
                >
                  404
                </p>
                <p style={{ fontSize: "18px", margin: 0, fontWeight: 500 }}>
                  PAGE NOT FOUND
                </p>
              </main>
            }
          />
        </Routes>
      </ScrollToTop>
    </Suspense>
  );
};
