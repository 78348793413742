import { Typography } from "@mui/material";
import React from "react";
import amountFormat from "utils/amountFormat";

export default function RenderCharges({ totalAmount, data, invoicePDF }) {
  return (
    <Typography
      component={"div"}
      sx={{
        position: "relative",
        border: "1.5px solid grey",
        borderRadius: "20px",
        minHeight: "420px",
      }}
    >
      {/* Heading */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 14px",
          borderBottom: "1.5px solid grey",
          fontSize: "18px",
          fontWeight: 500,
        }}
      >
        <span>Added Services</span>
        {invoicePDF && (
          <span
            style={{
              fontSize: "14px",
              textDecoration: "underline",
              textTransform: "uppercase",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={() => [window.open(invoicePDF)]}
          >
            Invoice
          </span>
        )}
      </div>
      <div style={{ padding: "10px 14px", marginBottom: "45px" }}>
        {data.map(({ _id, voucherType, amount, description }, index) => (
          <div
            key={_id}
            style={{
              display: "flex",
              borderTop: index > 0 ? "1.5px dashed black" : "none",
              padding: "4px 0",
              alignItems: "center",
              fontSize: "16px",
            }}
          >
            <Typography style={{ flex: 1 }} variant="subtitle1">
              {description}
            </Typography>
            <Typography variant="subtitle1">
              {`${voucherType === "debit" ? "" : "- "}₹${amountFormat.format(
                amount
              )}`}
            </Typography>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 14px",
          borderTop: "1.5px solid grey",
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
        }}
      >
        <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
          Total Amount
        </Typography>
        <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
          ₹{amountFormat.format(totalAmount)}
        </Typography>
      </div>
    </Typography>
  );
}
