import { setLoading } from "../../redux/actions";
import { store } from "../../redux/store";
import { AUTH_SERVICES } from "utils";

export const getDashboardUsersRequest = ({ onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  AUTH_SERVICES.GET_DASHBOARD_USER()
    .then(({ isSuccess, data }) => {
      if (isSuccess) {
        onSuccess &&
          onSuccess(
            data.map((item, index) => ({
              ...item,
              id: item._id,
              index: index + 1,
            }))
          );
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createDashboardUserRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  AUTH_SERVICES.CREATE_DASHBOARD_USER(payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess && onSuccess(data._id);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateDashboardUserRequest = ({ onSuccess, payload, id }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  AUTH_SERVICES.UPDATE_DASHBOARD_USER({ id, payload })
    .then(({ isSuccess }) => {
      isSuccess && onSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateDashboardUserStatusRequest = ({
  onSuccess,
  payload,
  id,
}) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  AUTH_SERVICES.UPDATE_DASHBOARD_USER_STATUS({ id, payload })
    .then(({ isSuccess }) => {
      isSuccess && onSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getDashboardUserInfoRequest = ({ onSuccess, id }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  AUTH_SERVICES.GET_DASHBOARD_USER_INFO(id)
    .then(({ isSuccess, data }) => {
      if (isSuccess) {
        onSuccess && onSuccess(data[0]);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
