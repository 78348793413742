import React, { useEffect } from "react";
import { Card, Box, CardContent } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Navbar } from "../../../components/header";
import ServiceConfig from "./ServiceConfig";
import ServiceNode from "./ServiceNode";
import { getServiceInfo } from "../utils";
import FaqScreen from "./Faq";
import ServiceLocation from "./ServiceLocation";
import ServicePrice from "./ServicePrice";
import TrainingVideos from "./TrainingVideos";
import { updateCurrentServiceData } from "redux/actions/services";
import { useDispatch } from "react-redux";
import { setScreenTitle } from "redux/actions";
import ServiceCharges from "./ServiceCharges";
import SpareParts from "./SpareParts";
import Reasons from "./Reasons";

const NAV_DATA = [
  { title: "Config", Component: ServiceConfig },
  { title: "Nodes", Component: ServiceNode },
  { title: "FAQs", Component: FaqScreen },
  { title: "Price", Component: ServicePrice },
  { title: "Locations", Component: ServiceLocation },
  { title: "Training Videos", Component: TrainingVideos },
  { title: "Charges", Component: ServiceCharges },
  { title: "Spare parts", Component: SpareParts },
  { title: "Reasons", Component: Reasons },
];
const ServiceDetails = () => {
  const { serviceId } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const selectedNav = Number(searchParams.get("active") || 0);
  const navigate = useNavigate();
  useEffect(() => {
    getServiceInfo(serviceId);
    dispatch(setScreenTitle(serviceId ? "Edit Service" : "Add Service"));
    return () => dispatch(updateCurrentServiceData([]));
  }, [dispatch, serviceId]);
  const { Component } = NAV_DATA[selectedNav] || {};

  const onSelectNav = (active) => {
    navigate(`/service-detail/${serviceId}/?active=${active}`);
  };
  return (
    <div>
      {serviceId && (
        <Navbar
          onSelectNav={onSelectNav}
          data={NAV_DATA}
          selectedNav={selectedNav}
        />
      )}
      <Box sx={{ my: 1 }}>
        <Card>
          <CardContent>
            {!!Component && <Component serviceId={serviceId} />}
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default ServiceDetails;
