import { ExpandCircleDown } from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import { Button, IconButton, Switch, Tooltip, Typography } from "@mui/material";
import { INPUT_TYPES } from "components";
import moment from "moment";
import { customSortComparatorForNums } from "utils";

const onSuccessCallback = (id) => {
  const getCategoryById = require("./utils").getCategoryById;
  getCategoryById({ id });
};

export const getCategoryListColumns = ({
  onUpdatePress,
  handleStatusChange,
  label,
  itemLength,
  moveCategoryDown,
  moveCategoryUp,
}) => [
  { field: "id", headerName: "ID", width: 90, sortable: true, hide: true },
  {
    field: "categoryId",
    headerName: "Category Id",
    width: 150,
    sortComparator: customSortComparatorForNums,
  },
  { field: "categoryName", headerName: "Category Name", width: 200 },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 250,
    renderCell: ({ row }) =>
      moment(new Date(row?.createdAt)).format("DD MMM YYYY, H:mm"),
  },
  {
    field: "Active",
    width: 150,
    renderCell: ({ row }) => {
      return (
        <Switch
          {...label}
          checked={row?.isActive}
          onChange={() => handleStatusChange(row?.categoryId)}
        />
      );
    },
  },
  {
    field: "Add/Update",
    headerName: "Add | Update",
    width: 200,
    renderCell: ({ row }) => {
      return (
        <div>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            sx={{ mr: 1 }}
            onClick={() => {
              onUpdatePress(row);
            }}
            startIcon={<Edit />}
          >
            Edit
          </Button>
        </div>
      );
    },
  },
  {
    field: "changeOrder",
    headerName: "Order",
    width: 200,
    renderCell: ({ row }) => {
      const { index, _id } = row;
      const color = "primary";
      return (
        <Typography component={"div"} sx={{ display: "flex" }}>
          <IconButton
            onClick={() => moveCategoryUp(_id)}
            disabled={!index}
            style={{ rotate: "180deg" }}
          >
            <Tooltip title="Move Up">
              <ExpandCircleDown color={index ? color : "disabled"} />
            </Tooltip>
          </IconButton>
          <IconButton
            onClick={() => moveCategoryDown(_id)}
            disabled={index === itemLength - 1}
          >
            <Tooltip title="Move Down">
              <ExpandCircleDown
                color={index === itemLength - 1 ? "disabled" : color}
              />
            </Tooltip>
          </IconButton>
        </Typography>
      );
    },
  },
];

export const CATEGORY_FIELDS = [
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "categoryName",
    placeholder: "Name",
  },
  {
    inputType: INPUT_TYPES.FILE,
    _key: "homeScreenImage",
    placeholder: "Thumbnail",
    showOnlyOnUpdate: true,
    isOptional: true,
    extraProps: {
      collection: "category",
      customMd: 12,
      onSuccessCallback,
    },
  },
  {
    inputType: INPUT_TYPES.FILE,
    _key: "bannerImages",
    placeholder: "Banners",
    showOnlyOnUpdate: true,
    isOptional: true,
    extraProps: {
      collection: "category",
      customMd: 12,
      onSuccessCallback,
      isNode: true,
    },
  },
  {
    inputType: INPUT_TYPES.FILE,
    _key: "wcsThumbnail",
    placeholder: "WCS Thubmnail",
    showOnlyOnUpdate: true,
    isOptional: true,
    extraProps: {
      collection: "category",
      customMd: 12,
      onSuccessCallback,
    },
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "wcsShortDescription",
    placeholder: "WCS Short Description",
    md: 8,
  },
  {
    inputType: INPUT_TYPES.TEXT_AREA,
    _key: "description",
    placeholder: "Description",
    md: 12,
    extraProps: { minRows: 3 },
  },
];
