import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { INPUT_TYPES } from "components";
import moment from "moment/moment";

export const PAYORDER_FILTER_FIELDS = [
  {
    _key: "type",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Type",
  },
];

export const PAYORDER_FIELDS = [
  {
    _key: "dateFrom",
    inputType: INPUT_TYPES.DATE,
    placeholder: "Start Date",
    disabled: true,
    renderCell: (data) => moment(data).format("DD MMM YYYY hh:mm a"),
  },
  {
    _key: "dateTo",
    inputType: INPUT_TYPES.DATE,
    placeholder: "End Date",
    disabled: true,
    renderCell: (data) => moment(data).format("DD MMM YYYY hh:mm a"),
  },
  {
    _key: "providerId",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Provider ID",
    disabled: true,
  },
  {
    _key: "providerName",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Provider Name",
    disabled: true,
  },
  {
    _key: "amount",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Amount",
    disabled: true,
    renderCell: (data) => `₹${data}`,
  },
  {
    _key: "orderType",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Type",
    extraData: ["Commission"],
  },
  {
    _key: "description",
    inputType: INPUT_TYPES.TEXT_AREA,
    placeholder: "Description",
    extraProps: { minRows: 3 },
  },
  {
    _key: "paid",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Paid",
    renderCell: (data) => (!!data ? "YES" : "NO"),
  },
];

export const SEARCH_FIELDS = [
  {
    _key: "fromDate",
    inputType: INPUT_TYPES.DATE,
    placeholder: "From Date",
  },
  {
    _key: "toDate",
    inputType: INPUT_TYPES.DATE,
    placeholder: "To Date",
  },
  {
    _key: "providerId",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Provider ID",
  },
];

export const getPayOrdersColumns = ({ onViewPress }) => [
  { field: "id", headerName: "ID", width: 90, hide: true },
  {
    field: "index",
    headerName: "S. No.",
    width: 80,
  },
  {
    field: "providerId",
    headerName: "Provider",
    width: 100,
  },
  {
    field: "paid",
    headerName: "Paid",
    width: 100,
    renderCell: ({ row }) => (!!row.paid ? "YES" : "NO"),
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 100,
    renderCell: ({ row }) => "₹ " + row.amount,
  },
  {
    field: "description",
    headerName: "Description",
    width: 150,
  },
  {
    field: "orderType",
    headerName: "Type",
    width: 150,
  },
  {
    field: "dateFrom",
    headerName: "Start Date",
    width: 250,
    renderCell: ({ row }) =>
      moment(row.dateFrom).format("DD MMM YYYY, hh:mm A"),
  },
  {
    field: "dateTo",
    headerName: "End Date",
    width: 250,
    renderCell: ({ row }) => moment(row.dateTo).format("DD MMM YYYY, hh:mm A"),
  },
  {
    field: "action",
    headerName: "Action",
    width: 100,
    renderCell: ({ row }) => {
      const onView = (e) => {
        e.stopPropagation();
        onViewPress(row);
      };
      return (
        <IconButton onClick={onView}>
          <Visibility color="primary" />
        </IconButton>
      );
    },
  },
];

export const getPayOrderJobsColumns = ({ onViewPress }) => [
  { field: "id", headerName: "ID", width: 90, hide: true },
  {
    field: "jobId",
    headerName: "Job ID",
    width: 80,
  },
  {
    field: "scheduledDate",
    headerName: "Scheduled Date",
    width: 250,
    renderCell: ({ row }) =>
      moment(row.scheduledDate).format("DD MMM YYYY, hh:mm A"),
  },
  {
    field: "balanceAmount",
    headerName: "Balance Amount",
    width: 180,
    renderCell: ({ row }) => `₹${row.balanceAmount}`,
  },
  {
    field: "earningAmount",
    headerName: "Earning Amount",
    width: 180,
    renderCell: ({ row }) => `₹${row.earningAmount}`,
  },
  {
    field: "action",
    headerName: "Action",
    width: 100,
    renderCell: ({ row }) => {
      const onView = (e) => {
        e.stopPropagation();
        onViewPress(row);
      };
      return (
        <IconButton onClick={onView}>
          <Visibility color="primary" />
        </IconButton>
      );
    },
  },
];
