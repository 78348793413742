import React, { useEffect, useState } from "react";
import ModalWrapper from "components/ModalWrapper";
import { CustomField, getErrorKey, getErrorText } from "components";
import { Grid } from "@mui/material";
import { validateFields } from "utils";
import { CS_TICKET_FIELDS } from "../constants";
import { createTicketRequest, fetchTicketTypesRequest } from "../utils";

export const AddEditTicketModal = ({
  userData,
  onSuccess = () => {},
  id,
  type,
  closeModal,
}) => {
  const [state, setState] = useState({});
  const [ticketTypes, setTicketTypes] = useState([]);

  useEffect(() => {
    fetchTicketTypesRequest({
      onSuccess: (data) =>
        setTicketTypes(
          data.map(({ name, _id }) => ({ label: name, value: _id }))
        ),
    });
  }, []);

  const validateData = () => {
    let isValidData = true;
    const { isValid, _state } = validateFields({
      _state: state,
      fields: CS_TICKET_FIELDS.slice(0, 3),
    });
    setState({ ..._state });

    if (!isValid) {
      isValidData = false;
    }

    return isValidData;
  };

  const onChange = ({ _key, value }) => {
    setState((_state) => ({
      ...state,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
      [_key]: value,
    }));
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }
    createTicketRequest({
      payload: {
        title: state.title,
        description: state.description,
        type: state.type,
        raisedForId: userData.id,
        raisedFor:
          type === "job" || type === "provider" ? "provider" : "customer",
        relatedToId: type === "job" || type === "booking" ? id : undefined,
        relatedToType: type === "job" || type === "booking" ? type : undefined,
      },
      onSuccess,
    });
  };

  return (
    <ModalWrapper
      footerButtons={[
        {
          title: "Submit",
          onClick: onSubmitPress,
        },
      ]}
      title={`Raise Ticket (${userData.name})`}
      closeModal={closeModal}
      sx={{ width: "50%" }}
      childSx={{ height: "50vh", p: 2 }}
    >
      <div
        style={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <Grid container rowSpacing={2} spacing={1}>
          {CS_TICKET_FIELDS.slice(0, 3).map((item) => {
            const { _key } = item;

            if (!_key) {
              return null;
            }

            if (_key === "type") {
              item.extraData = ticketTypes;
            }

            return (
              <Grid key={_key} item md={12} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={item}
                  errorText={state[getErrorText(_key)]}
                  error={state[getErrorKey(_key)]}
                  value={state[_key]}
                  onChange={onChange}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </ModalWrapper>
  );
};
