import React, { useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { RenderNodeData } from "./RenderNodeData";
import { IconButton, Switch, Tooltip, Typography } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import { AddCircle, ExpandCircleDown } from "@mui/icons-material";
import { NodeModal } from "./NodeModal";
import {
  deleteItemRequest,
  deleteNodeRequest,
  toggleItemActiveStatus,
  toggleNodeActiveStatus,
  updateServiceItemPositionRequest,
  updateServiceNodePositionRequest,
} from "../utils";
import { getErrorKey, getErrorText } from "../../../../components/CustomField";
import { ConfirmationModal } from "components/ConfirmationModal";
import Delete from "@mui/icons-material/Delete";

const LeftIcons = ({ active, color }) => {
  if (active) {
    return <IndeterminateCheckBoxIcon color={color} />;
  }
  return <AddBoxIcon color={color} />;
};

export const RenderNodeItems = ({
  itemsLength,
  index,
  disabled,
  serviceId,
  hardDisable,
  _opacity,
  availableLanguages,
  onUpdateData,
  ignoreMargin,
  onMoveCallback,
  isNode,
  data = {},
}) => {
  const [showChildrenNodes, toggleChildrenNodes] = useState(
    isNode ? false : true
  );
  const {
    isActive = false,
    title,
    nodeId,
    _id,
    position,
    itemId,
    children = [],
    items = [],
  } = data;

  const [modalData, setModalData] = useState(null);
  const [confirmationModalData, setConfirmationModalData] = useState(null);

  const onEditPress = (e) => {
    e?.stopPropagation && e.stopPropagation();
    setModalData({
      title: isNode ? "Edit Node" : "Edit Item",
      isEdit: true,
      data,
    });
  };

  const onAddPress = (e) => {
    e?.stopPropagation && e.stopPropagation();
    setModalData({
      title: (isNode ? "Add Item" : "Add Child Node") + ` in ${title}`,
      isEdit: false,
    });
  };

  const onToggleActiveStatus = (e) => {
    e?.stopPropagation && e.stopPropagation();
    const caller = isNode ? toggleNodeActiveStatus : toggleItemActiveStatus;
    caller({ isActive: !isActive, itemId, nodeId, serviceId });
  };

  const closeModal = () => setModalData(null);

  const onChange = ({ value, _key }) => {
    if (!onUpdateData) {
      return;
    }
    onUpdateData(
      {
        ...data,
        [_key]: value,
        [getErrorKey(_key)]: false,
        [getErrorText(_key)]: "",
      },
      index
    );
  };

  const onUpdateChild = (childData, _ind) => {
    if (!onUpdateData) {
      return;
    }
    if (data.children?.length) {
      data.children[_ind] = childData;
    }
    if (data.items?.length) {
      data.items[_ind] = childData;
    }
    onUpdateData({ ...data }, index);
  };

  const onMoveUp = (e) => {
    e?.stopPropagation && e.stopPropagation();
    onMoveCallback({ position, itemId, nodeId, withItem: index - 1 });
  };
  const onMoveDown = (e) => {
    e?.stopPropagation && e.stopPropagation();
    onMoveCallback({ position, itemId, nodeId, withItem: index + 1 });
  };

  const color = isNode ? "primary" : "secondary";
  const innerItems = children.length ? children : items;
  const hasInnerItems = !!innerItems.length;

  const onMoveApiCall = ({ withItem, ...rest }) => {
    const caller = isNode
      ? updateServiceItemPositionRequest
      : updateServiceNodePositionRequest;

    caller({ serviceId, data: rest, sibling: innerItems[withItem] });
  };

  const onDeletePress = (e) => {
    e?.stopPropagation && e.stopPropagation();

    setConfirmationModalData({
      title: `Delete ${
        data.title ? `(${data.title})` : isNode ? "Node" : "item"
      }`,
      description: `Do you really want to delete this ${
        isNode ? "node" : "item"
      }?`,
      footerButtons: [
        {
          title: "Delete",
          style: { color: "white" },
          onClick: () => {
            isNode
              ? deleteNodeRequest({
                  nodeId: data.nodeId,
                  serviceId: data.serviceId,
                })
              : deleteItemRequest({
                  itemId: data.itemId,
                  serviceId: data.serviceId,
                });

            setConfirmationModalData(null);
          },
        },
      ],
    });
  };

  const customStyle = isActive || !disabled;

  return (
    <div style={{ marginLeft: ignoreMargin ? 0 : 15 }}>
      {!!modalData && (
        <NodeModal
          closeModal={closeModal}
          isNode={isNode}
          itemId={itemId}
          _id={_id}
          availableLanguages={availableLanguages}
          nodeId={nodeId}
          serviceId={serviceId}
          modalData={modalData}
        />
      )}
      {!!confirmationModalData && (
        <ConfirmationModal
          color={"danger"}
          modalData={confirmationModalData}
          closeModal={() => setConfirmationModalData(null)}
        />
      )}
      <div
        className="hide-scrollbar"
        style={{
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
          width: "100%",
          padding: `${disabled ? 0 : 8}px 4px`,
          marginBottom: "8px",
          position: "relative",
          overflow: "auto",
          background: customStyle
            ? isNode
              ? "#f0fdf4"
              : "#eff6ff"
            : "#fee2e2",
        }}
        onClick={() => toggleChildrenNodes(!showChildrenNodes)}
      >
        {hasInnerItems && (
          <LeftIcons color={color} active={showChildrenNodes} />
        )}
        <div
          style={{
            width: "100%",
            paddingLeft: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textDecoration: !customStyle && "line-through",
              fontWeight: "500",
              whiteSpace: "nowrap",
            }}
            color={color}
          >
            {title}
          </Typography>
          {!!disabled && (
            <div style={{ display: "flex" }}>
              <IconButton
                onClick={onMoveUp}
                disabled={!index}
                sx={{ rotate: "180deg" }}
              >
                <Tooltip title="Move Up">
                  <ExpandCircleDown color={index ? color : "disabled"} />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={onMoveDown}
                disabled={index === itemsLength - 1}
              >
                <Tooltip title="Move Down">
                  <ExpandCircleDown
                    color={index === itemsLength - 1 ? "disabled" : color}
                  />
                </Tooltip>
              </IconButton>
              <Tooltip title="Edit">
                <IconButton onClick={onEditPress}>
                  <Edit color={color} />
                </IconButton>
              </Tooltip>
              <Tooltip title={`Add ${isNode ? "Item" : "Child Node"}`}>
                <IconButton onClick={onAddPress}>
                  <AddCircle color={color} />
                </IconButton>
              </Tooltip>
              <Tooltip title={`Delete ${!isNode ? "Item" : "Node"}`}>
                <IconButton onClick={onDeletePress}>
                  <Delete color={color} />
                </IconButton>
              </Tooltip>
              <Tooltip title={isActive ? "Disable" : "Enable"}>
                <Switch
                  label="check"
                  color={color}
                  name="showParentTitle"
                  checked={isActive}
                  onClick={onToggleActiveStatus}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        {showChildrenNodes && hasInnerItems && (
          <div
            style={{
              borderLeft: "1.5px dashed grey",
              marginLeft: "13px",
              marginTop: "-8px",
            }}
          ></div>
        )}
        {showChildrenNodes && (
          <div style={{ width: "100%" }}>
            <RenderNodeData
              disabled={disabled}
              onChange={onChange}
              isNode={isNode}
              hardDisable={hardDisable}
              data={data}
            />
            {innerItems.map((item, _index) => {
              return (
                <RenderNodeItems
                  itemsLength={innerItems.length}
                  index={_index}
                  isNode={!isNode}
                  onMoveCallback={onMoveApiCall}
                  availableLanguages={availableLanguages}
                  hardDisable={hardDisable}
                  onUpdateData={onUpdateChild}
                  serviceId={serviceId}
                  disabled={disabled}
                  data={item}
                  key={item._id}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default RenderNodeItems;
