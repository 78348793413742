import moment from "moment";

const getDayStart = (date, days = 1) => {
  return moment
    .utc(date)
    .add(days, "days")
    .set({ hours: 0, minutes: 0, seconds: 0 });
};

export const getSlotsData = ({
  date,
  hours,
  jobFrequency,
  daysCount = 0,
  monthsCount = 0,
  minutes,
}) => {
  const daily = jobFrequency === "MD";
  const monthly = jobFrequency === "MN";

  if (daily) {
    return getSlotsByDaysCount({ date, daysCount, hours });
  }

  if (monthly) {
    const days =
      moment.utc(date).add(1, "months").diff(moment.utc(date), "days") || 0;

    return getSlotsByDaysCount({ daysCount: days, date, hours });
  }

  return getOneTimeSlots({
    date,
    totalMinutes: (hours || 0) * 60 + Number(minutes || 0),
  });
};

const getSlotsByDaysCount = ({ daysCount, date, hours }) => {
  const slots = [];
  [...new Array(Number(daysCount))].forEach((_, index) => {
    slots.push(
      ...getOneTimeSlots({
        date: moment.utc(date).add(index, "days"),
        totalMinutes: (hours || 0) * 60,
      })
    );
  });
  return slots;
};

const getOneTimeSlots = ({ date, totalMinutes = 0 }) => {
  const startDate = moment.utc(date);
  const dayEnd = getDayStart(startDate);
  const minutes = moment(dayEnd).diff(startDate, "minutes");
  const minsToAdd = Math.min(totalMinutes, minutes);
  const slots = [];

  slots.push({
    startDate,
    endDate: moment(startDate).add(minsToAdd, "minutes"),
  });

  totalMinutes -= minsToAdd;
  let prevStartDate = getDayStart(startDate);
  while (totalMinutes > 0) {
    const { endDate, minutes } = getPendingSlots({
      minutes: totalMinutes,
      start: prevStartDate,
    });
    slots.push({
      startDate: prevStartDate,
      endDate,
    });
    totalMinutes = minutes;
    prevStartDate = getDayStart(endDate, 0);
  }

  return slots;
};

const getPendingSlots = ({ start, minutes }) => {
  const minsToAdd = Math.min(1440, minutes);

  return {
    minutes: minutes - minsToAdd,
    endDate: moment(start).add(minsToAdd, "minutes"),
  };
};
