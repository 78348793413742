import { Edit, Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { INPUT_TYPES } from "components";
import moment from "moment";
import { customSortComparatorForNums } from "utils";

export const RESPONSE_MESSAGE_FIELDS = [
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "languageId",
    placeholder: "Language",
    md: 12,
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "keyword",
    placeholder: "Keyword",
    md: 12,
  },
  {
    inputType: INPUT_TYPES.TEXT_AREA,
    _key: "message",
    placeholder: "Message",
    md: 12,
    extraProps: { minRows: 3 },
  },
];

export const getColumns = ({
  availableLanguages,
  onViewPress,
  onEditPress,
}) => {
  return [
    { field: "_id", headerName: "_id", width: 88, hide: true },
    {
      field: "id",
      headerName: "S.No",
      width: 100,
      sortable: true,
      sortComparator: customSortComparatorForNums,
    },
    {
      field: "keyword",
      headerName: "Keyword",
      width: 250,
    },
    {
      field: "message",
      headerName: "Message",
      width: 300,
    },
    {
      field: "languageId",
      headerName: "Language",
      width: 300,
      renderCell: ({ row }) =>
        availableLanguages.find(({ value }) => value === row.languageId)?.label,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 250,
      renderCell: ({ row }) =>
        moment(new Date(row.createdAt)).format("DD MMM YYYY, H:mm"),
    },
    {
      field: "view",
      headerName: "View",
      width: 100,
      renderCell: ({ row }) => {
        const onView = (e) => {
          e.stopPropagation();
          onViewPress(row);
        };

        return (
          <>
            <IconButton onClick={onView}>
              <Visibility color="primary" />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      hide: !onEditPress,
      renderCell: ({ row }) => {
        const onEdit = (e) => {
          e.stopPropagation();
          onEditPress(row);
        };

        return (
          <>
            <IconButton onClick={onEdit}>
              <Edit color="primary" />
            </IconButton>
          </>
        );
      },
    },
  ];
};
