import { INPUT_TYPES } from "components";
import { TRUE_FALSE } from "../ServiceNode/constants";
import { customSortComparatorForNums } from "utils";
import { Edit } from "@mui/icons-material";
import { IconButton, Switch } from "@mui/material";

export const CHARGE_CONFIG_TYPES = [
  {
    label: "Credit",
    value: "credit",
  },
  {
    label: "Debit",
    value: "debit",
  },
];

export const CHARGE_CONFIG_FIELDS = [
  {
    _key: "chargeTypeId",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Charge Type",
  },
  {
    _key: "isTaxApplicable",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Tax Applicable ?",
    extraData: TRUE_FALSE,
  },
  {
    _key: "taxPercentage",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Tax (%)",
    dependentKey: "isTaxApplicable",
  },
  {
    _key: "isCommissionAble",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Commission Applicable ?",
    extraData: TRUE_FALSE,
  },
  {
    _key: "configType",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Config Type",
    extraData: CHARGE_CONFIG_TYPES,
  },
];

export const getServiceChargesColumns = ({
  onEditPress,
  handleStatusChange,
  chargeTypes,
}) => {
  return [
    {
      field: "id",
      headerName: "Id",
      width: 60,
      hide: true,
    },
    {
      field: "index",
      headerName: "S. No.",
      width: 80,
      sortComparator: customSortComparatorForNums,
    },
    {
      field: "chargeTypeId",
      headerName: "Charge Type",
      width: 200,
      renderCell: ({ row }) =>
        chargeTypes.find(({ value }) => value === row.chargeTypeId)?.label,
    },
    {
      field: "isTaxApplicable",
      headerName: "Tax Applicable ?",
      width: 180,
      renderCell: ({ row }) => (!!row.isTaxApplicable ? "YES" : "NO"),
    },
    {
      field: "taxPercentage",
      headerName: "Tax",
      width: 80,
      renderCell: ({ row }) => `${row.taxPercentage}%`,
    },
    {
      field: "isCommissionAble",
      headerName: "Commission Applicable ?",
      width: 240,
      renderCell: ({ row }) => (!!row.isCommissionAble ? "YES" : "NO"),
    },
    {
      field: "configType",
      headerName: "Config Type",
      width: 150,
    },
    {
      field: "isActive",
      headerName: "Active",
      width: 120,
      renderCell: ({ row }) => {
        const onToggle = (e) => {
          e?.stopPropagation?.();
          handleStatusChange(row);
        };

        return (
          <IconButton onClick={onToggle}>
            <Switch checked={row.isActive} color="primary" />
          </IconButton>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 80,
      renderCell: ({ row }) => {
        const onEdit = (e) => {
          e?.stopPropagation?.();
          onEditPress(row);
        };

        return (
          <IconButton onClick={onEdit}>
            <Edit color="primary" />
          </IconButton>
        );
      },
    },
  ];
};
