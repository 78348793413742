import { Grid } from "@mui/material";
import CustomDataGrid from "components/DataGrid";
import Header from "components/HeaderV2";
import React, { useState } from "react";
import { getAddressColumns } from "../constants";
import { RenderLocationModal } from "./RenderLocationModal";

export default function RenderAddresses({ data }) {
  const [modalData, setModalData] = useState(null);
  const closeModal = () => setModalData(null);

  const onViewPress = (row) => {
    setModalData(row);
  };

  return (
    <Grid item sm={12} xs={12} md={5}>
      <Header skip title={"Addresses"} />
      <CustomDataGrid
        rows={data}
        columns={getAddressColumns({ onViewPress })}
        pagination={false}
      />

      {!!modalData && (
        <RenderLocationModal data={modalData} closeModal={closeModal} />
      )}
    </Grid>
  );
}
