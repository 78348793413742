import { Box, Card, CardContent, Grid } from "@mui/material";
import { CustomField } from "components";
import { ConfirmationModal } from "components/ConfirmationModal";
import CustomMap from "components/CustomMap";
import CustomDataGrid from "components/DataGrid";
import Header from "components/HeaderV2";
import { useHasWriteAccess } from "hooks";
import get from "lodash/get";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ImagePreviewModal } from "screens/Services/components/ImageUploaderModal";
import { successToast } from "utils";
import RenderNodeItems from "./components/RenderNodeItems";
import { getProviderKycColumns, PROVIDER_FIELDS } from "./constants";
import {
  activateDeactivateProviderRequest,
  approveRejectProviderRequest,
  getAccountInfoRequest,
  getProvidersInfoRequest,
} from "./utils";
import { ToggleMarkAsEmployeeModal } from "./components/ToggleMarkAsEmployeeModal";
import RenderUserSpecificJobsBookings from "./components/RenderUserSpecificJobsBookings";
import ProfilePic from "components/profilePic";
import RenderUserSpecificTickets from "screens/CustomerSupport/components/RenderUserSpecificTickets";

const ProviderDetails = ({ _data }) => {
  const [previewUrl, setPreviewUrl] = useState("");
  const [confirmationData, setConfirmationData] = useState(null);
  const [showMarkEmployeeModal, setShowMarkEmployeeModal] = useState(false);
  const closeMarkEmployeeModal = () => setShowMarkEmployeeModal(false);

  const [data, setData] = useState(
    { ...(_data || {}), provider: _data?.profile || {} } || {}
  );

  const { id } = useParams();
  const navigate = useNavigate();
  const hasWriteAccess = useHasWriteAccess();
  const documents = data?.provider?.documents?.map((document, index) => ({
    ...document,
    id: index + 1,
  }));
  const _id = id || _data._id;

  const fetchData = useCallback(() => {
    id &&
      getProvidersInfoRequest({
        id,
        onSuccess: (response) => {
          setData((_state) => ({ ..._state, ...response }));
          response.provider.isVerified &&
            getAccountInfoRequest({
              query: `ledgerObjectId=${_id}`,
              onSuccess: (res) => {
                delete res._id;
                setData((_state) => ({ ..._state, ...res }));
              },
            });
        },
      });
  }, [_id, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onViewPress = (row) => {
    setPreviewUrl(row?.image);
  };

  const onSubmit = (verificationStatus) => {
    approveRejectProviderRequest({
      id,
      payload: { verificationStatus },
      onSuccess: () => {
        setConfirmationData(null);
        if (verificationStatus === "rejected") {
          navigate("/providers-list/pending", { replace: true });
          successToast("Provider profile rejected successfully!");
          return;
        }
        fetchData();
        successToast("Provider profile approved successfully!");
      },
    });
  };

  const onApprove = () => {
    setConfirmationData({
      title: "Approve Provider",
      description: "Are you sure you want to approve the provider?",
      footerButtons: [
        {
          onClick: () => setConfirmationData(null),
          sx: { px: "20px", mx: "8px" },
          title: "Cancel",
          variant: "outlined",
        },
        {
          onClick: () => onSubmit("approved"),
          sx: { px: "20px" },
          title: "Approve",
        },
      ],
    });
  };

  const onReject = () => {
    setConfirmationData({
      title: "Reject Provider",
      color: "error",
      description: "Are you sure you want to reject the provider?",
      footerButtons: [
        {
          onClick: () => setConfirmationData(null),
          sx: { px: "20px", mx: "8px" },
          title: "Cancel",
          variant: "outlined",
        },
        {
          onClick: () => onSubmit("rejected"),
          sx: { px: "20px" },
          title: "Reject",
        },
      ],
    });
  };

  const onToggleActivation = () => {
    activateDeactivateProviderRequest({
      id,
      onSuccess: () => {
        successToast(
          data?.provider?.isActive
            ? "Provider profile deactivated"
            : "Provider profile activated"
        );
        fetchData();
      },
    });
  };

  const getButtons = () => {
    if (!data?.provider?._id || !hasWriteAccess) {
      return undefined;
    }

    if (data?.provider?.isVerified) {
      return [
        {
          title: data?.provider?.isActive ? "Deactivate" : "Activate",
          onClick: onToggleActivation,
        },
        {
          title: data?.provider?.employeeId
            ? "Remove as Employee"
            : "Mark as Employee",
          onClick: () => setShowMarkEmployeeModal(true),
        },
      ];
    }

    return [
      { title: "Approve", onClick: onApprove },
      { title: "Reject", color: "error", onClick: onReject },
    ];
  };

  return (
    <Box sx={{ my: 2 }}>
      <Card>
        <CardContent>
          <Header
            title={"Basic Details"}
            buttons={id && getButtons()}
            style={{ flexDirection: { xs: "column", sm: "row" } }}
          />
          <Grid container rowGap={2} sx={{ py: "15px" }}>
            {data?.provider?.profilePic && (
              <Grid item sx={{ margin: "auto" }}>
                <ProfilePic uri={data?.provider?.profilePic} size={150} />
              </Grid>
            )}
            <Grid item lg={10} md={12} sm={12} xs={12} sx={{ margin: "auto" }}>
              <Grid container rowSpacing={2} spacing={1}>
                {PROVIDER_FIELDS.map((provider) => {
                  const { md = 3, _key } = provider;
                  if (!_key) {
                    return null;
                  }

                  return (
                    <Grid key={_key} item md={md} sm={12} xs={12}>
                      <CustomField
                        item={provider}
                        disabled
                        itemData={[]}
                        value={get(data.provider, _key, "")}
                        onChange={() => {}}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>

          <Header skip title={"KYC Details"} />
          <div style={{ height: "100%" }}>
            <CustomDataGrid
              rows={documents || []}
              pagination={false}
              columns={getProviderKycColumns({ onViewPress })}
            />
          </div>

          {previewUrl && (
            <ImagePreviewModal
              previewUrl={previewUrl}
              closeModal={() => setPreviewUrl("")}
            />
          )}
          {confirmationData && (
            <ConfirmationModal
              modalData={confirmationData}
              color={confirmationData.color}
              closeModal={() => setConfirmationData(null)}
            />
          )}
          {showMarkEmployeeModal && (
            <ToggleMarkAsEmployeeModal
              employeeId={data?.provider?.employeeId}
              providerId={id}
              closeModal={closeMarkEmployeeModal}
              onSuccess={() => {
                closeMarkEmployeeModal();
                fetchData();
              }}
            />
          )}
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            {!!data?.provider?.enrolments?.length && (
              <Grid item sm={12} xs={12} md={6}>
                <Header
                  skip
                  title={
                    "Enrolment Details" +
                    ` (${
                      data?.services?.length
                        ? data.services
                            ?.map((service) => service.name)
                            ?.join(", ")
                        : ""
                    })`
                  }
                />
                {data?.provider?.enrolments?.map((enrolment, index) => (
                  <RenderNodeItems
                    itemsLength={data.provider.enrolments.length}
                    index={index}
                    disabled
                    ignoreMargin
                    isNode
                    data={enrolment}
                    key={enrolment._id}
                  />
                ))}
              </Grid>
            )}
            {!!data?.provider?.address?.state && (
              <Grid item sm={12} xs={12} md={5}>
                <Header skip title={"Location"} />
                <CustomMap data={data?.provider?.address} />
              </Grid>
            )}
          </Grid>
          <RenderUserSpecificJobsBookings id={id || data?.provider?._id} />
          <RenderUserSpecificTickets
            id={id || data?.provider?._id}
            userData={{
              name: `${data?.provider?.firstName || ""} ${
                data?.provider?.lastName || ""
              }`,
              id: data?._id,
            }}
            type="provider"
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProviderDetails;
