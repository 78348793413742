import cloneDeep from "lodash/cloneDeep";
import React, { useMemo, useState } from "react";
import { ITEM_FIELDS, NODE_FIELDS } from "../constants";
import { RenderNodeData } from "./RenderNodeData";
import {
  createItemsRequest,
  createNodesRequest,
  updateItemRequest,
  updateNodeRequest,
  validateSiblingsForShowIntoSummary,
} from "../utils";
import ModalWrapper from "../../../../../components/ModalWrapper";
import {
  getErrorKey,
  getErrorText,
} from "../../../../../components/CustomField";

const getColorData = ({ isEdit, isNode }) => {
  if (isEdit) {
    return { color: isNode ? "primary" : "secondary", bottomIsNode: isNode };
  }
  return { color: isNode ? "secondary" : "primary", bottomIsNode: !isNode };
};

const getDefaultState = ({ data, availableLanguages = [] }) => {
  let languagesData = cloneDeep(availableLanguages);
  let activeIndex = 0;
  if (data?.languageId) {
    languagesData = languagesData
      .filter((lang) => lang.languageId === data?.languageId)
      .map((lang, index) => {
        if (lang.languageId === data.languageId) {
          activeIndex = index;
          return { ...lang, stateData: cloneDeep(data) };
        }
        return lang;
      });
  }
  return { activeIndex, languagesData };
};

export const NodeModal = ({
  _id,
  availableLanguages = [],
  closeModal,
  isNode,
  itemId,
  modalData,
  onSubmitPress,
  customFields,
  nodeId,
  serviceId,
  isRootNode = false,
  parentNodeData,
  parentItemData,
  serviceConfig,
  hierLevel,
}) => {
  const { data = {}, title, isEdit, uploadImage } = modalData || {};
  const [state, setState] = useState(
    getDefaultState({ availableLanguages, data })
  );
  const { color, bottomIsNode } = useMemo(
    () => getColorData({ isEdit, isNode }),
    [isEdit, isNode]
  );
  const fieldsData = customFields ?? (bottomIsNode ? NODE_FIELDS : ITEM_FIELDS);

  if (!modalData) {
    return null;
  }

  const { activeIndex, languagesData } = state;
  const { languageName, stateData = {} } = languagesData[activeIndex] || {};
  const length = availableLanguages.length;
  const onChange = ({ value, _key }) => {
    setState((_state) => {
      _state.languagesData[activeIndex].stateData = {
        ...(_state.languagesData[activeIndex].stateData || {}),
        [_key]: value,
        [getErrorKey(_key)]: false,
        [getErrorText(_key)]: "",
      };
      return { ..._state };
    });
  };

  const validateData = () => {
    let isValidData = true;
    const _state = cloneDeep(stateData);
    fieldsData.forEach(({ isOptional, regex, _key }) => {
      if (isOptional || _key === "banners" || _key === "image") {
        return;
      }
      if (_state[_key] === undefined) {
        isValidData = false;
        _state[getErrorKey(_key)] = true;
        _state[getErrorText(_key)] = "This field can't be empty";
        return;
      }
      if (!((_state[_key] ?? "") + "")?.trim()?.length) {
        isValidData = false;
        _state[getErrorKey(_key)] = true;
        _state[getErrorText(_key)] = "Please enter a valid value";
        return;
      }
      if (regex && !regex.test(_state[_key])) {
        isValidData = false;
        _state[getErrorKey(_key)] = true;
        _state[getErrorText(_key)] = "Please enter a valid value";
        return;
      }
      _state[_key] = ((_state[_key] ?? "") + "")?.trim();
    });
    setState((_stateRef) => {
      _stateRef.languagesData[activeIndex].stateData = { ..._state };
      return { ..._stateRef };
    });
    return isValidData;
  };

  const updateActiveLanguage = (goBack = false) => {
    setState((_state) => {
      const _stateData = _state.languagesData[_state.activeIndex].stateData;
      _state.languagesData = _state.languagesData.map((langData) => {
        if (!langData.stateData) {
          langData.stateData = {};
        }
        fieldsData.forEach(({ shouldBeSame, _key }) => {
          if (shouldBeSame) {
            langData.stateData[_key] = _stateData[_key];
          }
        });
        return { ...langData };
      });
      return {
        ..._state,
        activeIndex: _state.activeIndex + (goBack ? -1 : 1),
      };
    });
  };

  const onBackPress = () => updateActiveLanguage(true);

  const onNextPress = () => {
    if (!validateData()) {
      return;
    }

    let currItem = state.languagesData[0].stateData;
    if (
      bottomIsNode &&
      currItem.showIntoSummary !== "none" &&
      currItem.isActive &&
      !validateSiblingsForShowIntoSummary({
        currItem,
        items: parentItemData.children,
      }).isValid
    )
      return;

    if (isEdit) {
      if (onSubmitPress) {
        onSubmitPress(state.languagesData);
        return;
      }
      const caller = bottomIsNode ? updateNodeRequest : updateItemRequest;
      caller({
        _id,
        onSuccess: closeModal,
        serviceId,
        data: state.languagesData,
      });
      return;
    }
    if (activeIndex < length - 1) {
      updateActiveLanguage();
      return;
    }
    if (onSubmitPress) {
      onSubmitPress(state.languagesData);
      return;
    }
    const caller = bottomIsNode ? createNodesRequest : createItemsRequest;
    caller({
      onSuccess: closeModal,
      nodeId,
      itemId,
      serviceId,
      data: state.languagesData.map((item) => ({
        ...item,
        stateData: { ...item.stateData, hierLevel },
      })),
    });
  };

  const getFooterButtons = () => {
    const buttons = [];
    if (!isEdit && length > 1) {
      buttons.push({
        onClick: onBackPress,
        sx: { px: "20px", mx: "8px" },

        title: "Back",
        disabled: !activeIndex,
      });
    }
    buttons.push({
      onClick: onNextPress,
      sx: { px: "20px" },
      title: uploadImage
        ? "Upload"
        : isEdit
        ? "Update"
        : activeIndex < length - 1
        ? "Next"
        : "Save",
    });
    return buttons;
  };

  return (
    <ModalWrapper
      sx={{ width: uploadImage ? "50%" : bottomIsNode ? "75%" : "50%" }}
      footerButtons={getFooterButtons()}
      background={bottomIsNode ? "#f0fdf4" : "#eff6ff"}
      childSx={{ overflowY: "auto", height: "70vh", p: 2, pl: 0 }}
      color={color}
      closeModal={closeModal}
      title={`${title}(${languageName})`}
    >
      <RenderNodeData
        customFields={customFields}
        color={color}
        customMd={!bottomIsNode && 12}
        data={stateData}
        hardDisable={isEdit ? false : !!activeIndex}
        isNode={bottomIsNode}
        isRootNode={isRootNode}
        onChange={onChange}
        parentNodeData={parentNodeData}
        parentItemData={parentItemData}
        disableImageUpload={true}
        serviceConfig={serviceConfig}
      />
    </ModalWrapper>
  );
};
