import React from "react";
import { Card, Grid } from "@mui/material";
import { CustomField } from "components";
import Header from "components/HeaderV2";
import { CONTACT_QUERY_FIELDS } from "../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { errorToast } from "utils";

export const QueryDetail = () => {
  const { data } = useLocation()?.state;
  const navigate = useNavigate();
  const pageTitle = "Query Detail";

  if (!data) {
    errorToast("Details not found!");
    navigate(-1);
  }

  return (
    <>
      <Card
        sx={{
          mt: "12px",
          py: 3,
          px: 2,
          rowGap: 2,
          display: "flex",
          flexDirection: "column",
        }}
        component={"div"}
      >
        <Header title={pageTitle} />

        <Grid container rowSpacing={2} spacing={1}>
          {CONTACT_QUERY_FIELDS.map((item) => {
            const { _key, md } = item;

            if (!_key) return null;

            return (
              <Grid key={_key} item md={md ? md : 4} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={item}
                  itemData={data}
                  value={data[_key]}
                  disabled={true}
                />
              </Grid>
            );
          })}
        </Grid>
      </Card>
    </>
  );
};
