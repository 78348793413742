import { Typography } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchSparePartsListRequest, sortByLanguageOrder } from "./utils";
import { queryString } from "utils";
import { useGetLangData } from "../hooks/useLanguagesData";
import { getApplianceColumns } from "./constants";
import CustomDataGrid from "components/DataGrid";
import { AddEditAppliance } from "./components/AddEditAppliance";
import { cloneDeep } from "lodash";

export default function SpareParts({ serviceId }) {
  const [state, setState] = useState([]);
  const { defaultLanguageId, availableLanguages, allLanguages } =
    useGetLangData({
      useAll: true,
      data: state,
    });

  const [selectedLanguage, setSelectedLanguage] = useState();
  const [modalData, setModalData] = useState(null);
  const closeModal = () => setModalData(null);
  const navigate = useNavigate();

  const onViewPress = (row) =>
    navigate(`${window.location.pathname}appliance-detail/${row.applianceId}`);

  const onEditPress = (row) => {
    const applianceData = cloneDeep(state).filter(
      ({ applianceId }) => applianceId === row.applianceId
    );

    setModalData({
      languageId: row.languageId,
      serviceId,
      languages: allLanguages,
      data: sortByLanguageOrder({
        languageOrder: allLanguages.map(({ value }) => value),
        applianceData,
      }),
      isEdit: true,
    });
  };

  const onAddPress = () =>
    setModalData({
      languageId: selectedLanguage,
      serviceId,
      languages: allLanguages,
    });

  const fetchSpareParts = useCallback(() => {
    fetchSparePartsListRequest({
      query: queryString({ serviceId }),
      onSuccess: setState,
    });
  }, [serviceId]);

  const onSuccessCallback = () => {
    closeModal();
    fetchSpareParts();
  };

  useEffect(() => {
    serviceId && fetchSpareParts();
  }, [serviceId, fetchSpareParts]);

  useMemo(() => {
    defaultLanguageId && setSelectedLanguage(defaultLanguageId);
  }, [defaultLanguageId]);

  return (
    <Typography
      sx={{
        overflow: "auto",
        margin: "-16px",
        padding: "16px",
      }}
    >
      <Header
        skip
        title={""}
        titleStyle={{ textTransform: "uppercase" }}
        dropDown={{
          value: selectedLanguage,
          onChange: setSelectedLanguage,
          data: availableLanguages,
        }}
        buttons={[{ title: "Add Appliance", onClick: onAddPress }]}
        style={{ mb: "10px" }}
      />
      <CustomDataGrid
        rows={state
          .filter((item) => item.languageId === selectedLanguage)
          .map((item, index) => ({
            ...item,
            index: index + 1,
            id: index,
          }))}
        columns={getApplianceColumns({
          onViewPress,
          onEditPress,
        })}
      />

      {!!modalData && (
        <AddEditAppliance
          data={modalData}
          onSuccessCallback={onSuccessCallback}
          closeModal={closeModal}
        />
      )}
    </Typography>
  );
}
