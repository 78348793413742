import CustomDataGrid from "components/DataGrid";
import React, { useCallback, useEffect, useState } from "react";
import {
  PROVIDER_ATTENDANCE,
  getProviderAttendanceColumns,
} from "../constants";
import { RenderLocationModal } from "screens/Customer/components/RenderLocationModal";
import ModalWrapper from "components/ModalWrapper";
import { getProviderAttendanceRequest } from "../utils";
import moment from "moment/moment";
import MarkAttendanceModal from "./MarkAttendanceModal";

const RenderProviderAttendanceModal = ({
  jobId,
  jobScheduledDate,
  closeModal,
}) => {
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState({ type: null });
  const onClose = () => {
    console.log("close modal");
    setModalData({ type: null });
  };

  const onViewLocation = (row) => {
    setModalData({ type: "location", data: row.location });
  };

  const onMarkAttendancePress = () => {
    setModalData({ type: "attendance" });
  };

  const getProviderAttendance = useCallback(() => {
    getProviderAttendanceRequest({
      jobId,
      onSuccess: (data) => {
        const fullMonthData = generateProviderAttendanceData({
          data,
          jobScheduledDate,
        });
        setData(fullMonthData);
      },
    });
  }, [jobId, jobScheduledDate]);

  useEffect(() => {
    jobId && getProviderAttendance();
  }, [jobId, getProviderAttendance]);

  return (
    <ModalWrapper
      title={"Provider Attendance"}
      closeModal={closeModal}
      sx={{ width: "75%" }}
      childSx={{ height: "82vh", p: 2 }}
    >
      <CustomDataGrid
        rows={data.map((item, index) => ({
          ...item,
          id: index,
          index: index + 1,
        }))}
        columns={getProviderAttendanceColumns({
          onViewLocation,
          onMarkAttendancePress,
        })}
      />

      {modalData.type === "location" && (
        <RenderLocationModal data={modalData.data} closeModal={onClose} />
      )}

      {modalData.type === "attendance" && (
        <MarkAttendanceModal
          jobId={jobId}
          onClose={onClose}
          onSuccessCallback={getProviderAttendance}
        />
      )}
    </ModalWrapper>
  );
};

export default RenderProviderAttendanceModal;

const generateProviderAttendanceData = ({ data, jobScheduledDate }) => {
  let fullMonthData = [];

  const todayDate = new Date().setHours(0, 0, 0, 0);
  const startDate = data.length
    ? new Date(data[0].currentDate).setHours(0, 0, 0, 0)
    : new Date(jobScheduledDate).setHours(0, 0, 0, 0);

  for (let i = 0; i < 30; i++) {
    const date = moment(startDate).add(i, "day");

    const datePresent = data.find(
      (item) =>
        !moment(date).diff(new Date(item.currentDate).setHours(0, 0, 0, 0))
    );

    if (!!datePresent) {
      fullMonthData.push({
        date: new Date(datePresent.currentDate),
        punchTime: new Date(datePresent.currentDate),
        location: datePresent.location,
        attendance: datePresent.status,
        providerId: datePresent.providerDetail.providerId,
        providerName: datePresent.providerDetail.name,
      });

      continue;
    }

    if (moment(todayDate).isAfter(date)) {
      fullMonthData.push({
        date: new Date(date),
        attendance: PROVIDER_ATTENDANCE.ABSENT,
      });

      continue;
    }

    fullMonthData.push({ date });
  }

  return fullMonthData;
};
