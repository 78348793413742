import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import Header from "../../../../../components/HeaderV2";
import Chip from "@mui/material/Chip";

const KeywordsSection = ({ data, onChange }) => {
  const [search, setSearch] = useState("");

  const onChangeValue = ({ target }) => {
    setSearch(target.value);
  };

  const onKeyDown = ({ key }) => {
    if (key === "Enter" && search.trim().length) {
      onChange({ value: [...data, search.trim()], _key: "searchKeyword" });
      setSearch("");
    }
  };

  const onDelete = (index) => {
    onChange({
      value: [...data.slice(0, index), ...data.slice(index + 1)],
      _key: "searchKeyword",
    });
  };

  return (
    <div>
      <Header skip title="Keywords" />
      <Autocomplete
        multiple
        id="tags-filled"
        options={[]}
        value={data}
        freeSolo
        onKeyDown={onKeyDown}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              onDelete={() => onDelete(index)}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            name="keywords_search_input"
            fullWidth
            color={"primary"}
            size="small"
            label={"Keywords"}
            variant="outlined"
            value={search}
            onChange={onChangeValue}
          />
        )}
      />
    </div>
  );
};

export default KeywordsSection;
