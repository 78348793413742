import React, { useCallback, useEffect, useState } from "react";
import Header from "../../../../components/HeaderV2";
import AddEditModalLocation from "./AddEditModalLocation";
import RenderNodeItems from "./components/RenderNodeItems";
import { getEnabledLocationsTreeRequest } from "./utils";

export default function ServiceLocation({ serviceId }) {
  const [nodesData, setNodesData] = useState([]);
  const [showModal, toggleModal] = useState(false);

  const getBasicInfo = useCallback(() => {
    getEnabledLocationsTreeRequest({
      id: serviceId,
      onSuccess: setNodesData,
    });
  }, [serviceId]);

  useEffect(() => {
    getBasicInfo();
  }, [getBasicInfo]);

  const onShowModal = () => toggleModal(true);
  const closeModal = () => toggleModal(false);

  return (
    <div>
      <Header
        skip
        title="Serving Locations"
        buttons={[{ title: "Add/Edit Locations", onClick: onShowModal }]}
      />
      {!!showModal && (
        <AddEditModalLocation
          fetchData={getBasicInfo}
          closeModal={closeModal}
          serviceId={serviceId}
        />
      )}

      {nodesData?.map((data, index) => (
        <RenderNodeItems index={index} data={data} key={data._id} />
      ))}
    </div>
  );
}
