import { INPUT_TYPES } from "components";

export const BROADCAST_FIELDS = [
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "name",
    placeholder: "Broadcast name",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "template",
    placeholder: "Template",
    dataKey: "templates",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "templateType",
    placeholder: "Template Type",
    dependentKey: "template",
    disabled: true,
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "templateAction",
    placeholder: "Template Action",
    dependentKey: "template",
    disabled: true,
  },
  {
    inputType: INPUT_TYPES.FILTER_OPTIONS,
    _key: "serviceId",
    placeholder: "Service",
    dataKey: "services",
  },
  {
    inputType: INPUT_TYPES.FILTER_OPTIONS,
    _key: "state",
    placeholder: "State",
    dataKey: "states",
    overRideValues: { district: [], pincode: [] },
  },
  {
    inputType: INPUT_TYPES.FILTER_OPTIONS,
    _key: "district",
    placeholder: "District",
    dataKey: "districts",
    dependentKey: "state",
    overRideValues: { pincode: [] },
  },
  {
    inputType: INPUT_TYPES.FILTER_OPTIONS,
    _key: "pincode",
    placeholder: "PIN Code",
    dataKey: "pincodes",
    dependentKey: "district",
  },
];
