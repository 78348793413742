import React, { useEffect, useState } from "react";
import ModalWrapper from "components/ModalWrapper";
import { COUPON_FIELDS, COUPON_FIELDS_LANG_SPECIFC } from "../constants";
import { CustomField, getErrorKey, getErrorText } from "components";
import { Grid, Typography } from "@mui/material";
import { createCouponRequest, updateCouponRequest } from "../utils";
import { errorToast, queryString, validateFields } from "utils";
import { fetchServices } from "utils/calls";
import {
  getDistrictsRequest,
  getStatesRequest,
} from "screens/Services/ServiceDetails/ServiceLocation/utils";
import { fetchChargeTypeRequest } from "screens/Charges/utils";
import moment from "moment";

const initState = ({ languages, data }) => {
  const { couponLanguageDetail = [] } = data;

  const unavailableLanguages = languages.filter(
    (item) =>
      !couponLanguageDetail.find(
        ({ languageId }) => +languageId === +item.value
      )
  );

  const _state = unavailableLanguages.map((language) => {
    return { languageId: language.value, languageName: language.label };
  });

  return {
    ...data,
    couponLanguageDetail: [...couponLanguageDetail, ..._state],
  };
};

export const AddEditCouponModal = ({
  closeModal,
  languages,
  modalData,
  onSuccessCallback,
}) => {
  const { data, readOnly } = modalData;
  const isEdit = !!data;
  const [state, setState] = useState(
    initState({ languages, data: data || {} })
  );
  const [dropdownData, setDropdownData] = useState({});

  const validateData = () => {
    const { isValid, _state } = validateFields({
      _state: state,
      fields: COUPON_FIELDS,
    });
    setState({ ..._state });

    return isValid;
  };

  const validateLangData = () => {
    let isValidData = true;

    const tempState = languages.map((_, index) => {
      const { isValid, _state } = validateFields({
        _state: state["couponLanguageDetail"][index] || {},
        fields: COUPON_FIELDS_LANG_SPECIFC,
      });

      if (!isValid) {
        isValidData = false;
      }
      return _state;
    });

    setState((_state) => ({ ..._state, couponLanguageDetail: [...tempState] }));
    return isValidData;
  };

  const onChangeLang = ({ _key, value, languageId, index }) => {
    let temp = state["couponLanguageDetail"];
    temp[index] = {
      ...temp[index],
      [_key]: value,
      languageId,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
    };
    setState((_state) => ({ ..._state, couponLanguageDetail: [...temp] }));
  };

  const onChange = ({ _key, value, overRideValues }) => {
    setState((_state) => ({
      ...state,
      ...overRideValues,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
      [_key]: value,
    }));

    if (_key === "state") {
      fetchDistricts({ data: { _key: value } });
    }
  };

  const onSuccess = () => {
    closeModal();
    onSuccessCallback();
  };

  const onSubmitPress = () => {
    if (!validateData() || !validateLangData()) {
      return;
    }

    if (
      moment(state["validFromDate"]).isAfter(moment(state["validTillDate"]))
    ) {
      return errorToast("Valid from date must be less than valid till date");
    }

    let payload = {};

    COUPON_FIELDS.forEach(({ _key, dependentKey }) => {
      if (
        ((dependentKey && state[dependentKey]) || !dependentKey) &&
        state[_key]
      ) {
        if (_key === "service_charges" || _key === "service") {
          payload[_key] = state[_key]?.map(({ value }) => value);
        } else if (_key === "validFromDate" || _key === "validTillDate") {
          payload[_key] = moment(state[_key])
            .set({ hours: 0, minutes: 0, seconds: 0 })
            .format("yyyy-MM-DDTHH:mm:ss.SSS");
        } else {
          payload[_key] = state[_key];
        }
      }
    });
    payload.couponLanguages = state?.couponLanguageDetail?.map((item) => ({
      languageId: item.languageId,
      title: item.title,
      shortDescription: item.shortDescription,
      description: item.description,
    }));

    const caller = !!isEdit ? updateCouponRequest : createCouponRequest;

    caller({
      payload,
      couponId: state._id,
      onSuccess: onSuccess,
    });
  };

  function fetchDistricts({ data }) {
    getDistrictsRequest({
      state: data["state"],
      includeAllOption: true,
      onSuccess: (districts) =>
        setDropdownData((_state) => ({ ..._state, districts })),
    });
  }

  useEffect(() => {
    fetchServices({
      onSuccess: (services) => {
        if (data && data["service"]) {
          setState((_state) => ({
            ..._state,
            service: [
              ...data["service"].map((value) => ({
                value,
                label:
                  services.find((item) => value === item.value)?.label || "",
              })),
            ],
          }));
        }
        setDropdownData((_state) => ({ ..._state, services }));
      },
    });

    getStatesRequest({
      onSuccess: (states) =>
        setDropdownData((_state) => ({ ..._state, states })),
    });

    if (data && data["state"]) {
      fetchDistricts({ data });
    }

    fetchChargeTypeRequest({
      query: queryString({ languageId: 1 }),
      onSuccess: (_data) => {
        _data = _data.map(({ chargeName, chargeTypeId }) => ({
          label: chargeName,
          value: chargeTypeId,
        }));

        if (data && data["service_charges"]) {
          setState((_state) => ({
            ..._state,
            service_charges: [
              ...data["service_charges"].map((value) => ({
                value,
                label: _data.find((item) => value === item.value)?.label || "",
              })),
            ],
          }));
        }

        setDropdownData((_state) => ({
          ..._state,
          serviceCharges: _data,
        }));
      },
    });
  }, [data]);

  return (
    <ModalWrapper
      footerButtons={
        !!readOnly
          ? [{ title: "Close", onClick: closeModal }]
          : [
              {
                title: isEdit ? "Update" : "Create",
                onClick: onSubmitPress,
              },
            ]
      }
      title={
        !!readOnly
          ? `Coupon Details (${data.name})`
          : isEdit
          ? "Edit Coupon"
          : "Add Coupon"
      }
      closeModal={closeModal}
      sx={{ width: "70%" }}
      childSx={{ height: "70vh", p: 2, overflow: "auto" }}
    >
      <div
        style={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <Grid container rowSpacing={2} spacing={1}>
          {COUPON_FIELDS.map((item) => {
            const { _key, dependentKey, dataKey } = item;

            if (!_key) {
              return null;
            }

            if (dependentKey && !state[dependentKey]) return null;

            if (dropdownData[dataKey]) {
              item.extraData = dropdownData[dataKey];
            }

            if (_key === "validTillDate" && state["validFromDate"]) {
              item.extraProps = {
                ...(item.extraProps || {}),
                minDate: new Date(state["validFromDate"]),
              };
            }

            return (
              <Grid key={_key} item md={6} sm={12} xs={12}>
                <CustomField
                  disabled={!!readOnly}
                  color={"primary"}
                  item={item}
                  errorText={state[getErrorText(_key)]}
                  error={state[getErrorKey(_key)]}
                  value={state[_key]}
                  onChange={onChange}
                />
              </Grid>
            );
          })}

          {state.couponLanguageDetail?.map(
            ({ languageId, languageName }, index) => {
              return (
                <Grid key={languageId} item md={12} sm={12} xs={12}>
                  <Typography
                    sx={{ fontSize: "18px", borderTop: "1px solid", pt: 1 }}
                  >
                    {languageName}
                  </Typography>

                  <Grid
                    container
                    rowSpacing={2}
                    spacing={1}
                    sx={{ mt: 0.5, mb: index > 0 ? 0.5 : 0 }}
                  >
                    {COUPON_FIELDS_LANG_SPECIFC.map((item) => {
                      const { _key, md } = item;

                      if (!_key) {
                        return null;
                      }

                      const couponDetail = state.couponLanguageDetail[index];

                      return (
                        <Grid key={_key} item md={md || 6} sm={12} xs={12}>
                          <CustomField
                            color={"primary"}
                            disabled={!!readOnly}
                            item={item}
                            errorText={
                              couponDetail && couponDetail[getErrorText(_key)]
                            }
                            error={
                              couponDetail && couponDetail[getErrorKey(_key)]
                            }
                            value={couponDetail && couponDetail[_key]}
                            onChange={({ _key, value }) =>
                              onChangeLang({
                                _key,
                                value,
                                languageId,
                                index,
                              })
                            }
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            }
          )}
        </Grid>
      </div>
    </ModalWrapper>
  );
};
