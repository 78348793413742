import React, { useState } from "react";
import ModalWrapper from "components/ModalWrapper";
import { PAYMENT_FIELDS } from "../constants";
import { CustomField, getErrorKey, getErrorText } from "components";
import { Grid } from "@mui/material";
import { validateFields } from "utils";
import { createBookingPaymentRequest } from "screens/Bookings/utils";
import { BOOKING_STATUS } from "screens/Bookings/constants";

export const AddPaymentModal = ({
  closeModal,
  data,
  isCustomer,
  onSuccessCallback,
}) => {
  const [state, setState] = useState(data || {});

  if (!data) return;

  const validateData = () => {
    const { isValid, _state } = validateFields({
      _state: state,
      fields: PAYMENT_FIELDS,
    });
    setState({ ..._state });
    return isValid;
  };
  const onChange = ({ _key, value }) => {
    console.log({ _key, value });
    setState((_state) => ({
      ...state,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
      [_key]: value,
    }));
  };
  const onSuccess = () => {
    closeModal();
    onSuccessCallback();
  };
  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    const payload = {
      bookingId: state.bookingId,
      customerId: state.customerId,
      type: state.type,
      paymentType: state.paymentType,
      languageId: 1,
      paymentMethod: state.paymentMethod,
      txnId: state.txnId,
      description: state.description,
      UtrNo: state.UtrNo,
      voucherType: state.voucherType,
      amount: +state.amount,
      status: state.status,
      bookingFinalPayment:
        state.balAmount === +state.amount &&
        BOOKING_STATUS.paymentPending.label === state.bookingStatus,
      entryDate: state.date,
    };

    createBookingPaymentRequest({
      payload,
      onSuccess,
    });
  };
  return (
    <ModalWrapper
      footerButtons={[
        {
          title: "Submit",
          onClick: onSubmitPress,
        },
      ]}
      title={"Add Payment"}
      closeModal={closeModal}
      sx={{ width: "70%" }}
      childSx={{ height: "70vh", p: 2 }}
    >
      <div
        style={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <Grid container rowSpacing={2} spacing={1}>
          {PAYMENT_FIELDS.map((item) => {
            const { _key } = item;
            if (!_key) {
              return null;
            }

            if (
              (_key === "accountType" || _key === "customerId") &&
              isCustomer
            ) {
              return null;
            }

            return (
              <Grid key={_key} item md={6} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={item}
                  errorText={state[getErrorText(_key)]}
                  error={state[getErrorKey(_key)]}
                  value={state[_key]}
                  onChange={onChange}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </ModalWrapper>
  );
};
