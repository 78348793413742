import { setLoading } from "../../../../redux/actions";
import { store } from "../../../../redux/store";
import { HTTP_SERVICE } from "../../../../utils/apiServices";

export const getTrainingVideosRequest = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_TRAINING_VIDEOS(query)
    .then(({ isSuccess, data }) => {
      if (isSuccess) {
        onSuccess &&
          onSuccess(
            data.map((item, index) => ({
              ...item,
              id: item._id,
              index: index + 1,
            }))
          );
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const addTrainingVideoRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));

  payload.duration = document.getElementById("training-video").duration;

  const formData = new FormData();

  Object.keys(payload).forEach((_key) => {
    formData.append(_key, payload[_key]);
  });

  HTTP_SERVICE.ADD_TRAINING_VIDEO(formData)
    .then(({ isSuccess, data }) => {
      if (isSuccess) {
        onSuccess && onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateTrainingVideoRequest = ({ id, payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));

  if (payload.files) {
    payload.duration = document.getElementById("training-video").duration;
  }

  const formData = new FormData();

  Object.keys(payload).forEach((_key) => {
    formData.append(_key, payload[_key]);
  });

  HTTP_SERVICE.UPDATE_TRAINING_VIDEO(id, formData)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const changeVideoStatusRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));

  HTTP_SERVICE.UPDATE_TRAINING_VIDEO_STATUS(id)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
