import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";

export default function PasswordField({
  color,
  disabled,
  error,
  _key,
  errorText,
  onChange,
  placeholder,
  value,
  extraProps,
}) {
  const [showPassword, toggleShowPassword] = useState(false);
  const togglePasswordPress = () => toggleShowPassword(!showPassword);
  const onChangeValue = ({ target }) => {
    onChange({ value: target.value, _key });
  };

  return (
    <FormControl
      disabled={disabled}
      error={error}
      color={color}
      fullWidth
      size="small"
      variant="outlined"
      {...extraProps}
    >
      <InputLabel htmlFor="outlined-adornment-password">
        {placeholder}
      </InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        value={value || ""}
        onChange={onChangeValue}
        label={placeholder}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordPress}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      {!!errorText && (
        <FormHelperText error={error}>{errorText}</FormHelperText>
      )}
    </FormControl>
  );
}
