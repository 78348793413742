import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIsLoggedIn } from "../hooks";

function PublicWrapper({ children }) {
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/", { replace: true });
    }
  }, [navigate, isLoggedIn]);

  if (!isLoggedIn) {
    return children;
  }

  return null;
}

export default PublicWrapper;
