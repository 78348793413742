import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { CustomField, INPUT_TYPES } from "components";
import ModalWrapper from "components/ModalWrapper";
import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useState } from "react";
import { getChargesForServiceRequest } from "screens/Services/ServiceDetails/ServiceCharges/utils";
import { getErrorKey, getErrorText, queryString, successToast } from "utils";
import amountFormat from "utils/amountFormat";
import { updateBookingChargesRequest } from "./utils";
import { CHARGES_UPDATED_FOR } from "./constants";

const ACTIONS = { INITIAL: "Initial", REMOVED: "Removed", ADDED: "Added" };

const AddChargeModal = ({ onSubmitPress, closeModal, dropDownData = [] }) => {
  const [state, setState] = useState({});

  const onSubmit = () => {
    onSubmitPress({
      action: ACTIONS.ADDED,
      description: state.typeData.label,
      chargeTypeId: state.chargeTypeId,
      amount: state.amount,
      voucherType: state.typeData.configType,
    });
  };

  const onChange = ({ value, _key }) => {
    const extraData = {};
    if (_key === "amount") {
      value = (value.match(/\d+/g) || [])[0];
    } else {
      extraData.typeData = dropDownData.find((item) => item.value === value);
    }
    setState((_state) => ({
      ..._state,
      [_key]: value,
      ...extraData,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
    }));
  };

  return (
    <ModalWrapper
      title="Add charge"
      closeModal={closeModal}
      childSx={{ p: 2 }}
      footerButtons={[
        {
          title: "Submit",
          disabled: !state.chargeTypeId || !state.amount,
          onClick: onSubmit,
        },
      ]}
    >
      <CustomField
        item={{
          _key: "chargeTypeId",
          inputType: INPUT_TYPES.DROPDOWN,
          placeholder: "Charge Description",
          extraData: dropDownData,
          extraProps: { sx: { marginBottom: "16px" } },
        }}
        value={state.chargeTypeId}
        onChange={onChange}
      />
      <CustomField
        item={{
          _key: "amount",
          inputType: INPUT_TYPES.INPUT,
          placeholder: "Amount",
          extraProps: { sx: { marginBottom: "12px" } },
        }}
        value={state.amount}
        onChange={onChange}
      />
    </ModalWrapper>
  );
};

const calculateTotal = (chargesData) => {
  return chargesData.reduce((a, b) => {
    if (b.action === ACTIONS.REMOVED) {
      return a;
    }

    return a + (b.voucherType === "debit" ? Number(b.amount) : -b.amount);
  }, 0);
};

const calculateUpdatedValues = ({ chargeTypes, chargesData = [], taxId }) => {
  const chargeTypesMap = {};
  chargeTypes.forEach((item) => (chargeTypesMap[item.chargeTypeId] = item));
  let taxIndex = -1;
  let taxValue = 0;
  chargesData.forEach((item, index) => {
    if (item.chargeTypeId === taxId) {
      taxIndex = index;
      return;
    }
    if (item.chargeTypeId !== taxId && item.action !== ACTIONS.REMOVED) {
      const { configType, isTaxApplicable, taxPercentage } =
        chargeTypesMap[item.chargeTypeId];
      if (isTaxApplicable && taxPercentage) {
        taxValue +=
          ((Number(item.amount) * Number(taxPercentage)) / 100) *
          (configType === "credit" ? -1 : 1);
      }
    }
  });

  if (taxIndex >= 0) {
    chargesData[taxIndex].amount = taxValue.toFixed(2);
    chargesData = [
      ...chargesData.slice(0, taxIndex),
      ...chargesData.slice(taxIndex + 1),
      chargesData[taxIndex],
    ];
  }

  return [...chargesData];
};

export default function EstimateModal({
  closeModal,
  data,
  onSuccessCallback,
  chargesUpdatedFor,
}) {
  const [showAddModal, toggleAddModal] = useState(false);
  const [chargeTypes, setChargeTypes] = useState([]);
  const [chargesData, setChargesData] = useState(
    cloneDeep(
      (data?.charges || []).map((item) => ({
        ...item,
        action: ACTIONS.INITIAL,
      }))
    )
  );
  const taxId = chargeTypes.find((item) => item.label === "Tax")?.chargeTypeId;
  const serviceId = data.service?.serviceId;

  useEffect(() => {
    getChargesForServiceRequest({
      query: queryString({ isActive: true, serviceId, languageId: 1 }),
      onSuccess: (response) =>
        setChargeTypes(
          response.map((item) => ({
            ...item,
            label: item.chargeTypes[0].chargeName,
            value: item.chargeTypeId,
          }))
        ),
    });
  }, [serviceId]);

  const onSubmitPress = () => {
    const payload = {
      bookingId: data._id,
      actionBy: "admin",
      charges: chargesData.map(
        ({
          chargeTypeId,
          amount,
          remarks: remark,
          description,
          action,
          _id: chargeId,
          voucherType,
        }) => {
          const _data = {
            chargeTypeId,
            amount: voucherType === "credit" ? +amount * -1 : amount,
            description,
            action,
            chargeId,
          };

          if (remark) {
            _data.remark = remark;
          }

          return _data;
        }
      ),
    };

    if (chargesUpdatedFor) {
      payload.chargesUpdatedFor = chargesUpdatedFor;
    }

    updateBookingChargesRequest({
      onSuccess: () => {
        successToast(`Updated successfully`);
        onSuccessCallback();
      },
      payload,
    });
  };

  const onAddPress = () => toggleAddModal(true);
  const closeAddModal = () => toggleAddModal(false);

  const onChargeAdded = (value) => {
    setChargesData((_value) =>
      calculateUpdatedValues({
        chargeTypes,
        chargesData: [..._value, value],
        taxId,
      })
    );
    closeAddModal();
  };

  return (
    <ModalWrapper
      title={`${chargesUpdatedFor || "Update Charges"} (${calculateTotal(
        chargesData
      )})`}
      closeModal={closeModal}
      childSx={{ p: 2 }}
      footerButtons={[
        {
          title: "Add",
          onClick: onAddPress,
          sx: { mr: 2 },
          color: "secondary",
        },
        {
          title:
            chargesUpdatedFor === CHARGES_UPDATED_FOR.ESTIMATE
              ? "Submit"
              : chargesUpdatedFor === CHARGES_UPDATED_FOR.PAYMENT_INITIATE
              ? "Initiate Payment"
              : "Update",
          onClick: onSubmitPress,
        },
      ]}
    >
      {chargesData.map((item, index) => {
        const {
          chargeTypeId,
          serviceItemId,
          action,
          voucherType,
          description,
          amount,
        } = item;

        if (action === ACTIONS.REMOVED) {
          return null;
        }

        const onDeletePress = () => {
          setChargesData((_state) => {
            _state[index].action = ACTIONS.REMOVED;
            return calculateUpdatedValues({
              chargeTypes,
              chargesData: _state,
              taxId,
            });
          });
        };

        const disabled = taxId === chargeTypeId || !!serviceItemId;
        return (
          <div
            key={index}
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr 0.15fr" }}
          >
            <p>{description}</p>
            <p style={{ textAlign: "end" }}>
              {`${voucherType === "debit" ? "" : "- "}₹${amountFormat.format(
                amount
              )}`}
            </p>

            <IconButton disabled={disabled} onClick={onDeletePress}>
              <Delete color={disabled ? "disabled" : "error"} />
            </IconButton>
          </div>
        );
      })}
      {!!showAddModal && (
        <AddChargeModal
          dropDownData={chargeTypes.filter(
            (item) => item.chargeTypeId !== taxId
          )}
          onSubmitPress={onChargeAdded}
          closeModal={closeAddModal}
        />
      )}
    </ModalWrapper>
  );
}
