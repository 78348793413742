import { Typography } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useCallback, useEffect, useState } from "react";
import { queryString } from "utils";
import {
  getChargesForServiceRequest,
  updateServiceChargeConfigRequest,
} from "./utils";
import { AddEditChargesModal } from "./components/AddEditChargesModal";
import CustomDataGrid from "components/DataGrid";
import { getServiceChargesColumns } from "./constants";
import { fetchChargeTypeRequest } from "screens/Charges/utils";

export default function ServiceCharges({ serviceId }) {
  const [state, setState] = useState([]);
  const [modalData, setModalData] = useState(null);
  const closeModal = () => setModalData(null);
  const [chargeTypes, setChargeTypes] = useState([]);

  const onAddPress = () => setModalData({ data: null });
  const onEditPress = (row) => setModalData({ data: row });
  const handleStatusChange = (row) => {
    updateServiceChargeConfigRequest({
      payload: {
        serviceId: row.serviceId,
        _id: row._id,
        chargeTypeId: row.chargeTypeId,
        isActive: !row.isActive,
        isTaxApplicable: row.isTaxApplicable,
        taxPercentage: row.taxPercentage,
        isCommissionAble: row.isCommissionAble,
        configType: row.configType,
      },
      onSuccess: getCharges,
    });
  };

  const onSuccessCallback = () => {
    getCharges();
    closeModal();
  };

  const getCharges = useCallback(() => {
    serviceId &&
      getChargesForServiceRequest({
        query: queryString({ serviceId, languageId: 1 }),
        onSuccess: (data) =>
          setState(
            data.map((item, index) => ({
              ...item,
              id: item._id,
              index: index + 1,
            }))
          ),
      });
  }, [serviceId]);

  useEffect(() => {
    getCharges();
  }, [getCharges]);

  useEffect(() => {
    fetchChargeTypeRequest({
      query: queryString({ languageId: 1 }),
      onSuccess: (data) => {
        setChargeTypes(
          data.map((item) => ({
            label: item.chargeName,
            value: item.chargeTypeId,
          }))
        );
      },
    });
  }, []);

  return (
    <Typography
      sx={{
        overflow: "auto",
        margin: "-16px",
        padding: "16px",
      }}
    >
      <Header
        skip
        title="Charges"
        buttons={[{ title: "Add Charge", onClick: onAddPress }]}
      />
      <CustomDataGrid
        rows={state}
        columns={getServiceChargesColumns({
          onEditPress,
          handleStatusChange,
          chargeTypes,
        })}
      />

      {!!modalData && (
        <AddEditChargesModal
          onSuccessCallback={onSuccessCallback}
          serviceId={serviceId}
          data={modalData}
          closeModal={closeModal}
          chargeTypes={chargeTypes}
        />
      )}
    </Typography>
  );
}
