import React, { useCallback, useEffect, useState } from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { CustomField, INPUT_TYPES } from "../../../../components/CustomField";
import TransferList from "./TransferList";
import {
  getDistrictsRequest,
  getEnabledLocationsRequest,
  getPinCodesRequest,
  getStatesRequest,
  updateEnabledLocsRequest,
} from "./utils";

export default function AddEditModalLocation({
  fetchData,
  closeModal,
  serviceId,
}) {
  const [state, setState] = useState({ state: "", district: "" });
  const [enabledPinCodes, setEnabledPinCodes] = useState([]);

  const onMount = useCallback(() => {
    getEnabledLocationsRequest({
      serviceId,
      onSuccess: setEnabledPinCodes,
    });
  }, [serviceId]);

  useEffect(() => {
    getStatesRequest({
      onSuccess: (statesData) =>
        setState((_state) => ({ ..._state, statesData })),
    });
    onMount();
  }, [onMount]);

  const onChangeState = ({ value }) => {
    setState((_state) => ({
      ..._state,
      state: value,
      districtsData: [],
      enabledLocs: [],
      disabledLocs: [],
      district: "",
    }));
    getDistrictsRequest({
      state: value,
      onSuccess: (districtsData) =>
        setState((_state) => ({ ..._state, districtsData })),
    });
  };
  const onChangeDistrict = ({ value }) => {
    setState((_state) => ({
      ..._state,
      enabledLocs: [],
      disabledLocs: [],
      district: value,
    }));
    getPinCodesRequest({
      state: state.state,
      district: value,
      onSuccess: (data) => {
        const enabledLocs = data.filter((item) =>
          enabledPinCodes.includes(item)
        );
        const disabledLocs = data.filter(
          (item) => !enabledPinCodes.includes(item)
        );
        setState((_state) => ({ ..._state, enabledLocs, disabledLocs }));
      },
    });
  };

  const { disabledLocs, enabledLocs } = state;
  const onSavePress = () => {
    if (!disabledLocs?.length && !enabledLocs?.length) {
      return;
    }
    updateEnabledLocsRequest({
      payload: {
        serviceId,
        pincodes: state.enabledLocs,
        toBeDeleted: state.disabledLocs,
      },
      onSuccess: () => {
        fetchData && fetchData();
        closeModal();
      },
    });
  };

  const onChangeSelection = ({ ...rest }) =>
    setState((_state) => ({ ..._state, ...rest }));

  return (
    <ModalWrapper
      footerButtons={[{ title: "Save", onClick: onSavePress }]}
      closeModal={closeModal}
      title="Add/Edit Locations"
    >
      <div style={{ display: "flex", gap: 10 }}>
        <CustomField
          value={state.state}
          onChange={onChangeState}
          item={{
            inputType: INPUT_TYPES.DROPDOWN,
            placeholder: "Select State",
            extraData: state.statesData,
          }}
        />
        {!!state.state && (
          <CustomField
            value={state.district}
            onChange={onChangeDistrict}
            item={{
              inputType: INPUT_TYPES.DROPDOWN,
              placeholder: "Select District",
              extraData: state.districtsData,
            }}
          />
        )}
      </div>
      {!!(disabledLocs?.length || enabledLocs?.length) && (
        <TransferList
          onChangeState={onChangeSelection}
          enabledLocs={enabledLocs}
          disabledLocs={disabledLocs}
        />
      )}
    </ModalWrapper>
  );
}
