import { cloneDeep } from "lodash";
import React, { useState } from "react";
import ModalWrapper from "../../../../../components/ModalWrapper";
import { ITEM_FIELDS, NODE_FIELDS } from "../constants";
import { createNodeReplicaForOtherLanguage } from "../utils";
import {
  CustomField,
  getErrorKey,
  getErrorText,
  INPUT_TYPES,
} from "../../../../../components/CustomField";
import RenderNodeItems from "./RenderNodesItems";

const validateFields = ({ _state, isNode }) => {
  const fieldsData = isNode ? NODE_FIELDS : ITEM_FIELDS;
  let isValid = true;
  fieldsData.forEach(({ isOptional, regex, _key }) => {
    if (isOptional || ["banners", "image"].includes(_key)) {
      return;
    }
    if (_state[_key] === undefined) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "This field can't be empty";
      return;
    }
    if (!((_state[_key] ?? "") + "")?.trim()?.length) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "Please enter a valid value";
      return;
    }
    if (regex && !regex.test(_state[_key])) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "Please enter a valid value";
      return;
    }
    _state[_key] = ((_state[_key] ?? "") + "")?.trim();
  });
  if (_state.children?.length) {
    _state.children.forEach((item, index) => {
      const { _state: newState, isValid: isValidData } = validateFields({
        _state: item,
        isNode: !isNode,
      });
      _state.children[index] = newState;
      if (!isValidData) {
        isValid = false;
      }
    });
  }
  if (_state.items?.length) {
    _state.items.forEach((item, index) => {
      const { _state: newState, isValid: isValidData } = validateFields({
        _state: item,
        isNode: !isNode,
      });
      _state.items[index] = newState;
      if (!isValidData) {
        isValid = false;
      }
    });
  }
  return { isValid, _state };
};

export const AddLanguageModal = ({
  unavailableLanguages,
  serviceId,
  closeModal,
  data,
  isRootNode,
}) => {
  const [state, setState] = useState(cloneDeep(data));
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const onUpdateData = (childData, ind) => {
    setState((_state) => {
      _state[ind] = childData;
      return [..._state];
    });
  };

  const validateData = () => {
    let isValidData = true;
    const clonedState = cloneDeep(state);
    clonedState.forEach((item, index) => {
      const { _state, isValid } = validateFields({
        _state: item,
        isNode: true,
      });
      clonedState[index] = _state;
      if (!isValid) {
        isValidData = false;
      }
    });
    setState(clonedState);
    return isValidData;
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }
    createNodeReplicaForOtherLanguage({
      data: state,
      serviceId,
      languageId: selectedLanguage,
      onSuccess: closeModal,
    });
  };

  return (
    <ModalWrapper
      footerButtons={[{ title: "Create", onClick: onSubmitPress }]}
      title="Add New Language"
      closeModal={closeModal}
    >
      <div style={{ marginBottom: 16 }}>
        <CustomField
          value={selectedLanguage}
          error={!selectedLanguage}
          onChange={({ value }) => setSelectedLanguage(value)}
          item={{
            inputType: INPUT_TYPES.DROPDOWN,
            placeholder: "Select Language",
            extraData: unavailableLanguages,
          }}
        />
      </div>
      {!!selectedLanguage &&
        state.map((data, index) => (
          <RenderNodeItems
            itemsLength={state.length}
            index={index}
            onUpdateData={onUpdateData}
            serviceId={serviceId}
            ignoreMargin
            hardDisable={!data.isActive}
            isNode
            data={data}
            key={data._id}
            isRootNode={isRootNode}
          />
        ))}
    </ModalWrapper>
  );
};
