import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export const TEMPLATE_FILTER = [
  // {
  //   inputType: INPUT_TYPES.DROPDOWN,
  //   _key: "serviceId",
  //   placeholder: "Service",
  //   dataKey: "services",
  // deleteValues: { serviceLabel: "" },
  // },
  // {
  //   inputType: INPUT_TYPES.DROPDOWN,
  //   _key: "state",
  //   placeholder: "State",
  //   dataKey: "states",
  //   overRideValues: { district: "" },
  // },
  // {
  //   inputType: INPUT_TYPES.DROPDOWN,
  //   _key: "district",
  //   placeholder: "District",
  //   dataKey: "districts",
  //   dependentKey: "state",
  // },
];

export const getTemplateListColumns = ({ onEditPress }) => [
  { field: "id", headerName: "ID", width: 90, sortable: true, hide: true },
  {
    field: "templateId",
    headerName: "ID",
    width: 90,
  },
  {
    field: "templateName",
    headerName: "Title",
    width: 200,
  },
  {
    field: "edit",
    headerName: "Edit",
    width: 100,
    renderCell: ({ row }) => {
      const onEdit = (e) => {
        e.stopPropagation();
        onEditPress(row);
      };
      return (
        <IconButton onClick={onEdit}>
          <Edit color="primary" />
        </IconButton>
      );
    },
  },
];
