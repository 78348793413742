import { Box, Card, CardContent, Grid } from "@mui/material";
import {
  CustomField,
  getErrorKey,
  getErrorText,
  INPUT_TYPES,
} from "../../components/";
import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RenderNodeData } from "screens/Services/ServiceDetails/ServiceNode/components/RenderNodeData";
import { ROLE_TYPES, USER_PRIVILEGE_TYPES } from "shared/constants";
import { HTTP_SERVICE, successToast, validateFields } from "../../utils";
import { USER_FIELDS } from "./constants";
import {
  createDashboardUserRequest,
  getDashboardUserInfoRequest,
  updateDashboardUserRequest,
} from "./utils";
import {
  getStatesRequest,
  getDistrictsRequest,
} from "../Services/ServiceDetails/ServiceLocation/utils";
import { useDispatch } from "react-redux";
import { setLoading } from "redux/actions";

const DROPDOWN_FIELDS = [
  {
    inputType: INPUT_TYPES.FILTER_OPTIONS,
    _key: "accessCountry",
    placeholder: "Country",
    extraData: [{ label: "INDIA", value: "INDIA" }],
  },
  {
    inputType: INPUT_TYPES.FILTER_OPTIONS,
    _key: "accessState",
    placeholder: "State",
    dataKey: "states",
    dependentKey: "accessCountry",
  },
  {
    inputType: INPUT_TYPES.FILTER_OPTIONS,
    _key: "accessDistricts",
    placeholder: "District",
    dataKey: "districts",
    dependentKey: "accessState",
  },
];

const RenderDropdown = ({ id, onChange, data = {} }) => {
  const [dropdownData, setDropdownData] = useState({});
  const { userRole } = data;
  useEffect(() => {
    if (id) {
      if (data.accessState?.length) {
        getStatesRequest({
          onSuccess: (states) =>
            setDropdownData((_state) => ({
              ..._state,
              states: states.map((item) => ({ value: item, label: item })),
            })),
        });
      }
      if (data.accessDistricts?.length) {
        getDistrictsRequest({
          state: data.accessState[0]?.value,
          onSuccess: (districts) =>
            setDropdownData((_state) => ({
              ..._state,
              districts: districts.map((item) => ({
                value: item,
                label: item,
              })),
            })),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!userRole) {
    return null;
  }

  const roleInfo = ROLE_TYPES[userRole] || {};
  const { accessCountry, accessDistricts, accessState } = roleInfo;

  const onChangeValue = ({ value, _key, ...rest }) => {
    let overRide = {};
    if (_key === "accessCountry") {
      overRide = { accessState: [], accessDistricts: [] };
      if (accessState && value.length === 1) {
        getStatesRequest({
          onSuccess: (states) =>
            setDropdownData((_state) => ({
              ..._state,
              states: states.map((item) => ({ value: item, label: item })),
            })),
        });
      }
    }
    if (_key === "accessState") {
      overRide = { accessDistricts: [] };
      if (accessDistricts && value.length > 1) {
        value = [value[1]];
      }
      if (accessDistricts && value.length === 1) {
        getDistrictsRequest({
          state: value[0].value,
          onSuccess: (districts) =>
            setDropdownData((_state) => ({
              ..._state,
              districts: districts.map((item) => ({
                value: item,
                label: item,
              })),
            })),
        });
      }
    }
    onChange({ value, _key, overRide, ...rest });
  };

  if (!accessCountry && !accessDistricts && !accessState) {
    return null;
  }
  return (
    <Grid container sx={{ mt: "0px" }} spacing={2}>
      {DROPDOWN_FIELDS.map((item) => {
        const { _key, dependentKey } = item;
        if (
          dependentKey &&
          (!data[dependentKey]?.length || data[dependentKey]?.length > 1)
        ) {
          return null;
        }
        if (!roleInfo[_key]) {
          return null;
        }
        return (
          <Grid key={_key} item sm={12} xs={12}>
            <CustomField
              item={{ extraData: dropdownData[item?.dataKey], ...item }}
              itemData={data}
              key={_key}
              errorText={data[getErrorText(_key)]}
              error={data[getErrorKey(_key)]}
              value={data[_key]}
              onChange={onChangeValue}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const USER_PRIVILEGES = [
  {
    _key: "authorityType",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Authority Type",
    extraData: Object.values(USER_PRIVILEGE_TYPES),
  },
  {
    _key: "serviceIds",
    inputType: INPUT_TYPES.FILTER_OPTIONS,
    placeholder: "Services",
  },
];

const RenderPrivileges = ({ data, onChange, services }) => {
  return (
    <Grid container sx={{ mt: "0px" }} spacing={2}>
      {USER_PRIVILEGES.map((item) => {
        const { _key } = item;

        if (_key === "serviceIds") {
          item.extraData = services;
        }

        return (
          <Grid key={_key} item sm={12} xs={12}>
            <CustomField
              item={item}
              itemData={data}
              key={_key}
              errorText={data[getErrorText(_key)]}
              error={data[getErrorKey(_key)]}
              value={data[_key]}
              onChange={onChange}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default function UserDetails() {
  const [state, setState] = useState({});
  const [services, setServices] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading({ loading: true }));
    HTTP_SERVICE.GET_SERVICES().then(({ data: _services }) => {
      _services = _services.map((item) => ({
        ...item,
        label: item.name,
        value: item.serviceId,
      }));
      if (id) {
        getDashboardUserInfoRequest({
          id,
          onSuccess: (data) => {
            data.accessCountry = data.accessCountry.map((value) => ({
              value,
              label: value,
            }));
            data.accessState = data.accessState.map((value) => ({
              value,
              label: value,
            }));
            data.accessDistricts = data.accessDistricts.map((value) => ({
              value,
              label: value,
            }));
            if (!data.serviceIds) {
              data.serviceIds = [];
            }
            data.serviceIds = data.serviceIds.map((item) =>
              _services.find((serv) => serv.serviceId === item)
            );
            setState(data);
          },
        });
      } else {
        dispatch(setLoading({ loading: false }));
      }
      setServices(_services);
    });
  }, [id, dispatch]);

  const onSubmitPress = () => {
    let { _state, isValid } = validateFields({
      _state: state,
      fields: USER_FIELDS,
    });
    if (!state.authorityType) {
      isValid = false;
      _state[getErrorKey("authorityType")] = true;
      _state[getErrorText("authorityType")] = "This field can't be empty";
    }
    if (!state.serviceIds?.length) {
      isValid = false;
      _state[getErrorKey("serviceIds")] = true;
      _state[getErrorText("serviceIds")] = "This field can't be empty";
    }
    const roleInfo = ROLE_TYPES[state.userRole] || {};
    if (roleInfo.accessCountry && !state.accessCountry?.length) {
      isValid = false;
      _state[getErrorKey("accessCountry")] = true;
      _state[getErrorText("accessCountry")] = "This field can't be empty";
    }
    if (roleInfo.accessState && !state.accessState?.length) {
      isValid = false;
      _state[getErrorKey("accessState")] = true;
      _state[getErrorText("accessState")] = "This field can't be empty";
    }
    if (roleInfo.accessDistricts && !state.accessDistricts?.length) {
      isValid = false;
      _state[getErrorKey("accessDistricts")] = true;
      _state[getErrorText("accessDistricts")] = "This field can't be empty";
    }

    setState({ ..._state });
    if (isValid) {
      const payload = {};
      USER_FIELDS.forEach((item) => (payload[item._key] = state[item._key]));
      payload.accessCountry =
        state.accessCountry?.map((item) => item.value) || [];
      payload.accessState = state.accessState?.map((item) => item.value) || [];
      payload.accessDistricts =
        state.accessDistricts?.map((item) => item.value) || [];
      payload.serviceIds = state.serviceIds?.map((item) => item.value) || [];
      payload.authorityType = state.authorityType;
      const caller = id
        ? updateDashboardUserRequest
        : createDashboardUserRequest;
      caller({
        id,
        payload,
        onSuccess: (_id) => {
          successToast(`User ${id ? "updated" : "created"} successfully!`);
          navigate("/users", { replace: true });
        },
      });
    }
  };

  const onChange = ({ overRide = {}, maxLength, value, _key }) => {
    if (maxLength && value) {
      value = value.slice(0, maxLength);
    }
    setState((_state) => {
      if (_key === "userRole") {
        _state.accessCountry = [];
        _state.accessState = [];
        _state.accessDistricts = [];
      }
      return {
        ..._state,
        [_key]: value,
        [getErrorKey(_key)]: false,
        [getErrorText(_key)]: "",
        ...overRide,
      };
    });
  };

  return (
    <Box sx={{ my: 2 }}>
      <Card>
        <CardContent>
          <Header title={"Users Info"} />
          <RenderNodeData
            customFields={USER_FIELDS}
            isNode
            onChange={onChange}
            marginLeft="0px"
            data={state}
          />
          <Header skip title="Privileges" />
          <RenderPrivileges
            data={state}
            onChange={onChange}
            services={services}
          />

          {!!state.userRole && (
            <RenderDropdown id={id} onChange={onChange} data={state} />
          )}
          <Header
            skip
            buttons={[
              {
                title: id ? "Update" : "Add",
                size: "large",
                sx: { mt: "16px" },
                onClick: onSubmitPress,
              },
            ]}
          />
        </CardContent>
      </Card>
    </Box>
  );
}
