import { Edit } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";

const ContactUsCard = ({ icon, content, btnAction, btn = false }) => {
  return (
    <Box sx={{ mt: 3, width: "100%" }}>
      <Card>
        <CardContent style={{ paddingBottom: "16px" }}>
          <Box
            sx={{
              width: "100%",
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              p: 0,
              gap: 3,
            }}
            p={0.5}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: {xs: 2, sm: 3},
              }}
              variant="div"
            >
              <Typography sx={{ fontSize: "14px" }} variant="span">
                {icon}
              </Typography>

              <Typography
                sx={{ fontSize: "18px", fontWeight: 500 }}
                variant="span"
              >
                {content}
              </Typography>
            </Typography>
            {btn && (
              <Box>
                <div
                  style={{
                    background: "#1b5e20",
                    borderRadius: "50px",
                    width: "38px",
                    height: "38px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={btnAction}
                >
                  <Edit color={"white"} />
                </div>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ContactUsCard;
