import { Box, Card, CardContent, Typography } from "@mui/material";
import Header from "components/HeaderV2";
import React from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { PAYMENT_FIELDS } from "../constants";

export default function PaymentDetail() {
  const { state } = useLocation();
  console.log(state);
  const data = state?.data || {};

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header
            title={`Transaction #${data.txnId}`}
            variant="h5"
            titleStyle={{ fontWeight: "medium" }}
          />
          {PAYMENT_FIELDS.map((item) => {
            const { _key, placeholder } = item;
            if (!_key) return null;
            return (
              //   data[_key] && (
              <PaymentDetailItem
                key={_key}
                data={{
                  _key,
                  label: placeholder,
                  value:
                    _key === "createdAt"
                      ? moment(data[_key]).format("DD MMM YYYY hh:mm a")
                      : data[_key],
                }}
              />
              //   )
            );
          })}
        </CardContent>
      </Card>
    </Box>
  );
}
const PaymentDetailItem = ({ data }) => {
  const { label, value } = data;
  return (
    <Typography>
      <Typography
        component={"span"}
        sx={{ fontSize: "16px", fontWeight: "medium" }}
      >
        {label}:{" "}
      </Typography>
      <Typography
        component={"span"}
        sx={{
          cursor: "pointer",
          fontSize: "14px",
        }}
      >
        {value}
      </Typography>
    </Typography>
  );
};
