import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { CustomField, getErrorKey, getErrorText } from "components";
import ModalWrapper from "components/ModalWrapper";
import { Grid } from "@mui/material";
import { getPageData } from "../utils";
import { validateFields } from "utils";

export const EditViewByLanguage = ({
  closeModal,
  pageType,
  version,
  appType,
  modalData,
}) => {
  const [state, setState] = useState(cloneDeep(modalData.data));

  const { customFields, key } = getPageData({
    pageType,
    version,
  });

  const validateData = () => {
    const { _state, isValid } = validateFields({
      _state: state,
      fields: customFields,
    });
    setState({ ..._state });
    return isValid;
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    const caller = getPageData({ pageType }).editRequest;

    const data = {};

    customFields.forEach(({ _key }) => (data[_key] = state[_key]));

    data.languageId = state.languageId;

    if (caller) {
      caller({
        id: state._id,
        data,
        version,
        appType,
        onSuccess: closeModal,
      });
    }
  };

  const onChange = ({ value, _key }) => {
    setState((_state) => ({
      ..._state,
      [_key]: value,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
    }));
  };

  return (
    <ModalWrapper
      footerButtons={
        modalData.isEdit && [{ title: "Update", onClick: onSubmitPress }]
      }
      closeModal={closeModal}
      title={modalData.title}
      sx={{ width: "50%" }}
      childSx={{ height: "50vh", p: 2, overflow: "auto" }}
    >
      <Grid container rowSpacing={2} spacing={1}>
        {customFields.map((item, index) => {
          const { _key } = item;

          if (!_key) return null;
          if (!modalData.isEdit && _key === key) return null;

          return (
            <Grid key={_key} item md={12} sm={12} xs={12}>
              <CustomField
                color={"primary"}
                item={item}
                disabled={!modalData.isEdit}
                itemData={state}
                errorText={state[getErrorText(_key)]}
                error={state[getErrorKey(_key)]}
                value={state[_key]}
                onChange={onChange}
              />
            </Grid>
          );
        })}
      </Grid>
      {!modalData.isEdit && (
        <div
          style={{ padding: 10, wordWrap: "break-word" }}
          dangerouslySetInnerHTML={{ __html: state[key] }}
        />
      )}
    </ModalWrapper>
  );
};
