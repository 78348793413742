import React, { useState } from "react";
import ModalWrapper from "components/ModalWrapper";
import { CustomField, getErrorKey, getErrorText } from "components";
import { Grid } from "@mui/material";
import { validateFields } from "utils";
import { FAQ_FIELDS } from "../constants";
import { addFaqRequest, editFaqRequest } from "../utils";

export const AddEditFaqModal = ({ onSuccessCallback, closeModal, data }) => {
  const { data: _data, topicData = {} } = data;
  const isEdit = !!_data;
  const [state, setState] = useState(_data || {});

  const validateData = () => {
    let isValidData = true;
    const { isValid, _state } = validateFields({
      _state: state,
      fields: FAQ_FIELDS,
    });
    setState({ ..._state });

    if (!isValid) {
      isValidData = false;
    }

    return isValidData;
  };

  const onChange = ({ _key, value }) => {
    setState((_state) => ({
      ...state,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
      [_key]: value,
    }));
  };

  const onSuccess = (data) => {
    onSuccessCallback(data);
    closeModal();
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    const payload = {
      question: state.question,
      answer: state.answer,
    };

    const caller = isEdit ? editFaqRequest : addFaqRequest;

    caller({
      topicId: topicData?._id,
      id: _data?._id,
      payload,
      onSuccess: onSuccess,
    });
  };

  return (
    <ModalWrapper
      footerButtons={[
        {
          title: isEdit ? "Update" : "Add",
          onClick: onSubmitPress,
        },
      ]}
      title={`${isEdit ? "Edit" : "Add"} Faq`}
      closeModal={closeModal}
      sx={{ width: "50%" }}
      childSx={{ height: "50vh", p: 2 }}
    >
      <div
        style={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <Grid container rowSpacing={2} spacing={1}>
          {FAQ_FIELDS.map((item) => {
            const { _key } = item;

            if (!_key) {
              return null;
            }

            return (
              <Grid key={_key} item md={12} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={item}
                  errorText={state[getErrorText(_key)]}
                  error={state[getErrorKey(_key)]}
                  value={state[_key]}
                  onChange={onChange}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </ModalWrapper>
  );
};
