import React, { useCallback, useEffect, useState } from "react";
import Header from "../../../../components/HeaderV2";
import { useGetLangData } from "../hooks/useLanguagesData";
import { AddLanguageModal } from "./AddLanguageModal";
import { NodeModal } from "../ServiceNode/components/NodeModal";
import { FAQ_FIELDS } from "./constants";
import {
  addServiceFaqLangRequest,
  addServiceFaqRequest,
  getServiceFaqRequest,
  toggleServiceFaqStatusRequest,
  updateServiceFaqPositionRequest,
  updateServiceFaqRequest,
} from "../../utils";
import FaqItem from "./FaqItem";
import { Typography } from "@mui/material";

const formatFaqData = ({ serviceId, data: { languageId, stateData } = {} }) => {
  return {
    faqsAnswer: stateData.faqsAnswer,
    faqsQuestion: stateData.faqsQuestion,
    position: stateData.position,
    languageId: languageId ?? stateData.languageId,
    faqsId: stateData.faqsId,
    serviceId,
  };
};

export default function FaqScreen({ serviceId }) {
  const [state, setState] = useState([]);
  const [showModal, toggleModal] = useState(false);
  const [modalData, toggleModalData] = useState(null);
  const { availableLanguages, unavailableLanguages, defaultLanguageId } =
    useGetLangData({ data: state });
  const [selectedLanguage, setSelectedLanguage] = useState();

  const getFaqData = useCallback(() => {
    getServiceFaqRequest({ id: serviceId, onSuccess: setState });
  }, [serviceId]);

  useEffect(() => {
    getFaqData();
  }, [getFaqData]);

  useEffect(() => {
    defaultLanguageId && setSelectedLanguage(defaultLanguageId);
  }, [defaultLanguageId]);

  const onAddLanguagePress = () => toggleModal(true);
  const closeModal = () => toggleModal(false);

  const onAddFaq = () => toggleModalData({ title: "Add FAQ" });
  const onEditPress = ({ data }) =>
    toggleModalData({
      isEdit: true,
      data,
      title: "Edit FAQ",
    });
  const closeAddModal = () => toggleModalData(null);
  const { faqs = [] } =
    state.find((item) => item.languageId === selectedLanguage) || {};

  const onSubmitPress = (val) => {
    if (modalData.isEdit) {
      updateServiceFaqRequest({
        data: formatFaqData({ data: val[0], serviceId }),
        onSuccess: () => {
          closeAddModal();
          getFaqData();
        },
      });
      return;
    }
    addServiceFaqRequest({
      payload: {
        serviceId,
        faqs: val.map((item) => formatFaqData({ data: item, serviceId })),
      },
      onSuccess: () => {
        closeAddModal();
        getFaqData();
      },
    });
  };

  const onAddLanguageSubmit = ({ data, languageId }) => {
    addServiceFaqLangRequest({
      payload: {
        faqs: data.map((item) =>
          formatFaqData({ serviceId, data: { stateData: item, languageId } })
        ),
      },
      onSuccess: () => {
        closeModal();
        getFaqData();
      },
    });
  };

  const onToggleCallback = (id) =>
    toggleServiceFaqStatusRequest({ id, onSuccess: getFaqData });

  const onPositionUpdate = ({ position, faqsId, withItem }) => {
    const sibling = faqs[withItem];
    updateServiceFaqPositionRequest({
      payload: {
        positions: [
          { faqsId, position: sibling.position },
          { faqsId: sibling.faqsId, position },
        ],
      },
      onSuccess: getFaqData,
    });
  };

  return (
    <Typography
      sx={{
        overflow: "auto",
        margin: "-16px",
        padding: "16px",
      }}
    >
      <Header
        title="FAQ's"
        skip
        dropDown={{
          value: selectedLanguage,
          onChange: setSelectedLanguage,
          data: availableLanguages,
        }}
        buttons={[
          { title: "Add FAQ", onClick: onAddFaq },
          {
            onClick: onAddLanguagePress,
            disabled: !state.length || !unavailableLanguages.length,
            title: "Add Language",
          },
        ]}
      />
      {!!showModal && (
        <AddLanguageModal
          closeModal={closeModal}
          serviceId={serviceId}
          data={faqs}
          onSubmitPress={onAddLanguageSubmit}
          unavailableLanguages={unavailableLanguages}
        />
      )}
      {!!modalData && (
        <NodeModal
          availableLanguages={availableLanguages}
          closeModal={closeAddModal}
          customFields={FAQ_FIELDS}
          isNode={!modalData.isEdit}
          modalData={modalData}
          onSubmitPress={onSubmitPress}
        />
      )}
      {faqs.map((item, index) => (
        <FaqItem
          onEditPress={onEditPress}
          index={index}
          data={item}
          onPositionUpdate={onPositionUpdate}
          onToggleCallback={onToggleCallback}
          itemsLength={faqs.length}
          key={item._id}
        />
      ))}
    </Typography>
  );
}
