import React, { useState } from "react";
import { CustomField, getErrorKey, getErrorText } from "components";
import ModalWrapper from "components/ModalWrapper";
import { Grid } from "@mui/material";
import { validateFields } from "utils";
import { APPLICATION_TAGS_FIELDS } from "../constants";
import { updateApplicationTagsRequest } from "../utils";

export const AddEditApplicationTag = ({
  closeModal,
  modalData,
  selectedLangData,
  onSuccessCallback,
}) => {
  const [state, setState] = useState(
    modalData.data || { languageId: selectedLangData.languageId }
  );
  const isEdit = modalData.isEdit;

  const validateData = () => {
    const { _state, isValid } = validateFields({
      _state: state,
      fields: APPLICATION_TAGS_FIELDS,
    });
    setState({ ..._state });
    return isValid;
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    updateApplicationTagsRequest({
      id: selectedLangData._id,
      payload: {
        tags: { ...selectedLangData.tags, [state.tagKey]: state.tagValue },
      },
      onSuccess: onSuccessCallback,
    });
  };

  const onChange = ({ value, _key }) => {
    setState((_state) => ({
      ..._state,
      [_key]: value,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
    }));
  };

  return (
    <ModalWrapper
      footerButtons={
        !modalData.viewOnly && [
          { title: isEdit ? "Update" : "Save", onClick: onSubmitPress },
        ]
      }
      closeModal={closeModal}
      title={modalData?.title}
      sx={{ width: "50%" }}
      childSx={{ height: "45vh", p: 2, overflow: "auto" }}
    >
      <Grid container rowSpacing={2} spacing={1}>
        {APPLICATION_TAGS_FIELDS.map((item) => {
          const { _key } = item;

          if (!_key) return null;

          let disabled = false;

          if (_key === "tagKey") {
            disabled = !!isEdit;
          }

          if (_key === "languageId") {
            disabled = true;
            item.extraData = modalData.activeLanguages;
          }

          return (
            <Grid key={_key} item md={12} sm={12} xs={12}>
              <CustomField
                color={"primary"}
                item={item}
                itemData={state}
                errorText={state[getErrorText(_key)]}
                error={state[getErrorKey(_key)]}
                value={state[_key]}
                onChange={onChange}
                disabled={disabled}
              />
            </Grid>
          );
        })}
      </Grid>
    </ModalWrapper>
  );
};
