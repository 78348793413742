import { Box, Card, CardContent } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import CustomFilter from "components/CustomFilter";
import { queryString } from "utils";
import CustomDataGrid from "components/DataGrid";
import { BROADCAST_FILTER } from "./constant";
import { getBroadcastListColumns } from "./constant";
import { fetchBroadcastsRequest } from "screens/Broadcast/utils";
import { useNavigate } from "react-router-dom";

export default function BroadcastListing() {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const navigate = useNavigate();
  const onViewPress = () => {};

  const onSuccess = (data) => {
    setData(
      data?.map((broadcast, index) => ({
        ...broadcast,
        id: broadcast._id,
        index: index + 1,
      }))
    );
  };

  useEffect(() => {
    fetchBroadcastsRequest({
      query: queryString({ ...filters }),
      onSuccess,
    });
  }, [filters]);

  const onSendPress = () => {
    navigate("/broadcast/create");
  };

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header
            title="Broadcasts"
            buttons={[{ title: "Send Broadcast", onClick: onSendPress }]}
          />
          <CustomFilter
            onChange={setFilters}
            filtersData={filters}
            fields={BROADCAST_FILTER}
          />
          <CustomDataGrid
            rows={data}
            columns={getBroadcastListColumns({ onViewPress })}
          />
        </CardContent>
      </Card>
    </Box>
  );
}
