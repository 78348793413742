import SearchIcon from "@mui/icons-material/Search";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PaymentsIcon from "@mui/icons-material/Payments";
import { ROLE_TYPES } from "shared/constants";

const PROVIDER_PAYORDERS = {
  href: "/provider/payOrders",
  icon: <PaymentsIcon fontSize="small" />,
  title: "Pay Orders",
};

const ACCOUNT_PROVIDER_ROUTES = [PROVIDER_PAYORDERS];

const CUSTOMER_SEARCH = {
  href: "/customer/account/search",
  icon: <SearchIcon fontSize="small" />,
  title: "Search Customer",
};

const ACCOUNT_CUSTOMER_ROUTES = [CUSTOMER_SEARCH];

export const ACCOUNT_ROUTES = [
  {
    icon: <AccountTreeIcon fontSize="small" />,
    title: "Account",
    enabled: { [ROLE_TYPES["super-admin"].value]: true },
    child: [
      {
        icon: <AccountTreeIcon fontSize="small" />,
        title: "Provider",
        child: ACCOUNT_PROVIDER_ROUTES,
      },
      {
        icon: <AccountTreeIcon fontSize="small" />,
        title: "Customer",
        child: ACCOUNT_CUSTOMER_ROUTES,
      },
    ],
  },
];
