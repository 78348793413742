import { Box } from "@mui/system";
import React, { useState } from "react";

import {
  Close,
  Cancel,
  Image,
  ViewCarousel,
  ExpandCircleDown,
  PlayCircleFilled,
} from "@mui/icons-material";
import { IconButton, Modal, Tooltip, Typography } from "@mui/material";
import ModalWrapper from "../../../components/ModalWrapper";
import {
  changeBannersOrderRequest,
  fetchServiceNodesData,
  removeImageRequest,
  uploadImagesRequest,
} from "../ServiceDetails/ServiceNode/utils";
import { ConfirmationModal } from "components/ConfirmationModal";
import { isArray } from "lodash";
import { FILE_TYPES } from "shared/constants";

export const ImageUploaderModal = ({
  closeModal,
  multiple,
  isNode,
  onSuccessCallback,
  _key,
  modalData,
  data,
  collection,
  placeholder,
}) => {
  const { title } = modalData || {};
  const [images, setImages] = useState([]);

  const color = multiple ? "primary" : "secondary";

  if (!modalData) {
    return null;
  }

  const onSuccess = () => {
    const caller = onSuccessCallback ?? fetchServiceNodesData;
    caller(data.serviceId || data.categoryId);
    setImages([]);
  };

  const onUploadPress = () => {
    uploadImagesRequest({
      data: images,
      documentId: data._id,
      action: multiple ? "push" : "set",
      collection: collection ?? (isNode ? "serviceNodes" : "serviceNodeItems"),
      docKey: _key ?? (multiple ? "banners" : "image"),
      onSuccess: onSuccess,
    });
  };

  const onChangeOrder = ({ bannerId, event }) => {
    changeBannersOrderRequest({
      bannerId,
      event,
      documentId: data._id,
      collection: collection ?? (isNode ? "serviceNodes" : "serviceNodeItems"),
      docKey: _key ?? (multiple ? "banners" : "image"),
      onSuccess: onSuccess,
    });
  };

  const onRemovePress = (index) => {
    removeImageRequest({
      documentId: data._id,
      collection: collection ?? (isNode ? "serviceNodes" : "serviceNodeItems"),
      docKey: _key ?? (multiple ? "banners" : "image"),
      docKeyIndex: index,
      action: multiple ? "pull" : "set",
      onSuccess: onSuccess,
    });
  };

  const onChange = ({ value = {} }) => {
    const temp = [];
    Object.keys(value).forEach((key) => {
      temp.push({ uri: URL.createObjectURL(value[key]), data: value[key] });
    });

    if (multiple) {
      setImages((_state) => [..._state, ...temp]);
    } else {
      setImages(temp);
    }
  };

  const getFooterButtons = () => {
    const buttons = [];

    buttons.push({
      disabled: !images.length,
      onClick: onUploadPress,
      sx: { px: "20px" },
      title: "Upload",
    });
    return buttons;
  };
  const imagesData = _key ? data[_key] : multiple ? data.banners : data.image;

  return (
    <ModalWrapper
      sx={{ width: "50%" }}
      footerButtons={getFooterButtons()}
      background={multiple ? "#f0fdf4" : "#eff6ff"}
      childSx={{ p: 0 }}
      color={color}
      closeModal={closeModal}
      title={`${placeholder || title}`}
    >
      <Uploader
        placeholder={placeholder}
        multiple={multiple}
        color={color}
        onChange={onChange}
      />
      <RenderImages
        color={color}
        data={isArray(imagesData) ? imagesData : imagesData ? [imagesData] : []}
        images={images}
        multiple={multiple}
        onRemovePress={onRemovePress}
        setImages={setImages}
        onChangeOrder={onChangeOrder}
      />
    </ModalWrapper>
  );
};

const Uploader = ({ placeholder, color, multiple, onChange }) => {
  return (
    <Box color={color} sx={{ m: 3, mb: 0 }}>
      <Typography
        color={color}
        component={"label"}
        htmlFor={"image-input"}
        sx={{
          border: "1.5px dashed",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "20vh",
          gap: 1,
          cursor: "pointer",
        }}
      >
        <input
          id="image-input"
          multiple={multiple && "multiple"}
          accept={multiple ? "image/*,video/*" : "image/*"}
          type={"file"}
          style={{ display: "none" }}
          onChange={({ target }) => {
            onChange({ value: target.files });
          }}
        />
        {multiple ? <ViewCarousel color={color} /> : <Image color={color} />}
        <Typography
          component={"span"}
          color={color}
          sx={{ fontSize: "15px", fontWeight: "500" }}
        >
          Upload {placeholder ? placeholder : multiple ? "banners" : "image"}
        </Typography>
      </Typography>
    </Box>
  );
};

export const ImagePreviewModal = ({ previewUrl, closeModal }) => {
  return (
    <Modal
      open={!!previewUrl}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ outline: "none" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "40vw",
          width: "100%",
          height: "100%",
          bgcolor: `rgba(0,0,0,0.7)`,
          boxShadow: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}
      >
        <IconButton
          onClick={closeModal}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <Close color="white" />
        </IconButton>
        <img
          src={previewUrl}
          alt="preview"
          style={{
            width: "fit-content",
            height: "fit-content",
            borderRadius: "10px",
            maxWidth: window.innerHeight - 50,
            maxHeight: window.innerHeight - 50,
          }}
        />
      </Box>
    </Modal>
  );
};

export const VideoPreviewModal = ({ previewUrl, closeModal }) => {
  return (
    <Modal
      open={!!previewUrl}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ outline: "none" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "40vw",
          width: "100%",
          height: "100%",
          bgcolor: `rgba(0,0,0,0.7)`,
          boxShadow: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}
      >
        <IconButton
          onClick={closeModal}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <Close color="white" />
        </IconButton>
        <video
          src={previewUrl}
          style={{
            width: "90%",
            height: "90%",
            borderRadius: "10px",
          }}
          controls
          alt="preview"
        ></video>
      </Box>
    </Modal>
  );
};

const RenderImages = ({
  color,
  data,
  images,
  multiple,
  onRemovePress,
  setImages,
  onChangeOrder,
}) => {
  const [confirmationModalData, setConfirmationModalData] = useState(null);

  const removeImage = (isLocal, index) => {
    setConfirmationModalData({
      title: "Delete Image",
      description: "Are you sure, you want to delete the image?",
      footerButtons: [
        {
          onClick: () => setConfirmationModalData(null),
          sx: { px: "20px", mx: "8px" },
          title: "Cancel",
          variant: "outlined",
          color: "error",
        },
        {
          color: "error",
          onClick: () => {
            setConfirmationModalData(null);
            if (isLocal) {
              setImages((_images) => [
                ..._images.filter((_image, imageIndex) => index !== imageIndex),
              ]);
              return;
            }
            onRemovePress(index);
          },
          sx: { px: "20px" },
          title: "Delete",
        },
      ],
    });
  };

  const [previewUrl, setPreviewUrl] = useState(null);
  const showPreview = (previewUrl, type) => {
    setPreviewUrl({ url: previewUrl, type: type });
  };

  const closePreview = () => setPreviewUrl(null);

  return (
    <Typography component={"div"} sx={{ pb: 3 }}>
      {(!!images.length || !!data.length) && (
        <Typography
          component={"div"}
          sx={{
            display: "flex",
            pt: 4,
            height: "fit-content",
            overflow: "auto",
          }}
          color={color}
          className={"hide-scrollbar"}
        >
          {(multiple
            ? [...data, ...images]
            : images.length
            ? images
            : data
          ).map((image, index) => {
            const type = image?.type || getFileType(image?.data?.type);

            return (
              <Typography
                key={index}
                component={"div"}
                sx={{
                  mx: 3,
                  border: "1.5px solid",
                  p: "4px",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  showPreview(image.uri || image.fileUrl || image, type);
                }}
              >
                <IconButton
                  color={color}
                  sx={{
                    position: "absolute",
                    right: "-21px",
                    top: "-21px",
                    zIndex: 2,
                  }}
                  onClick={(e) => {
                    e && e.stopPropagation && e.stopPropagation();
                    const isLocal = !!image.uri;
                    removeImage(isLocal, isLocal ? index - data.length : index);
                  }}
                >
                  <Cancel />
                </IconButton>
                <span
                  style={{
                    background: "white",
                    width: "10px",
                    height: "10px",
                    position: "absolute",
                    right: "-6px",
                    top: "-6px",
                    zIndex: 1,
                  }}
                ></span>

                <div
                  style={{
                    width: 100,
                    height: 100,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {type === FILE_TYPES.VIDEO ? (
                    <>
                      <video
                        src={image.uri || image.fileUrl || image}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        alt={"video"}
                      ></video>
                      <PlayCircleFilled
                        sx={{
                          position: "absolute",
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    </>
                  ) : (
                    <img
                      src={`${image.uri || image.fileUrl || image}`}
                      alt={image.uri}
                      style={{
                        objectFit: "contain",
                        maxHeight: "100%",
                        maxWidth: "100%",
                      }}
                    />
                  )}
                </div>
                {multiple && !images.length && (
                  <ChangeOrderButtons
                    index={index}
                    item={image}
                    itemsLength={data.length}
                    onChangeOrder={({ bannerId, event }) =>
                      onChangeOrder({ bannerId, event })
                    }
                  />
                )}
              </Typography>
            );
          })}
          {!!previewUrl &&
            (previewUrl?.type === FILE_TYPES.VIDEO ? (
              <VideoPreviewModal
                previewUrl={previewUrl.url}
                closeModal={closePreview}
              />
            ) : (
              <ImagePreviewModal
                previewUrl={previewUrl.url}
                closeModal={closePreview}
              />
            ))}

          {!!confirmationModalData && (
            <ConfirmationModal
              closeModal={() => setConfirmationModalData(null)}
              color={color}
              modalData={confirmationModalData}
            />
          )}
        </Typography>
      )}
    </Typography>
  );
};

const ChangeOrderButtons = ({ item, index, itemsLength, onChangeOrder }) => {
  const color = "primary";
  return (
    <Typography
      component={"div"}
      sx={{
        display: "flex",
        position: "absolute",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onChangeOrder({ bannerId: item._id, event: "decrement" });
        }}
        style={{
          rotate: "90deg",
          left: "-20px",
          cursor: !index ? "not-allowed" : "pointer",
          zIndex: 1,
        }}
      >
        <span
          style={{
            background: "black",
            position: "absolute",
            width: "10px",
            height: "10px",
            zIndex: -1,
          }}
        ></span>
        <Tooltip title="Move Left">
          <ExpandCircleDown color={color} />
        </Tooltip>
      </IconButton>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onChangeOrder({ bannerId: item._id, event: "increment" });
        }}
        style={{
          rotate: "-90deg",
          right: "-20px",
          cursor: index === itemsLength - 1 ? "not-allowed" : "pointer",
        }}
      >
        <span
          style={{
            background: "black",
            position: "absolute",
            width: "10px",
            height: "10px",
            zIndex: -1,
          }}
        ></span>
        <Tooltip title="Move Right">
          <ExpandCircleDown color={color} />
        </Tooltip>
      </IconButton>
    </Typography>
  );
};

export const getFileType = (mime = "") => {
  if (mime?.includes("video/")) {
    return FILE_TYPES.VIDEO;
  }
  if (mime?.includes("image/")) {
    return FILE_TYPES.IMAGE;
  }
  return "";
};
