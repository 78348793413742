import { store } from "redux/store";

const { setLoading } = require("redux/actions");
const { PROVIDER_SERVICE } = require("utils");

export const getProviderList = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  PROVIDER_SERVICE.GET_PROVIDER_LIST(query)
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getProvidersInfoRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  PROVIDER_SERVICE.GET_PROVIDERS_INFO(id)
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getAccountInfoRequest = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  PROVIDER_SERVICE.GET_PROVIDER_ACCOUNT_DATA(query)
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const approveRejectProviderRequest = ({ id, payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  PROVIDER_SERVICE.APPROVE_REJECT_PROVIDER({ id, payload })
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getProviderCalendarRequest = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  PROVIDER_SERVICE.GET_PROVIDER_CALENDAR(query)
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const activateDeactivateProviderRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  PROVIDER_SERVICE.ACTIVATE_DEACTIVATE_PROVIDER(id)
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const toggleMarkAsEmployeeRequest = ({ id, payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));

  PROVIDER_SERVICE.TOGGLE_MARK_AS_EMPLOYEE(id, payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
