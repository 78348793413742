import { TextField } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";

export default function CustomDatePicker({
  disabled,
  error,
  _key,
  errorText,
  onChange,
  color,
  extraProps = {},
  placeholder,
  value,
}) {
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value ? new Date(value) : undefined);
  }, [value]);

  const onAccept = (val) => {
    onChange({
      value: moment(new Date(val)).toISOString(),
      _key,
    });
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        label={placeholder}
        disabled={disabled}
        inputFormat="DD MMM YYYY"
        value={state || null}
        onAccept={onAccept}
        onClose={() => setState(value)}
        {...extraProps}
        onChange={setState}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={disabled}
            error={error}
            helperText={errorText}
            size="small"
            color={color}
            fullWidth
            {...extraProps}
          />
        )}
      />
    </LocalizationProvider>
  );
}
