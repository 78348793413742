import { Delete } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import {
  CustomField,
  INPUT_TYPES,
  getErrorKey,
  getErrorText,
} from "components";
import React from "react";
import Header from "components/HeaderV2";

export default function RenderFillers({ fillers = [], setTemplateData }) {
  const onAddFiller = () => {
    setTemplateData((_data) => ({ ..._data, fillers: [..._data.fillers, {}] }));
  };

  return (
    <>
      <Header
        skip
        title="Fillers"
        buttons={[{ title: "Add", onClick: onAddFiller }]}
      />
      <Grid container>
        {fillers.map((item, index) => {
          const onDeletePress = () => {
            setTemplateData((_data) => {
              const _fillers = [
                ..._data.fillers.slice(0, index),
                ..._data.fillers.slice(index + 1),
              ];
              return { ..._data, fillers: _fillers };
            });
          };

          const onChange = ({ _key, value }) => {
            setTemplateData((_data) => {
              _data.fillers[index][_key] = value;
              _data.fillers[index][getErrorText(_key)] = "";
              _data.fillers[index][getErrorKey(_key)] = false;

              return { ..._data };
            });
          };
          const _key = "key";
          return (
            <Grid
              key={index}
              item
              md={fillers.length === 1 ? 12 : 6}
              style={{
                alignItems: "center",
                display: "flex",
                marginBottom: "16px",
              }}
            >
              <CustomField
                onChange={onChange}
                value={item[_key]}
                errorText={item[getErrorText(_key)]}
                error={item[getErrorKey(_key)]}
                item={{
                  _key,
                  inputType: INPUT_TYPES.INPUT,
                  placeholder: `${index + 1}) Filler Name`,
                }}
              />
              {fillers?.length > 1 && (
                <IconButton onClick={onDeletePress}>
                  <Delete color="error" />
                </IconButton>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
