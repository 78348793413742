import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { COMMUNICATION_SERVICES } from "utils";

export const getCommunicationConfigRequest = ({ onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  COMMUNICATION_SERVICES.FETCH_COMMUNICATION_CONFIG()
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateCommunicationConfigRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  COMMUNICATION_SERVICES.UPDATE_COMMUNICATION_CONFIG(payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
