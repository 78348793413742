import { Box, Card } from "@mui/material";
import { CustomField, INPUT_TYPES } from "components";
import Header from "components/HeaderV2";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { searchUserCS } from "./utils";
import qs from "query-string";
import { ProviderDetails } from "screens/Provider";
import { CustomerDetails } from "screens/Customer";

export default function UserSearch() {
  const pathName = window.location.pathname;
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState(null);
  const [userType] = pathName.slice(1, pathName.length).split("/");
  const title = `Search ${
    userType.charAt(0).toUpperCase() + userType.slice(1)
  }`;
  const isProvider = userType === "provider";
  const navigate = useNavigate();

  const onSearch = useCallback(
    (e, _search) => {
      const value = _search || search;
      setUserData(null);
      e?.preventDefault();
      !!value &&
        searchUserCS({
          search: value,
          userType,
          onSuccess: (_data) => {
            setUserData({
              ..._data,
              addresses: _data.addresses?.map((address, index) => ({
                ...address,
                id: address._id,
                index: index + 1,
              })),
            });
          },
        });
    },
    [search, userType]
  );

  useEffect(() => {
    const params = qs.parse(searchParams.toString());
    setSearch(params.q || "");
    onSearch(undefined, params.q);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeValue = ({ target }) => {
    navigate(`${window.location.pathname}?q=${target.value}`, {
      replace: true,
    });
    setSearch(target.value);
  };

  useEffect(() => {
    setUserData(null);
    setSearch("");
  }, [userType]);
  return (
    <Box sx={{ my: 2 }}>
      <Card sx={{ px: 2, py: 1 }}>
        <Header
          title={title}
          style={{ marginBottom: 0 }}
          buttons={[{ title: "Search", onClick: onSearch }]}
        >
          <form style={{ width: "100%" }} onSubmit={onSearch}>
            <CustomField
              value={search}
              onChange={onChangeValue}
              item={{
                inputType: INPUT_TYPES.INPUT,
                placeholder: "Enter registered Email/ Mobile Number",
              }}
            />
          </form>
        </Header>
      </Card>
      {!!userData &&
        (isProvider ? (
          <ProviderDetails _data={userData} />
        ) : (
          <CustomerDetails _data={userData} />
        ))}
    </Box>
  );
}
