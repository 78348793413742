import { queryString } from "./helper";
import { requests } from "./Services";

export const AUTH_SERVICES = {
  LOGIN(payload) {
    return requests.post("/team/login", payload);
  },
  PROFILE() {
    return requests.get("/team/get-user-info");
  },
  GET_DASHBOARD_USER() {
    return requests.get("/team/all");
  },
  CREATE_DASHBOARD_USER(payload) {
    return requests.post("/team/register", payload);
  },
  UPDATE_DASHBOARD_USER({ id, payload }) {
    return requests.put(`/team/update/${id}`, payload);
  },
  UPDATE_DASHBOARD_USER_STATUS({ id, payload }) {
    return requests.patch(`/team/activate/${id}`, payload);
  },
  GET_DASHBOARD_USER_INFO(id) {
    return requests.get(`/team/${id}`);
  },
};

export const BOOKING_SERVICES = {
  GET_BOOKINGS(query) {
    return requests.get(`/bookings?${query}`);
  },
  GET_BOOKING_DETAILS(id) {
    return requests.get(`/bookings/${id}`);
  },
  GET_BOOKING_PROVIDERS(query) {
    return requests.get(`/provider/available?${query}`);
  },
  ASSIGN_BOOKING(data) {
    return requests.post(`/bookings/assignJob`, data);
  },
  REASSIGN_BOOKING(data) {
    return requests.post(`/bookings/reassignJob`, data);
  },
  RESCHEDULE_BOOKING_JOB(data) {
    return requests.post(`/bookings/reschedule`, data);
  },
  CANCEL_BOOKING(data) {
    return requests.post(`/bookings/cancel`, data);
  },
  CANCEL_JOB(data) {
    return requests.put(`/jobs/cancel`, data);
  },
  START_JOB(data) {
    return requests.post(`/jobs/start`, data);
  },
  END_JOB(data) {
    return requests.post(`/jobs/end`, data);
  },
  UPDATE_CHARGES(data) {
    return requests.put("/jobService/updateCharges", data);
  },
  CREATE_BOOKING_PAYMENT(payload) {
    return requests.post(`/bookings/createBookingPaymentByAdmin`, payload);
  },
  SUSPEND(data) {
    return requests.put(`/bookings/suspend`, data);
  },
  RELATED_BOOKINGS(id) {
    return requests.get(`/bookings/relatedBookings/${id}`);
  },
};

export const PROVIDER_SERVICE = {
  GET_PROVIDER_LIST(query = "") {
    return requests.get(`/provider/search?${query}`);
  },
  GET_PROVIDER_ACCOUNT_DATA(query = "") {
    return requests.get(`/account?${query}`);
  },
  GET_PROVIDER_CALENDAR(query = "") {
    return requests.get(`/providerSlots?${query}`);
  },
  GET_PROVIDERS_INFO(id) {
    return requests.get(`/services/profile/info/${id}`);
  },
  APPROVE_REJECT_PROVIDER({ id, payload }) {
    return requests.post(`/provider/approve/reject/${id}`, payload);
  },
  ACTIVATE_DEACTIVATE_PROVIDER(id) {
    return requests.post(`/provider/active/deactive/${id}`);
  },
  TOGGLE_MARK_AS_EMPLOYEE(id, data) {
    return requests.post(`/provider/toggleEmployeeStatus/${id}`, data);
  },
};

export const JOB_SERVICES = {
  GET_JOBS(query) {
    return requests.get(`/jobs/search?${query}`);
  },
  GET_JOB_DETAILS(id) {
    return requests.get(`/jobs/${id}`);
  },
  GET_PROVIDER_ATTENDANCE(jobId) {
    return requests.get(`/providerJobService/attendance/${jobId}`);
  },
  MARK_PROVIDER_ATTENDANCE(data) {
    return requests.post(`/providerJobService/markAttendance`, data);
  },
};

export const APP_CONFIGURATION_SERVICES = {
  // Config
  GET_CONFIG() {
    return requests.get(`/appconfig/appconfig/get`);
  },

  UPDATE_CONFIG(data) {
    return requests.put(`/appconfig/appconfig/update`, data);
  },

  // faqs
  GET_FAQS(query) {
    return requests.get(`/faq/topics?${query}`);
  },
  CREATE_FAQ_TOPIC(data) {
    return requests.post(`/faq/topics`, data);
  },
  UPDATE_FAQ_TOPIC(id, data) {
    return requests.put(`/faq/topics/${id}`, data);
  },
  UPDATE_FAQ_TOPIC_ORDER(data) {
    return requests.put(`/faq/topics/listOrder`, data);
  },
  CREATE_FAQ(topicId, data) {
    return requests.post(`/faq/topics/${topicId}/questions`, data);
  },
  UPDATE_FAQ(topicId, questionId, data) {
    return requests.put(`/faq/topics/${topicId}/questions/${questionId}`, data);
  },
  UPDATE_FAQ_ORDER(topicId, data) {
    return requests.put(`/faq/topics/${topicId}/questions/listOrder`, data);
  },

  // Terms and conditions
  GET_TERMS_AND_CONDITIONS(query) {
    return requests.get(`/termsAndConditions?${query}`);
  },
  CREATE_A_TERMS_CONDITION(data) {
    return requests.post(`/termsAndConditions/bulk`, data);
  },
  ADD_NEW_TERMS_LANGUAGE(data) {
    return requests.post("/termsAndConditions", data);
  },
  GET_TERMS_BY_ID(id) {
    return requests.get(`/termsAndConditions/id/${id}`);
  },
  EDIT_TERMS(id, data) {
    return requests.put(`/termsAndConditions/edit/${id}`, data);
  },
  CHANGE_TERMS_STATUS(data) {
    return requests.put("/termsAndConditions/version", data);
  },

  // Privacy and policy

  GET_PRIVACY_POLICIES(query) {
    return requests.get(`/privacy-policy?${query ? query : ""}`);
  },
  CREATE_A_PRIVACY_POLICY(data) {
    return requests.post(`/privacy-policy/bulk  `, data);
  },
  ADD_NEW_PRIVACY_LANGUAGE(data) {
    return requests.post("/privacy-policy", data);
  },
  GET_PRIVACY_BY_ID(id) {
    return requests.get(`/privacy-policy/id/${id}`);
  },
  EDIT_PRIVACY(id, data) {
    return requests.put(`/privacy-policy/edit/${id}`, data);
  },
  CHANGE_PRIVACY_STATUS(data) {
    return requests.put("/privacy-policy/version", data);
  },

  // About us

  GET_ABOUT_US(query) {
    return requests.get(`/about-us?${query ? query : ""}`);
  },
  CREATE_A_ABOUT_US(data) {
    return requests.post(`/about-us/bulk`, data);
  },
  ADD_NEW_ABOUT_LANGUAGE(data) {
    return requests.post("/about-us", data);
  },
  GET_ABOUT_BY_ID(id) {
    return requests.get(`/about-us/id/${id}`);
  },
  EDIT_ABOUT(id, data) {
    return requests.put(`/about-us/edit/${id}`, data);
  },
  CHANGE_ABOUT_STATUS(data) {
    return requests.put("/about-us/version", data);
  },

  // contact us

  GET_CONTACT_US(query) {
    return requests.get(`/appconfig/contactus?${query}`);
  },
  SAVE_CONTACT_US(payload) {
    return requests.post(`/appconfig/contactus`, payload);
  },
  UPDATE_CONTACT_US(id, payload) {
    return requests.put(`/appconfig/contactus/edit/${id}`, payload);
  },

  // contact queries

  GET_CONTACT_QUERIES(query) {
    return requests.get(`/appconfig/contactusquery?${query}`);
  },

  // api response messages
  GET_RESPONSE_MESSAGES() {
    return requests.get(`/appconfig/languageMessage/adminGetError`);
  },
  ADD_RESPONSE_MESSAGE(data) {
    return requests.post(`/appconfig/languageMessage`, data);
  },
  UPDATE_RESPONSE_MESSAGE(id, data) {
    return requests.put(`/appconfig/languageMessage/${id}`, data);
  },

  // Application Tags
  GET_APPLICATION_TAGS(query) {
    return requests.get(`/static/languageData?${query}`);
  },
  UPDATE_APPLICATION_TAGS(id, data) {
    return requests.put(`/static/languageData/${id}`, data);
  },

  // Cancellation policy

  GET_CANCELLATION_POLICIES(query) {
    return requests.get(`/cancellation-policy?${query ? query : ""}`);
  },
  CREATE_A_CANCELLATION_POLICY(data) {
    return requests.post(`/cancellation-policy/bulk  `, data);
  },
  ADD_NEW_CANCELLATION_LANGUAGE(data) {
    return requests.post("/cancellation-policy", data);
  },
  GET_CANCELLATION_BY_ID(id) {
    return requests.get(`/cancellation-policy/id/${id}`);
  },
  EDIT_CANCELLATION(id, data) {
    return requests.put(`/cancellation-policy/edit/${id}`, data);
  },
  CHANGE_CANCELLATION_STATUS(data) {
    return requests.put("/cancellation-policy/version", data);
  },

  // Refund policy

  GET_REFUND_POLICIES(query) {
    return requests.get(`/refund-policy?${query ? query : ""}`);
  },
  CREATE_A_REFUND_POLICY(data) {
    return requests.post(`/refund-policy/bulk  `, data);
  },
  ADD_NEW_REFUND_LANGUAGE(data) {
    return requests.post("/refund-policy", data);
  },
  GET_REFUND_BY_ID(id) {
    return requests.get(`/refund-policy/id/${id}`);
  },
  EDIT_REFUND(id, data) {
    return requests.put(`/refund-policy/edit/${id}`, data);
  },
  CHANGE_REFUND_STATUS(data) {
    return requests.put("/refund-policy/version", data);
  },
};

export const ENROLLMENT_SERVICES = {
  GET_ENROLLMENTS(serviceId) {
    return requests.get(`/enrollments/node/tree?serviceId=${serviceId}`);
  },
  ADD_ENROLLMENT_NODE(data) {
    return requests.post("/enrollments/create", data);
  },
  ADD_ENROLLMENT_ITEM(data) {
    return requests.post("/enrollments/create/item", data);
  },
  UPDATE_ENROLLMENT_NODE({ id, data }) {
    return requests.put(`/enrollments/${id}`, data);
  },
  UPDATE_ENROLLMENT_ITEM({ id, data }) {
    return requests.put(`/enrollments/update/item/${id}`, data);
  },
  UPDATE_ENROLLMENT_NODE_POSITION(payload) {
    return requests.post("/enrollments/position/node", payload);
  },
  UPDATE_ENROLLMENT_ITEM_POSITION(payload) {
    return requests.post("enrollments/position/item", payload);
  },
  CREATE_NODE_REPLICA_FOR_OTHER_LANGUAGE({ data }) {
    return requests.post(`/enrollments/create/nodeAndItemsForSingleLang`, data);
  },
  TOGGLE_NODE_ACTIVE_STATUS({ data }) {
    return requests.post(`/enrollments/activeDeactive/node`, data);
  },
  TOGGLE_ITEM_ACTIVE_STATUS({ data }) {
    return requests.post(`/enrollments/activeDeactive/item`, data);
  },
  DELETE_ENROLMENT_NODE(query) {
    return requests.delete(`/enrollments/delete?${query}`);
  },
  DELETE_ENROLMENT_NODE_ITEM(query) {
    return requests.delete(`/enrollments/delete/item?${query}`);
  },
};

export const HTTP_SERVICE = {
  GET_LANGUAGES_LIST() {
    return requests.get(`/languages`);
  },
  GET_CATEGORY() {
    return requests.get(`/category`);
  },
  GET_CATEGORY_BY_ID(id) {
    return requests.get(`/category/${id}`);
  },
  ADD_CATEGORY(data) {
    return requests.post(`/category`, data);
  },
  UPDATE_CATEGORY(payload, categoryId, languageId) {
    return requests.put(`/category/${categoryId}/${languageId}`, payload);
  },
  UPDATE_CATEGORY_STATUS(categoryId) {
    return requests.patch(`/category/activateCategory/${categoryId}`);
  },
  ADD_FAQS(data) {
    return requests.post(`/faqs`, data);
  },
  TOGGLE_FAQ_STATUS(id) {
    return requests.patch(`/faqs/activateFaqs/${id}`);
  },
  UPDATE_FAQ_POSITION_STATUS(data) {
    return requests.post(`/faqs/update/positions`, data);
  },
  GET_SERVICE_PRICE(id) {
    return requests.get(`/serviceNodeItemValues?serviceId=${id}`);
  },
  ADD_SERVICE_PRICE({ payload }) {
    return requests.post(`/serviceNodeItemValues`, payload);
  },
  UPDATE_SERVICE_PRICE({ payload, id }) {
    return requests.put(`/serviceNodeItemValues/${id}`, payload);
  },
  DELETE_SERVICE_PRICE(id) {
    return requests.delete(`/serviceNodeItemValues/${id}`);
  },
  GET_SERVICE_COMMISSION(id) {
    return requests.get(`/serviceNodeItemCommission?serviceId=${id}`);
  },
  ADD_SERVICE_COMMISSION({ payload }) {
    return requests.post(`/serviceNodeItemCommission`, payload);
  },
  UPDATE_SERVICE_COMMISSION({ payload, id }) {
    return requests.put(`/serviceNodeItemCommission/${id}`, payload);
  },
  DELETE_SERVICE_COMMISSION(id) {
    return requests.delete(`/serviceNodeItemCommission/${id}`);
  },
  ADD_FAQ_LANG(data) {
    return requests.post(`/faqs/create/single`, data);
  },
  UPDATE_FAQS(data) {
    return requests.put(`/faqs/${data.faqsId}/${data.languageId}`, data);
  },
  ADD_A_SERVICE(data) {
    return requests.post("/services/create", data);
  },
  UPDATE_A_SERVICE(data, id) {
    return requests.put(`/services/${id}`, data);
  },
  ADD_SERVICE_BY_LANG(data) {
    return requests.post("/services/create/by/lang", data);
  },
  GET_STATES() {
    return requests.get("/states/user-states");
  },
  GET_DISTRICTS({ state }) {
    return requests.get(`/districts/user-district?state=${state}`);
  },
  GET_PIN_CODES({ state, district }) {
    return requests.get(`/pinCodes?state=${state}&district=${district}`);
  },
  UPDATE_ENABLED_LOCS(payload) {
    return requests.post("/serviceArea", payload);
  },
  GET_ENABLED_LOCATION_TREE(id) {
    return requests.get(`/serviceArea/tree?serviceId=${id}`);
  },
  FETCH_LOCALITIES(codes) {
    return requests.get(`/localities?pincode=${codes}`);
  },
  GET_ENABLED_PIN_CODES(id) {
    return requests.get(`/serviceArea/get?serviceId=${id}`);
  },
  GET_SERVICE_FAQ(id) {
    return requests.get(`/faqs/faqsByServiceId/${id}`);
  },
  GET_SERVICES({ categoryId, languageId = 1 } = {}) {
    return requests.get(`/services?${queryString({ categoryId, languageId })}`);
  },
  GET_A_SERVICE(id) {
    return requests.get(`/services/${id}`);
  },
  GET_A_SERVICE_NODES(id) {
    return requests.get(`/serviceNodes/node/tree?serviceId=${id}`);
  },
  UPLOAD_IMAGES(data) {
    return requests.post(`/images/upload`, data);
  },
  REMOVE_IMAGE(data) {
    return requests.post(`/images/remove`, data);
  },
  CHANGE_BANNERS_ORDER(data) {
    return requests.put(`/images/list-order`, data);
  },
  ADD_SERVICE_NODE(data) {
    return requests.post("/serviceNodes", data);
  },
  DELETE_SERVICE_NODE(query) {
    return requests.delete(`/serviceNodes/delete?${query}`);
  },
  DELETE_SERVICE_NODE_ITEM(query) {
    return requests.delete(`/serviceNodes/delete/item?${query}`);
  },
  ADD_SERVICE_ITEM(data) {
    return requests.post("/serviceNodes/create/item", data);
  },
  UPDATE_SERVICE_NODE({ id, data }) {
    return requests.put(`/serviceNodes/${id}`, data);
  },
  UPDATE_SERVICE_ITEM({ id, data }) {
    return requests.put(`/serviceNodes/update/item/${id}`, data);
  },
  UPDATE_SERVICE_NODE_POSITION(payload) {
    return requests.post("/serviceNodes/position/node", payload);
  },
  UPDATE_SERVICE_ITEM_POSITION(payload) {
    return requests.post("serviceNodes/position/item", payload);
  },
  CREATE_NODE_REPLICA_FOR_OTHER_LANGUAGE({ data }) {
    return requests.post(
      `/serviceNodes/create/nodeAndItemsForSingleLang`,
      data
    );
  },
  TOGGLE_NODE_ACTIVE_STATUS({ data }) {
    return requests.post(`/serviceNodes/activeDeactive/node`, data);
  },
  TOGGLE_ITEM_ACTIVE_STATUS({ data }) {
    return requests.post(`/serviceNodes/activeDeactive/item`, data);
  },
  ADD_SERVICES(data) {
    return requests.post(`/services/`, data);
  },
  UPDATE_SERVICE_BY_LANGUAGE(data) {
    return requests.put(`/services/${data.serviceId}`, data);
  },
  UPDATE_STATUS_SERVICE(serviceId) {
    return requests.patch(`/services/active/deactive/${serviceId}`);
  },
  GET_LANGUAGE() {
    return requests.get(`/languages`);
  },
  ADD_LANGUAGE(data) {
    return requests.post(`/languages/`, data);
  },
  UPDATE_LANGUAGE(data, id) {
    return requests.put(`/languages/${id}`, data);
  },
  UPDATE_STATUS_LANGUAGE(cityId) {
    return requests.patch(`/languages/activateLanguage/${cityId}`);
  },

  // change order

  CHANGE_SERVICE_LIST_ORDER(body) {
    return requests.put(`/services/list-order`, body);
  },
  CHANGE_LANGUAGE_LIST_ORDER(body) {
    return requests.put(`/languages/list-order`, body);
  },
  CHANGE_CATEGORY_LIST_ORDER(body) {
    return requests.put(`/category/list-order`, body);
  },

  // service training videos
  GET_TRAINING_VIDEOS(query) {
    return requests.get(`/trainingVideos/list-info?${query}`);
  },
  ADD_TRAINING_VIDEO(data) {
    return requests.post(`/trainingVideos/upload`, data);
  },
  UPDATE_TRAINING_VIDEO(id, data) {
    return requests.put(`/trainingVideos/edit/${id}`, data);
  },
  UPDATE_TRAINING_VIDEO_STATUS(id) {
    return requests.post(`/trainingVideos/statusChange/${id}`);
  },

  // customer
  GET_CUSTOMER_INFO(id) {
    return requests.get(`/customer/info/${id}`);
  },
};

export const CUSTOMER_SUPPORT = {
  GET_CS_TICKETS_LIST(query) {
    return requests.get(`/ticket?${query}`);
  },
  SEARCH_PROFILE_CS({ search, userType }) {
    return requests.get(`/support/getProfile?q=${search}&type=${userType}`);
  },
  FETCH_USER_SPECIFIC_TICKETS(query) {
    return requests.get(`/ticket/getTicketsForCustomerSupport?${query}`);
  },
  CREATE_TICKET_REQUEST(data) {
    return requests.post("/ticket", data);
  },
  GET_TICKET_INFO(query) {
    return requests.get(`/ticket/getTicketInfo?${query}`);
  },
  CHANGE_TICKET_STATUS(id, data) {
    return requests.put(`/ticket/changeStatus/${id}`, data);
  },
  CHANGE_TICKET_ASSIGNEE(id, data) {
    return requests.put(`/ticket/assignTo/${id}`, data);
  },
  FETCH_TICKET_TYPES_REQUEST() {
    return requests.get("/ticketType");
  },
  ADD_COMMENT(data) {
    return requests.post("/ticket/comment", data);
  },
  GET_COMMENTS_BY_TICKET(query) {
    return requests.get(`/ticket/getComments?${query}`);
  },
};

export const CHARGE_SERVICES = {
  FETCH_CHARGE_TYPES(query) {
    return requests.get(`/serviceChargeTypes/getAll?${query}`);
  },
  GET_CHARGE_BY_ID_REQUEST({ id, payload }) {
    return requests.get(`/template/${id}`);
  },
  CREATE_CHARGE_REQUEST(payload) {
    return requests.post(`/serviceChargeTypes/createType`, payload);
  },
  UPDATE_CHARGE_REQUEST(payload) {
    return requests.put(`/serviceChargeTypes/update`, payload);
  },

  // for service
  FETCH_CHARGES_BY_SERVICE_ID(query) {
    return requests.get(`/serviceChargeTypeConfig/getByServiceId?${query}`);
  },
  UPDATE_SERVICE_CHARGE_CONFIG(data) {
    return requests.put(
      `/serviceChargeTypeConfig/updatechargesTypeConfig`,
      data
    );
  },
  CREATE_SERVICE_CHARGE_CONFIG(payload) {
    return requests.post(`/serviceChargeTypeConfig/createConfig`, payload);
  },
};

export const COMMUNICATION_SERVICES = {
  FETCH_TEMPLATE_LIST() {
    return requests.get(`/communicationTemp`);
  },
  CREATE_NEW_TEMPLATE(data) {
    return requests.post(`/communicationTemp`, data);
  },
  FETCH_TEMPLATE_BY_ID(id) {
    return requests.get(`/communicationTemp/${id}`);
  },
  UPDATE_TEMPLATE({ id, payload }) {
    return requests.put(`/communicationTemp/${id}`, payload);
  },
  FETCH_BROADCAST_LIST() {
    return requests.get(`/broadcast`);
  },
  CREATE_NEW_BROADCAST(data) {
    return requests.post(`/broadcast`, data);
  },
  FETCH_BROADCAST_BY_ID(id) {
    return requests.get(`/broadcast/${id}`);
  },
  FETCH_COMMUNICATION_CONFIG() {
    return requests.get(`/appconfig/communicationConfig/get`);
  },
  UPDATE_COMMUNICATION_CONFIG(data) {
    return requests.put(`/appconfig/communicationConfig/edit`, data);
  },
};

export const ACCOUNT_SERVICES = {
  FETCH_PAYORDERS_LIST(query) {
    return requests.get(`/jobPayOrder?${query}`);
  },

  GET_PAYORDER_INFO(id) {
    return requests.get(`/jobPayOrder/${id}`);
  },
  CREATE_PAYORDER(payload) {
    return requests.post(`/jobPayOrder/create`, payload);
  },

  GET_PAYMENTS(query) {
    return requests.get(`/payment/getBookingVoucher?${query}`);
  },
  GET_PAYMENT_INFO(id) {
    return requests.get(`/payments/${id}`);
  },

  UPDATE_PAYMENT(id, payload) {
    return requests.put(`/payments/${id}`, payload);
  },
};

export const REASONS_SERVICES = {
  GET_ALL_REASONS(query) {
    return requests.get(`/reason/getAll?${query}`);
  },
  GET_REASON_BY_ID(id) {
    return requests.get(`/reason/getAll?reasonId=${id}`);
  },
  CREATE_REASON(payload) {
    return requests.post("/reason/bulkCreate", payload);
  },
  UPDATE_REASON(id, payload) {
    return requests.put(`/reason/updateReason/${id}`, payload);
  },
  TOGGLE_REASON(id) {
    return requests.get(`/reason/activateInactivate/${id}`);
  },
};

export const PROMOTION_SERVICES = {
  FETCH_COUPONS_LIST(query) {
    return requests.get(`/coupon/list-info?${query}`);
  },
  CREATE_COUPON(payload) {
    return requests.post(`/coupon/create`, payload);
  },
  UPDATE_COUPON(couponId, payload) {
    return requests.put(`/coupon/edit/${couponId}`, payload);
  },
  FETCH_BANNERS_LIST(query) {
    return requests.get(`/banner/list-info?${query}`);
  },
  CREATE_BANNER(payload) {
    return requests.post(`/banner/create`, payload);
  },
  ADD_BANNER_LANGUAGE(payload) {
    return requests.post(`/banner/create`, payload);
  },
  UPDATE_BANNER(bannerId, payload) {
    return requests.put(`/banner/edit/${bannerId}`, payload);
  },
};

export const SPARE_PART_SERVICES = {
  GET(query) {
    return requests.get(`/appconfig/spareparts?${query}`);
  },
  SAVE(data) {
    return requests.post(`/appconfig/spareparts/save/new`, data);
  },
  UPDATE(data) {
    return requests.put(`/appconfig/spareparts/Update/SpareParts`, data);
  },
};
