import { Grid } from "@mui/material";
import React, { useState } from "react";
import { ITEM_FIELDS, NODE_FIELDS } from "../constants";
import {
  CustomField,
  getErrorKey,
  getErrorText,
} from "../../../../components/CustomField";
import { NodeModal } from "./NodeModal";

export const RenderNodeData = ({
  color = "primary",
  customMd,
  isNode,
  hardDisable,
  customFields,
  onChange = () => {},
  marginLeft,
  data = {},
  disabled,
  showImageUploader,
}) => {
  const [modalData, setModalData] = useState(null);
  const closeModal = () => setModalData(null);

  return (
    <div
      style={{
        marginTop: "8px",
        marginBottom: "8px",
        marginLeft: marginLeft ?? "20px",
      }}
    >
      <Grid container rowSpacing={2} spacing={1}>
        {(customFields ?? (isNode ? NODE_FIELDS : ITEM_FIELDS)).map((item) => {
          if (!item._key) {
            return null;
          }
          const { md = 3, _key } = item;
          return (
            <Grid
              key={_key}
              item
              md={customMd || (!isNode && !disabled ? 4 : md)}
              sm={12}
              xs={12}
            >
              <CustomField
                color={color}
                item={item}
                hardDisable={hardDisable}
                disabled={disabled}
                errorText={data[getErrorText(_key)]}
                error={data[getErrorKey(_key)]}
                value={data[_key]}
                onChange={onChange}
              />
            </Grid>
          );
        })}
        {!!modalData && (
          <NodeModal
            closeModal={closeModal}
            isNode={isNode}
            modalData={modalData}
          />
        )}
      </Grid>
    </div>
  );
};
