import { INPUT_TYPES } from "components";

export const APP_CONFIG_FIELDS = [
  {
    _key: "fieldKey",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Field Name",
  },
  {
    _key: "fieldValue",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Field Value",
  },
];
