import { ConfirmationModal } from "components/ConfirmationModal";
import React from "react";
import { markProviderAttendanceRequest } from "../utils";

const MarkAttendanceModal = ({ jobId, onClose, onSuccessCallback }) => {
  const onMarkAttendance = (status) => {
    markProviderAttendanceRequest({
      data: {
        jobId,
        currentDate: new Date(),
        status,
      },
      onSuccess: () => {
        onSuccessCallback();
        onClose();
      },
    });
  };

  const modalData = {
    title: "Mark Attendance",
    description: "Mark provider attendance as present/absent.",
    footerButtons: [
      {
        title: "Absent",
        onClick: () => onMarkAttendance("absent"),
        sx: { mr: 2 },
        color: "error",
        variant: "outlined",
      },
      {
        title: "Present",
        color: "error",
        isRequired: true,
        onClick: () => onMarkAttendance("present"),
      },
    ],
  };

  return (
    <ConfirmationModal
      modalData={modalData}
      closeModal={onClose}
      color={"error"}
    />
  );
};

export default MarkAttendanceModal;
