import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { BOOKING_SERVICES, successToast } from "utils";

const dispatch = store.dispatch;

export const getBookingsRequest = ({ query, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  BOOKING_SERVICES.GET_BOOKINGS(query)
    .then(({ isSuccess, data }) => {
      if (isSuccess) {
        const { items = [], totalRecords } = data || {};
        onSuccess({
          totalRecords,
          response: items.map((item) => ({ ...item, id: item._id })),
        });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getBookingDetailsRequest = ({ id, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  BOOKING_SERVICES.GET_BOOKING_DETAILS(id)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getBookingProvidersRequest = ({ query, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  BOOKING_SERVICES.GET_BOOKING_PROVIDERS(query)
    .then(({ isSuccess, data }) => {
      if (isSuccess) {
        const { items = [], totalRecords } = data || {};
        onSuccess({
          totalRecords,
          response: items.map((item) => ({ ...item, id: item._id })),
        });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const assignBookingRequest = ({ payload, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  BOOKING_SERVICES.ASSIGN_BOOKING(payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const reassignBookingRequest = ({ payload, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  BOOKING_SERVICES.REASSIGN_BOOKING(payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const cancelBookingRequest = ({ payload, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  BOOKING_SERVICES.CANCEL_BOOKING(payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const cancelJobRequest = ({ payload, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  BOOKING_SERVICES.CANCEL_JOB(payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const rescheduleBookingRequest = ({ payload, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  BOOKING_SERVICES.RESCHEDULE_BOOKING_JOB(payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const startJobRequest = ({ payload, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  BOOKING_SERVICES.START_JOB(payload)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const endJobRequest = ({ payload, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  BOOKING_SERVICES.END_JOB(payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateBookingChargesRequest = ({ payload, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  BOOKING_SERVICES.UPDATE_CHARGES(payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createBookingPaymentRequest = ({ payload, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  BOOKING_SERVICES.CREATE_BOOKING_PAYMENT(payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const suspendBookingRequest = ({ payload, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  BOOKING_SERVICES.SUSPEND(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess();
        successToast({ message: "Booking suspended successfully!" });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getRelatedBookingsRequest = ({ id, onSuccess }) => {
  dispatch(setLoading({ loading: true }));

  BOOKING_SERVICES.RELATED_BOOKINGS(id)
    .then(({ isSuccess, data }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
