import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import {
  APPLIANCE_FIELDS,
  CATEGORY_FIELDS,
  SPARE_PART_FIELDS,
} from "../constants";
import { CustomField, getErrorKey, getErrorText } from "components";
import { validateFields } from "utils";
import Header from "components/HeaderV2";
import ModalWrapper from "components/ModalWrapper";
import { createSparePartRequest, updateSparePartRequest } from "../utils";
import { RenderCategories } from "./RenderCategories";

const initState = ({ languages }) => {
  const state = languages.map((language) => {
    return {
      categories: [
        {
          name: "",
          spareParts: [{}],
        },
      ],
      languageId: language.value,
    };
  });

  return state;
};

export const AddEditAppliance = ({
  data: _data,
  onSuccessCallback = () => {},
  closeModal,
}) => {
  const { data, serviceId, isEdit, languages, isAddNewLang } = _data;
  const [applianceData, setApplianceData] = useState(
    isAddNewLang || isEdit ? data : initState({ languages })
  );

  const [currIndex, setCurrIndex] = useState(0);
  const hideActions = currIndex !== 0 || isAddNewLang;

  const selectedLanguage = languages[currIndex];

  const validateData = (currentAppliance) => {
    let isValidData = true;

    const tempState = (
      currentAppliance ? [currentAppliance] : applianceData
    ).map((appliance) => {
      const { isValid, _state } = validateFields({
        _state: appliance,
        fields: APPLIANCE_FIELDS,
      });

      if (!isValid) {
        isValidData = false;
      }

      const categoriesValidator = validateCategories({
        categories: appliance.categories,
      });

      if (isValidData) {
        isValidData = categoriesValidator.isValid;
      }

      return { ..._state, categories: [...categoriesValidator._state] };
    });

    setApplianceData((_state) => {
      if (!currentAppliance) {
        return [...tempState];
      }

      return _state.map((appliance, index) => {
        if (currIndex === index) {
          return {
            ...tempState[0],
          };
        }
        return appliance;
      });
    });
    return isValidData;
  };

  const validateCategories = ({ categories }) => {
    let isValidData = true;
    const tempState = categories.map((category) => {
      const { isValid, _state } = validateFields({
        _state: category,
        fields: CATEGORY_FIELDS,
      });

      if (!isValid) {
        isValidData = false;
      }

      const sparePartValidator = validateSpareParts({
        spareParts: category.spareParts,
      });

      if (isValidData) {
        isValidData = sparePartValidator.isValid;
      }

      return { ..._state, spareParts: [...sparePartValidator._state] };
    });

    return { isValid: isValidData, _state: tempState };
  };

  const validateSpareParts = ({ spareParts }) => {
    let isValidData = true;
    const tempState = spareParts.map((sparePart) => {
      const { isValid, _state } = validateFields({
        _state: sparePart,
        fields: SPARE_PART_FIELDS,
      });

      if (!isValid) {
        isValidData = false;
      }

      return _state;
    });

    return { isValid: isValidData, _state: tempState };
  };

  const onChange = ({ _key, value }) => {
    setApplianceData((_state) => {
      _state[currIndex][_key] = value;
      delete _state[currIndex][getErrorKey(_key)];
      delete _state[currIndex][getErrorText(_key)];
      return [..._state];
    });
  };

  const onAddCategory = () => {
    setApplianceData((_state) => {
      return _state.map((appliance) => {
        return {
          ...appliance,
          categories: [
            ...(appliance.categories || []),
            {
              name: "",
              spareParts: [
                {
                  name: "",
                  charges: "",
                },
              ],
            },
          ],
        };
      });
    });
  };

  const onBack = () => setCurrIndex(currIndex - 1);
  const onNext = () => {
    const isValid = validateData(applianceData[currIndex]);

    if (!isValid) {
      return;
    }

    let tempData = applianceData;
    let currData = tempData[0];
    let nextData = tempData[currIndex + 1];

    nextData.appliance = nextData.appliance || currData.appliance;

    nextData.categories = nextData.categories.map((item, index) => {
      let currentDataCatgory = currData.categories[index];

      return {
        ...item,
        name: item.name || currentDataCatgory.name,
        spareParts: [
          ...item.spareParts.map((sparePartItem, sparePartIndex) => {
            let currDataSparePart =
              currentDataCatgory.spareParts[sparePartIndex];

            return {
              ...sparePartItem,
              name: sparePartItem.name || currDataSparePart.name,
              charges: currDataSparePart.charges,
            };
          }),
        ],
      };
    });

    tempData[currIndex + 1] = nextData;

    setApplianceData([...tempData]);
    setCurrIndex(currIndex + 1);
  };

  const onSubmitPress = () => {
    const isValid = validateData();

    if (!isValid) {
      return;
    }

    const payload = {
      applianceId: isAddNewLang ? applianceData[0].applianceId : undefined,
      sparePartsData: applianceData.map(
        ({ _id, appliance, categories, languageId }) => {
          return {
            id: isEdit ? _id : undefined,
            appliance,
            categories: categories.map(({ categoryId, name, spareParts }) => {
              return {
                categoryId,
                name,
                spareParts: spareParts.map(
                  ({ sparePartId, name, charges }) => ({
                    sparePartId,
                    name,
                    charges,
                  })
                ),
              };
            }),
            languageId,
            serviceId,
          };
        }
      ),
    };

    const caller = isEdit ? updateSparePartRequest : createSparePartRequest;

    caller({
      payload,
      onSuccess: onSuccessCallback,
    });
  };

  return (
    <ModalWrapper
      title={`Appliance ${!isAddNewLang ? `(${selectedLanguage.label})` : ""}`}
      sx={{ width: "70%" }}
      closeModal={closeModal}
      footerButtons={[
        {
          title: "Back",
          disabled: currIndex === 0,
          onClick: onBack,
          sx: { display: !isAddNewLang ? "block" : "none" },
        },
        {
          title: currIndex === applianceData.length - 1 ? "Submit" : "Next",
          onClick:
            currIndex === applianceData.length - 1 ? onSubmitPress : onNext,
          sx: { ml: 2 },
        },
      ]}
    >
      <Grid container rowSpacing={2} spacing={1}>
        {APPLIANCE_FIELDS.map((item) => {
          const { _key, md } = item;

          if (!_key) return null;

          if (_key === "languageId" && !isAddNewLang) {
            return null;
          }

          if (_key === "languageId") {
            item.extraData = languages;
          }

          return (
            <Grid key={_key} item md={md ? md : 6} sm={12} xs={12}>
              <CustomField
                color={"primary"}
                item={item}
                itemData={applianceData[currIndex]}
                errorText={applianceData[currIndex][getErrorText(_key)]}
                error={applianceData[currIndex][getErrorKey(_key)]}
                value={applianceData[currIndex][_key]}
                onChange={onChange}
              />
            </Grid>
          );
        })}
      </Grid>

      <Typography
        component={"div"}
        sx={{
          borderTop: "1px solid black",
          p: 1,
          my: 2,
          position: "relative",
        }}
      >
        <Header
          skip
          title={"Categories"}
          buttons={
            !hideActions
              ? [{ title: "Add Category", onClick: onAddCategory }]
              : []
          }
        />

        <RenderCategories
          hideActions={hideActions}
          categories={applianceData[currIndex].categories}
          applianceIndex={currIndex}
          setApplianceData={setApplianceData}
        />
      </Typography>
    </ModalWrapper>
  );
};
