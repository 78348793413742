import React, { useEffect, useState } from "react";
import Header from "components/HeaderV2";
import { Card, CardContent } from "@mui/material";
import { Box } from "@mui/system";
import { queryString, useGetLanguageData } from "utils";
import { useParams } from "react-router-dom";
import { AddEditTopicModal } from "./components/AddEditTopicModal";
import FaqTopic from "./components/FaqTopic";
import {
  editTopicRequest,
  getFaqsRequest,
  updateTopicOrderRequest,
} from "./utils";

export const FAQ = () => {
  const [data, setData] = useState([]);
  const { userType } = useParams();

  const [modalData, setModalData] = useState(null);
  const closeModal = () => setModalData(null);

  const { allLanguages, defaultLanguageId } = useGetLanguageData({
    data,
  });

  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguageId);

  const onEditPress = (item) => setModalData({ data: item });

  const onFaqChangeCallback = ({ topicId, topicData }) => {
    setData((_state) => [
      ..._state.map((topic) =>
        topic._id === topicId ? { ...topicData } : topic
      ),
    ]);
  };

  const onTopicChangeCallback = ({ topicData }) => {
    setData([...topicData]);
  };

  const onChangeStatusCallback = ({ id, isActive }) => {
    editTopicRequest({
      id,
      payload: { isActive },
      onSuccess: (data) =>
        onFaqChangeCallback({ topicId: id, topicData: data }),
    });
  };

  const onPositionUpdate = ({ type, id }) => {
    updateTopicOrderRequest({
      payload: {
        step: type,
        topicId: id,
      },
      onSuccess: (data) => onTopicChangeCallback({ topicData: data }),
    });
  };

  const onSuccess = ({ id, topicData }) => {
    if (id) {
      onFaqChangeCallback({ topicId: id, topicData });
    } else {
      console.log([...data, topicData]);
      setData((_state) => [..._state, topicData]);
    }
    closeModal();
  };

  useEffect(() => {
    getFaqsRequest({
      query: queryString({ appType: userType }),
      onSuccess: (data) => setData(data),
    });
  }, [userType]);

  const languageWiseData = data.filter(
    (item) => +item.languageId === selectedLanguage
  );

  return (
    <Box sx={{ my: 2 }}>
      <Card>
        <CardContent>
          <Header
            title="FAQ's"
            buttons={[
              {
                title: "Add Topic",
                onClick: () =>
                  setModalData({
                    data: null,
                  }),
              },
            ]}
            dropDown={{
              value: selectedLanguage,
              onChange: setSelectedLanguage,
              data: allLanguages,
            }}
          />
          {languageWiseData.map((item, index) => (
            <FaqTopic
              onEditPress={(e) => {
                e?.stopPropagation && e?.stopPropagation();
                onEditPress(item);
              }}
              index={index}
              data={item}
              color="primary"
              onPositionUpdate={onPositionUpdate}
              onChangeStatusCallback={onChangeStatusCallback}
              onFaqChangeCallback={onFaqChangeCallback}
              itemsLength={languageWiseData.length}
              key={item._id}
            />
          ))}

          {!!modalData && (
            <AddEditTopicModal
              userType={userType}
              languageId={selectedLanguage}
              data={modalData}
              languages={allLanguages}
              closeModal={closeModal}
              onSuccessCallback={onSuccess}
            />
          )}
        </CardContent>
      </Card>
    </Box>
  );
};
