import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export const BROADCAST_FILTER = [
  // {
  //   inputType: INPUT_TYPES.DROPDOWN,
  //   _key: "serviceId",
  //   placeholder: "Service",
  //   dataKey: "services",
  // deleteValues: { serviceLabel: "" },
  // },
  // {
  //   inputType: INPUT_TYPES.DROPDOWN,
  //   _key: "state",
  //   placeholder: "State",
  //   dataKey: "states",
  //   overRideValues: { district: "" },
  // },
  // {
  //   inputType: INPUT_TYPES.DROPDOWN,
  //   _key: "district",
  //   placeholder: "District",
  //   dataKey: "districts",
  //   dependentKey: "state",
  // },
];

export const getBroadcastListColumns = ({ onViewPress }) => [
  { field: "id", headerName: "ID", width: 90, sortable: true, hide: true },
  // {
  //   field: "title",
  //   headerName: "Title",
  //   width: 200,
  // },
  // {
  //   field: "description",
  //   headerName: "Description",
  //   width: 200,
  // },
  // {
  //   field: "type",
  //   headerName: "Type",
  //   width: 200,
  // },
  // {
  //   field: "relatedToType",
  //   headerName: "Related To",
  //   width: 200,
  // },
  // {
  //   field: "raisedFor",
  //   headerName: "Raised For",
  //   width: 200,
  // },
  // {
  //   field: "createdByType",
  //   headerName: "Raised By",
  //   width: 200,
  // },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   width: 200,
  // },
  // {
  //   field: "view",
  //   headerName: "View",
  //   width: 100,
  //   renderCell: ({ row }) => {
  //     const onView = (e) => {
  //       e.stopPropagation();
  //       onViewPress(row);
  //     };
  //     return (
  //       <IconButton onClick={onView}>
  //         <Visibility color="primary" />
  //       </IconButton>
  //     );
  //   },
  // },
];
