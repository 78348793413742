import React, { useCallback, useEffect, useState } from "react";
import { HTTP_SERVICE, customSortComparatorForNums } from "../../../utils";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, Card, IconButton, Tooltip, Typography } from "@mui/material";

import DataGrid from "../../../components/DataGrid";
import Switch from "@mui/material/Switch";
import { getCategoryList } from "screens/Category/utils";
import Header from "components/HeaderV2";
import { Box } from "@mui/system";
import { ExpandCircleDown } from "@mui/icons-material";
import { changeServiceOrderRequest } from "../utils";

const ServicesList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState(
    searchParams.get("categoryId")
  );
  const disableChangeOrder = !selectedCategory || selectedCategory === "all";
  const navigate = useNavigate();

  const getServiceList = useCallback(() => {
    setIsLoading(true);
    HTTP_SERVICE.GET_SERVICES({
      categoryId:
        !selectedCategory || selectedCategory === "all"
          ? undefined
          : selectedCategory,
    })
      .then(({ data }) => {
        setData(
          data.map((item, index) => ({ ...item, id: item._id, index: index }))
        );
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [selectedCategory]);

  useEffect(() => {
    setSelectedCategory(searchParams.get("categoryId"));
  }, [searchParams]);

  useEffect(() => {
    getCategoryList({
      onSuccess: (data) =>
        setCategories(
          data.map(({ categoryId, categoryName }) => ({
            label: categoryName,
            value: categoryId,
          }))
        ),
    });
  }, []);

  const handleStatusChange = async (serviceId) => {
    await HTTP_SERVICE.UPDATE_STATUS_SERVICE(serviceId);
    getServiceList();
  };

  const moveServiceUp = (serviceId) => {
    changeServiceOrderRequest({
      payload: {
        id: serviceId,
        event: "decrement",
      },
      onSuccess: () => getServiceList(),
    });
  };

  const moveServiceDown = (serviceId) => {
    changeServiceOrderRequest({
      payload: {
        id: serviceId,
        event: "increment",
      },
      onSuccess: () => getServiceList(),
    });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: true, hide: true },
    {
      field: "serviceId",
      headerName: "Service Id",
      width: 120,
      sortComparator: customSortComparatorForNums,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
    },
    {
      field: "shortDescription",
      headerName: "Short Description",
      width: 200,
    },
    {
      field: "isActive",
      headerName: "Active",
      width: 80,
      renderCell: (cellValues) => {
        return (
          <Switch
            checked={cellValues?.row?.isActive}
            onChange={() => handleStatusChange(cellValues?.row?.serviceId)}
          />
        );
      },
    },
    {
      field: "Update",
      headerName: "Update",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <div>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              sx={{ mr: 1 }}
              onClick={() => navigate(`/service-detail/${row?.serviceId}`)}
            >
              Edit Service
            </Button>
          </div>
        );
      },
    },
    {
      field: "changeOrder",
      headerName: "Order",
      width: 200,
      renderCell: ({ row }) => {
        const { index, _id } = row;
        const color = "primary";
        return (
          <Typography component={"div"} sx={{ display: "flex" }}>
            <IconButton
              onClick={() => moveServiceUp(_id)}
              disabled={!index || disableChangeOrder}
              style={{ rotate: "180deg" }}
            >
              <Tooltip title="Move Up">
                <ExpandCircleDown
                  color={!index || disableChangeOrder ? "disabled" : color}
                />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={() => moveServiceDown(_id)}
              disabled={index === data?.length - 1 || disableChangeOrder}
            >
              <Tooltip title="Move Down">
                <ExpandCircleDown
                  color={
                    index === data?.length - 1 || disableChangeOrder
                      ? "disabled"
                      : color
                  }
                />
              </Tooltip>
            </IconButton>
          </Typography>
        );
      },
    },
  ];

  useEffect(() => getServiceList(), [getServiceList]);

  return (
    <>
      <Box sx={{ my: 2 }}>
        <Card sx={{ px: 2, py: 1 }}>
          <Header
            title="Service List"
            style={{ marginBottom: 0 }}
            dropDown={{
              value: selectedCategory,
              placeholder: "Select Category",
              onChange: (categoryId) => {
                setSearchParams({ categoryId });
              },
              data: [{ label: "All", value: "all" }, ...categories],
            }}
            buttons={[
              {
                title: "Add Service",
                onClick: () => navigate(`/create-service`),
              },
            ]}
          />
        </Card>
      </Box>

      <DataGrid loading={isLoading} rows={data} columns={columns} />
    </>
  );
};
export default ServicesList;
