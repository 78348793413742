import { useTheme } from "@emotion/react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";

export const HeaderTitle = ({
  heading,
  btn = false,
  btnTitle,
  btnAction = null,
  customCardStyle,
}) => {
  const theme = useTheme();
  const SizeLessThenMd = useMediaQuery(theme.breakpoints.down("md"));

  if (SizeLessThenMd && !btn) return null;

  return (
    <Box sx={{ mt: 3 }}>
      <Card sx={customCardStyle}>
        <CardContent style={{ paddingBottom: "16px" }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: { xs: "flex-end", md: "space-between" },
              flexWrap: "wrap",
              m: -1,
            }}
            p={0.5}
          >
            <Typography
              sx={{ m: 0, display: { xs: "none", md: "inline-flex" } }}
              variant="h6"
            >
              {heading}
            </Typography>
            {btn && (
              <Box>
                <Button color="primary" onClick={btnAction} variant="contained">
                  {btnTitle}
                </Button>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
