import React, { useState } from "react";
import { CustomField, getErrorKey, getErrorText } from "components";
import ModalWrapper from "components/ModalWrapper";
import { Grid } from "@mui/material";
import { validateFields } from "utils";
import { APP_CONFIG_FIELDS } from "../constants";
import { updateAppConfigRequest } from "../utils";

export const AddFieldModal = ({ closeModal, onSuccessCallback, data = {} }) => {
  const [state, setState] = useState({});

  const validateData = () => {
    const { _state, isValid } = validateFields({
      _state: state,
      fields: APP_CONFIG_FIELDS,
    });
    setState({ ..._state });
    return isValid;
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    updateAppConfigRequest({
      payload: {
        config: {
          ...data.config,
          [state.fieldKey]: state.fieldValue,
        },
        id: data._id,
      },
      onSuccess: onSuccessCallback,
    });
  };

  const onChange = ({ value, _key }) => {
    setState((_state) => ({
      ..._state,
      [_key]: value,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
    }));
  };

  return (
    <ModalWrapper
      footerButtons={[{ title: "Add", onClick: onSubmitPress }]}
      closeModal={closeModal}
      title={"Add Field"}
      sx={{ width: "50%" }}
      childSx={{ height: "45vh", p: 2, overflow: "auto" }}
    >
      <Grid container rowSpacing={2} spacing={1}>
        {APP_CONFIG_FIELDS.map((item) => {
          const { _key } = item;

          if (!_key) return null;

          return (
            <Grid key={_key} item md={12} sm={12} xs={12}>
              <CustomField
                color={"primary"}
                item={item}
                itemData={state}
                errorText={state[getErrorText(_key)]}
                error={state[getErrorKey(_key)]}
                value={state[_key]}
                onChange={onChange}
              />
            </Grid>
          );
        })}
      </Grid>
    </ModalWrapper>
  );
};
