import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { INPUT_TYPES } from "components";
import { customSortComparatorForNums } from "utils";

export const APPLICATION_TAGS_FIELDS = [
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "languageId",
    placeholder: "Language",
    md: 12,
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "tagKey",
    placeholder: "Tag",
    md: 12,
  },
  {
    inputType: INPUT_TYPES.TEXT_AREA,
    _key: "tagValue",
    placeholder: "Value",
    md: 12,
    extraProps: { minRows: 3 },
  },
];

export const getColumns = ({ onEditPress }) => {
  return [
    { field: "_id", headerName: "_id", width: 88, hide: true },
    {
      field: "id",
      headerName: "S.No",
      width: 100,
      sortable: true,
      sortComparator: customSortComparatorForNums,
    },
    {
      field: "tagKey",
      headerName: "Tag",
      width: 250,
    },
    {
      field: "tagValue",
      headerName: "Value",
      width: 350,
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      hide: !onEditPress,
      renderCell: ({ row }) => {
        const onEdit = (e) => {
          e.stopPropagation();
          onEditPress(row);
        };

        return (
          <>
            <IconButton onClick={onEdit}>
              <Edit color="primary" />
            </IconButton>
          </>
        );
      },
    },
  ];
};
