import Close from "@mui/icons-material/Close";
import { Typography, Grid, Button } from "@mui/material";
import { CATEGORY_FIELDS } from "../constants";
import { CustomField } from "components";
import { getErrorKey, getErrorText } from "utils";
import { RenderSpareParts } from "./RenderSpareParts";

export const RenderCategories = ({
  hideActions,
  categories,
  applianceIndex,
  setApplianceData,
}) => {
  const onAddSparePart = ({ catIndex }) => {
    setApplianceData((_state) => [
      ..._state.map((appliance) => {
        return {
          ...appliance,
          categories: appliance?.categories?.map((category, index) => {
            if (catIndex === index) {
              return {
                ...category,
                spareParts: [...(category.spareParts || []), {}],
              };
            }
            return category;
          }),
        };
      }),
    ]);
  };

  const onChange = ({ _key, value, catIndex }) => {
    setApplianceData((_state) => {
      _state[applianceIndex].categories[catIndex][_key] = value;
      delete _state[applianceIndex].categories[catIndex][getErrorKey(_key)];
      delete _state[applianceIndex].categories[catIndex][getErrorText(_key)];
      return [..._state];
    });
  };

  const onDeletePress = ({ catIndex }) => {
    setApplianceData((_state) => {
      return [
        ..._state.map((item) => {
          item.categories.splice(catIndex, 1);
          return item;
        }),
      ];
    });
  };

  return categories.map((category, catIndex) => {
    return (
      <Typography
        key={catIndex}
        component={"div"}
        sx={{
          border: "1px solid black",
          borderRadius: "5px",
          p: 1,
          mb: 2,
          mt: 1,
          position: "relative",
        }}
      >
        {!hideActions && categories.length > 1 && (
          <Typography
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: -10,
              right: -10,
              bgcolor: "black",
              borderRadius: "100%",
              width: "25px",
              height: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => onDeletePress({ catIndex })}
          >
            <Close color="white" fontSize="10" />
          </Typography>
        )}

        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {CATEGORY_FIELDS.map((item) => {
            const { _key, md } = item;

            if (!_key) return null;

            return (
              <Grid key={_key} item md={md ? md : 6} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={item}
                  itemData={category}
                  errorText={category[getErrorText(_key)]}
                  error={category[getErrorKey(_key)]}
                  value={category[_key]}
                  onChange={({ _key, value }) =>
                    onChange({ _key, value, catIndex })
                  }
                />
              </Grid>
            );
          })}

          {!hideActions && (
            <Button
              size="small"
              variant="contained"
              style={{ whiteSpace: "nowrap" }}
              onClick={() => onAddSparePart({ catIndex })}
            >
              Add Spare Part
            </Button>
          )}
        </Grid>
        <RenderSpareParts
          hideActions={hideActions}
          spareParts={category.spareParts}
          catIndex={catIndex}
          applianceIndex={applianceIndex}
          setApplianceData={setApplianceData}
        />
      </Typography>
    );
  });
};
