import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { REASONS_SERVICES } from "utils";

export const getReasonsListRequest = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  REASONS_SERVICES.GET_ALL_REASONS(query)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const toggleReasonRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  REASONS_SERVICES.TOGGLE_REASON(id)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getReasonByIdRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  REASONS_SERVICES.GET_REASON_BY_ID(id)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createReasonRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  REASONS_SERVICES.CREATE_REASON(payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateReasonRequest = ({ id, payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  REASONS_SERVICES.UPDATE_REASON(id, payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
