import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = { width: "400px", height: "400px" };

export default function CustomMap({ style = containerStyle, data = {} }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  if (!isLoaded || !data.lat) {
    return null;
  }
  return (
    <GoogleMap mapContainerStyle={style} center={data} zoom={16}>
      <Marker position={data} draggable={false} />
    </GoogleMap>
  );
}
