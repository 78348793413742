import { Box, Button, Card, Grid } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createBroadcastRequest,
  fetchTemplatesRequest,
  getBroadcastByIdRequest,
} from "screens/Broadcast/utils";
import {
  getDistrictsRequest,
  getPinCodesRequest,
  getStatesRequest,
} from "screens/Services/ServiceDetails/ServiceLocation/utils";
import { successToast } from "utils";
import { BROADCAST_FIELDS } from "./constant";
import { CustomField, getErrorKey, getErrorText } from "components";
import { fetchServices } from "utils/calls";
import RenderLanguageSpecificData from "./RenderLanguageSpecificData";
import { TEMPLATE_TYPE } from "../AddEditTemplate/constants";

const initState = ({ activeLanguages, _data = {} }) => {
  const state = {
    fillers: [{}],
    ..._data,
    details: activeLanguages.map((item) => ({
      ...item,
      ...((_data?.details || {})[item.languageId] || {}),
    })),
  };
  return state;
};

export default function TriggerBroadcast() {
  const { type = "", id } = useParams();
  const navigate = useNavigate();
  const activeLanguages = useSelector(
    ({ common }) => common.allActiveLanguages
  );
  const [broadcast, setBroadcastData] = useState({});
  const [dropDownData, setDropDownData] = useState({});

  useEffect(() => {
    if (id) {
      getBroadcastByIdRequest({
        id,
        onSuccess: (data) => {
          setBroadcastData(initState({ activeLanguages, _data: data }));
        },
      });
    } else {
      fetchTemplatesRequest({
        onSuccess: (templates) =>
          setDropDownData((_data) => ({
            ..._data,
            templates: templates.map((item) => ({
              ...item,
              value: item.templateId,
              label: `${item.templateName}`,
            })),
          })),
      });
      getStatesRequest({
        onSuccess: (states) =>
          setDropDownData((_data) => ({
            ..._data,
            states: states.map((label) => ({ value: label, label })),
          })),
      });
      fetchServices({
        onSuccess: (services) =>
          setDropDownData((_data) => ({
            ..._data,
            services: services.filter((item) => item.isActive),
          })),
      });
    }
  }, [activeLanguages, id]);

  const onSubmitPress = () => {
    return;
    // const { isValid, state } = validateData({ state: broadcast });
    // if (!isValid) {
    //   setBroadcastData({ ...state });
    //   return;
    // }
    const payload = {}; // createPayload(broadcast);

    createBroadcastRequest({
      payload,
      onSuccess: () => {
        navigate("/broadcast", { replace: true });
        successToast(`Broadcast triggered successfully!`);
      },
    });
  };

  const onChange = ({ overRideValues = {}, value, _key }) => {
    const extraData = {};
    if (_key === "template") {
      const temp = dropDownData.templates?.find((item) => item.value === value);
      extraData.templateData = {
        ...temp,

        details: activeLanguages.map((item) => ({
          ...item,
          ...((temp?.details || {})[item.languageId] || {}),
        })),
      };
      extraData.templateType = TEMPLATE_TYPE.find(
        (item) => item.value === temp.templateType
      ).label;
      extraData.templateAction = temp.templateAction;
    }

    setBroadcastData((_state) => ({
      ..._state,
      ...overRideValues,
      ...extraData,
      [_key]: value,
    }));

    if (_key === "state" && value.length === 1) {
      getDistrictsRequest({
        state: value[0].value,
        onSuccess: (districts) =>
          setDropDownData((_state) => ({
            ..._state,
            districts: districts.map((label) => ({ value: label, label })),
          })),
      });
    }
    if (_key === "district" && value.length === 1) {
      getPinCodesRequest({
        state: broadcast.state[0].value,
        district: value[0].value,
        onSuccess: (pincodes) =>
          setDropDownData((_state) => ({
            ..._state,
            pincodes: pincodes.map((label) => ({ value: label, label })),
          })),
      });
    }
  };

  return (
    <Box sx={{ my: 2 }}>
      <Card sx={{ px: 2, py: 1 }}>
        <Header
          variant="h5"
          title={`${type[0].toLocaleUpperCase()}${type.slice(1)} Broadcast`}
        />
        <Grid container rowSpacing={2} spacing={1}>
          {BROADCAST_FIELDS.map((item) => {
            const { dataKey, md, _key, dependentKey } = item;
            if (
              dependentKey === "template" &&
              dependentKey &&
              !broadcast[dependentKey]
            ) {
              return null;
            }

            if (
              dependentKey !== "template" &&
              dependentKey &&
              (!broadcast[dependentKey]?.length ||
                broadcast[dependentKey]?.length > 1)
            ) {
              return null;
            }

            return (
              <Grid key={_key} item md={md || 6} sm={12} xs={12}>
                <CustomField
                  item={{ extraData: dropDownData[dataKey] || [], ...item }}
                  itemData={broadcast}
                  errorText={broadcast[getErrorText(_key)]}
                  error={broadcast[getErrorKey(_key)]}
                  value={broadcast[_key]}
                  disabled={id ? true : item.disabled}
                  onChange={onChange}
                />
              </Grid>
            );
          })}
        </Grid>

        <RenderLanguageSpecificData data={broadcast.templateData} />

        {!id && (
          <div
            style={{
              marginTop: "16px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="large"
              variant="contained"
              onClick={onSubmitPress}
              style={{ whiteSpace: "nowrap" }}
            >
              Send Broadcast
            </Button>
          </div>
        )}
      </Card>
    </Box>
  );
}
