import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { HTTP_SERVICE } from "utils";

export const getCustomerInfoRequest = ({ customerId, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_CUSTOMER_INFO(customerId)
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
