import Visibility from "@mui/icons-material/Visibility";
import Present from "@mui/icons-material/Done";
import NotPresent from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { INPUT_TYPES } from "components";
import moment from "moment/moment";
import ProfilePic from "components/profilePic";
import Fingerprint from "@mui/icons-material/Fingerprint";

export const PROVIDER_ATTENDANCE = {
  PRESENT: "present",
  ABSENT: "absent",
};

export const JOB_STATUS = {
  Scheduled: { value: "Scheduled", label: "Scheduled" },
  Ongoing: { value: "Ongoing", label: "Ongoing" },
  End: { value: "Job Ended", label: "Finished" },
  Complete: { value: "Complete", label: "Complete" },
  Cancelled: { value: "Cancelled", label: "Cancelled" },
  Rejected: { value: "Rejected", label: "Rejected" },
};

export const JOB_FILTERS = [
  {
    inputType: INPUT_TYPES.FILTER_OPTIONS,
    _key: "status",
    md: 12,
    placeholder: "Status",
    extraData: Object.values(JOB_STATUS),
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "serviceId",
    placeholder: "Service",
    dataKey: "services",
    deleteValues: { serviceLabel: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "state",
    placeholder: "State",
    dataKey: "states",
    overRideValues: { district: "", pincode: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "district",
    placeholder: "District",
    dataKey: "districts",
    dependentKey: "state",
    overRideValues: { pincode: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "pincode",
    placeholder: "PIN Code",
    dataKey: "pincodes",
    dependentKey: "district",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "phoneNumber",
    placeholder: "Mobile",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "bookingId",
    placeholder: "Booking ID",
  },
  // {
  //   inputType: INPUT_TYPES.DATE,
  //   _key: "bookingFromDate",
  //   placeholder: "Booking From Date",
  // },
  // {
  //   inputType: INPUT_TYPES.DATE,
  //   _key: "bookingToDate",
  //   placeholder: "Booking To Date",
  // },
  {
    inputType: INPUT_TYPES.DATE,
    _key: "scheduledFromDate",
    placeholder: "Scheduled From Date",
  },
  {
    inputType: INPUT_TYPES.DATE,
    _key: "scheduledToDate",
    placeholder: "Scheduled To Date",
  },
];

export const getJobsListColumns = ({
  onViewPress,
  forPayOrder,
  hideProvider = false,
  hideService = false,
}) => [
  {
    field: "jobId",
    headerName: "Id",
    renderCell: ({ row }) => row.jobId || "-",
  },
  {
    field: "createdAt",
    headerName: "Assigned At",
    width: 150,
    renderCell: ({ row }) =>
      row.createdAt
        ? moment(new Date(row.createdAt)).format("DD MMM YYYY, HH:mm")
        : "-",
  },
  {
    field: "provider",
    headerName: "Provider",
    width: 200,
    hide: !!hideProvider,
    renderCell: ({ row }) => (
      <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
        <ProfilePic size={35} uri={row.provider.profilePic} />
        <span>
          {row.provider.firstName} {row.provider.lastName}
        </span>
      </div>
    ),
  },
  {
    field: "booking",
    headerName: "Scheduled For",
    width: 150,
    renderCell: ({ row }) => {
      if (!row.booking) return "-";

      return row.booking.jobScheduledTime
        ? moment(new Date(row.booking.jobScheduledTime))
            .utc()
            .format("DD MMM YYYY, HH:mm")
        : row.booking.jobScheduledDate
        ? moment(new Date(row.booking.jobScheduledDate)).format("DD MMM YYYY")
        : "-";
    },
  },
  {
    field: "earningAmount",
    headerName: "Provider Commission",
    width: 150,
    renderCell: ({ row }) => `₹${row.earningAmount}`,
  },
  {
    field: "service",
    headerName: "Service",
    width: 150,
    hide: !!hideService,
    renderCell: ({ row }) => row.service.name || "-",
  },
  {
    field: "status",
    headerName: "Job Status",
    width: 120,
  },
  !forPayOrder && {
    field: "action",
    headerName: "Action",
    width: 100,
    renderCell: ({ row }) => {
      const onView = (e) => {
        e.stopPropagation();
        onViewPress(row);
      };

      return (
        <>
          <IconButton onClick={onView}>
            <Visibility color="primary" />
          </IconButton>
        </>
      );
    },
  },
];

export const getProviderAttendanceColumns = ({
  onViewLocation,
  onMarkAttendancePress,
}) => [
  { field: "id", headerName: "ID", width: 90, hide: true },
  { field: "index", headerName: "S. No.", width: 80 },
  {
    field: "providerId",
    headerName: "Provider ID",
    width: 120,
    renderCell: ({ row }) => (row.providerId ? row.providerId : "-"),
  },
  {
    field: "providerName",
    headerName: "Provider Name",
    width: 160,
    renderCell: ({ row }) => (row.providerName ? row.providerName : "-"),
  },
  {
    field: "date",
    headerName: "Date",
    width: 150,
    renderCell: ({ row }) => moment(row.date).format("DD MMM YYYY"),
  },
  {
    field: "punchTime",
    headerName: "Punch Time",
    width: 150,
    renderCell: ({ row }) => {
      if (!row.punchTime) {
        return "-";
      }

      return moment(row.punchTime).format("hh:mm A");
    },
  },
  {
    field: "attendance",
    headerName: "Attendance",
    width: 150,
    renderCell: ({ row }) => {
      if (!row.attendance) {
        return "-";
      }

      return row.attendance === PROVIDER_ATTENDANCE.PRESENT ? (
        <Present color="primary" />
      ) : (
        <NotPresent color="error" />
      );
    },
  },
  {
    field: "location",
    headerName: "Location",
    width: 120,
    renderCell: ({ row }) => {
      if (!row.location) {
        return "-";
      }
      return (
        <IconButton onClick={() => onViewLocation(row)}>
          <Visibility color="primary" />
        </IconButton>
      );
    },
  },
  {
    field: "markAttendane",
    headerName: "Mark Attendance",
    width: 200,
    renderCell: ({ row }) => {
      const todayDate = new Date().setHours(0, 0, 0, 0);

      return (
        !row.punchTime &&
        moment(todayDate).diff(row.date) >= 0 && (
          <IconButton>
            <Fingerprint onClick={() => onMarkAttendancePress(row)} />
          </IconButton>
        )
      );
    },
  },
];
