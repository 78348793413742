import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import moment from "moment";
import { INPUT_TYPES } from "../../../../components/CustomField";
import { ITEM_SELECTION_TYPE, OPERATOR_TYPES } from "../ServiceNode/constants";

export const REGEX = {
  NUMBERS: /^\d+$/,
  DECIMALS: /^\d+(\.\d{1,2})?$/,
};

export const NODE_FIELDS = [
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "itemSelectionType",
    shouldBeSame: true,
    md: 4,
    placeholder: "Item Selection Type",
    extraData: ITEM_SELECTION_TYPE,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "operator",
    shouldBeSame: true,
    md: 4,
    placeholder: "Operator",
    extraData: OPERATOR_TYPES,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "itemOperator",
    shouldBeSame: true,
    md: 4,
    placeholder: "Item Operator",
    extraData: OPERATOR_TYPES,
  },
];

export const getColumnsData = ({ onEditPress, onDeletePress }) => [
  { field: "id", headerName: "ID", width: 90, sortable: true, hide: true },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 200,
    renderCell: ({ row: { createdAt } = {} }) =>
      moment(new Date(createdAt)).format("DD MMM YYYY, H:mm"),
  },
  {
    field: "name",
    headerName: "Price Tag",
    width: 150,
  },
  {
    field: "pincodes",
    headerName: "PIN Codes",
    width: 288,
    renderCell: ({ row: { pincodes } = {} }) => pincodes.join(", "),
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    width: 200,
    renderCell: ({ row: { updatedAt } = {} }) =>
      moment(new Date(updatedAt)).format("DD MMM YYYY, H:mm"),
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 200,
    renderCell: ({ row }) => {
      const onEdit = (e) => {
        e.stopPropagation();
        onEditPress(row);
      };
      const onDelete = (e) => {
        e.stopPropagation();
        onDeletePress(row);
      };

      return (
        <>
          <IconButton onClick={onEdit}>
            <Edit color="secondary" />
          </IconButton>
          <IconButton onClick={onDelete}>
            <Delete sx={{ color: "red" }} />
          </IconButton>
        </>
      );
    },
  },
];
