import { cloneDeep } from "lodash";
import React, { useState } from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import {
  CustomField,
  getErrorKey,
  getErrorText,
  INPUT_TYPES,
} from "../../../../components/CustomField";
import { RenderNodeData } from "../ServiceNode/components/RenderNodeData";

import { validateFields } from "../../utils";
import { FAQ_FIELDS } from "./constants";

export const AddLanguageModal = ({
  unavailableLanguages,
  onSubmitPress,
  data,
  closeModal,
}) => {
  const [state, setState] = useState(cloneDeep(data));
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const validateData = () => {
    let isValidData = true;
    const finalState = state.map((item) => {
      const { _state, isValid } = validateFields({
        fields: FAQ_FIELDS,
        _state: item,
      });
      if (!isValid) {
        isValidData = false;
      }
      return { ..._state };
    });
    setState([...finalState]);
    return isValidData;
  };

  const onSubmit = () => {
    if (!validateData()) {
      return;
    }
    onSubmitPress({ data: state, languageId: selectedLanguage });
  };

  return (
    <ModalWrapper
      closeModal={closeModal}
      footerButtons={[{ onClick: onSubmit, title: "Create" }]}
      title="Add New Language"
    >
      <div style={{ marginBottom: 16 }}>
        <CustomField
          value={selectedLanguage}
          error={!selectedLanguage}
          onChange={({ value }) => setSelectedLanguage(value)}
          item={{
            inputType: INPUT_TYPES.DROPDOWN,
            placeholder: "Select Language",
            extraData: unavailableLanguages,
          }}
        />
      </div>
      {!!selectedLanguage &&
        state.map((item, index) => {
          const onChange = ({ value, _key }) => {
            setState((_state) => {
              _state[index] = {
                ...(_state[index] || {}),
                [_key]: value,
                [getErrorKey(_key)]: false,
                [getErrorText(_key)]: "",
              };
              return [..._state];
            });
          };
          return (
            <>
              {!!index && (
                <div
                  style={{
                    margin: "24px 0px",
                    border: "0.5px solid slategrey",
                  }}
                />
              )}
              <RenderNodeData
                customFields={FAQ_FIELDS}
                customMd={12}
                key={index}
                marginLeft="0px"
                data={item}
                onChange={onChange}
              />
            </>
          );
        })}
    </ModalWrapper>
  );
};
