import { Edit, Visibility } from "@mui/icons-material";
import { IconButton, Switch } from "@mui/material";
import { INPUT_TYPES, REGEX } from "components";
import moment from "moment";
import {
  FIXED_PERCENTAGE,
  TRUE_FALSE,
} from "screens/Services/ServiceDetails/ServiceNode/constants";
import { COUNTRIES } from "shared/constants";

export const COUPON_FIELDS = [
  {
    _key: "name",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Name",
  },
  {
    _key: "couponCode",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Coupon Code",
  },
  {
    _key: "couponType",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Coupon Type",
    extraData: FIXED_PERCENTAGE,
  },
  {
    _key: "couponValue",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Coupon Value",
    regex: REGEX.NUMBERS,
  },
  {
    _key: "validFromDate",
    inputType: INPUT_TYPES.DATE,
    placeholder: "Start Date",
    extraProps: { minDate: new Date() },
  },
  {
    _key: "validTillDate",
    inputType: INPUT_TYPES.DATE,
    placeholder: "End Date",
    extraProps: { minDate: new Date() },
  },
  {
    _key: "consumptionFrequency",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Consumption Frequency",
    regex: REGEX.NUMBERS,
  },
  {
    _key: "service",
    inputType: INPUT_TYPES.FILTER_OPTIONS,
    placeholder: "Service",
    dataKey: "services",
  },
  {
    _key: "service_charges",
    inputType: INPUT_TYPES.FILTER_OPTIONS,
    placeholder: "Service Charge",
    dataKey: "serviceCharges",
  },
  {
    _key: "country",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Country",
    extraData: COUNTRIES,
  },
  {
    _key: "state",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "State",
    dependentKey: "country",
    dataKey: "states",
    isOptional: true,
  },
  {
    _key: "district",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "District",
    dependentKey: "state",
    dataKey: "districts",
    isOptional: true,
  },
];

export const COUPON_FIELDS_LANG_SPECIFC = [
  {
    _key: "title",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Title",
  },
  {
    _key: "shortDescription",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Short Description",
  },
  {
    _key: "description",
    inputType: INPUT_TYPES.TEXT_AREA,
    placeholder: "Description",
    extraProps: { minRows: 3 },
    md: 12,
  },
];

export const COUPON_FILTER_FIELDS = [
  {
    _key: "isActive",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Active",
    extraData: TRUE_FALSE,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "service",
    placeholder: "Service",
    dataKey: "services",
    deleteValues: { serviceLabel: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "country",
    placeholder: "Country",
    overRideValues: { state: "" },
    extraData: COUNTRIES,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "state",
    placeholder: "State",
    dataKey: "states",
    dependentKey: "country",
    overRideValues: { district: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "district",
    placeholder: "District",
    dataKey: "districts",
    dependentKey: "state",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "pincode",
    placeholder: "Pincode",
    dataKey: "pincodes",
    dependentKey: "district",
  },
];

export const getCouponsColumns = ({
  onViewPress,
  onEditPress,
  handleStatusChange,
}) => [
  { field: "id", headerName: "ID", width: 90, hide: true },
  {
    field: "index",
    headerName: "S.No.",
    width: 80,
  },
  {
    field: "name",
    headerName: "Name",
    width: 120,
  },
  {
    field: "couponCode",
    headerName: "Code",
    width: 120,
  },
  {
    field: "couponType",
    headerName: "Type",
    width: 120,
  },
  {
    field: "couponValue",
    headerName: "Value",
    width: 80,
  },
  {
    field: "consumptionFrequency",
    headerName: "Max. Redemptions",
    width: 160,
  },
  {
    field: "validFromDate",
    headerName: "Valid From",
    width: 120,
    renderCell: ({ row }) =>
      moment(new Date(row.validFromDate)).format("DD MMM YYYY"),
  },
  {
    field: "validTillDate",
    headerName: "Valid Till",
    width: 120,
    renderCell: ({ row }) =>
      moment(new Date(row.validTillDate)).format("DD MMM YYYY"),
  },
  {
    field: "isActive",
    headerName: "Active",
    width: 100,
    renderCell: ({ row }) => {
      const onToggle = (e) => {
        e.stopPropagation();
        handleStatusChange(row);
      };
      return (
        <IconButton onClick={onToggle}>
          <Switch checked={row.isActive} color="primary" />
        </IconButton>
      );
    },
  },
  {
    field: "view",
    headerName: "View",
    width: 80,
    renderCell: ({ row }) => {
      const onView = (e) => {
        e.stopPropagation();
        onViewPress(row);
      };
      return (
        <IconButton onClick={onView}>
          <Visibility color="primary" />
        </IconButton>
      );
    },
  },
  {
    field: "edit",
    headerName: "Edit",
    width: 80,
    renderCell: ({ row }) => {
      const onEdit = (e) => {
        e.stopPropagation();
        onEditPress(row);
      };
      return (
        <IconButton onClick={onEdit}>
          <Edit color="primary" />
        </IconButton>
      );
    },
  },
];
