import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { PROMOTION_SERVICES } from "utils";

export const getCouponsListRequest = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  PROMOTION_SERVICES.FETCH_COUPONS_LIST(query)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createCouponRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  PROMOTION_SERVICES.CREATE_COUPON(payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateCouponRequest = ({ couponId, payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  PROMOTION_SERVICES.UPDATE_COUPON(couponId, payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
