import React, { useState } from "react";
import ModalWrapper from "components/ModalWrapper";
import { LANGUAGE_FIELDS } from "../constants";
import { CustomField, getErrorKey, getErrorText } from "components";
import { Grid } from "@mui/material";
import { createLanguageRequest, updateLanguageRequest } from "../utils";

const validateFields = ({ _state }) => {
  let isValid = true;
  LANGUAGE_FIELDS.forEach(({ isOptional, _key }) => {
    if (isOptional) {
      return;
    }

    if (_state[_key] === undefined) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "This field can't be empty";
      return;
    }
    if (!((_state[_key] ?? "") + "")?.trim()?.length) {
      isValid = false;
      _state[getErrorKey(_key)] = true;
      _state[getErrorText(_key)] = "Please enter a valid value";
      return;
    }
    _state[_key] = ((_state[_key] ?? "") + "")?.trim();
  });

  return { isValid, data: _state };
};

export const AddEditLanguageModal = ({
  closeModal,
  loadLanguages,
  modalData,
}) => {
  const [state, setState] = useState(modalData.data);
  if (!modalData) return;
  const validateData = () => {
    let isValidData = true;
    const { isValid, data } = validateFields({
      _state: state,
    });
    setState({ ...data });
    if (!isValid) {
      isValidData = false;
    }

    return isValidData;
  };

  const onChange = ({ _key, value }) => {
    setState((_state) => ({
      ...state,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
      [_key]: value,
    }));
  };

  const onSuccess = () => {
    closeModal();
    loadLanguages();
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    const caller = modalData.isUpdating
      ? updateLanguageRequest
      : createLanguageRequest;

    caller({
      payload: state,
      id: state.languageId,
      onSuccess: onSuccess,
    });
  };

  return (
    <ModalWrapper
      footerButtons={[
        {
          title: modalData.isUpdating ? "Update" : "Create",
          onClick: onSubmitPress,
        },
      ]}
      title={modalData.title}
      closeModal={closeModal}
      sx={{ width: "50%" }}
      childSx={{ height: "50vh", p: 2 }}
    >
      <div
        style={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <Grid container rowSpacing={2} spacing={1}>
          {LANGUAGE_FIELDS.map((language) => {
            if (!language._key) {
              return null;
            }
            const { _key } = language;
            return (
              <Grid key={_key} item md={12} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={language}
                  errorText={state[getErrorText(_key)]}
                  error={state[getErrorKey(_key)]}
                  value={state[_key]}
                  onChange={onChange}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </ModalWrapper>
  );
};
