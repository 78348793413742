import { CalendarMonth, Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { INPUT_TYPES } from "components";
import { Link } from "react-router-dom";
import ProfilePic from "components/profilePic";

const JOB_TYPES = [
  {
    label: "Part Time",
    value: "partTime",
  },
  {
    label: "Full Time",
    value: "fullTime",
  },
];

export const PROVIDER_FILTERS = [
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "providerId",
    placeholder: "Provider ID",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "providerName",
    placeholder: "Provider Name",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "phoneNumber",
    placeholder: "Phone Number",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "jobType",
    placeholder: "Job Type",
    extraData: JOB_TYPES,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "serviceId",
    placeholder: "Service",
    dataKey: "services",
    deleteValues: { serviceLabel: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "state",
    placeholder: "State",
    dataKey: "states",
    overRideValues: { district: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "district",
    placeholder: "District",
    dataKey: "districts",
    dependentKey: "state",
  },
];

export const getProviderListColumns = ({
  isVerified,
  target = "_self",
  hideCalendar = true,
  jobScheduledTime,
  hideServices,
  withAvailability,
} = {}) => [
  {
    field: "providerId",
    headerName: "ID",
    width: 90,
    sortable: false,
    renderCell: ({ row }) => row.providerId || "-",
  },
  {
    field: "firstName",
    headerName: "Name",
    width: 200,
    renderCell: ({ row }) => (
      <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
        <ProfilePic size={35} uri={row.profilePic} />
        <span>
          {row.firstName} {row.lastName}
        </span>
      </div>
    ),
  },
  {
    field: "gender",
    headerName: "Gender",
    renderCell: ({ row }) => row.gender || "-",
  },
  {
    field: "email",
    headerName: "Email ID",
    width: 200,
    renderCell: ({ row }) => row.email || "-",
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    width: 150,
    renderCell: ({ row }) => row.phoneNumber || "-",
  },
  {
    field: "distance",
    headerName: "Distance (in km)",
    width: 150,
    renderCell: ({ row }) => (!!row.distance ? row.distance / 1000 : "-"),
  },
  {
    field: "services",
    headerName: "Services",
    width: 200,
    hide: hideServices,
    renderCell: ({ row }) =>
      row.services?.map((item) => item.name).join(", ") || "-",
  },
  {
    field: withAvailability ? "providerStatus" : "verificationStatus",
    headerName: "Status",
    hide: isVerified,
    width: 144,
  },
  {
    field: "calendar",
    headerName: "Calendar",
    width: 88,
    hide: hideCalendar,
    renderCell: ({ row }) => {
      return (
        <>
          <Link
            to={`/provider/calendar/${row.id}?date=${jobScheduledTime}`}
            target={target}
          >
            <IconButton>
              <CalendarMonth color="primary" />
            </IconButton>
          </Link>
        </>
      );
    },
  },
  {
    field: "action",
    headerName: "Action",
    width: 80,
    renderCell: ({ row }) => {
      return (
        <>
          <Link to={`/provider-details/${row.id}`} target={target}>
            <IconButton>
              <Visibility color="primary" />
            </IconButton>
          </Link>
        </>
      );
    },
  },
];

export const PROVIDER_FIELDS = [
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "firstName",
    placeholder: "First Name",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "lastName",
    placeholder: "Last Name",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "phoneNumber",
    placeholder: "Phone Number",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "jobType",
    placeholder: "Job Type",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "email",
    placeholder: "Email",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "address.state",
    placeholder: "State",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "address.district",
    placeholder: "District",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "address.address",
    placeholder: "Address",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "address.addressline2",
    placeholder: "Address Line 2",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "balance",
    placeholder: "Balance",
  },
];

export const getProviderKycColumns = ({ onViewPress }) => [
  { field: "id", headerName: "S.No.", width: 90, sortable: true },
  {
    field: "title",
    headerName: "Document Type",
    width: 300,
  },
  {
    field: "view",
    headerName: "View",
    width: 200,
    renderCell: ({ row }) => {
      const onView = (e) => {
        e.stopPropagation();
        onViewPress(row);
      };

      return (
        <>
          <IconButton onClick={onView}>
            <Visibility color="primary" />
          </IconButton>
        </>
      );
    },
  },
];

export const MARK_AS_EMPLOYEE_FIELDS = [
  {
    _key: "employeeId",
    placeholder: "Employee Id",
    inputType: INPUT_TYPES.INPUT,
  },
];
