import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { isArray } from "lodash";

export default function FilterOptions({
  color,
  disabled,
  error,
  _key,
  errorText,
  overRideValues,
  onChange,
  placeholder,
  value,
  extraData,
  extraProps,
  isSingle,
}) {
  const onChangeValue = (e, val) => {
    if (!isSingle && val.filter((item) => item.value === "select_all").length) {
      onChange({ overRideValues, value: extraData, _key });
      return;
    }
    onChange({ overRideValues, value: val, _key });
  };

  return (
    <Autocomplete
      multiple={!isSingle}
      disabled={disabled}
      options={
        extraData.length && !isSingle
          ? [{ value: "select_all", label: "Select All" }, ...extraData]
          : extraData
      }
      onChange={onChangeValue}
      value={isSingle ? value || null : isArray(value) ? value : []}
      color={color}
      size="small"
      defaultValue={isSingle ? "" : []}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          error={error}
          helperText={errorText}
          label={placeholder}
          placeholder={placeholder}
          {...extraProps}
        />
      )}
      {...extraProps}
    />
  );
}
