import { cloneDeep } from "lodash";
import React, { useState } from "react";
import ModalWrapper from "../../../../../components/ModalWrapper";
import {
  CustomField,
  getErrorKey,
  getErrorText,
  INPUT_TYPES,
} from "../../../../../components/CustomField";
import { validateFields } from "../../../utils";
import { RenderNodeData } from "../../ServiceNode/components/RenderNodeData";
import { SERVICE_FIELDS } from "./constants";
import { createServiceByLangRequest } from "../utils";

export const AddLanguageModal = ({
  unavailableLanguages,
  serviceId,
  closeModal,
}) => {
  const [state, setState] = useState(cloneDeep({}));
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const validateData = () => {
    const { _state, isValid } = validateFields({ _state: state });
    setState({ ..._state });
    return isValid;
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    const payload = {
      languageId: selectedLanguage,
      serviceId,
    };

    SERVICE_FIELDS.forEach(({ _key }) => {
      if (state[_key]) {
        payload[_key] = state[_key];
      }
    });

    createServiceByLangRequest({
      payload,
      onSuccess: closeModal,
    });
  };

  const onChange = ({ value, _key }) => {
    setState((_state) => ({
      ..._state,
      [_key]: value,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
    }));
  };

  return (
    <ModalWrapper
      footerButtons={[{ title: "Create", onClick: onSubmitPress }]}
      closeModal={closeModal}
      title="Add New Language"
    >
      <div style={{ marginBottom: 16 }}>
        <CustomField
          value={selectedLanguage}
          error={!selectedLanguage}
          onChange={({ value }) => setSelectedLanguage(value)}
          item={{
            inputType: INPUT_TYPES.DROPDOWN,
            placeholder: "Select Language",
            extraData: unavailableLanguages,
          }}
        />
      </div>
      {!!selectedLanguage && (
        <RenderNodeData
          customFields={SERVICE_FIELDS}
          customMd={12}
          data={state}
          onChange={onChange}
          marginLeft="0px"
        />
      )}
    </ModalWrapper>
  );
};
