import { queryString } from "utils";
import { setLoading } from "../../../redux/actions";
import { updateCurrentEnrollmentData } from "../../../redux/actions/services";
import { store } from "../../../redux/store";
import { ENROLLMENT_SERVICES } from "../../../utils/apiServices";

const formatNodeData = ({ langId, item, itemId, serviceId }) => {
  const finalVal = (item.layout ? item : item.stateData) || {};
  return {
    parentItemId: finalVal.parentItemId ?? itemId,
    serviceId,
    nodeId: finalVal.nodeId,
    description: finalVal.description?.trim(),
    position: finalVal.position,
    itemLayout: finalVal.itemLayout,
    itemSelectionType: finalVal.itemSelectionType,
    itemStyle: finalVal.itemStyle,
    layout: finalVal.layout,
    title: finalVal.title?.trim(),
    languageId: langId ?? item.languageId,
  };
};

const formatItemData = ({ langId, item, nodeId, serviceId }) => {
  const finalVal = item.title ? item : item.stateData;
  return {
    description: finalVal.description?.trim(),
    languageId: langId ?? item.languageId,
    nodeId: finalVal.nodeId ?? nodeId,
    itemId: finalVal.itemId,
    position: finalVal.position,
    serviceId,
    title: finalVal.title?.trim(),
  };
};

const formatLangHierarchy = ({ data, serviceId, languageId: langId }) => {
  let nodesArray = [],
    itemsArray = [];
  data.forEach((node) => {
    nodesArray.push(formatNodeData({ item: node, serviceId, langId }));
    (node.items || []).forEach((item) => {
      itemsArray.push(formatItemData({ item, langId, serviceId }));
      const { itemsArray: _itemsArray, nodesArray: _nodesArray } =
        formatLangHierarchy({
          data: item.children,
          languageId: langId,
          serviceId,
        });
      nodesArray.push(..._nodesArray);
      itemsArray.push(..._itemsArray);
    });
  });
  return { nodesArray, itemsArray };
};

export const fetchEnrollmentQuestionsData = (serviceId) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  ENROLLMENT_SERVICES.GET_ENROLLMENTS(serviceId)
    .then(({ data = [] }) => {
      dispatch(updateCurrentEnrollmentData(data));
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createNodesRequest = ({ onSuccess, data, serviceId, itemId }) => {
  const payload = {
    serviceId,
    itemId,
    nodes: data.map((item) => formatNodeData({ item, itemId, serviceId })),
  };
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  ENROLLMENT_SERVICES.ADD_ENROLLMENT_NODE(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchEnrollmentQuestionsData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createItemsRequest = ({ onSuccess, data, nodeId, serviceId }) => {
  const payload = {
    nodeId,
    serviceId,
    items: data.map((item) => formatItemData({ item, serviceId, nodeId })),
  };
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  ENROLLMENT_SERVICES.ADD_ENROLLMENT_ITEM(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchEnrollmentQuestionsData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateNodeRequest = ({ _id: id, serviceId, onSuccess, data }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  const payload = formatNodeData({ item: data[0] });
  ENROLLMENT_SERVICES.UPDATE_ENROLLMENT_NODE({ data: payload, id })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchEnrollmentQuestionsData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateItemRequest = ({ _id: id, serviceId, onSuccess, data }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  const payload = formatItemData({ item: data[0] });
  ENROLLMENT_SERVICES.UPDATE_ENROLLMENT_ITEM({ data: payload, id })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchEnrollmentQuestionsData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createNodeReplicaForOtherLanguage = ({
  serviceId,
  languageId,
  onSuccess,
  data,
}) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  const { itemsArray, nodesArray } = formatLangHierarchy({
    serviceId,
    data,
    languageId,
  });
  ENROLLMENT_SERVICES.CREATE_NODE_REPLICA_FOR_OTHER_LANGUAGE({
    data: { items: itemsArray, nodes: nodesArray },
  })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchEnrollmentQuestionsData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const toggleNodeActiveStatus = ({
  onSuccess,
  serviceId,
  nodeId,
  isActive,
}) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  ENROLLMENT_SERVICES.TOGGLE_NODE_ACTIVE_STATUS({ data: { nodeId, isActive } })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchEnrollmentQuestionsData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const toggleItemActiveStatus = ({
  onSuccess,
  serviceId,
  itemId,
  isActive,
}) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  ENROLLMENT_SERVICES.TOGGLE_ITEM_ACTIVE_STATUS({ data: { itemId, isActive } })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchEnrollmentQuestionsData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateServiceNodePositionRequest = ({
  serviceId,
  data: { position, nodeId } = {},
  sibling = {},
}) => {
  const dispatch = store.dispatch;
  const positions = [
    { position: sibling.position, nodeId },
    { position, nodeId: sibling.nodeId },
  ];
  dispatch(setLoading({ loading: true }));
  ENROLLMENT_SERVICES.UPDATE_ENROLLMENT_NODE_POSITION({ positions })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        fetchEnrollmentQuestionsData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateServiceItemPositionRequest = ({
  serviceId,
  data: { position, itemId } = {},
  sibling = {},
}) => {
  const dispatch = store.dispatch;
  const positions = [
    { position: sibling.position, itemId },
    { position: position, itemId: sibling.itemId },
  ];
  dispatch(setLoading({ loading: true }));
  ENROLLMENT_SERVICES.UPDATE_ENROLLMENT_ITEM_POSITION({ positions })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        fetchEnrollmentQuestionsData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const deleteNodeRequest = ({ nodeId, serviceId, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  ENROLLMENT_SERVICES.DELETE_ENROLMENT_NODE(queryString({ nodeId }))
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchEnrollmentQuestionsData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const deleteItemRequest = ({ itemId, serviceId, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  ENROLLMENT_SERVICES.DELETE_ENROLMENT_NODE_ITEM(queryString({ itemId }))
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchEnrollmentQuestionsData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
