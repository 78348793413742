import { Box, Card, CardContent } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomFilter from "components/CustomFilter";
import { queryString, useGetLanguageData } from "utils";
import CustomDataGrid from "components/DataGrid";
import qs from "query-string";
import { getCouponsListRequest, updateCouponRequest } from "./utils";
import { COUPON_FILTER_FIELDS, getCouponsColumns } from "./constants";
import { AddEditCouponModal } from "./components/AddEditCouponModal";

export default function Coupons() {
  const [data, setData] = useState([]);
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState({});
  const selectedLanguage = 1;
  const [modalData, setModalData] = useState(null);

  const closeModal = () => setModalData(null);

  const navigate = useNavigate();

  const { allLanguages, languagesKeyValPair } = useGetLanguageData({
    data,
  });

  const fetchCouponsList = useCallback(
    () => {
      getCouponsListRequest({
        query: queryString(filters),
        onSuccess: (data) =>
          setData(
            data.map((item, index) => ({
              ...item,
              id: item._id,
              index: index + 1,
              couponLanguageDetail: item.couponLanguageDetail.map(
                (langItem) => ({
                  ...langItem,
                  languageName: languagesKeyValPair[langItem.languageId],
                })
              ),
            }))
          ),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );

  const onCreatePress = () => setModalData({ data: null });
  const onViewPress = (row) => setModalData({ data: row, readOnly: true });
  const onEditPress = (row) => setModalData({ data: row });

  const handleStatusChange = (row) => {
    updateCouponRequest({
      couponId: row?._id,
      payload: { isActive: !row.isActive },
      onSuccess: fetchCouponsList,
    });
  };

  const setFiltersData = (_state) => {
    navigate(window.location.pathname + "?" + queryString(_state), {
      replace: true,
    });
    setFilters(_state);
  };

  useEffect(() => {
    const params = qs.parse(searchParams.toString());
    if (params.status) {
      params.status = params.status
        .split(",")
        .map((value) => ({ label: value, value }));
    }
    if (JSON.stringify(filters) !== JSON.stringify(params)) {
      setFilters({ ...params });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCouponsList();
  }, [fetchCouponsList]);

  const languageData = data.map((item) => {
    return {
      ...item,
      ...item.couponLanguageDetail?.find(
        (langItem) => langItem.languageId === selectedLanguage
      ),
    };
  });

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header
            title="Coupons"
            buttons={[
              {
                title: "Create Coupon",
                onClick: onCreatePress,
              },
            ]}
          />
          
          <CustomFilter
            onChange={setFiltersData}
            filtersData={filters}
            fields={COUPON_FILTER_FIELDS}
          />
          <CustomDataGrid
            rows={languageData}
            columns={getCouponsColumns({
              onViewPress,
              onEditPress,
              handleStatusChange,
            })}
          />
        </CardContent>
      </Card>

      {!!modalData && (
        <AddEditCouponModal
          modalData={modalData}
          languages={allLanguages}
          onSuccessCallback={fetchCouponsList}
          closeModal={closeModal}
        />
      )}
    </Box>
  );
}
