import { combineReducers } from "redux";
import commonReducer from "./common";
import ServicesReducer from "./services";
import CategoriesReducer from "./categories";
import AppConfigurationReducer from "./appConfiguration";

const rootReducer = combineReducers({
  common: commonReducer,
  services: ServicesReducer,
  categories: CategoriesReducer,
  appConfiguration: AppConfigurationReducer,
});

export default rootReducer;
