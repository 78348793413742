import { INPUT_TYPES } from "../../../../../components/CustomField";

const onSuccessCallback = (...rest) => {
  const getServiceInfo = require("../../../utils").getServiceInfo;
  getServiceInfo(...rest);
};

export const SERVICE_FIELDS = [
  { md: 6, inputType: INPUT_TYPES.INPUT, _key: "name", placeholder: "Name" },
  {
    md: 6,
    inputType: INPUT_TYPES.INPUT,
    _key: "shortDescription",
    isOptional: true,
    placeholder: "Short Description",
  },
  {
    md: 6,
    inputType: INPUT_TYPES.FILE,
    _key: "banners",
    placeholder: "Banners",
    isOptional: true,
    showOnlyOnUpdate: true,
    extraProps: {
      collection: "services",
      customMd: 12,
      onSuccessCallback,
      isNode: true,
    },
  },
  {
    md: 6,
    inputType: INPUT_TYPES.FILE,
    _key: "thumbnail",
    placeholder: "Thumbnail",
    isOptional: true,
    showOnlyOnUpdate: true,
    extraProps: {
      collection: "services",
      customMd: 12,
      onSuccessCallback,
    },
  },
  {
    md: 6,
    inputType: INPUT_TYPES.TEXT_AREA,
    _key: "description",
    isOptional: true,
    placeholder: "Description",
  },
  {
    md: 6,
    inputType: INPUT_TYPES.TEXT_AREA,
    _key: "bookingConfirmationMessage",
    placeholder: "Booking Confirmation Message",
  },
];
