import React, { useEffect, useState } from "react";
import { REASON_FIELDS } from "./constants";
import { CustomField, INPUT_TYPES } from "components";
import { Box, Button, Card, Grid } from "@mui/material";
import { fetchServices } from "utils/calls";
import { getErrorKey, getErrorText, successToast, validateFields } from "utils";
import { useNavigate, useParams } from "react-router-dom";
import {
  createReasonRequest,
  getReasonByIdRequest,
  updateReasonRequest,
} from "./utils";
import { store } from "redux/store";
import Header from "components/HeaderV2";

export default function AddEditReasons() {
  const { id } = useParams();
  const [dropdownData, setDropdownData] = useState({});
  const [data, setData] = useState({
    languages: store.getState().common.allActiveLanguages.map((item) => ({
      languageId: item.languageId,
      languageName: item.languageName,
    })),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getReasonByIdRequest({
        id,
        onSuccess: (response) => {
          const activeLanguages = store.getState().common.allActiveLanguages;
          const first = response[0] || {};
          setData({
            serviceId: first.serviceId,
            reasonApp: first.reasonApp,
            type: first.type,
            languages: activeLanguages.map((item) => {
              const langData =
                response.find((_) => _.languageId === item.languageId) || {};
              return {
                languageName: item.languageName,
                languageId: item.languageId,
                ...langData,
              };
            }),
          });
        },
      });
    }
    fetchServices({
      onSuccess: (services) =>
        setDropdownData((_state) => ({ ..._state, services })),
    });
  }, [id]);

  const onChange = ({ overRideValues = {}, value, _key }) => {
    setData((_state) => {
      if (_key === "serviceId") {
        _state.serviceLabel = dropdownData.services.find(
          (item) => item.value === value
        ).label;
      }
      return {
        ..._state,
        [getErrorKey(_key)]: false,
        [getErrorText(_key)]: "",
        ...overRideValues,
        [_key]: value,
      };
    });
  };

  const validateData = () => {
    let isValid = true;
    const validator1 = validateFields({
      _state: data,
      fields: REASON_FIELDS,
    });

    if (!validator1.isValid) {
      isValid = false;
    }

    validator1._state.languages = validator1._state.languages.map((item) => {
      const validator2 = validateFields({
        _state: item,
        fields: [{ _key: "reason", inputType: INPUT_TYPES.INPUT }],
      });

      if (!validator2.isValid) {
        isValid = false;
      }

      return validator2._state;
    });

    setData({ ...validator1._state });
    return isValid;
  };

  const createPayload = () => {
    const payload = { reasons: [] };
    REASON_FIELDS.forEach(({ _key }) => {
      payload[_key] = data[_key];
    });
    data.languages.forEach((item) => {
      payload.reasons.push({
        _id: item._id,
        reason: item.reason,
        languageId: String(item.languageId),
      });
    });

    return payload;
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }
    const caller = id ? updateReasonRequest : createReasonRequest;
    caller({
      payload: createPayload(),
      id,
      onSuccess: () => {
        navigate(-1, { replace: true });
        successToast(`Reason ${id ? "updated" : "created"} successfully!`);
      },
    });
  };

  return (
    <Box sx={{ m: 2 }}>
      <Card sx={{ p: 2 }}>
        <Header title={"Create Reason"} />
        {(data.languages || []).map((item, index) => {
          const { reason, languageName, _key = "reason" } = item;

          const onChangeReason = ({ value }) => {
            setData((_data) => {
              _data.languages[index].reason = value;
              _data.languages[index][getErrorText(_key)] = "";
              _data.languages[index][getErrorKey(_key)] = false;
              return { ..._data };
            });
          };

          return (
            <CustomField
              key={index}
              item={{
                inputType: INPUT_TYPES.INPUT,
                _key,
                placeholder: `Title(${languageName})`,
                extraProps: { sx: { marginBottom: "16px" } },
              }}
              errorText={item[getErrorText(_key)]}
              error={item[getErrorKey(_key)]}
              value={reason}
              onChange={onChangeReason}
            />
          );
        })}
        <Grid container spacing={2}>
          {REASON_FIELDS.map((item) => {
            const { dataKey, _key, dependentKey, md } = item;
            if (dependentKey && !data[dependentKey]) {
              return null;
            }
            return (
              <Grid key={_key} item md={md || 12} sm={12} xs={12}>
                <CustomField
                  item={{ extraData: dropdownData[dataKey] || [], ...item }}
                  errorText={data[getErrorText(_key)]}
                  error={data[getErrorKey(_key)]}
                  value={data[_key]}
                  onChange={onChange}
                />
              </Grid>
            );
          })}
        </Grid>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onSubmitPress} variant="contained">
            {id ? "Update" : "Create"}
          </Button>
        </div>
      </Card>
    </Box>
  );
}
