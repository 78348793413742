import React, { useEffect, useState } from "react";
import RenderNodeItems from "./components/RenderNodesItems";
import "./index.css";
import { NodeModal } from "./components/NodeModal";
import {
  fetchEnrollmentQuestionsData,
  updateServiceNodePositionRequest,
} from "./utils";
import { AddLanguageModal } from "./components/AddLanguageModal";
import { useGetNodesData } from "./hooks/useLanguagesData";
import Header from "../../../components/HeaderV2";
import { Box } from "@mui/system";
import { Card, CardContent } from "@mui/material";
import { useParams } from "react-router-dom";

const EnrollmentQuestionsDetail = () => {
  const [modalData, setModalData] = useState(null);
  const { serviceId } = useParams();
  const {
    defaultLanguageId,
    availableLanguages,
    enrollmentNodes,
    unavailableLanguages,
  } = useGetNodesData();
  const [showAddLanguageModal, toggleAddLanguageModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const { nodes: data = [] } =
    enrollmentNodes.find((item) => item.languageId === selectedLanguage) || {};
  const itemsLength = data.length;

  useEffect(() => {
    fetchEnrollmentQuestionsData(serviceId);
  }, [serviceId]);

  useEffect(() => {
    defaultLanguageId && setSelectedLanguage(defaultLanguageId);
  }, [defaultLanguageId]);

  const onAddRootNode = () => setModalData({ title: "Add Root Node" });

  const closeModal = () => setModalData(null);
  const closeAddLanguageModal = () => toggleAddLanguageModal(false);
  const onAddLanguageModal = () => toggleAddLanguageModal(true);

  const onMoveCallback = ({ withItem, ...rest }) => {
    updateServiceNodePositionRequest({
      serviceId,
      data: rest,
      sibling: data[withItem],
    });
  };

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header
            title="Enrollment Questions"
            dropDown={{
              value: selectedLanguage,
              onChange: setSelectedLanguage,
              data: availableLanguages,
            }}
            buttons={[
              { onClick: onAddRootNode, title: "Add Root Node" },
              {
                disabled:
                  !enrollmentNodes.length || !unavailableLanguages.length,
                onClick: onAddLanguageModal,
                title: "Add Language",
              },
            ]}
          />

          {!!modalData && (
            <NodeModal
              modalData={modalData}
              serviceId={serviceId}
              isNode={false}
              availableLanguages={availableLanguages}
              closeModal={closeModal}
            />
          )}
          {!!showAddLanguageModal && (
            <AddLanguageModal
              serviceId={serviceId}
              data={data}
              unavailableLanguages={unavailableLanguages}
              closeModal={closeAddLanguageModal}
            />
          )}
          {data.map((data, index) => (
            <RenderNodeItems
              itemsLength={itemsLength}
              index={index}
              disabled
              onMoveCallback={onMoveCallback}
              availableLanguages={availableLanguages}
              serviceId={serviceId}
              ignoreMargin
              isNode
              data={data}
              key={data._id}
            />
          ))}
        </CardContent>
      </Card>
    </Box>
  );
};

export default EnrollmentQuestionsDetail;
