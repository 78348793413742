import { INPUT_TYPES } from "../../../../components/CustomField";
import {
  FIXED_PERCENTAGE,
  TRUE_FALSE,
  YES_NO_PARTIAL,
} from "../ServiceNode/constants";
import { REGEX } from "../ServicePrice/constants";

const JOB_ASSIGNMENT_TYPE = [
  { value: "auto", label: "Auto" },
  { value: "manual", label: "Manual" },
];

// group 1 (Schedule)
export const CONFIG_FIELDS_1 = [
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "parentId",
    placeholder: "Category",
    dataKey: "categories",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "slotShow",
    extraData: YES_NO_PARTIAL,
    placeholder: "Show Slot",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "slotDuration",
    regex: REGEX.NUMBERS,
    placeholder: "Job Slot Duration (minutes)",
    dependentKey: "slotShow",
    hideOnDependentKeyValue: "no",
  },
  {
    inputType: INPUT_TYPES.TIME,
    _key: "startTime",
    placeholder: "Start Time (hh:mm)",
    extraProps: { minDate: new Date() },
    dependentKey: "slotShow",
    hideOnDependentKeyValue: "no",
  },
  {
    inputType: INPUT_TYPES.TIME,
    _key: "endTime",
    placeholder: "End Time (hh:mm)",
    extraProps: { minDate: new Date() },
    dependentKey: "slotShow",
    hideOnDependentKeyValue: "no",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "preBookingTimeUnit",
    extraData: ["Day", "Hour"],
    placeholder: "Pre Booking Duration Unit",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "preBookingTime",
    regex: REGEX.NUMBERS,
    placeholder: "Pre Booking Duration Value",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "bookingDayUpto",
    regex: REGEX.NUMBERS,
    placeholder: "Booking days Upto",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "alertPriorToStartJob",
    extraData: TRUE_FALSE,
    placeholder: "Alert Prior To Start Job",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "jobInterval",
    regex: REGEX.NUMBERS,
    placeholder: "Job Interval (minutes)",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "jobBufferTime",
    regex: REGEX.NUMBERS,
    placeholder: "Job Buffer Time (minutes)",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "jobAssignmentType",
    extraData: JOB_ASSIGNMENT_TYPE,
    placeholder: "Job Assignment Type",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "showUptoDate",
    extraData: YES_NO_PARTIAL,
    placeholder: "Show Upto Date",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "showUptoTime",
    extraData: YES_NO_PARTIAL,
    placeholder: "Show Upto Time",
  },
];

// group 2 (cancellation/rescheduling detail)
export const CONFIG_FIELDS_2 = [
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "isCancellationAllowed_customer",
    placeholder: "Customer Cancellation Allowed",
    extraData: TRUE_FALSE,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "cancelTimeUnit",
    extraData: ["Day", "Hour"],
    placeholder: "Customer Cancel Duration Unit",
    dependentKey: "isCancellationAllowed_customer",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "cancelTimeDuration",
    regex: REGEX.NUMBERS,
    placeholder: "Customer Cancel Duration Value",
    dependentKey: "isCancellationAllowed_customer",
  },

  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "isRescheduleAllowed_customer",
    placeholder: "Customer Reschedule Allowed",
    extraData: TRUE_FALSE,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "rescheduleTimeUnit",
    extraData: ["Day", "Hour"],
    placeholder: "Customer Reschedule Duration Unit",
    dependentKey: "isRescheduleAllowed_customer",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "rescheduleTimeDuration",
    regex: REGEX.NUMBERS,
    placeholder: "Customer Reschedule Duration Value",
    dependentKey: "isRescheduleAllowed_customer",
  },

  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "isCancellationAllowed_provider",
    placeholder: "Provider Cancellation Allowed",
    extraData: TRUE_FALSE,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "providerCancelTimeUnit",
    extraData: ["Day", "Hour"],
    placeholder: "Provider Cancel Duration Unit",
    dependentKey: "isCancellationAllowed_provider",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "providerCancelTimeDuration",
    regex: REGEX.NUMBERS,
    placeholder: "Provider Cancel Duration Value",
    dependentKey: "isCancellationAllowed_provider",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "isRescheduleAllowed_provider",
    placeholder: "Provider Reschedule Allowed",
    extraData: TRUE_FALSE,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "providerRescheduleTimeUnit",
    extraData: ["Day", "Hour"],
    placeholder: "Provider Reschedule Duration Unit",
    dependentKey: "isRescheduleAllowed_provider",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "providerRescheduleTimeDuration",
    regex: REGEX.NUMBERS,
    placeholder: "ProviderReschedule Duration Value",
    dependentKey: "isRescheduleAllowed_provider",
  },
];

// group 3 - (provider details)
export const CONFIG_FIELDS_3 = [
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "isProviderShareApplicable",
    placeholder: "Provider Share Applicable",
    extraData: TRUE_FALSE,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "providerShareUnit",
    placeholder: "Provider Share Unit",
    extraData: FIXED_PERCENTAGE,
    dependentKey: "isProviderShareApplicable",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "providerShare",
    regex: REGEX.DECIMALS,
    placeholder: "Provider Share Value",
    dependentKey: "isProviderShareApplicable",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "validateProvider",
    extraData: TRUE_FALSE,
    placeholder: "Validate Provider",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "servingRadius",
    regex: REGEX.NUMBERS,
    placeholder: "Serving Radius (meters)",
    dependentKey: "validateProvider",
  },
];

// group 4 - (charges)
export const CONFIG_FIELDS_4 = [
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "visitChargesApplicable",
    extraData: TRUE_FALSE,
    placeholder: "Visit Charges Applicable",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "visitCharges",
    regex: REGEX.DECIMALS,
    placeholder: "Visit Charges (₹)",
    dependentKey: "visitChargesApplicable",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "isCancellationPenaltyApplicable",
    placeholder: "Cancellation Penalty Applicable",
    extraData: TRUE_FALSE,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "cancellationPenaltyUnit",
    placeholder: "Cancellation Penal=ty Unit",
    extraData: FIXED_PERCENTAGE,
    dependentKey: "isCancellationPenaltyApplicable",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "cancellationPenaltyValue",
    regex: REGEX.DECIMALS,
    placeholder: "Cancellation Penalty Value",
    dependentKey: "isCancellationPenaltyApplicable",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "serviceItemChargesApplicable",
    extraData: TRUE_FALSE,
    placeholder: "Service Item Charges Applicable",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "isServiceEstimateApplicable",
    extraData: TRUE_FALSE,
    placeholder: "Estimate Applicable",
  },
];

export const CONFIG_FIELDS = [
  ...CONFIG_FIELDS_1,
  ...CONFIG_FIELDS_2,
  ...CONFIG_FIELDS_3,
  ...CONFIG_FIELDS_4,
];

// group 1 (Schedule)
// 1. Job day start
// 2. job day end
// 3. show slot (boolean)
// 4. slot interval
// 5. prebooking unit
// 6. prebooking duration
// 7. booking calendar days count
// 2. alert prior to start job - CLARITY
// 1. Job duration
// 2. Job buffer time
// 3. job assignment (auto/manual)
// 4. provider slot freeze time (in minutes) - CLARITY

// group 2 (cancellation/rescheduling detail)
// 1. customer cancellation allowed (yes/no)
// 2. customer reschedule allowd (yes/no)
// 3. customer allowed time unit (day / minutes)
// 4. customer allowed time duration (in minutes)
// 5. provider cancellation allowed (yes/no)
// 6. provider reschedule allowed (yes/no)
// 7. provider allowed time unit (day / minutes)
// 8. provider allowed time duration (in minutes)

// group 3 - (provider details)
// 1. provider share alllowed (yes/no) - CLARITY
// 2. provider share unit (fixed/percentage)
// 3. provider share value
// 1. serving radius
// 3. validate provider

// group 4 - (charges)
// 1. visit charges applicable (yes/no)
// 2. visit charges amount
// 3. cancellation charges unit (fixed/percentage)
// 4. cancellation charges value
// 5. service item charges applicable

// may be not needed

// 1. Auto Wave Time Interval (minutes)
// 2. Second Wave Time Interval (minutes)
