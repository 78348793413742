import React, { useState } from "react";
import { Typography } from "@mui/material";
import CustomDataGrid from "components/DataGrid";
import { getSparePartCategoryColumns } from "../constants";
import { ChevronLeftRounded } from "@mui/icons-material";

export const CategoryItem = ({
  data,
  expandedCategory,
  setExpandedCategory,
}) => {
  const isExpanded = data.index === expandedCategory;

  const onHeaderClick = () => {
    if (isExpanded) {
      return;
    }

    setExpandedCategory(data.index);
  };

  return (
    <Typography
      component={"div"}
      sx={{
        border: `1px solid #1b5e20`,
        borderRadius: "8px",
        mt: data.index !== 0 ? "10px" : "0px",
      }}
    >
      <Typography
        sx={{
          p: 1,
          background: "#1b5e20",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          borderBottomLeftRadius: isExpanded ? "0px" : "8px",
          borderBottomRightRadius: isExpanded ? "0px" : "8px",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={onHeaderClick}
      >
        <span style={{ fontSize: "14px" }}>{data.name}</span>
        <ChevronLeftRounded color="white" sx={{ rotate: "270deg" }} />
      </Typography>
      {isExpanded && (
        <Typography>
          <CustomDataGrid
            pagination={false}
            rows={data.spareParts?.map((item, index) => ({
              ...item,
              id: index,
              index: index + 1,
            }))}
            columns={getSparePartCategoryColumns()}
          />
        </Typography>
      )}
    </Typography>
  );
};
