import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { HTTP_SERVICE } from "./apiServices";

export const fetchServices = ({ categoryId, onSuccess, includeAllOption }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_SERVICES({ categoryId })
    .then(({ data, isSuccess }) => {
      if (isSuccess && onSuccess) {
        const _data = data.map((item) => ({
          ...item,
          label: item.name,
          value: item.serviceId,
          id: item._id,
        }));

        onSuccess(
          includeAllOption ? [{ label: "All", value: "ALL" }, ..._data] : _data
        );
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
