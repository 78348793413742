import React, { useState } from "react";
import { CustomField, getErrorKey, getErrorText } from "components";
import ModalWrapper from "components/ModalWrapper";
import { Grid } from "@mui/material";
import {
  addResponseMessageRequest,
  updateResponseMessageRequest,
} from "../utils";
import { validateFields } from "utils";
import { RESPONSE_MESSAGE_FIELDS } from "../constants";

export const AddEditResponseMessage = ({
  closeModal,
  modalData,
  isKeyword,
}) => {
  const [state, setState] = useState(modalData.data || []);
  const isEdit = modalData.isEdit;

  const validateData = () => {
    const { _state, isValid } = validateFields({
      _state: state,
      fields: RESPONSE_MESSAGE_FIELDS,
    });
    setState({ ..._state });
    return isValid;
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    const { _id, keyword, message, languageId } = state;

    const caller = isEdit
      ? updateResponseMessageRequest
      : addResponseMessageRequest;

    caller({
      id: _id,
      payload: isEdit
        ? {
            keyword,
            message,
            languageId,
          }
        : {
            keyword,
            message,
            languageId,
          },
      onSuccess: modalData?.onSuccess,
    });
  };

  const onChange = ({ value, _key }) => {
    setState((_state) => ({
      ..._state,
      [_key]: value,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
    }));
  };

  return (
    <ModalWrapper
      footerButtons={
        !modalData.viewOnly && [
          { title: isEdit ? "Update" : "Save", onClick: onSubmitPress },
        ]
      }
      closeModal={closeModal}
      title={modalData?.title}
      sx={{ width: "50%" }}
      childSx={{ height: "45vh", p: 2, overflow: "auto" }}
    >
      <Grid container rowSpacing={2} spacing={1}>
        {RESPONSE_MESSAGE_FIELDS.map((item) => {
          const { _key } = item;

          if (!_key) return null;
          let disabled = false;
          if (_key === "keyword" || modalData?.viewOnly) {
            disabled = !isKeyword;
          }
          if (_key === "languageId") {
            disabled = isEdit;
            item.extraData = modalData.activeLanguages;
          }

          return (
            <Grid key={_key} item md={12} sm={12} xs={12}>
              <CustomField
                color={"primary"}
                item={item}
                itemData={state}
                errorText={state[getErrorText(_key)]}
                error={state[getErrorKey(_key)]}
                value={state[_key]}
                onChange={onChange}
                disabled={disabled}
              />
            </Grid>
          );
        })}
      </Grid>
    </ModalWrapper>
  );
};
