import {
  CustomField,
  INPUT_TYPES,
  getErrorKey,
  getErrorText,
} from "components";
import CustomDataGrid from "components/DataGrid";
import ModalWrapper from "components/ModalWrapper";
import React, { useEffect, useState } from "react";
import { getProviderListColumns } from "screens/Provider/ProviderList/constants";
import { errorToast, queryString, successToast } from "utils";
import {
  assignBookingRequest,
  getBookingProvidersRequest,
  reassignBookingRequest,
  rescheduleBookingRequest,
} from "./utils";
import JobDurationSelector, { JOB_FREQUENCY_TYPE } from "./JobDurationSelector";
import Header from "components/HeaderV2";
import BasicInfo from "./BasicInfo";
import { getSlotsData } from "./helper";
import { getReasonsListRequest } from "screens/Services/ServiceDetails/Reasons/utils";
import moment from "moment";
import { ConfirmationModal } from "components/ConfirmationModal";
import { REASON_TYPES } from "screens/Services/ServiceDetails/Reasons/constants";

const getStatusData = ({ type }) => {
  switch (type) {
    case "assign":
      return { title: "Assign job to Provider" };
    case "reschedule":
      return { title: "Reschedule this Booking" };
    case "jobAssigned":
      return { title: "Assign job to the other Provider" };
    default:
      return {};
  }
};

export default function AssignModal({
  bookingData = {},
  fetchData,
  isBooking,
  modalData: { type } = {},
  closeModal,
}) {
  const {
    provider,
    _id: bookingId,
    service,
    endTime,
    jobFrequency,
    duration,
  } = bookingData;
  const jobScheduledTime =
    bookingData.jobScheduledTime ||
    moment(new Date(bookingData.jobScheduledDate))
      .utc()
      .set({
        hours: 0,
        minutes: 0,
        seconds: 0,
      })
      .format("DD MMM YYYY hh:mm a");
  const [state, setState] = useState({ providers: [] });
  const [filters, setFilters] = useState({
    bookingId,
    pageNo: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const closeConfirmationModal = () => setShowConfirmationModal(false);
  const [selectedProvider, setSelectedProvider] = useState(provider?._id);
  const [additionalData, setAdditionalData] = useState({
    jobFrequency: jobFrequency || "OT",
    daysCount: duration,
    hours: 1,
    minutes: 0,
    endTime,
  });
  const [rescheduleReasons, setRescheduleReasons] = useState([]);
  const canAddReason = type !== "assign";
  const isReschedule = type === "reschedule";
  const isReassign = type === "jobAssigned";

  useEffect(() => {
    !isReschedule &&
      getBookingProvidersRequest({
        query: queryString(filters),
        onSuccess: ({ totalRecords, response }) => {
          setState((_state) => ({ ..._state, providers: response }));
          setTotalCount(totalRecords);
        },
      });
  }, [filters, isReschedule]);

  useEffect(() => {
    isReschedule &&
      getReasonsListRequest({
        query: queryString({
          reasonApp: isBooking ? "customer" : "provider",
          type: REASON_TYPES.reschedule,
          serviceId: service?.serviceId,
          languageId: 1,
          isActive: true,
        }),
        onSuccess: (data) => {
          setRescheduleReasons(
            data.map((reason) => ({
              label: reason.reason,
              value: reason._id,
            }))
          );
        },
      });
  }, [isReschedule, isBooking, service]);

  const onPageChange = (pNo) =>
    setFilters((_state) => ({ ..._state, pageNo: pNo + 1 }));

  const onPageSizeChange = (pSize) =>
    setFilters((_state) => ({ ..._state, pageSize: pSize }));

  const onSelectionModelChange = (users) => {
    if (users.length > 2) {
      return;
    }
    setSelectedProvider(users.length ? users[1] ?? users[0] : null);
  };

  const validator = () => {
    const { hours, jobFrequency, daysCount, minutes, monthsCount } =
      additionalData;
    if (
      !isReassign &&
      (jobFrequency === "MN"
        ? !monthsCount || !hours
        : jobFrequency === "MD"
        ? !hours || !daysCount
        : !hours && !minutes)
    ) {
      errorToast("Please enter all job duration fields");
      return false;
    }
    if (provider?._id && !additionalData.reason) {
      errorToast("Please enter a valid reason");
      return false;
    }
    if (!selectedProvider) {
      errorToast("Please select a provider");
      return false;
    }
    return true;
  };

  const onSubmitAssign = () => {
    const caller = isReassign ? reassignBookingRequest : assignBookingRequest;

    const payload = {
      bookingId,
      providerId: selectedProvider,
      reason: additionalData.reason,
    };

    if (!isReassign) {
      payload.jobFrequency = additionalData.jobFrequency;
      let slots = getSlotsData({
        ...additionalData,
        date: jobScheduledTime,
      });

      if (
        endTime &&
        slots.length &&
        additionalData.jobFrequency !== JOB_FREQUENCY_TYPE.monthly.value
      ) {
        slots[slots.length - 1].endDate = moment(endTime);
      }

      payload.slots = slots;

      const { jobFrequency, hours, minutes, daysCount, monthsCount } =
        additionalData;

      const totalMinutes = (+hours || 0) * 60 + (+minutes || 0);

      switch (jobFrequency) {
        case "MD":
          payload.duration = daysCount;
          payload.perDayDuration = totalMinutes;
          break;

        case "MN":
          payload.duration = monthsCount;
          payload.perDayDuration = totalMinutes;
          payload.serviceUptoDate = moment(new Date(jobScheduledTime))
            .add(monthsCount, "month")
            .subtract(1, "day")
            .add(totalMinutes, "minutes")
            .toISOString()
            .split("T")[0];
          break;

        default:
          payload.duration = 1;
          payload.perDayDuration = totalMinutes;
          break;
      }
    }

    caller({
      onSuccess: () => {
        successToast(
          `${isReassign ? "New " : ""}Provider assigned successfully!`
        );
        fetchData();
        closeModal();
      },
      payload,
    });
  };

  const onSubmitReschedule = () => {
    if (!additionalData.rescheduledTime) {
      errorToast("Please select the new date and time");
      return;
    }
    if (!additionalData.reason) {
      errorToast("Please enter required reason");
      return;
    }
    // jobScheduledDate - jobScheduledTime - bookingId - reason
    rescheduleBookingRequest({
      payload: {
        bookingId,
        reason: additionalData.reason,
        jobScheduledDate: additionalData.rescheduledTime,
        jobScheduledTime: additionalData.rescheduledTime,
      },
      onSuccess: () => {
        successToast("Booking rescheduled successfully!");
        fetchData();
        closeModal();
      },
    });
  };

  const onSubmit = () => {
    if (isReschedule) {
      onSubmitReschedule();
      return;
    }
    if (!validator()) {
      return;
    }
    onSubmitAssign();
  };

  const onChange = ({ value, _key }) => {
    setAdditionalData((_state) => ({
      ..._state,
      [_key]: value,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
    }));
  };

  const { title } = getStatusData({ type });

  const hideProviderFields = ["services", "status"];

  return (
    <ModalWrapper
      footerText={`Serving Distance (${service.servingRadius / 1000} KM)`}
      title={title}
      sx={{ width: "80%" }}
      closeModal={closeModal}
      footerButtons={[
        {
          title: "Not Now",
          variant: "error",
          sx: { mr: 2 },
          onClick: closeModal,
        },
        {
          title: isReschedule ? "Reschedule" : "Update",
          onClick: () => {
            if (
              state.providers.find((item) => item._id === selectedProvider)
                .distance > service.servingRadius
            ) {
              setShowConfirmationModal(true);
              return;
            }

            onSubmit();
          },
        },
      ]}
    >
      <BasicInfo data={bookingData} />
      {!isReassign && (
        <JobDurationSelector
          jobScheduledTime={jobScheduledTime}
          isReschedule={isReschedule}
          state={additionalData}
          setState={setAdditionalData}
        />
      )}

      {canAddReason && (
        <CustomField
          value={additionalData.reason}
          error={additionalData[getErrorKey("reason")]}
          errorText={additionalData[getErrorText("reason")]}
          onChange={onChange}
          item={{
            _key: "reason",
            inputType: isReschedule ? INPUT_TYPES.DROPDOWN : INPUT_TYPES.INPUT,
            placeholder: "reason",
            extraProps: { sx: { marginBottom: "16px" } },
            extraData: rescheduleReasons,
          }}
        />
      )}

      {!isReschedule && (
        <>
          <Header
            skip
            title="Available Providers"
            style={{ pb: "0px", pt: "16px" }}
          />
          <CustomDataGrid
            sx={{ minHeight: "500px" }}
            rows={state.providers}
            onPageChange={onPageChange}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pageSize={Number(filters?.pageSize || 10)}
            page={(filters?.pageNo || 1) - 1}
            paginationMode="server"
            rowCount={totalCount}
            onPageSizeChange={onPageSizeChange}
            columns={getProviderListColumns({
              target: "_blank",
              hideServices: true,
              jobScheduledTime,
              hideCalendar: false,
              withAvailability: true,
            }).filter(
              (item) =>
                !item.hide &&
                !hideProviderFields.includes(item.headerName.toLowerCase())
            )}
            dataGridProps={{
              checkboxSelection: true,
              disableMultipleRowSelection: true,
              onRowSelectionModelChange: (newRowSelectionModel) => {
                onSelectionModelChange(newRowSelectionModel);
              },
              selectionModel: selectedProvider ? [selectedProvider] : [],
              getRowClassName: (params) => {
                return params.row.distance < service.servingRadius
                  ? "bg-green"
                  : "bg-red";
              },
            }}
          />

          {!!showConfirmationModal && (
            <ConfirmationModal
              modalData={{
                title: "Assign provider (Beyond serving radius)",
                description:
                  "Are you sure you want to assign provider which is beyond serving radius?",
                footerButtons: [
                  {
                    title: "Cancel",
                    onClick: closeConfirmationModal,
                    sx: { mr: 2 },
                    color: "error",
                    variant: "outlined",
                  },
                  {
                    title: "Yes, Assign",
                    color: "error",
                    isRequired: true,
                    onClick: onSubmit,
                  },
                ],
              }}
              closeModal={closeConfirmationModal}
              color={"error"}
            />
          )}
        </>
      )}
    </ModalWrapper>
  );
}
