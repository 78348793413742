import { Visibility } from "@mui/icons-material";
import { IconButton, Switch } from "@mui/material";
import { INPUT_TYPES } from "components";
import moment from "moment";
import { customSortComparatorForNums } from "utils";

export const getTermsColumns = ({
  onViewPress,
  handleStatusChange,
  availableLanguages,
}) => [
  {
    field: "id",
    headerName: "S.No",
    width: 88,
    sortable: true,
    sortComparator: customSortComparatorForNums,
  },
  {
    field: "terms",
    headerName: "Terms",
    width: 200,
  },
  {
    field: "remarks",
    headerName: "Remarks",
    width: 200,
  },
  {
    field: "version",
    headerName: "Version",
    width: 150,
  },
  {
    field: "languageId",
    headerName: "Language",
    width: 200,
    renderCell: ({ row }) =>
      availableLanguages?.filter(
        ({ languageId }) => +row?.languageId === languageId
      )[0]?.languageName,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    width: 200,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 200,
    renderCell: ({ row }) =>
      moment(new Date(row.createdAt)).format("DD MMM YYYY, hh:mm A"),
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    width: 200,
    renderCell: ({ row }) =>
      moment(new Date(row.updatedAt)).format("DD MMM YYYY, hh:mm A"),
  },
  {
    field: "isActive",
    headerName: "Active",
    width: 80,
    renderCell: ({ row }) => {
      return (
        <Switch
          checked={row?.isActive}
          onChange={() => handleStatusChange(row)}
        />
      );
    },
  },
  {
    field: "view",
    headerName: "View",
    width: 100,
    renderCell: ({ row }) => {
      const onView = (e) => {
        e.stopPropagation();
        onViewPress(row);
      };

      return (
        <>
          <IconButton onClick={onView}>
            <Visibility color="primary" />
          </IconButton>
        </>
      );
    },
  },
];

export const getPrivacyColumns = ({
  onViewPress,
  handleStatusChange,
  availableLanguages,
}) => [
  {
    field: "id",
    headerName: "S.No",
    width: 88,
    sortable: true,
    sortComparator: customSortComparatorForNums,
  },
  {
    field: "policy",
    headerName: "Policy",
    width: 200,
  },
  {
    field: "remarks",
    headerName: "Remarks",
    width: 200,
  },
  {
    field: "version",
    headerName: "Version",
    width: 150,
  },
  {
    field: "languageId",
    headerName: "Language",
    width: 200,
    renderCell: ({ row }) =>
      availableLanguages?.filter(
        ({ languageId }) => +row?.languageId === languageId
      )[0]?.languageName,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    width: 200,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 200,
    renderCell: ({ row }) =>
      moment(new Date(row.createdAt)).format("DD MMM YYYY, hh:mm A"),
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    width: 200,
    renderCell: ({ row }) =>
      moment(new Date(row.updatedAt)).format("DD MMM YYYY, hh:mm A"),
  },
  {
    field: "isActive",
    headerName: "Active",
    width: 80,
    renderCell: ({ row }) => {
      return (
        <Switch
          checked={row?.isActive}
          onChange={() => handleStatusChange(row)}
        />
      );
    },
  },
  {
    field: "view",
    headerName: "View",
    width: 100,
    renderCell: ({ row }) => {
      const onView = (e) => {
        e.stopPropagation();
        onViewPress(row);
      };

      return (
        <>
          <IconButton onClick={onView}>
            <Visibility color="primary" />
          </IconButton>
        </>
      );
    },
  },
];

export const getAboutColumns = ({
  onViewPress,
  handleStatusChange,
  availableLanguages,
}) => [
  {
    field: "id",
    headerName: "S.No",
    width: 88,
    sortable: true,
    sortComparator: customSortComparatorForNums,
  },
  {
    field: "about",
    headerName: "About",
    width: 200,
  },
  {
    field: "remarks",
    headerName: "Remarks",
    width: 200,
  },
  {
    field: "version",
    headerName: "Version",
    width: 150,
  },
  {
    field: "languageId",
    headerName: "Language",
    width: 200,
    renderCell: ({ row }) =>
      availableLanguages?.filter(
        ({ languageId }) => +row?.languageId === languageId
      )[0]?.languageName,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    width: 200,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 200,
    renderCell: ({ row }) =>
      moment(new Date(row.createdAt)).format("DD MMM YYYY, hh:mm A"),
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    width: 200,
    renderCell: ({ row }) =>
      moment(new Date(row.updatedAt)).format("DD MMM YYYY, hh:mm A"),
  },
  {
    field: "isActive",
    hheaderName: "Active",
    width: 80,
    renderCell: ({ row }) => {
      return (
        <Switch
          checked={row?.isActive}
          onChange={() => handleStatusChange(row)}
        />
      );
    },
  },
  {
    field: "view",
    headerName: "View",
    width: 100,
    renderCell: ({ row }) => {
      const onView = (e) => {
        e.stopPropagation();
        onViewPress(row);
      };

      return (
        <>
          <IconButton onClick={onView}>
            <Visibility color="primary" />
          </IconButton>
        </>
      );
    },
  },
];

export const TERMS_FIELDS = [
  {
    inputType: INPUT_TYPES.TEXT_AREA,
    _key: "terms",
    placeholder: "Terms And Conditions",
    md: 12,
    extraProps: { minRows: 3 },
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "remarks",
    placeholder: "Remarks",
    md: 12,
  },
];

export const PRIVACY_FIELDS = [
  {
    inputType: INPUT_TYPES.TEXT_AREA,
    _key: "policy",
    placeholder: "Privacy And Policy",
    md: 12,
    extraProps: { minRows: 3 },
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "remarks",
    placeholder: "Remarks",
    md: 12,
  },
];

export const ABOUT_FIELDS = [
  {
    inputType: INPUT_TYPES.TEXT_AREA,
    _key: "about",
    placeholder: "About Us",
    md: 12,
    extraProps: { minRows: 3 },
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "remarks",
    placeholder: "Remarks",
    md: 12,
  },
];

export const CANCELLATION_POLICY_FIELDS = [
  {
    inputType: INPUT_TYPES.TEXT_AREA,
    _key: "policy",
    placeholder: "Cancellation Policy",
    md: 12,
    extraProps: { minRows: 3 },
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "remarks",
    placeholder: "Remarks",
    md: 12,
  },
];

export const REFUND_POLICY_FIELDS = [
  {
    inputType: INPUT_TYPES.TEXT_AREA,
    _key: "policy",
    placeholder: "Refund Policy",
    md: 12,
    extraProps: { minRows: 3 },
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "remarks",
    placeholder: "Remarks",
    md: 12,
  },
];
