import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import Header from "components/HeaderV2";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CS_TICKET_FIELDS,
  CS_TICKET_STATUSES,
  CS_TICKET_USERS,
} from "./constants";
import moment from "moment";
import { Send } from "@mui/icons-material";
import {
  addCommentRequest,
  changeAssigneeRequest,
  changeTicketStatusRequest,
  getCommentsByTicketId,
  getTicketInfoById,
} from "./utils";
import { queryString } from "utils";
import { CustomField, INPUT_TYPES } from "components";
import { getDashboardUsersRequest } from "screens/Users/utils";

const DROPDOWNS = [
  {
    _key: "status",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Ticket Status",
    extraData: CS_TICKET_STATUSES,
  },
  {
    _key: "assignedToId",
    inputType: INPUT_TYPES.FILTER_OPTIONS,
    placeholder: "Assigned To",
    isSingle: true,
  },
];

export default function CsTicketDetails() {
  const [ticketDetails, setTicketDetails] = useState({});
  const [comments, setComments] = useState([]);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const { id } = useParams();

  const onChange = ({ _key, value }) => {
    setTicketDetails((_state) => ({ ..._state, [_key]: value }));
    if (_key === "status") {
      changeTicketStatusRequest({
        ticketId: id,
        payload: { status: value },
      });
    }

    if (_key === "assignedToId") {
      changeAssigneeRequest({
        ticketId: id,
        payload: { assignedToId: value.value },
      });
    }
  };

  const fetchTicketDetails = useCallback(() => {
    id &&
      users.length &&
      getTicketInfoById({
        query: queryString({ ticketId: id }),
        onSuccess: (data) =>
          setTicketDetails({
            ...data,
            assignedToId: data.assignee
              ? {
                  label: data?.assignee?.name,
                  value: data?.assignee?._id,
                }
              : "",
          }),
      });
  }, [id, users]);

  const onAddComment = () => {
    if (!message) return;
    setIsLoading(true);
    addCommentRequest({
      payload: {
        ticketId: ticketDetails._id,
        comment: message,
      },
      setIsLoading,
      onSuccess: (data) => {
        setMessage("");
        setComments((_state) => [..._state, data]);
      },
    });
  };

  useEffect(() => {
    getDashboardUsersRequest({
      onSuccess: (data) =>
        setUsers(
          data
            .filter((user) => !!user.isActive)
            .map((user) => ({
              label: `${user.firstName} ${user.lastName}`,
              value: user._id,
            }))
        ),
    });
  }, []);

  useEffect(() => {
    if (id) {
      fetchTicketDetails();
      getCommentsByTicketId({
        query: queryString({ ticketId: id }),
        onSuccess: (data) => {
          setComments(data.items);
        },
      });
    }
  }, [id, fetchTicketDetails]);

  useEffect(() => {
    const commentContainer = document.getElementById("commentContainer");

    commentContainer.scrollTop = commentContainer.scrollHeight;
  }, [comments]);

  return (
    <Box sx={{ my: 2 }}>
      <Card sx={{ height: "100%" }}>
        <CardContent sx={{ height: "100%" }}>
          <Grid
            container
            rowGap={2}
            spacing={2}
            justifyContent={"flex-end"}
            sx={{ mb: 2 }}
          >
            {DROPDOWNS.map((item) => {
              const { _key } = item;

              if (!_key) return null;

              if (_key === "assignedToId") {
                item.extraData = users;
              }

              return (
                <Grid key={_key} item md={3} sm={3} xs={3}>
                  <CustomField
                    color={"primary"}
                    item={item}
                    value={ticketDetails[_key]}
                    onChange={onChange}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid container rowGap={2} sx={{ height: "100%" }}>
            <Grid
              item
              md={7}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Header
                title={`Support Ticket #${ticketDetails._id}`}
                variant="h5"
                titleStyle={{ fontWeight: "medium" }}
              />
              {CS_TICKET_FIELDS.map((item) => {
                const { _key, placeholder } = item;

                if (!_key) return null;

                return (
                  ticketDetails[_key] && (
                    <TicketDetailItem
                      key={_key}
                      data={{
                        _key,
                        label: placeholder,
                        value:
                          _key === "createdAt"
                            ? moment(ticketDetails[_key]).format(
                                "DD MMM YYYY hh:mm a"
                              )
                            : ticketDetails[_key],
                        ticketDetails: ticketDetails,
                      }}
                    />
                  )
                );
              })}
            </Grid>

            <Grid
              item
              md={5}
              sm={12}
              xs={12}
              sx={{
                background: "#8080801f",
                borderRadius: 3,
                height: window.innerHeight - 180,
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 1.5,
              }}
            >
              <Typography
                id="commentContainer"
                component={"div"}
                sx={{
                  height: "95%",
                  overflowY: "auto",
                  px: 1.5,
                }}
                className="hide-scrollbar"
              >
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  {comments.map((comment, index) => {
                    return (
                      <CommentItem
                        key={comment._id}
                        comment={comment}
                        prevCommentDate={
                          index > 0 && comments[index - 1].createdAt
                        }
                      />
                    );
                  })}
                </Typography>
              </Typography>

              <Typography
                component={"form"}
                onSubmit={(e) => {
                  e.preventDefault();
                  onAddComment();
                }}
                sx={{
                  mx: 1.5,
                  mb: 1,
                  background: "white",
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  name="comment"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    background: "white",
                    outline: "none",
                    border: "none",
                    height: "30px",
                    borderRadius: 5,
                    padding: "20px 10px",
                  }}
                  placeholder="enter your comment"
                />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    minWidth: "0px !important",
                    width: "50px !important",
                    height: 50,
                    borderRadius: 0,
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                  }}
                  type="submit"
                >
                  {!isLoading ? (
                    <Send color={"white"} />
                  ) : (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress
                        size={20}
                        variant="indeterminate"
                        color="white"
                      />
                    </Box>
                  )}
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

const TicketDetailItem = ({ data }) => {
  const { _key, label, value, ticketDetails } = data;

  const navigate = useNavigate();

  const isLink =
    (_key === "createdByType" ||
      _key === "raisedFor" ||
      _key === "relatedToType") &&
    value !== CS_TICKET_USERS.ADMIN;

  return (
    <Typography>
      <Typography
        component={"span"}
        sx={{ fontSize: "16px", fontWeight: "medium" }}
      >
        {label}:{" "}
      </Typography>
      <Typography
        component={"span"}
        className={isLink ? "hover-underline" : ""}
        sx={{
          cursor: isLink ? "pointer" : "default",

          fontSize: "14px",
        }}
        onClick={() => {
          if (!isLink) return;

          if (_key === "relatedToType") {
            navigate(`/${value}s/${ticketDetails["relatedToId"]}`);
            return;
          }

          navigate(
            `/${value}-details/${
              ticketDetails[
                _key === "createdByType" ? "createdById" : "raisedForId"
              ]
            }`
          );
        }}
      >
        {value}
      </Typography>
    </Typography>
  );
};

const CommentItem = ({ comment, prevCommentDate }) => {
  const isCurrentUser = comment.commentBy === "admin";
  const bgColor = isCurrentUser ? "#d9fdd2" : "#fff";
  const textColor = "#000";
  //   const bgColor = isCurrentUser ? "#a5d6a7" : "#075985";
  const currDate = moment(comment.createdAt.split("T")[0]);
  const prevDate = prevCommentDate && moment(prevCommentDate.split("T")[0]);

  return (
    <Typography component={"div"} sx={{ mt: !!prevDate ? 1.5 : 0 }}>
      {(!prevDate || !areSameDates(currDate, prevDate)) && (
        <CommentDate createdAt={comment.createdAt} />
      )}

      <Typography component={"div"} sx={{ fontSize: "14px", width: "100%" }}>
        <Typography
          component={"div"}
          sx={{
            width: "100%",
            display: "flex",
            position: "relative",
            justifyContent: isCurrentUser ? "flex-end" : "flex-start",
          }}
        >
          <span
            style={{
              width: 0,
              height: 0,
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              borderBottom: `20px solid ${bgColor}`,
              position: "absolute",
              top: 0,
              left: isCurrentUser ? undefined : -5,
              right: isCurrentUser ? -5 : undefined,
              rotate: "180deg",
            }}
          ></span>
          <Typography
            sx={{
              display: "flex",
              flexDirection: "column",
              background: bgColor,
              p: 1,
              borderRadius: 2,
              minWidth: "150px",
              width: "fit-content",
              height: "50px",
              position: "relative",
            }}
          >
            <span style={{ fontSize: "15px", color: textColor }}>
              {comment.comment}
            </span>
            <span
              style={{
                position: "absolute",
                right: 6,
                bottom: 3,
                fontSize: "10px",
                color: textColor,
              }}
            >
              {moment(comment.createdAt).format("hh:mm a")}
            </span>
          </Typography>
        </Typography>
      </Typography>
    </Typography>
  );
};

const CommentDate = ({ createdAt }) => {
  return (
    <Typography
      sx={{
        color: "gray",
        p: 1,
        borderRadius: 5,
        mx: "auto",
        width: "fit-content",
        fontSize: "12px",
        fontWeight: "medium",
      }}
    >
      {moment(createdAt).format("DD MMM YYYY")}
    </Typography>
  );
};

const areSameDates = (date1, date2) => {
  if (!date1 || !date2) {
    return false;
  }

  return !date1.isAfter(date2) && !date1.isBefore(date2);
};
