import { INPUT_TYPES, REGEX } from "components";

export const LOGIN_FIELDS = [
  {
    _key: "email",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Email",
    regex: REGEX.EMAIL,
  },
  {
    _key: "password",
    inputType: INPUT_TYPES.PASSWORD,
    placeholder: "Password",
  },
];
