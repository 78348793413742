import { shallowEqual, useSelector } from "react-redux";

export const useGetLangData = ({ useAll = false, data = [] }) => {
  const allLanguages = useSelector(({ common }) => common.allLanguages) || [];
  const categoryLanguage =
    useSelector(
      ({ categories }) => categories.categoryLanguage,
      shallowEqual
    ) || [];

  const languages = allLanguages.filter((item) =>
    categoryLanguage.includes(item.languageId)
  );

  const availableLanguages = (useAll ? allLanguages : languages)
    .filter((item) =>
      data.find((_node) => _node.categoryLanguage === item.languageId)
    )
    .map((lang) => ({
      ...lang,
      label: lang.languageName,
      value: lang.languageId,
    }));

  const unavailableLanguages = (useAll ? allLanguages : languages)
    .filter(
      (item) =>
        !data.find((_node) => _node.categoryLanguage === item.languageId) &&
        item.isActive
    )
    .map((lang) => ({
      ...lang,
      label: lang.languageName,
      value: lang.languageId,
    }));

  if (!availableLanguages.length) {
    const defaultLang = allLanguages[0] || {};
    availableLanguages.push({
      ...defaultLang,
      label: defaultLang.languageName,
      value: defaultLang.languageId,
    });
  }
  return {
    availableLanguages,
    defaultLanguageId: availableLanguages[0]?.languageId,
    unavailableLanguages,
    allLanguages: allLanguages
      .filter((item) => item.isActive)
      .map((item) => ({
        label: item.languageName,
        value: item.languageId,
      })),
  };
};

export const useGetCategoryData = () => {
  const categoryInfo =
    useSelector(({ categories }) => categories.categoryInfo) || [];
  const { defaultLanguageId, availableLanguages, unavailableLanguages } =
    useGetLangData({ useAll: true, data: categoryInfo });

  return {
    defaultLanguageId,
    availableLanguages,
    unavailableLanguages,
    categoryInfo,
  };
};
