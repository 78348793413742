import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

export const getWeek = (date = new Date()) => {
  const weekFirstDate = date.getDate() - date.getDay();
  return [...new Array(7)].map((_, index) =>
    moment
      .utc(date)
      .date(weekFirstDate)
      .add(index, "days")
      .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
  );
};

const sortSlotsAccordingToDate = ({ slots, startDate }) => {
  const slotsMap = {};

  slots
    .filter((item) => item.startDate !== item.endDate)
    .forEach((slot) => {
      const date = new Date(slot.startDate).getDate();
      if (!slotsMap[date]) {
        slotsMap[date] = [];
      }
      slotsMap[date].push({
        ...slot,
        duration: moment(new Date(slot.endDate)).diff(
          new Date(slot.startDate),
          "minutes"
        ),
      });
    });
  return getWeek(new Date(startDate)).map((item) => {
    return (slotsMap[new Date(item).getDate()] || []).sort(
      (a, b) => b.duration - a.duration
    );
  });
};

const sortSingleDaySlots = (slots = []) => {
  let slotsCopy = cloneDeep(slots);
  const finalSlots = [];
  while (slotsCopy.length) {
    const { startDate, endDate } = slotsCopy[0];
    finalSlots.push(slotsCopy[0]);
    slotsCopy = slotsCopy.slice(1);
    let index = 0;
    while (index < slotsCopy.length) {
      const { startDate: sDate, endDate: eDate } = slotsCopy[index];
      if (sDate >= startDate && endDate >= eDate) {
        if (!finalSlots[finalSlots.length - 1].child) {
          finalSlots[finalSlots.length - 1].child = [];
        }
        finalSlots[finalSlots.length - 1].child.push(slotsCopy[index]);
        slotsCopy = [
          ...slotsCopy.slice(0, index),
          ...slotsCopy.slice(index + 1),
        ];
      } else {
        index += 1;
      }
    }
  }
  return finalSlots;
};

export const formatSlotsData = ({ slots = [], startDate }) => {
  const sortedSlots = sortSlotsAccordingToDate({ slots, startDate });
  const finalResults = sortedSlots.map(sortSingleDaySlots);
  return finalResults;
};
