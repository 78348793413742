import React, { useEffect, useState } from "react";
import RenderNodeItems from "./components/RenderNodesItems";
import "./index.css";
import { NodeModal } from "./components/NodeModal";
import {
  fetchServiceNodesData,
  updateServiceNodePositionRequest,
} from "./utils";
import { AddLanguageModal } from "./components/AddLanguageModal";
import Header from "../../../../components/HeaderV2";
import { useGetNodesData, useGetServiceData } from "../hooks/useLanguagesData";
import { Typography } from "@mui/material";

const ServiceNode = ({ serviceId }) => {
  const [modalData, setModalData] = useState(null);
  const {
    defaultLanguageId,
    availableLanguages,
    serviceNodes,
    unavailableLanguages,
  } = useGetNodesData();
  const serviceConfig = useGetServiceData().serviceInfo[0]?.serviceConfig;
  const [showAddLanguageModal, toggleAddLanguageModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const { nodes: data = [] } =
    serviceNodes.find((item) => item.languageId === selectedLanguage) || {};
  const itemsLength = data.length;

  useEffect(() => {
    fetchServiceNodesData(serviceId);
  }, [serviceId]);

  useEffect(() => {
    defaultLanguageId && setSelectedLanguage(defaultLanguageId);
  }, [defaultLanguageId]);

  const onAddRootNode = () => setModalData({ title: "Add Root Node" });

  const closeModal = () => setModalData(null);
  const closeAddLanguageModal = () => toggleAddLanguageModal(false);
  const onAddLanguageModal = () => toggleAddLanguageModal(true);

  const onMoveCallback = ({ withItem, ...rest }) => {
    updateServiceNodePositionRequest({
      serviceId,
      data: rest,
      sibling: data[withItem],
    });
  };

  return (
    <Typography
      sx={{
        overflow: "auto",
        margin: "-16px",
        padding: "16px",
      }}
    >
      <Header
        title="Nodes"
        skip
        dropDown={{
          value: selectedLanguage,
          onChange: setSelectedLanguage,
          data: availableLanguages,
        }}
        buttons={[
          { onClick: onAddRootNode, title: "Add Root Node" },
          {
            disabled: !serviceNodes.length || !unavailableLanguages.length,
            onClick: onAddLanguageModal,
            title: "Add Language",
          },
        ]}
      />

      {!!modalData && (
        <NodeModal
          modalData={modalData}
          serviceId={serviceId}
          isNode={false}
          availableLanguages={availableLanguages}
          closeModal={closeModal}
          isRootNode={true}
          hierLevel={0}
        />
      )}
      {!!showAddLanguageModal && (
        <AddLanguageModal
          serviceId={serviceId}
          data={data}
          isRootNode={true}
          unavailableLanguages={unavailableLanguages}
          closeModal={closeAddLanguageModal}
        />
      )}
      {data.map((data, index) => (
        <RenderNodeItems
          itemsLength={itemsLength}
          index={index}
          disabled
          onMoveCallback={onMoveCallback}
          availableLanguages={availableLanguages}
          serviceId={serviceId}
          ignoreMargin
          isNode
          data={data}
          isRootNode={true}
          key={data._id}
          serviceConfig={serviceConfig}
        />
      ))}
    </Typography>
  );
};

export default ServiceNode;
