import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import { fetchUserSpecificPayments } from "../utils";
import CustomDataGrid from "components/DataGrid";
import { getPaymentsColumns } from "../constants";
import { queryString } from "utils";
import { useNavigate } from "react-router-dom";

export default function RenderUserSpecificPayments({ customerId, bookingId }) {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({ pageNo: 1, pageSize: 10 });
  const [totalCount, setTotalCount] = useState(1);
  const navigate = useNavigate();

  const fetchPayments = ({ query }) => {
    fetchUserSpecificPayments({
      query,
      onSuccess: (data) => {
        setData(
          data.vouchers?.map((voucher) => ({
            ...voucher,
            ...voucher?.payments[0],
            id: voucher._id,
          }))
        );
        setTotalCount(data.totalRecords);
      },
    });
  };

  useEffect(() => {
    if (customerId) {
      fetchPayments({
        query: queryString({
          ...filters,
          customerId: customerId,
          bookingId: bookingId,
        }),
      });
    }
  }, [customerId, filters, bookingId]);

  const onPageChange = (pNo) =>
    setFilters((_filters) => ({ ..._filters, pageNo: pNo + 1 }));

  const onPageSizeChange = (pSize) =>
    setFilters((_filters) => ({ ..._filters, pageSize: pSize }));

  const onViewPress = (row) => {
    navigate(`/payment/${row._id}`, {
      state: { data: row },
    });
  };

  return (
    <div>
      <Header skip title="Payments" />
      <CustomDataGrid
        rows={data}
        onPageChange={onPageChange}
        rowsPerPageOptions={[10, 25, 50, 100]}
        pageSize={Number(filters?.pageSize || 10)}
        page={(filters?.pageNo || 1) - 1}
        paginationMode="server"
        rowCount={totalCount}
        onPageSizeChange={onPageSizeChange}
        columns={getPaymentsColumns({ onViewPress })}
      />
    </div>
  );
}
