import React, { useState, useEffect } from "react";
import { Button, Card, Grid, Typography } from "@mui/material";

import { useParams } from "react-router-dom";
import { CATEGORY_FIELDS } from "./constants";
import { CustomField, getErrorKey, getErrorText } from "components";
import { getCategoryById, updateCategoryRequest } from "./utils";
import { validateFields } from "utils";
import Header from "components/HeaderV2";
import { useGetCategoryData } from "./hooks/useLanguageData";
import { AddLanguageModal } from "./components/AddLanguageModal";

export const UpdateCategory = () => {
  const { id } = useParams();
  const {
    defaultLanguageId,
    availableLanguages,
    unavailableLanguages,
    categoryInfo,
  } = useGetCategoryData();

  const [state, setState] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [showModal, setShowModal] = useState(false);

  const validateData = () => {
    let isValidData = true;
    const { isValid, _state } = validateFields({
      _state: state,
      fields: CATEGORY_FIELDS,
    });
    setState({ ..._state });

    if (!isValid) {
      isValidData = false;
    }

    return isValidData;
  };

  const onChange = ({ _key, value }) => {
    setState((_state) => ({
      ...state,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
      [_key]: value,
    }));
  };

  const onAddLanguagePress = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    const {
      categoryName,
      categoryLanguage,
      categoryId,
      description,
      wcsShortDescription,
    } = state;

    updateCategoryRequest({
      payload: { categoryName, description, wcsShortDescription },
      categoryId: categoryId,
      languageId: categoryLanguage,
      onSuccess: () => getCategoryById({ id }),
    });
  };

  useEffect(() => {
    defaultLanguageId && setSelectedLanguage(defaultLanguageId);
  }, [defaultLanguageId]);

  useEffect(() => {
    if (categoryInfo.length) {
      const data =
        categoryInfo.find(
          (item) => item.categoryLanguage === selectedLanguage
        ) || {};
      setState(data);
    }
  }, [categoryInfo, selectedLanguage]);

  useEffect(() => {
    if (id) {
      getCategoryById({ id });
    }
  }, [id]);

  return (
    <>
      <Card
        sx={{
          mt: "12px",
          py: 3,
          px: 2,
          rowGap: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header
          title="Edit Category"
          dropDown={{
            value: selectedLanguage,
            onChange: setSelectedLanguage,
            data: availableLanguages,
          }}
          buttons={
            id
              ? [
                  {
                    disabled: !unavailableLanguages.length,
                    title: "Add Language",
                    onClick: onAddLanguagePress,
                  },
                ]
              : []
          }
        />

        <Grid container rowSpacing={2} spacing={1}>
          {CATEGORY_FIELDS.map((category) => {
            const { _key, md } = category;

            if (!_key) return null;

            return (
              <Grid key={_key} item md={md ? md : 4} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={category}
                  itemData={state}
                  errorText={state[getErrorText(_key)]}
                  error={state[getErrorKey(_key)]}
                  value={state[_key]}
                  onChange={onChange}
                />
              </Grid>
            );
          })}
        </Grid>
        <Typography
          component={"div"}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <Button
            variant="contained"
            sx={{ px: "20px" }}
            onClick={onSubmitPress}
          >
            Update
          </Button>
        </Typography>
      </Card>

      {!!showModal && (
        <AddLanguageModal
          categoryId={id}
          closeModal={closeModal}
          unavailableLanguages={unavailableLanguages}
        />
      )}
    </>
  );
};
