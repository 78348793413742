import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { COMMUNICATION_SERVICES } from "utils";

export const fetchTemplatesRequest = ({ onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  COMMUNICATION_SERVICES.FETCH_TEMPLATE_LIST()
    .then(({ data = [], isSuccess }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createTemplatesRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  COMMUNICATION_SERVICES.CREATE_NEW_TEMPLATE(payload)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateTemplatesRequest = ({ id, payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  COMMUNICATION_SERVICES.UPDATE_TEMPLATE({ payload, id })
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getTemplateByIdRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  COMMUNICATION_SERVICES.FETCH_TEMPLATE_BY_ID(id)
    .then(({ data = [], isSuccess }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

/**
 *
 * BROADCAST SERVICES
 */

export const fetchBroadcastsRequest = ({ onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  COMMUNICATION_SERVICES.FETCH_BROADCAST_LIST()
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createBroadcastRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  COMMUNICATION_SERVICES.CREATE_NEW_BROADCAST(payload)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getBroadcastByIdRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  COMMUNICATION_SERVICES.FETCH_BROADCAST_BY_ID(id)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
