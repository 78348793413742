import { ROLE_TYPES, USER_PRIVILEGE_TYPES } from "shared/constants";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { ListAltOutlined, Search } from "@mui/icons-material";

const PROVIDER_USER_SEARCH = {
  href: "/provider/support/search",
  icon: <Search fontSize="small" />,
  title: "Search Provider",
};

const PROVDIER_TICKETS = {
  href: "/provider/support/tickets",
  icon: <ListAltOutlined fontSize="small" />,
  title: "Tickets",
};

const CUSTOMER_USER_SEARCH = {
  href: "/customer/support/search",
  icon: <Search fontSize="small" />,
  title: "Search Customer",
};

const CUSTOMER_TICKETS = {
  href: "/customer/support/tickets",
  icon: <ListAltOutlined fontSize="small" />,
  title: "Tickets",
};

const SUPPORT_PROVIDER_ROUTES = [PROVIDER_USER_SEARCH, PROVDIER_TICKETS];
const SUPPORT_SERVICE_ROUTES = [CUSTOMER_USER_SEARCH, CUSTOMER_TICKETS];

const SUPPORT_BOTH_ENABLED = {
  [ROLE_TYPES["country-support-admin"].value]: true,
  // [ROLE_TYPES["state-support-admin"].value]: true,
  // [ROLE_TYPES["district-support-admin"].value]: true,
};

export const SUPPORT_ROUTES = [
  {
    icon: <AccountTreeIcon fontSize="small" />,
    title: "Support",
    enabled: { [ROLE_TYPES["super-admin"].value]: true },
    child: [
      {
        icon: <AccountTreeIcon fontSize="small" />,
        title: "Provider",
        child: SUPPORT_PROVIDER_ROUTES,
      },
      {
        icon: <AccountTreeIcon fontSize="small" />,
        title: "Customer",
        child: SUPPORT_SERVICE_ROUTES,
      },
    ],
  },
  {
    enabled: SUPPORT_BOTH_ENABLED,
    icon: <AccountTreeIcon fontSize="small" />,
    checkPrivilege: { [USER_PRIVILEGE_TYPES["both"].value]: true },
    title: "Provider",
    child: SUPPORT_PROVIDER_ROUTES,
  },
  {
    enabled: SUPPORT_BOTH_ENABLED,
    icon: <AccountTreeIcon fontSize="small" />,
    checkPrivilege: { [USER_PRIVILEGE_TYPES["both"].value]: true },
    title: "Customer",
    child: SUPPORT_SERVICE_ROUTES,
  },
  {
    enabled: SUPPORT_BOTH_ENABLED,
    checkPrivilege: { [USER_PRIVILEGE_TYPES["providers"].value]: true },
    ...PROVIDER_USER_SEARCH,
  },
  {
    enabled: SUPPORT_BOTH_ENABLED,
    checkPrivilege: { [USER_PRIVILEGE_TYPES["providers"].value]: true },
    ...PROVDIER_TICKETS,
  },
  {
    enabled: SUPPORT_BOTH_ENABLED,
    checkPrivilege: { [USER_PRIVILEGE_TYPES["services"].value]: true },
    ...CUSTOMER_USER_SEARCH,
  },
  {
    enabled: SUPPORT_BOTH_ENABLED,
    checkPrivilege: { [USER_PRIVILEGE_TYPES["services"].value]: true },
    ...CUSTOMER_TICKETS,
  },
];
