import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomFilter from "components/CustomFilter";
import { queryString, validateFields } from "utils";
import qs from "query-string";
import CustomDataGrid from "components/DataGrid";
import { JOB_FILTERS, getJobsListColumns } from "screens/Jobs/constants";
import { getJobsRequest } from "screens/Jobs/utils";
import { CustomField, getErrorKey, getErrorText } from "components";
import { SEARCH_FIELDS } from "./constants";
import { CreatePayOrderModal } from "./components/CreatePayOrderModal";

export default function CreatePayOrder() {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState();
  const [search, setSearch] = useState({});
  const navigate = useNavigate();
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [modalData, setModalData] = useState(null);
  const totalAmount = getTotalAmount({
    selectedJobs: data.filter((job) => selectedJobs.includes(job._id)),
  });

  const closeModal = () => setModalData(null);

  const onCreatePayOrder = () => {
    const { firstName, lastName } = data[0]?.provider;
    const _data = {
      fromDate: search.fromDate,
      toDate: search.toDate,
      providerId: filters.providerId,
      providerName: `${firstName} ${lastName}`,
      amount: totalAmount,
      jobs: selectedJobs,
    };

    setModalData(_data);
  };

  const onSuccessCallback = () => {
    closeModal();
    navigate(-1);
  };

  const onSelectionModelChange = (jobs) => {
    setSelectedJobs(jobs);
  };

  const onViewPress = ({ booking }) => {
    navigate("/jobs/" + booking._id);
  };

  const setFiltersData = (_state) => {
    console.log({ _state });
    navigate(`${window.location.pathname}?${queryString(_state)}`, {
      replace: true,
    });
    setFilters(_state);
  };

  const validateData = () => {
    const { isValid, _state } = validateFields({
      _state: search,
      fields: SEARCH_FIELDS,
    });

    setSearch({ ..._state });
    return { isValid };
  };

  const onSearch = () => {
    const { isValid } = validateData();

    if (!isValid) return;

    setFilters({ ...filters, fromDate: null, toDate: null, providerId: null });
    setFiltersData({
      ...filters,
      fromDate: search.fromDate,
      toDate: search.toDate,
      providerId: search.providerId,
    });
  };

  const onChange = ({ _key, value }) => {
    setSearch((_state) => ({
      ..._state,
      [_key]: value,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
    }));
  };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const params = qs.parse(searchParams.toString());
    if (params.status) {
      params.status = params.status
        .split(",")
        .map((value) => ({ label: value, value }));
    }
    setFilters({ ...params });
    setSearch({
      fromDate: params.fromDate,
      toDate: params.toDate,
      providerId: params.providerId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !!filters &&
      filters.fromDate &&
      filters.toDate &&
      getJobsRequest({
        query: queryString({ ...filters, isLimited: false }),
        onSuccess: ({ response }) => {
          setData(response);
        },
      });
  }, [filters]);

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header
            title="Search Jobs"
            style={{ marginBottom: 0 }}
            buttons={[
              {
                title: "Search",
                onClick: onSearch,
                sx: { height: "40px", width: "100px" },
              },
            ]}
          >
            <Typography
              component={"form"}
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
                justifyContent: "space-between",
                flexWrap: { xs: "wrap", sm: "nowrap" },
              }}
              onSubmit={onSearch}
            >
              {SEARCH_FIELDS.map((item) => {
                const { _key } = item;
                if (!_key) return null;

                return (
                  <CustomField
                    item={item}
                    value={search[_key]}
                    error={search[getErrorKey(_key)]}
                    errorText={search[getErrorText(_key)]}
                    onChange={onChange}
                  />
                );
              })}
            </Typography>
          </Header>
          <Divider sx={{ my: 2 }} />
          <CustomFilter
            onChange={setFiltersData}
            filtersData={filters}
            fields={JOB_FILTERS}
          />
          <CustomDataGrid
            rows={data}
            columns={getJobsListColumns({ onViewPress, forPayOrder: true })}
            dataGridProps={{
              checkboxSelection: true,
              onSelectionModelChange: onSelectionModelChange,
              selectionModel: selectedJobs,
            }}
          />
        </CardContent>
      </Card>

      {!!selectedJobs.length && (
        <Card sx={{ mt: 2 }}>
          <CardContent sx={{ p: "10px", pb: `10px !important` }}>
            <Header
              skip
              title={"Total :- ₹" + totalAmount}
              style={{ marginBottom: 0 }}
              buttons={[
                {
                  title: "Create Pay Order",
                  onClick: onCreatePayOrder,
                },
              ]}
            />
          </CardContent>
        </Card>
      )}

      {!!modalData && (
        <CreatePayOrderModal
          data={modalData}
          closeModal={closeModal}
          onSuccessCallback={onSuccessCallback}
        />
      )}
    </Box>
  );
}

const getTotalAmount = ({ selectedJobs }) => {
  let totalAmount = 0;

  console.log({ selectedJobs });
  selectedJobs.forEach(({ earningAmount }) => (totalAmount += +earningAmount));

  return totalAmount;
};
