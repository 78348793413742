import { INPUT_TYPES } from "components";

export const LANGUAGE_FIELDS = [
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "languageName",
    placeholder: "Language Name",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "languageCode",
    placeholder: "Language Code",
  },
];
