import CustomDataGrid from "components/DataGrid";
import React, {
  useCallback,
  useEffect,
  //  useRef,
  useState,
} from "react";
import { getApplicationTagsRequest } from "./utils";
import { getColumns } from "./constants";
import { queryString, useGetLanguageData } from "utils";
import Header from "components/HeaderV2";
import {
  Box,
  Card,
  // Typography
} from "@mui/material";
import { AddEditApplicationTag } from "./components/AddEditApplicationTag";
import { ConfirmationModal } from "components/ConfirmationModal";
import { useParams } from "react-router-dom";

const ApplicationTags = () => {
  const { userType } = useParams();
  const [data, setData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [modalData, setModalData] = useState();
  const [confirmationModalData, setConfirmationModalData] = useState();
  const closeModal = () => setModalData(null);
  const closeConfirmationModal = () => setConfirmationModalData(null);
  // const inputRef = useRef();

  const selectedLangData =
    data.find(({ languageId }) => languageId === selectedLanguage) || {};

  const tags = selectedLangData?.tags || {};

  const { availableLanguages, defaultLanguageId, allLanguages } =
    useGetLanguageData({ data }) || {};

  const onSuccessCallback = () => {
    closeModal();
    getApplicationTags();
  };

  const onEditPress = (row) =>
    setModalData({
      title: "Edit Tag",
      data: { ...row, languageId: selectedLanguage },
      isEdit: true,
      activeLanguages: allLanguages,
    });

  const onAddPress = () =>
    setModalData({
      title: "Add Tag",
      data: null,
      activeLanguages: allLanguages,
    });

  // const onDownloadJSON = () => {
  //   const languagesResponseFile = new Blob([JSON.stringify({ data })], {
  //     type: "application/json",
  //   });
  //   const element = document.createElement("a");
  //   element.href = URL.createObjectURL(languagesResponseFile);
  //   element.download = "languageResponse.json";
  //   document.body.appendChild(element);
  //   element.click();
  // };

  // const onUploadJSON = () => {
  //   inputRef.current.click();

  //   inputRef.current.addEventListener("change", (e) => {
  //     let reader = new FileReader();
  //     reader.onload = (e) => {
  //       const fileData = JSON.parse(e.target.result).data;
  //       const mandatoryKeys = ["languageId", "name", "isDefault", "updatedAt"];
  //       let missingKeys = [];

  //       fileData.forEach((obj) => {
  //         missingKeys = missingKeys.union(obj.getMissingKeys(mandatoryKeys));
  //       });

  //       if (missingKeys.length) {
  //         setConfirmationModalData({
  //           title: "Missing Keys",
  //           description: (
  //             <pre style={{ margin: 0 }}>
  //               {missingKeys.toString().replaceAll(",", "\n")}
  //             </pre>
  //           ),
  //         });
  //         return;
  //       }

  //       const maxTags = Object.keys(
  //         fileData.sort(
  //           (a, b) => Object.keys(b.tags).length - Object.keys(a.tags).length
  //         )[0]?.tags || {}
  //       );

  //       let missingTags = [];

  //       fileData.forEach(({ name, tags }) => {
  //         const missing = tags.getMissingKeys(maxTags);
  //         if (missing.length) {
  //           missingTags.push({
  //             name,
  //             tags: missing,
  //           });
  //         }
  //       });

  //       if (missingTags.length) {
  //         setConfirmationModalData({
  //           title: "Missing Tags in some languages",
  //           description: missingTags.map((missing, index) => {
  //             return (
  //               <div
  //                 key={missing.name}
  //                 style={{
  //                   display: "flex",
  //                   flexDirection: "column",
  //                 }}
  //               >
  //                 {index > 0 && (
  //                   <Typography
  //                     sx={{ borderTop: "1.5px dashed black", my: 1 }}
  //                   ></Typography>
  //                 )}
  //                 <p
  //                   style={{
  //                     margin: 0,
  //                     fontWeight: 500,
  //                     fontSize: "16px",
  //                     textTransform: "capitalize",
  //                   }}
  //                 >
  //                   {missing.name}
  //                 </p>
  //                 <pre style={{ margin: 0, fontSize: "14px" }}>
  //                   {missing.tags.toString().replaceAll(",", "\n")}
  //                 </pre>
  //               </div>
  //             );
  //           }),
  //         });
  //         return;
  //       }

  //       setData(
  //         fileData.map((item) => ({
  //           ...item,
  //           id: item.languageId,
  //         }))
  //       );
  //     };
  //     reader.readAsText(e.target.files[0]);
  //   });
  // };

  const getApplicationTags = useCallback(() => {
    getApplicationTagsRequest({
      query: queryString({ appType: userType }),
      onSuccess: setData,
    });
  }, [userType]);

  useEffect(() => {
    userType && getApplicationTags();
  }, [userType, getApplicationTags]);

  useEffect(() => {
    defaultLanguageId && setSelectedLanguage(defaultLanguageId);
  }, [defaultLanguageId]);

  return (
    <>
      <Box sx={{ my: 2 }}>
        <Card sx={{ px: 2, py: 1 }}>
          <Header
            title="Application Tags"
            dropDown={{
              value: selectedLanguage,
              onChange: setSelectedLanguage,
              data: availableLanguages,
            }}
            buttons={[
              {
                title: "Add Tag",
                onClick: onAddPress,
              },
              // {
              //   title: "Upload JSON",
              //   onClick: onUploadJSON,
              // },
              // {
              //   title: "Download JSON",
              //   onClick: onDownloadJSON,
              // },
            ]}
          />
        </Card>
      </Box>

      <CustomDataGrid
        rows={Object.keys(tags).map((_key, index) => ({
          tagKey: _key,
          tagValue: tags[_key],
          id: index + 1,
        }))}
        columns={getColumns({ availableLanguages, onEditPress })}
      />

      {/* <input ref={inputRef} type="file" accept="*.json" hidden /> */}

      {!!modalData && (
        <AddEditApplicationTag
          closeModal={closeModal}
          modalData={modalData}
          selectedLangData={selectedLangData}
          onSuccessCallback={onSuccessCallback}
        />
      )}

      {!!confirmationModalData && (
        <ConfirmationModal
          closeModal={closeConfirmationModal}
          modalData={confirmationModalData}
        />
      )}
    </>
  );
};

export default ApplicationTags;
