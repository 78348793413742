import { Grid } from "@mui/material";
import { CustomField, INPUT_TYPES } from "components";
import Header from "components/HeaderV2";
import React from "react";
import { JOB_FREQUENCY_TYPE } from "./JobDurationSelector";

const DATA = [
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "bookingId",
    placeholder: "Booking ID",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "service",
    placeholder: "Service",
    getValue: (state) => state.service.name,
  },
  {
    inputType: INPUT_TYPES.DATE_TIME,
    _key: "bookingTime",
    placeholder: "Booking At",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "status",
    placeholder: "Booking Status",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "jobId",
    placeholder: "Job ID",
  },
  {
    inputType: INPUT_TYPES.DATE_TIME,
    _key: "jobScheduledTime",
    placeholder: "Job Scheduled Time",
  },
  {
    inputType: INPUT_TYPES.DATE,
    _key: "jobScheduledDate",
    placeholder: "Job Scheduled Date",
  },
  {
    inputType: INPUT_TYPES.DATE_TIME,
    _key: "endTime",
    placeholder: "End Time",
  },
  {
    inputType: INPUT_TYPES.DATE,
    _key: "endDate",
    placeholder: "End Date",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "jobStatus",
    placeholder: "Job Status",
  },

  {
    inputType: INPUT_TYPES.INPUT,
    _key: "totalAmount",
    placeholder: "Total Amount",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "jobFrequency",
    placeholder: "Job Frequency",
    getValue: (state) =>
      Object.values(JOB_FREQUENCY_TYPE).find(
        (item) => item.value === state.jobFrequency
      )?.label,
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "jobFrequencyCounter",
    placeholder: "Job Frequency Counter",
  },
  {
    inputType: INPUT_TYPES.DATE,
    _key: "serviceFromDate",
    placeholder: "Service From Date",
  },
  {
    inputType: INPUT_TYPES.DATE,
    _key: "serviceUptoDate",
    placeholder: "Service Upto Date",
  },
];

export default function BasicInfo({ data }) {
  return (
    <>
      <Header skip title="Basic Info" style={{ pt: "0px" }} />
      <Grid
        container
        sx={{
          mb: "16px",
          p: "16px 8px",
          borderRadius: "8px",
          backgroundColor: "#f1f1f1",
        }}
        rowGap={2}
      >
        {DATA.map((item) => {
          const value = item.getValue ? item.getValue(data) : data[item._key];
          if (!value) {
            return null;
          }
          return (
            <Grid style={{ padding: "0px 8px" }} item sm={12} xs={12} md={3}>
              <CustomField value={value} item={item} key={item._key} disabled />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
