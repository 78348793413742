import * as React from "react";

import { Box, Tabs, Tab } from "@mui/material";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Navbar = ({ onSelectNav, selectedNav, data = [] }) => {
  const onUpdateScreen = (e, value) => onSelectNav(value);

  return (
    <Box
      sx={{
        borderBottom: 1,
        mt: 2,
        background: "white",
        borderColor: "primary",
      }}
    >
      <Tabs
        value={selectedNav}
        onChange={onUpdateScreen}
        variant="scrollable"
        sx={{ width: "100%" }}
        scrollButtons="auto"
        aria-label="basic tabs example"
      >
        {data.map((item, index) => (
          <Tab
            sx={{ p: "16px" }}
            label={item.title}
            key={index}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
    </Box>
  );
};
