import React, { useCallback, useEffect, useState } from "react";
import ModalWrapper from "components/ModalWrapper";
import { BANNER_FIELDS, BANNER_FIELDS_LANG_SPECIFIC } from "../constants";
import { CustomField, getErrorKey, getErrorText } from "components";
import { Grid, Typography } from "@mui/material";
import {
  addBannerLanguageRequest,
  createBannerRequest,
  updateBannerLanguageRequest,
  updateBannerRequest,
} from "../utils";
import { errorToast, queryString, validateFields } from "utils";
import { getCouponsListRequest } from "screens/Coupons/utils";
import { fetchServices } from "utils/calls";
import {
  getDistrictsRequest,
  getStatesRequest,
} from "screens/Services/ServiceDetails/ServiceLocation/utils";
import { getCategoryList } from "screens/Category/utils";
import { ImagePreviewModal } from "screens/Services/components/ImageUploaderModal";
import moment from "moment/moment";

const initState = ({ languages, data }) => {
  const { bannerLanguageDetail = [] } = data;

  const unavailableLanguages = languages.filter(
    (item) =>
      !bannerLanguageDetail.find(
        ({ languageId }) => +languageId === +item.value
      )
  );

  const _state = unavailableLanguages.map((language) => {
    return { languageId: language.value, languageName: language.label };
  });

  return {
    ...data,
    bannerLanguageDetail: [...bannerLanguageDetail, ..._state],
  };
};

const BANNER_FILE_KEY = "bannerUrlfile";

export const AddEditBannerModal = ({
  closeModal,
  languages,
  selectedLanguage,
  modalData,
  onSuccessCallback,
  fetchBanners,
}) => {
  const [previewUrl, setPreviewUrl] = useState("");
  const { data, isReadOnly } = modalData;
  const isEdit = !!data;
  const [state, setState] = useState(
    initState({ languages, data: data || {} })
  );
  const [dropdownData, setDropdownData] = useState([]);

  const validateData = () => {
    const { isValid, _state } = validateFields({
      _state: state,
      fields: BANNER_FIELDS,
    });
    setState({ ..._state });

    return isValid;
  };

  const validateLangData = () => {
    let isValidData = true;

    const tempState = languages.map((_, index) => {
      const { isValid, _state } = validateFields({
        _state: state["bannerLanguageDetail"][isEdit ? index : 0] || {},
        fields: [
          {
            _key: BANNER_FILE_KEY,
            inputType: "NORMAL_FILE",
          },
        ],
      });

      if (!isValid) {
        isValidData = false;
      }
      return _state;
    });

    setState((_state) => ({ ..._state, bannerLanguageDetail: [...tempState] }));
    return isValidData;
  };

  const onChangeLang = ({
    _key,
    value,
    languageId,
    index,
    target,
    bannerLangauageDetailId,
  }) => {
    let temp = state["bannerLanguageDetail"];
    temp[index] = {
      ...temp[index],
      [_key]: value,
      [BANNER_FILE_KEY]: target.files[0],
      languageId,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
    };
    setState((_state) => ({ ..._state, bannerLanguageDetail: [...temp] }));

    if (bannerLangauageDetailId) {
      return updateBannerLanguageRequest({
        bannerId: state?._id,
        payload: {
          languageId,
          bannerLangauageDetailId,
          [BANNER_FILE_KEY]: target.files[0],
        },
        fileKey: BANNER_FILE_KEY,
        onSuccess: fetchBanners,
      });
    }

    if (isEdit) {
      addBannerLanguageRequest({
        payload: {
          languageId,
          id: state._id,
          [BANNER_FILE_KEY]: target.files[0],
        },
        fileKey: BANNER_FILE_KEY,
        onSuccess: fetchBanners,
      });
    }
  };

  const onChange = ({ _key, value, overRideValues }) => {
    setState((_state) => ({
      ...state,
      ...overRideValues,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
      [_key]: value,
    }));

    if (_key === "state") {
      getDistrictsRequest({
        state: value,
        includeAllOption: true,
        onSuccess: (districts) =>
          setDropdownData((_state) => ({ ..._state, districts })),
      });
    }

    if (_key === "categoryId") {
      fetchServices({
        includeAllOption: true,
        categoryId: value !== "ALL" ? value : undefined,
        onSuccess: (services) =>
          setDropdownData((_state) => ({ ..._state, services })),
      });
    }

    if (
      (_key === "serviceId" && value !== "ALL") ||
      _key === "validFrom" ||
      _key === "validTill"
    ) {
      fetchCoupons({ data: state });
    }
  };

  const onSuccess = () => {
    closeModal();
    onSuccessCallback();
  };

  const onSubmitPress = () => {
    if (!validateData() || !validateLangData()) {
      return;
    }
    if (moment(state["validFrom"]).isAfter(moment(state["validTill"]))) {
      return errorToast("Valid from date must be less than valid till date");
    }

    const caller = !!isEdit ? updateBannerRequest : createBannerRequest;

    const formData = new FormData();

    BANNER_FIELDS.forEach(({ _key, dependentKey }) => {
      if (
        (dependentKey && state[dependentKey]) ||
        !dependentKey
        // &&
        // state[_key]
      ) {
        if (_key === "validFrom" || _key === "validTill") {
          formData.append(
            _key,
            moment(state[_key])
              .set({ hours: 0, minutes: 0, seconds: 0 })
              .format("yyyy-MM-DDTHH:mm:ss.SSS")
          );
        } else {
          formData.append(_key, state[_key]);
        }
      }
    });

    if (!isEdit) {
      formData.append("files", state.bannerLanguageDetail[0].bannerUrlfile);
      formData.append("languageId", selectedLanguage);
    }

    caller({
      payload: formData,
      bannerId: state._id,
      onSuccess: onSuccess,
    });
  };

  const fetchCoupons = useCallback(({ data }) => {
    data["serviceId"] &&
      data["validFrom"] &&
      data["validTill"] &&
      getCouponsListRequest({
        query: queryString({
          fromDate: data["validFrom"],
          toDate: data["validTill"],
          serviceId: data["serviceId"],
        }),
        onSuccess: (data) => {
          setDropdownData((_state) => ({
            ..._state,
            coupons: data.map((item) => ({
              label: item.couponCode,
              value: item._id,
              ...item,
            })),
          }));
        },
      });
  }, []);

  useEffect(() => {
    getCategoryList({
      onSuccess: (data) =>
        setDropdownData((_state) => ({
          ..._state,
          categories: [
            { label: "All", value: "ALL" },
            ...data.map(({ categoryId, categoryName }) => ({
              label: categoryName,
              value: categoryId,
            })),
          ],
        })),
    });

    if (data && data["categoryId"]) {
      fetchServices({
        includeAllOption: true,
        categoryId: data["categoryId"] !== "ALL" && data["categoryId"],
        onSuccess: (services) =>
          setDropdownData((_state) => ({ ..._state, services })),
      });
    }

    if (
      data &&
      data["serviceId"] &&
      data["serviceId"] !== "ALL" &&
      data["validFrom"] &&
      data["validTill"]
    ) {
      fetchCoupons({ data });
    }

    getStatesRequest({
      includeAllOption: true,
      onSuccess: (states) =>
        setDropdownData((_state) => ({ ..._state, states })),
    });

    if (data && data["state"]) {
      getDistrictsRequest({
        state: data["state"],
        includeAllOption: true,
        onSuccess: (districts) =>
          setDropdownData((_state) => ({ ..._state, districts })),
      });
    }
  }, [data, fetchCoupons]);

  return (
    <ModalWrapper
      footerButtons={[
        !!isReadOnly
          ? {
              title: "Close",
              onClick: closeModal,
            }
          : {
              title: isEdit ? "Update" : "Create",
              onClick: onSubmitPress,
            },
      ]}
      title={isEdit ? "Edit Banner" : "Add Banner"}
      closeModal={closeModal}
      sx={{ width: "70%" }}
      childSx={{ height: "70vh", p: 2, overflow: "auto" }}
    >
      <div
        style={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <Grid container rowSpacing={2} spacing={1}>
          {BANNER_FIELDS.map((item) => {
            const { _key, dependentKey, dataKey, hideForParentValue } = item;

            if (!_key) {
              return null;
            }

            if (
              dependentKey &&
              (!state[dependentKey] ||
                (hideForParentValue &&
                  state[dependentKey] === hideForParentValue))
            ) {
              return null;
            }

            let extraData = dropdownData[dataKey] || [];

            if (_key === "couponId" && extraData) {
              if (state.serviceId) {
                extraData = extraData.filter((coupon) =>
                  coupon.service?.includes(state.serviceId)
                );
              }

              if (state["district"]) {
                extraData = extraData.filter(
                  (coupon) => coupon.district === state.district
                );
              } else if (state["state"]) {
                extraData = extraData.filter(
                  (coupon) => coupon.state === state.state
                );
              } else if (state["country"]) {
                extraData = extraData.filter(
                  (coupon) => coupon.country === state.country
                );
              }
            }

            if (_key === "validTill" && state["validFrom"]) {
              item.extraProps = {
                ...(item.extraProps || {}),
                minDate: new Date(state["validFrom"]),
              };
            }

            return (
              <Grid key={_key} item md={6} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={{ extraData: extraData || [], ...item }}
                  errorText={state[getErrorText(_key)]}
                  error={state[getErrorKey(_key)]}
                  value={state[_key]}
                  onChange={onChange}
                  disabled={!!isReadOnly}
                />
              </Grid>
            );
          })}

          {isEdit ? (
            state.bannerLanguageDetail?.map((langItem, index) => {
              return (
                <RenderLangSpecificFields
                  langItem={langItem}
                  index={index}
                  isReadOnly={isReadOnly}
                  onChangeLang={onChangeLang}
                  setPreviewUrl={setPreviewUrl}
                  state={state}
                />
              );
            })
          ) : (
            <RenderLangSpecificFields
              langItem={{ languageId: selectedLanguage }}
              index={0}
              isReadOnly={isReadOnly}
              onChangeLang={onChangeLang}
              setPreviewUrl={setPreviewUrl}
              state={state}
            />
          )}
        </Grid>
      </div>

      {previewUrl && (
        <ImagePreviewModal
          previewUrl={previewUrl}
          closeModal={() => setPreviewUrl("")}
        />
      )}
    </ModalWrapper>
  );
};

const RenderLangSpecificFields = ({
  langItem,
  index,
  isReadOnly,
  state,
  onChangeLang,
  setPreviewUrl,
}) => {
  return (
    <Grid key={langItem.languageId} item md={12} sm={12} xs={12}>
      <Typography sx={{ fontSize: "18px", borderTop: "1px solid", pt: 1 }}>
        {langItem.languageName}
      </Typography>

      <Grid
        container
        rowSpacing={2}
        spacing={1}
        sx={{ mt: 0.5, mb: index > 0 ? 0.5 : 0 }}
      >
        {BANNER_FIELDS_LANG_SPECIFIC.map((item) => {
          const { _key } = item;

          if (!_key) {
            return null;
          }

          const bannerDetail = state.bannerLanguageDetail[index];

          return (
            <>
              {!isReadOnly && (
                <Grid key={_key} item md={12} sm={12} xs={12}>
                  <CustomField
                    color={"primary"}
                    item={item}
                    errorText={
                      bannerDetail &&
                      bannerDetail[getErrorText(BANNER_FILE_KEY)]
                    }
                    error={
                      bannerDetail && bannerDetail[getErrorKey(BANNER_FILE_KEY)]
                    }
                    value={bannerDetail && bannerDetail[_key]}
                    onChange={({ _key, value, target }) =>
                      onChangeLang({
                        _key,
                        value,
                        languageId: langItem.languageId,
                        bannerLangauageDetailId: langItem._id,
                        index,
                        target,
                      })
                    }
                    disabled={!!isReadOnly}
                  />
                </Grid>
              )}
              <RenderFileItem
                file={bannerDetail[BANNER_FILE_KEY]}
                setPreviewUrl={setPreviewUrl}
              />
            </>
          );
        })}
      </Grid>
    </Grid>
  );
};

const RenderFileItem = ({ file, setPreviewUrl }) => {
  return (
    !!file && (
      <Typography
        component={"div"}
        sx={{
          mt: 2,
          mx: 1,
          p: "4px",
          border: "1.5px solid",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          width: "200px",
          height: "100px",
        }}
        onClick={() => {
          setPreviewUrl(getFileUrl({ file }));
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={getFileUrl({ file })}
            style={{
              width: "100%",
              height: "100%",
            }}
            alt={"banner"}
          />
        </div>
      </Typography>
    )
  );
};

const getFileUrl = ({ file }) => {
  return typeof file === "string" ? file : URL.createObjectURL(file);
};
