import { Typography } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useCallback, useEffect, useState } from "react";
import { useGetLangData } from "screens/Category/hooks/useLanguageData";
import { changeVideoStatusRequest, getTrainingVideosRequest } from "./utils";
import CustomDataGrid from "components/DataGrid";
import { getTrainingVideoColumns } from "./constants";
import { queryString } from "utils";
import { VideoPreviewModal } from "screens/Services/components/ImageUploaderModal";
import { AddEditVideoModal } from "./AddEditVideoModal";

export default function TrainingVideos({ serviceId }) {
  const [state, setState] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [previewUrl, setPreviewUrl] = useState("");
  const [addVideoModalData, setAddVideoModalData] = useState(null);
  const closeAddModal = () => setAddVideoModalData(null);

  const { allLanguages, defaultLanguageId } = useGetLangData({
    data: state,
  });

  const onAddVideo = () => setAddVideoModalData({ data: null });

  const onViewPress = (data) => {
    setPreviewUrl(data.videoFile);
  };

  const onEditPress = (row) => {
    setAddVideoModalData({ data: row });
  };

  const handleStatusChange = (row) => {
    changeVideoStatusRequest({
      id: row._id,
      onSuccess: () => {
        setState((_state) => [
          ...state.map((item) => {
            if (item._id === row._id) {
              return {
                ...item,
                isActive: !item.isActive,
              };
            }

            return item;
          }),
        ]);
      },
    });
  };

  const getTrainingVideos = useCallback(() => {
    getTrainingVideosRequest({
      query: queryString({ serviceId, languageId: selectedLanguage }),
      onSuccess: (data) => setState(data),
    });
  }, [selectedLanguage, serviceId]);

  useEffect(() => {
    getTrainingVideos();
  }, [getTrainingVideos]);

  useEffect(() => {
    defaultLanguageId && setSelectedLanguage(defaultLanguageId);
  }, [defaultLanguageId]);

  return (
    <Typography
      sx={{
        overflow: "auto",
        margin: "-16px",
        padding: "16px",
      }}
    >
      <Header
        skip
        title="Training Videos"
        dropDown={{
          value: selectedLanguage,
          onChange: setSelectedLanguage,
          data: allLanguages,
        }}
        buttons={[{ title: "Add Video", onClick: onAddVideo }]}
      />
      <CustomDataGrid
        rows={state}
        columns={getTrainingVideoColumns({
          onViewPress,
          onEditPress,
          handleStatusChange,
        })}
      />

      {!!addVideoModalData && (
        <AddEditVideoModal
          onSuccessCallback={getTrainingVideos}
          serviceId={serviceId}
          languageId={selectedLanguage}
          data={addVideoModalData.data}
          closeModal={closeAddModal}
        />
      )}

      {!!previewUrl && (
        <VideoPreviewModal
          previewUrl={previewUrl}
          closeModal={() => setPreviewUrl("")}
        />
      )}
    </Typography>
  );
}
