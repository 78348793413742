import React, { useState } from "react";
import ModalWrapper from "components/ModalWrapper";
import { CustomField, getErrorKey, getErrorText } from "components";
import { Grid, Typography } from "@mui/material";
import { successToast, validateFields } from "utils";
import { TRAINING_VIDEOS_FIELDS } from "./constants";
import { addTrainingVideoRequest, updateTrainingVideoRequest } from "./utils";
import { PlayCircleFilled } from "@mui/icons-material";
import { VideoPreviewModal } from "screens/Services/components/ImageUploaderModal";

export const AddEditVideoModal = ({
  serviceId,
  languageId,
  onSuccessCallback,
  closeModal,
  data,
}) => {
  const isEdit = !!data;
  const [state, setState] = useState(data ? data : {});
  const [file, setFile] = useState(data ? data.videoFile : "");
  const [previewUrl, setPreviewUrl] = useState("");

  const validateData = () => {
    let isValidData = true;
    const { isValid, _state } = validateFields({
      _state: { ...state, files: file },
      fields: TRAINING_VIDEOS_FIELDS,
    });
    setState({ ..._state, files: state.files });

    if (!isValid) {
      isValidData = false;
    }

    return isValidData;
  };

  const onChange = ({ _key, value, target }) => {
    setState((_state) => ({
      ...state,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
      [_key]: value,
    }));
    if (_key === "files") {
      setFile(target?.files[0]);
    }
  };

  const onSuccess = () => {
    successToast(`Video ${isEdit ? "updated" : "added"} successfully!`);
    onSuccessCallback();
    closeModal();
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    const payload = {
      title: state.title,
      description: state.description,
      serviceId,
      languageId,
    };

    if (typeof file !== "string") {
      payload.files = file;
    }

    const caller = isEdit
      ? updateTrainingVideoRequest
      : addTrainingVideoRequest;

    caller({
      id: data?._id,
      payload,
      onSuccess: () => {
        onSuccess();
      },
    });
  };

  const getFileUrl = () => {
    return typeof file === "string" ? file : URL.createObjectURL(file);
  };

  return (
    <ModalWrapper
      footerButtons={[
        {
          title: isEdit ? "Update" : "Add",
          onClick: onSubmitPress,
        },
      ]}
      title={isEdit ? "Edit Training Video" : "Add Training Video"}
      closeModal={closeModal}
      sx={{ width: "50%" }}
      childSx={{ height: "50vh", p: 2 }}
    >
      <div
        style={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <Grid container rowSpacing={2} spacing={1}>
          {TRAINING_VIDEOS_FIELDS.map((item) => {
            const { _key } = item;

            if (!_key) {
              return null;
            }

            return (
              <Grid key={_key} item md={12} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={item}
                  errorText={state[getErrorText(_key)]}
                  error={state[getErrorKey(_key)]}
                  value={state[_key]}
                  onChange={onChange}
                />
              </Grid>
            );
          })}
        </Grid>

        {!!file && (
          <Typography
            component={"div"}
            sx={{
              my: 3,
              p: "4px",
              border: "1.5px solid",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              width: 100,
              height: 100,
            }}
            onClick={() => {
              setPreviewUrl(getFileUrl());
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <video
                id="training-video"
                src={getFileUrl()}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                alt={"video"}
              ></video>
              <PlayCircleFilled
                sx={{
                  position: "absolute",
                  width: "30px",
                  height: "30px",
                }}
                color="primary"
              />
            </div>
          </Typography>
        )}

        {!!previewUrl && (
          <VideoPreviewModal
            previewUrl={previewUrl}
            closeModal={() => setPreviewUrl("")}
          />
        )}
      </div>
    </ModalWrapper>
  );
};
