import { Typography } from "@mui/material";
import React from "react";

export default function RenderNodeItems({ isNode = true, data = [] }) {
  return data.map(({ title, items, children, _id }) => {
    return (
      <div key={_id}>
        <div
          style={{
            borderRadius: "4px",
            padding: `8px 6px`,
            marginBottom: "8px",
            background: isNode ? "#f0fdf4" : "#eff6ff",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography key={title}>{title}</Typography>
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          {!!(items || children)?.length && (
            <div
              style={{
                borderLeft: "1.5px dashed grey",
                margin: "0px 13px",
                marginTop: "-8px",
              }}
            />
          )}
          <div style={{ width: "100%" }}>
            <RenderNodeItems
              isNode={!!children?.length}
              data={items || children}
            />
          </div>
        </div>
      </div>
    );
  });
}
