import { Edit, Visibility } from "@mui/icons-material";
import { IconButton, Switch } from "@mui/material";
import { INPUT_TYPES } from "components";
import { customSortComparatorForNums } from "utils";

export const getTrainingVideoColumns = ({
  onViewPress,
  onEditPress,
  handleStatusChange,
}) => {
  return [
    { field: "id", filterable: false, headerName: "ID", width: 90, hide: true },
    {
      field: "index",
      headerName: "S.No.",
      width: 100,
      sortComparator: customSortComparatorForNums,
    },
    {
      field: "title",
      headerName: "Title",
      width: 200,
      renderCell: ({ row }) => row.title,
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      renderCell: ({ row }) => row.title,
    },
    {
      field: "isActive",
      headerName: "Active",
      width: 80,
      renderCell: ({ row }) => {
        return (
          <Switch
            checked={row?.isActive}
            onChange={() => handleStatusChange(row)}
          />
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      width: 80,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const onView = (e) => {
          e.stopPropagation();
          onViewPress(row);
        };

        return (
          <>
            <IconButton onClick={onView}>
              <Visibility color="primary" />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 80,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const onEdit = (e) => {
          e.stopPropagation();
          onEditPress(row);
        };

        return (
          <>
            <IconButton onClick={onEdit}>
              <Edit color="primary" />
            </IconButton>
          </>
        );
      },
    },
  ];
};

export const TRAINING_VIDEOS_FIELDS = [
  {
    _key: "title",
    placeholder: "Title",
    inputType: INPUT_TYPES.INPUT,
  },
  {
    _key: "description",
    placeholder: "Description",
    inputType: INPUT_TYPES.TEXT_AREA,
    extraProps: {
      minRows: 4,
    },
  },
  {
    _key: "files",
    placeholder: "Video",
    inputType: INPUT_TYPES.NORMAL_FILE,
    extraProps: {
      inputProps: {
        accept: "video/*",
      },
    },
  },
];
