class AmountFormat {
  constructor() {
    this.intl = new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 2,
    });
  }

  format = (amount) => {
    return this.intl.format(Math.abs(amount));
  };
}

export default new AmountFormat();
