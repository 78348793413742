import { Box, Card, CardContent } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import DataGrid from "../../../components/DataGrid";
import { useNavigate, useParams } from "react-router-dom";
import { getProviderListColumns, PROVIDER_FILTERS } from "./constants";
import { getProviderList } from "./utils";
import CustomFilter from "components/CustomFilter";
import { queryString } from "utils";

export default function ProvidersList() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [filters, setFilters] = useState({});
  const { listType = "" } = useParams();
  const isVerified = listType !== "pending";

  const onSuccess = (data) => {
    setData(
      data?.items?.map((provider, index) => ({
        ...provider,
        id: provider._id,
        index: index + 1,
      }))
    );
    setRowCount(data?.totalRecords);
  };

  const onPageChange = (pNo) => setFiltersData({ ...filters, pageNo: pNo + 1 });

  const onPageSizeChange = (pageSize) =>
    setFiltersData({ ...filters, pageSize });

  const setFiltersData = (_state) => {
    navigate(
      `/providers-list${listType ? `/${listType}` : ""}?${queryString(_state)}`,
      { replace: true }
    );
    setFilters(_state);
  };

  useEffect(() => {
    getProviderList({
      query: queryString({ ...filters, isVerified }),
      onSuccess,
    });
  }, [filters, isVerified]);

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header
            title={isVerified ? "Provider's List" : "Pending Approvals"}
          />
          <CustomFilter
            onChange={setFiltersData}
            filtersData={filters}
            fields={PROVIDER_FILTERS}
          />
          <DataGrid
            rows={data}
            columns={getProviderListColumns({ isVerified }).filter(
              (item) => !item.hide && item.field !== "distance"
            )}
            rowCount={rowCount}
            paginationMode="server"
            onPageChange={onPageChange}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pageSize={Number(filters?.pageSize || 10)}
            page={(filters?.pageNo || 1) - 1}
            onPageSizeChange={onPageSizeChange}
          />
        </CardContent>
      </Card>
    </Box>
  );
}
