import { Grid } from "@mui/material";
import React, { useState } from "react";
import {
  ITEM_FIELDS,
  ITEM_LAYOUT_TYPES,
  ITEM_LAYOUT_TYPES_CONSTANTS,
  ITEM_SELECTION_TYPE_CONSTANTS,
  LAYOUT_TYPES,
  NODE_FIELDS,
  NONE_SELF_WITHPARENT,
  ROOT_LAYOUT_TYPE,
} from "../constants";
import {
  CustomField,
  getErrorKey,
  getErrorText,
} from "../../../../../components/CustomField";
import { NodeModal } from "./NodeModal";

export const RenderNodeData = ({
  color = "primary",
  customMd,
  isNode,
  hardDisable,
  dropdownData = {},
  customFields,
  onChange = () => {},
  marginLeft,
  data = {},
  disabled,
  id,
  isRootNode = false,
  parentNodeData,
  parentItemData,
  disableImageUpload,
  serviceConfig = {},
}) => {
  const [modalData, setModalData] = useState(null);
  const closeModal = () => setModalData(null);
  return (
    <div
      style={{
        marginTop: "8px",
        marginBottom: "8px",
        marginLeft: marginLeft ?? "20px",
      }}
    >
      <Grid container rowSpacing={2} spacing={1}>
        {(customFields ?? (isNode ? NODE_FIELDS : ITEM_FIELDS)).map((item) => {
          if (!item._key) {
            return null;
          }
          const {
            md = 3,
            _key,
            showOnlyOnUpdate,
            dependentKey,
            hideOnDependentKeyValue,
          } = item;

          if (
            dependentKey &&
            (!data[dependentKey] ||
              data[dependentKey] === "false" ||
              data[dependentKey] === hideOnDependentKeyValue)
          ) {
            return null;
          }

          if (_key === "itemLayout") {
            if (
              data.itemSelectionType !== ITEM_SELECTION_TYPE_CONSTANTS.SINGLE &&
              data.itemSelectionType !== ITEM_SELECTION_TYPE_CONSTANTS.MULTIPLE
            ) {
              item.extraData = [ITEM_LAYOUT_TYPES[0]];
            } else {
              item.extraData = ITEM_LAYOUT_TYPES;
            }
          }

          if (_key === "layout") {
            item.extraData = ROOT_LAYOUT_TYPE;
          }

          if (_key === "showIntoSummary") {
            item.extraData = NONE_SELF_WITHPARENT.slice(0, 2);
          }

          if (!isRootNode) {
            if (_key === "layout") {
              if (
                parentNodeData.itemLayout === ITEM_LAYOUT_TYPES_CONSTANTS.ROW &&
                parentNodeData.itemSelectionType ===
                  ITEM_SELECTION_TYPE_CONSTANTS.MULTIPLE
              ) {
                item.extraData = [LAYOUT_TYPES[0]];
              } else if (
                parentNodeData.itemLayout ===
                  ITEM_LAYOUT_TYPES_CONSTANTS.GRID &&
                parentNodeData.itemSelectionType ===
                  ITEM_SELECTION_TYPE_CONSTANTS.SINGLE
              ) {
                item.extraData = LAYOUT_TYPES.slice(1);
              } else {
                item.extraData = LAYOUT_TYPES;
              }
            }

            if (_key === "showIntoSummary") {
              if (parentNodeData.showIntoSummary === "none") {
                item.extraData = NONE_SELF_WITHPARENT.slice(0, 2);
              } else {
                item.extraData = NONE_SELF_WITHPARENT;
              }
            }
          }

          if ((_key === "banners" || _key === "image") && disableImageUpload) {
            return null;
          }

          let isDisabled = false;
          if (_key === "slotShow") {
            if (
              serviceConfig.slotShow &&
              serviceConfig.slotShow !== "partial"
            ) {
              data[_key] = serviceConfig.slotShow === "no" ? "false" : "true";
              isDisabled = true;
            } else if (
              parentItemData?.slotShow === "true" ||
              parentItemData?.slotShow
            ) {
              if (!data[_key]) data[_key] = "true";
              isDisabled = true;
            }
          }

          if (_key === "showUptoDate") {
            if (
              serviceConfig.showUptoDate &&
              serviceConfig.showUptoDate !== "partial"
            ) {
              data[_key] =
                serviceConfig.showUptoDate === "no" ? "false" : "true";
              isDisabled = true;
            } else if (
              parentItemData?.showUptoDate === "true" ||
              parentItemData?.showUptoDate
            ) {
              if (!data[_key]) data[_key] = "true";
              isDisabled = true;
            }
          }

          if (_key === "showUptoTime") {
            if (
              serviceConfig.showUptoTime &&
              serviceConfig.showUptoTime !== "partial"
            ) {
              data[_key] =
                serviceConfig.showUptoTime === "no" ? "false" : "true";
              isDisabled = true;
            } else if (
              parentItemData?.showUptoTime === "true" ||
              parentItemData?.showUptoTime
            ) {
              if (!data[_key]) data[_key] = "true";
              isDisabled = true;
            }
          }

          if (_key === "showIntoSummary") {
            if (data[_key] === true) data[_key] = "full";
            else if (data[_key] === false) data[_key] = "none";
          }

          return (
            (id ? true : !showOnlyOnUpdate) && (
              <Grid
                key={_key}
                item
                md={customMd || (!isNode && !disabled ? 4 : md)}
                sm={12}
                xs={12}
              >
                <CustomField
                  color={color}
                  item={{ extraData: dropdownData[item?.dataKey], ...item }}
                  hardDisable={hardDisable}
                  disabled={disabled || isDisabled}
                  itemData={data}
                  errorText={data[getErrorText(_key)]}
                  error={data[getErrorKey(_key)]}
                  value={data[_key]}
                  onChange={onChange}
                />
              </Grid>
            )
          );
        })}

        {/* {disabled && (
          <ImageUploader
            customMd={customMd}
            isNode={isNode}
            color={color}
            onChange={onChange}
            data={data}
          />
        )} */}

        {!!modalData && (
          <NodeModal
            closeModal={closeModal}
            isNode={isNode}
            modalData={modalData}
          />
        )}
      </Grid>
    </div>
  );
};
