import React, { useEffect, useState } from "react";
import { getCategoryList } from "screens/Category/utils";
import Header from "../../../../../components/HeaderV2";
import { RenderNodeData } from "../../ServiceNode/components/RenderNodeData";
import {
  CONFIG_FIELDS_1,
  CONFIG_FIELDS_2,
  CONFIG_FIELDS_3,
  CONFIG_FIELDS_4,
} from "../constants";

export default function ConfigsSection({ data, onChange }) {
  const [dropdownData, setDropdownData] = useState({});

  useEffect(() => {
    getCategoryList({
      onSuccess: (data) =>
        setDropdownData((_state) => ({
          ..._state,
          categories: data.map(({ categoryId, categoryName }) => ({
            label: categoryName,
            value: categoryId,
          })),
        })),
    });
  }, []);
  return (
    <div>
      <Header skip title="Configs" />

      <Header skip title="Schedule" variant="span" />
      <RenderNodeData
        data={data}
        customFields={CONFIG_FIELDS_1}
        marginLeft="0px"
        dropdownData={dropdownData}
        onChange={onChange}
      />

      <Header
        skip
        title="Cancellation / Rescheduling Detail"
        variant="span"
        style={{ my: 2 }}
      />
      <RenderNodeData
        data={data}
        customFields={CONFIG_FIELDS_2}
        marginLeft="0px"
        dropdownData={dropdownData}
        onChange={onChange}
      />

      <Header skip title="Provider Details" variant="span" style={{ my: 2 }} />
      <RenderNodeData
        data={data}
        customFields={CONFIG_FIELDS_3}
        marginLeft="0px"
        dropdownData={dropdownData}
        onChange={onChange}
      />

      <Header skip title="Charges" variant="span" style={{ my: 2 }} />
      <RenderNodeData
        data={data}
        customFields={CONFIG_FIELDS_4}
        marginLeft="0px"
        dropdownData={dropdownData}
        onChange={onChange}
      />
    </div>
  );
}
