import { Edit } from "@mui/icons-material";
import { IconButton, Switch } from "@mui/material";
import { INPUT_TYPES, REGEX } from "components";
import {
  ACCESS_TYPES,
  ROLE_TYPES,
  USER_PRIVILEGE_TYPES,
} from "shared/constants";
import { customSortComparatorForNums } from "utils";

export const getUserInfoColumns = ({ handleStatusChange, onEditPress }) => [
  { field: "id", filterable: false, headerName: "ID", width: 90, hide: true },
  {
    field: "index",
    headerName: "S.No.",
    width: 100,
    disableColumnMenu: true,
    sortComparator: customSortComparatorForNums,
  },
  {
    field: "firstName",
    headerName: "Name",
    width: 200,
    disableColumnMenu: true,
    renderCell: ({ row }) => (row.firstName || "") + " " + (row.lastName || ""),
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
    disableColumnMenu: true,
  },
  {
    field: "mobile",
    headerName: "Mobile",
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: "userAccessType",
    headerName: "Access",
    width: 150,
    disableColumnMenu: true,
    renderCell: ({ row }) => ACCESS_TYPES[row.userAccessType]?.label || "",
  },
  {
    field: "authorityType",
    headerName: "Authority",
    width: 150,
    disableColumnMenu: true,
    renderCell: ({ row }) =>
      USER_PRIVILEGE_TYPES[row.authorityType]?.label || "",
  },
  {
    field: "userRole",
    headerName: "Role",
    width: 150,
    disableColumnMenu: true,
    renderCell: ({ row }) => ROLE_TYPES[row.userRole].label,
  },
  {
    field: "Active",
    width: 80,
    renderCell: ({ row }) => {
      if (row.userRole === "super-admin") {
        return "";
      }
      return (
        <Switch
          checked={row?.isActive}
          onChange={() => handleStatusChange(row)}
        />
      );
    },
  },
  {
    field: "action",
    headerName: "Action",
    width: 80,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      if (row.userRole === "super-admin") {
        return "";
      }
      const onView = (e) => {
        e.stopPropagation();
        onEditPress(row);
      };

      return (
        <>
          <IconButton onClick={onView}>
            <Edit color="primary" />
          </IconButton>
        </>
      );
    },
  },
];

export const USER_FIELDS = [
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "firstName",
    placeholder: "First Name",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "lastName",
    placeholder: "Last Name",
  },
  {
    inputType: INPUT_TYPES.PASSWORD,
    _key: "password",
    extraProps: { type: "password" },
    placeholder: "Password",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "mobile",
    regex: REGEX.PHONE,
    maxLength: 10,
    placeholder: "Mobile Number",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "email",
    regex: REGEX.EMAIL,
    placeholder: "Email",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "alternateMobile",
    maxLength: 10,
    regex: REGEX.PHONE,
    isOptional: true,
    placeholder: "Alternate Mobile Number",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "alternateEmail",
    regex: REGEX.EMAIL,
    isOptional: true,
    placeholder: "Alternate Email",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "userRole",
    placeholder: "Role",
    extraData: Object.values(ROLE_TYPES).slice(1),
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "userAccessType",
    placeholder: "Access",
    extraData: Object.values(ACCESS_TYPES),
  },
];
