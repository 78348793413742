import { useSelector } from "react-redux";

export const useGetNodesData = () => {
  const allLanguages = useSelector(({ common }) => common.allLanguages) || [];
  const enrollmentNodes =
    useSelector(({ services }) => services.enrollmentNodes) || [];

  const availableLanguages = allLanguages
    .filter((item) =>
      enrollmentNodes.find((_node) => _node.languageId === item.languageId)
    )
    .map((lang) => ({
      ...lang,
      label: lang.languageName,
      value: lang.languageId,
    }));
  const unavailableLanguages = allLanguages
    .filter(
      (item) =>
        item.isActive &&
        !enrollmentNodes.find((_node) => _node.languageId === item.languageId)
    )
    .map((lang) => ({
      ...lang,
      label: lang.languageName,
      value: lang.languageId,
    }));

  if (!availableLanguages.length) {
    const defaultLang = allLanguages[0] || {};
    availableLanguages.push({
      ...defaultLang,
      label: defaultLang.languageName,
      value: defaultLang.languageId,
    });
  }
  return {
    availableLanguages,
    enrollmentNodes,
    defaultLanguageId: availableLanguages[0]?.languageId,
    unavailableLanguages,
  };
};
