import { Box, Card, CardContent } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import CustomFilter from "components/CustomFilter";
import { queryString } from "utils";
import CustomDataGrid from "components/DataGrid";
import { TEMPLATE_FILTER } from "./constant";
import { getTemplateListColumns } from "./constant";
import { fetchTemplatesRequest } from "screens/Broadcast/utils";
import { useNavigate } from "react-router-dom";

export default function CommunicationTemplatesListing() {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const navigate = useNavigate();
  const onEditPress = (row) => navigate(`/template/edit/${row.templateId}`);

  const onSuccess = (data) => {
    setData(
      data?.map((template, index) => ({
        ...template,
        id: template._id,
        index: index + 1,
      }))
    );
  };

  useEffect(() => {
    fetchTemplatesRequest({
      query: queryString({ ...filters }),
      onSuccess,
    });
  }, [filters]);

  const onClick = () => {
    navigate("/template/create");
  };

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header
            title="Templates"
            buttons={[{ title: "Add Template", onClick }]}
          />
          <CustomFilter
            onChange={setFilters}
            filtersData={filters}
            fields={TEMPLATE_FILTER}
          />
          <CustomDataGrid
            rows={data}
            columns={getTemplateListColumns({ onEditPress })}
          />
        </CardContent>
      </Card>
    </Box>
  );
}
