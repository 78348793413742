import { errorToast, queryString } from "utils";
import { setLoading } from "../../../../redux/actions";
import { updateCurrentServiceNodesData } from "../../../../redux/actions/services";
import { store } from "../../../../redux/store";
import { HTTP_SERVICE } from "../../../../utils/apiServices";

export const validateSiblingsForShowIntoSummary = ({
  currItem,
  items = [],
}) => {
  const mismatchedNodes = items.filter(
    (item) =>
      item.showIntoSummary !== "none" &&
      currItem._id !== item._id &&
      currItem.showIntoSummary !== item.showIntoSummary &&
      item.isActive
  );
  if (mismatchedNodes.length) {
    const mismatchedNodesString = mismatchedNodes.reduce(
      (acc, current, currentIndex) =>
        (acc +=
          currentIndex !== mismatchedNodes.length - 1
            ? `${current.title}, `
            : current.title),
      ""
    );
    errorToast(
      `Show into summary mismatched with (${mismatchedNodesString}) node(s)`
    );
    return { isValid: false };
  }
  return { isValid: true };
};

const formatNodeData = ({ langId, item, itemId, serviceId }) => {
  const finalVal = (item.layout ? item : item.stateData) || {};
  return {
    parentItemId: finalVal.parentItemId ?? itemId,
    serviceId,
    nodeId: finalVal.nodeId,
    position: finalVal.position,
    description: finalVal.description?.trim(),
    itemHeading: finalVal.itemHeading?.trim(),
    itemLayout: finalVal.itemLayout,
    itemOperator: finalVal.itemOperator,
    itemSelectionType: finalVal.itemSelectionType,
    itemStyle: finalVal.itemStyle,
    layout: finalVal.layout,
    operator: finalVal.operator,
    shortDescription: finalVal.shortDescription?.trim(),
    showIntoSummary: finalVal.showIntoSummary,
    showParentTitle: JSON.parse(finalVal.showParentTitle),
    showSuccessMessage: JSON.parse(finalVal.showSuccessMessage),
    title: finalVal.title?.trim(),
    languageId: langId ?? item.languageId,
    hierLevel: finalVal.hierLevel,
    serviceChargeApplicable: finalVal.serviceChargeApplicable,
  };
};

const formatItemData = ({ langId, item, nodeId, serviceId }) => {
  const finalVal = item.itemId ? item : item.stateData;
  return {
    description: finalVal.description?.trim(),
    languageId: langId ?? item.languageId,
    nodeId: finalVal.nodeId ?? nodeId,
    position: finalVal.position,
    itemId: finalVal.itemId,
    slotShow: finalVal.slotShow,
    serviceId,
    title: finalVal.title?.trim(),
    value: Number(finalVal.value),
  };
};

const formatLangHierarchy = ({ data, serviceId, languageId: langId }) => {
  let nodesArray = [],
    itemsArray = [];
  data.forEach((node) => {
    nodesArray.push(formatNodeData({ item: node, serviceId, langId }));
    (node.items || []).forEach((item) => {
      itemsArray.push(formatItemData({ item, langId, serviceId }));
      const { itemsArray: _itemsArray, nodesArray: _nodesArray } =
        formatLangHierarchy({
          data: item.children,
          languageId: langId,
          serviceId,
        });
      nodesArray.push(..._nodesArray);
      itemsArray.push(..._itemsArray);
    });
  });
  return { nodesArray, itemsArray };
};

export const fetchServiceNodesData = (serviceId) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_A_SERVICE_NODES(serviceId)
    .then(({ data = [] }) => {
      dispatch(updateCurrentServiceNodesData(data));
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createNodesRequest = ({ onSuccess, data, serviceId, itemId }) => {
  const payload = {
    serviceId,
    itemId,
    nodes: data.map((item) => formatNodeData({ item, itemId, serviceId })),
  };
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.ADD_SERVICE_NODE(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchServiceNodesData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createItemsRequest = ({ onSuccess, data, nodeId, serviceId }) => {
  const payload = {
    nodeId,
    serviceId,
    items: data.map((item) => formatItemData({ item, serviceId, nodeId })),
  };
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.ADD_SERVICE_ITEM(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchServiceNodesData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateNodeRequest = ({ _id: id, serviceId, onSuccess, data }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  const payload = formatNodeData({ item: data[0] });
  HTTP_SERVICE.UPDATE_SERVICE_NODE({ data: payload, id })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchServiceNodesData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateItemRequest = ({ _id: id, serviceId, onSuccess, data }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  const payload = formatItemData({ item: data[0] });
  HTTP_SERVICE.UPDATE_SERVICE_ITEM({ data: payload, id })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchServiceNodesData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createNodeReplicaForOtherLanguage = ({
  serviceId,
  languageId,
  onSuccess,
  data,
}) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  const { itemsArray, nodesArray } = formatLangHierarchy({
    serviceId,
    data,
    languageId,
  });
  HTTP_SERVICE.CREATE_NODE_REPLICA_FOR_OTHER_LANGUAGE({
    data: { items: itemsArray, nodes: nodesArray },
  })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchServiceNodesData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const toggleNodeActiveStatus = ({
  onSuccess,
  serviceId,
  nodeId,
  isActive,
}) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.TOGGLE_NODE_ACTIVE_STATUS({ data: { nodeId, isActive } })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchServiceNodesData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const toggleItemActiveStatus = ({
  onSuccess,
  serviceId,
  itemId,
  isActive,
}) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.TOGGLE_ITEM_ACTIVE_STATUS({ data: { itemId, isActive } })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchServiceNodesData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateServiceNodePositionRequest = ({
  serviceId,
  data: { position, nodeId } = {},
  sibling = {},
}) => {
  const dispatch = store.dispatch;
  const positions = [
    { position: sibling.position, nodeId },
    { position, nodeId: sibling.nodeId },
  ];
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.UPDATE_SERVICE_NODE_POSITION({ positions })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        fetchServiceNodesData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateServiceItemPositionRequest = ({
  serviceId,
  data: { position, itemId } = {},
  sibling = {},
}) => {
  const dispatch = store.dispatch;
  const positions = [
    { position: sibling.position, itemId },
    { position: position, itemId: sibling.itemId },
  ];
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.UPDATE_SERVICE_ITEM_POSITION({ positions })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        fetchServiceNodesData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const uploadImagesRequest = ({
  data,
  documentId,
  action = "push",
  collection,
  docKey = "banners",
  onSuccess,
}) => {
  const formData = new FormData();

  collection && formData.append("collection", collection);
  documentId && formData.append("documentId", documentId);
  docKey && formData.append("docKey", docKey);
  action && formData.append("action", action);
  !!data.length && data.forEach(({ data }) => formData.append("files", data));

  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.UPLOAD_IMAGES(formData)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const removeImageRequest = ({
  documentId,
  collection,
  docKey,
  docKeyIndex,
  action,
  onSuccess,
}) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.REMOVE_IMAGE({
    documentId,
    collection,
    docKey,
    docKeyIndex,
    action,
  })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const changeBannersOrderRequest = ({
  bannerId,
  event,
  documentId,
  docKey,
  collection,
  onSuccess,
}) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.CHANGE_BANNERS_ORDER({
    bannerId,
    event,
    documentId,
    docKey,
    collection,
  })
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const deleteNodeRequest = ({ nodeId, serviceId, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.DELETE_SERVICE_NODE(queryString({ nodeId }))
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchServiceNodesData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const deleteItemRequest = ({ itemId, serviceId, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.DELETE_SERVICE_NODE_ITEM(queryString({ itemId }))
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
        fetchServiceNodesData(serviceId);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
