import { Box, Button, Card, Chip, Grid, Typography } from "@mui/material";
import { CustomField } from "components";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import qs from "query-string";
import { DLOGGER_FIELDS, DLOGGER_LOG_CHIPS } from "./constants";
import { getErrorKey, getErrorText } from "utils";

export default function Dlogger() {
  const [searchParams] = useSearchParams();
  const [state, setState] = useState({});
  const navigate = useNavigate();
  const [currLogIndex, setCurrLogIndex] = useState(0);

  const logData = [
    {
      _id: "660588d386117f038ba2861c",
      appName: "Chutki-BackEnd",
      moduleName: "/login",
      apiName: "/login",
      logs: "Thu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- Log Starts Here :- @ getStartTime() {\r\n        return this.startTime;\r\n    }\nThu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- body :[object Object]\nThu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- query :[object Object]\nThu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- param :[object Object]\nThu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- undefined\nThu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- Log Ends Here :- @ getStartTime() {\r\n        return this.startTime;\r\n    }",
      startTime: "2024-03-28T15:12:19.482Z",
      endTime: "2024-03-28T15:12:19.663Z",
      duration: 181,
      level: 5,
      createdAt: "2024-03-28T15:12:19.688Z",
      updatedAt: "2024-03-28T15:12:19.688Z",
    },
    {
      _id: "660588d386117f038ba2861c",
      appName: "Chutki-BackEnd",
      moduleName: "/service",
      apiName: "/service",
      logs: "Thu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- Log Starts Here :- @ getStartTime() {\r\n        return this.startTime;\r\n    }\nThu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- body :[object Object]\nThu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- query :[object Object]\nThu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- param :[object Object]\nThu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- undefined\nThu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- Log Ends Here :- @ getStartTime() {\r\n        return this.startTime;\r\n    }",
      startTime: "2024-03-28T15:12:19.482Z",
      endTime: "2024-03-28T15:12:19.663Z",
      duration: 120,
      level: 8,
      createdAt: "2024-03-28T15:12:19.688Z",
      updatedAt: "2024-03-28T15:12:19.688Z",
    },
    {
      _id: "660588d386117f038ba2861c",
      appName: "Chutki-BackEnd",
      moduleName: "/login",
      apiName: "/login",
      logs: "Thu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- Log Starts Here :- @ getStartTime() {\r\n        return this.startTime;\r\n    }\nThu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- body :[object Object]\nThu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- query :[object Object]\nThu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- param :[object Object]\nThu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- undefined\nThu Mar 28 2024 20:42:19 GMT+0530 (India Standard Time):- Log Ends Here :- @ getStartTime() {\r\n        return this.startTime;\r\n    }",
      startTime: "2024-03-28T15:12:19.482Z",
      endTime: "2024-03-28T15:12:19.663Z",
      duration: 181,
      level: 5,
      createdAt: "2024-03-28T15:12:19.688Z",
      updatedAt: "2024-03-28T15:12:19.688Z",
    },
  ];

  const onChange = ({ value, _key }) => {
    setState((_state) => ({ ..._state, [_key]: value }));
  };

  const onNext = () => {
    setCurrLogIndex((currIndex) => currIndex + 1);
  };

  const onPrev = () => {
    setCurrLogIndex((currIndex) => currIndex - 1);
  };

  useEffect(() => {
    const params = qs.parse(searchParams.toString());
    setState(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Typography
      component={"div"}
      sx={{
        background: "#a5d6a7 !important",
        p: 2,
        minHeight: "100vh",
      }}
    >
      <Box sx={{ my: 2 }}>
        <Card sx={{ p: 2 }}>
          <Grid container rowGap={1} columnGap={0} spacing={1}>
            {DLOGGER_FIELDS.map((item) => {
              const { _key, md, sm } = item;

              if (!_key) return null;

              return (
                <Grid key={_key} item md={md || 1} sm={sm | 6} xs={12}>
                  <CustomField
                    color={"primary"}
                    item={item}
                    itemData={state}
                    errorText={state[getErrorText(_key)]}
                    error={state[getErrorKey(_key)]}
                    value={state[_key]}
                    onChange={onChange}
                  />
                </Grid>
              );
            })}
            <Grid item md={1} sm={1} xs={1}>
              <Button
                variant="contained"
                size="medium"
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Box sx={{ my: 2 }}>
        <Card sx={{ p: 2 }}>
          <div style={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
            {DLOGGER_LOG_CHIPS.map((item) => {
              const { _key, render } = item;
              const data = logData[currLogIndex][_key];

              return (
                <Chip
                  label={`${item.placeholder} : ${
                    render ? render(data) : data
                  }`}
                  key={_key}
                  color="primary"
                />
              );
            })}
          </div>
          <pre>{logData[currLogIndex].logs}</pre>
          <Typography
            component="div"
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              size="medium"
              sx={{ height: "fit-content" }}
              disabled={currLogIndex === 0}
              onClick={onPrev}
            >
              Prev
            </Button>
            <Button
              variant="contained"
              size="medium"
              sx={{ height: "fit-content" }}
              disabled={currLogIndex === logData.length - 1}
              onClick={onNext}
            >
              Next
            </Button>
          </Typography>
        </Card>
      </Box>
    </Typography>
  );
}
