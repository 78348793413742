import { ROLE_TYPES, USER_PRIVILEGE_TYPES } from "shared/constants";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RoomServiceIcon from "@mui/icons-material/RoomService";

const PROVIDER_LIST_ACTIVE = {
  href: "/providers-list",
  icon: <EngineeringIcon fontSize="small" />,
  title: "Provider List",
};

const PROVIDER_LIST_PENDING = {
  href: "/providers-list/pending",
  icon: <PendingActionsIcon fontSize="small" />,
  title: "Pending Approvals",
};

const PROVIDER_JOBS = {
  href: "/jobs",
  icon: <CalendarMonthIcon fontSize="small" />,
  title: "Jobs",
};

const SERVICES_NEAR_ME = {
  href: "/services-near-me",
  icon: <RoomServiceIcon fontSize="small" />,
  title: "Service",
};

// const SEARCH_PINCODE = {
//   href: "/search-pincode",
//   icon: <LocationCity fontSize="small" />,
//   title: "Search PIN Code",
// };

const BOOKINGS = {
  href: "/bookings",
  icon: <CalendarMonthIcon fontSize="small" />,
  title: "Bookings",
};

const OPERATION_PROVIDER_ROUTES = [
  PROVIDER_LIST_ACTIVE,
  PROVIDER_LIST_PENDING,
  PROVIDER_JOBS,
];
const OPERATION_SERVICE_ROUTES = [SERVICES_NEAR_ME, BOOKINGS];

const OPERATIONS_BOTH_ENABLED = {
  [ROLE_TYPES["country-admin"].value]: true,
  [ROLE_TYPES["state-admin"].value]: true,
  [ROLE_TYPES["district-admin"].value]: true,
};

export const OPERATION_ROUTES = [
  {
    icon: <AccountTreeIcon fontSize="small" />,
    title: "Operations",
    enabled: { [ROLE_TYPES["super-admin"].value]: true },
    child: [
      {
        icon: <AccountTreeIcon fontSize="small" />,
        title: "Provider",
        child: OPERATION_PROVIDER_ROUTES,
      },
      {
        icon: <AccountTreeIcon fontSize="small" />,
        title: "Service",
        child: OPERATION_SERVICE_ROUTES,
      },
    ],
  },
  {
    enabled: OPERATIONS_BOTH_ENABLED,
    icon: <AccountTreeIcon fontSize="small" />,
    checkPrivilege: { [USER_PRIVILEGE_TYPES["both"].value]: true },
    title: "Provider",
    child: OPERATION_PROVIDER_ROUTES,
  },
  {
    enabled: OPERATIONS_BOTH_ENABLED,
    icon: <AccountTreeIcon fontSize="small" />,
    checkPrivilege: { [USER_PRIVILEGE_TYPES["both"].value]: true },
    title: "Service",
    child: OPERATION_SERVICE_ROUTES,
  },
  {
    enabled: OPERATIONS_BOTH_ENABLED,
    checkPrivilege: { [USER_PRIVILEGE_TYPES["providers"].value]: true },
    ...PROVIDER_LIST_ACTIVE,
  },
  {
    enabled: OPERATIONS_BOTH_ENABLED,
    checkPrivilege: { [USER_PRIVILEGE_TYPES["providers"].value]: true },
    ...PROVIDER_LIST_PENDING,
  },
  {
    enabled: OPERATIONS_BOTH_ENABLED,
    checkPrivilege: { [USER_PRIVILEGE_TYPES["providers"].value]: true },
    ...PROVIDER_JOBS,
  },
  {
    enabled: OPERATIONS_BOTH_ENABLED,
    checkPrivilege: { [USER_PRIVILEGE_TYPES["services"].value]: true },
    ...SERVICES_NEAR_ME,
  },
  // {
  //   enabled: OPERATIONS_BOTH_ENABLED,
  //   checkPrivilege: { [USER_PRIVILEGE_TYPES["services"].value]: true },
  //   ...SEARCH_PINCODE,
  // },
  {
    enabled: OPERATIONS_BOTH_ENABLED,
    checkPrivilege: { [USER_PRIVILEGE_TYPES["services"].value]: true },
    ...BOOKINGS,
  },
];
