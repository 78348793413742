import React, { useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Typography } from "@mui/material";

const LeftIcons = ({ active, color }) => {
  if (active) {
    return <IndeterminateCheckBoxIcon color={color} />;
  }
  return <AddBoxIcon color={color} />;
};

export const RenderNodeItems = ({
  disabled,
  hardDisable,
  ignoreMargin,
  isNode,
  data = {},
}) => {
  const [showChildrenNodes, toggleChildrenNodes] = useState(
    isNode ? false : true
  );
  const { isActive = false, title, children = [], items = [] } = data;

  const color = isNode ? "primary" : "secondary";
  const innerItems = children.length ? children : items;
  const hasInnerItems = !!innerItems.length;
  const customStyle = isActive || !disabled;
  return (
    <div style={{ marginLeft: ignoreMargin ? 0 : 15 }}>
      <div
        style={{
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
          width: "100%",
          padding: `${disabled ? 0 : 8}px 4px`,
          marginBottom: "8px",
          position: "relative",
          background: customStyle
            ? isNode
              ? "#f0fdf4"
              : "#eff6ff"
            : "#fee2e2",
        }}
        onClick={() => toggleChildrenNodes(!showChildrenNodes)}
      >
        {hasInnerItems && (
          <LeftIcons color={color} active={showChildrenNodes} />
        )}
        <div
          style={{
            width: "100%",
            padding: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

          }}
        >
          <Typography
            sx={{
              textDecoration: !customStyle && "line-through",
              fontWeight: "500",
            }}
            color={color}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        {showChildrenNodes && hasInnerItems && (
          <div
            style={{
              borderLeft: "1.5px dashed grey",
              marginLeft: "13px",
              marginTop: "-8px",
            }}
          ></div>
        )}
        {showChildrenNodes && (
          <div style={{ width: "100%" }}>
            {innerItems.map((item, _index) => {
              return (
                <RenderNodeItems
                  itemsLength={innerItems.length}
                  index={_index}
                  isNode={!isNode}
                  hardDisable={hardDisable}
                  disabled={disabled}
                  data={item}
                  key={item._id}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default RenderNodeItems;
