import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { APP_CONFIGURATION_SERVICES } from "utils";

export const getContactUsRequest = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_CONTACT_US(query)
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const saveContactUsRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.SAVE_CONTACT_US(payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateContactUsRequest = ({ id, payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.UPDATE_CONTACT_US(id, payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getContactQueriesRequest = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  APP_CONFIGURATION_SERVICES.GET_CONTACT_QUERIES(query)
    .then(({ data, isSuccess }) => {
      isSuccess &&
        onSuccess(data?.map((item, index) => ({ id: index + 1, ...item })));
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
