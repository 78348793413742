import { INPUT_TYPES, REGEX } from "../../../../components/CustomField";

export const OPERATOR_TYPES = ["add", "NA", "multiply"];

export const LAYOUT_TYPES_CONSTANTS = {
  PAGE: "page",
  CARD: "card",
  POPUP: "popup",
  BOTTOMSHEET: "bottomSheet",
};

export const ITEM_SELECTION_TYPE_CONSTANTS = {
  SINGLE: "single",
  MULTIPLE: "multiple",
  INPUT_INT: "inputInt",
  INPUT_DATE: "inputDate",
  INPUT_TEXT: "inputText",
  INPUT_STRING: "inputString",
  INPUT_DOUBLE: "inputDouble",
};

export const ITEM_LAYOUT_TYPES_CONSTANTS = {
  ROW: "row",
  FLOW: "flow",
  GRID: "grid",
};

export const LAYOUT_TYPES = [
  { value: "card", label: "Card" },
  { value: "page", label: "Page" },
  { value: "popup", label: "Popup" },
  { value: "bottomSheet", label: "Bottom Sheet" },
];

export const ROOT_LAYOUT_TYPE = [{ value: "root", label: "Root" }];

export const ITEM_LAYOUT_TYPES = [
  { value: "row", label: "Row" },
  { value: "flow", label: "Flow" },
  { value: "grid", label: "Grid" },
];

export const ITEM_STYLE = [
  { value: "circle", label: "Circle" },
  { value: "rectangle", label: "Rectangle" },
];

export const ITEM_SELECTION_TYPE = [
  { value: "single", label: "Single" },
  { value: "multiple", label: "Multiple" },
  { value: "inputInt", label: "Numeric" },
  { value: "inputDate", label: "Date" },
  { value: "inputText", label: "Text" },
  { value: "inputString", label: "String" },
  { value: "inputDouble", label: "Decimal" },
];

export const TRUE_FALSE = [
  { label: "true", value: "true" },
  { label: "false", value: "false" },
];

export const FIXED_PERCENTAGE = [
  { label: "Fixed", value: "fixed" },
  { label: "Percentage", value: "percentage" },
];

export const YES_NO_PARTIAL = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
  { label: "Partial", value: "partial" },
];

export const NONE_SELF_WITHPARENT = [
  {
    label: "None",
    value: "none",
  },
  {
    label: "Self",
    value: "self",
  },
  {
    label: "With Parent",
    value: "withParent",
  },
];

export const NODE_FIELDS = [
  {
    inputType: INPUT_TYPES.INPUT,
    isOptional: true,
    _key: "title",
    placeholder: "Title",
  },
  {
    md: 6,
    inputType: INPUT_TYPES.INPUT,
    _key: "shortDescription",
    isOptional: true,
    placeholder: "Short Description",
  },
  {
    md: 1.5,
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "layout",
    shouldBeSame: true,
    placeholder: "Layout",
    extraData: LAYOUT_TYPES,
  },
  {
    md: 1.5,
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "operator",
    shouldBeSame: true,
    placeholder: "Operator",
    extraData: OPERATOR_TYPES,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "showParentTitle",
    shouldBeSame: true,
    placeholder: "Show Parent Title",
    extraData: TRUE_FALSE,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "showIntoSummary",
    shouldBeSame: true,
    placeholder: "Show Into Summary",
    extraData: NONE_SELF_WITHPARENT,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "serviceChargeApplicable",
    shouldBeSame: true,
    placeholder: "Service Charge Applicable",
    extraData: TRUE_FALSE,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "showSuccessMessage",
    shouldBeSame: true,
    placeholder: "Show Success Message",
    extraData: TRUE_FALSE,
  },
  {
    inputType: INPUT_TYPES.FILE,
    _key: "banners",
    // placeholder: "Banners",
    extraProps: { customMd: 12, isNode: true },
  },
  {
    md: 12,
    inputType: INPUT_TYPES.TEXT_AREA,
    _key: "description",
    isOptional: true,
    placeholder: "Description",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "itemSelectionType",
    shouldBeSame: true,
    placeholder: "Item Selection Type",
    extraData: ITEM_SELECTION_TYPE,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "itemOperator",
    shouldBeSame: true,
    placeholder: "Item Operator",
    extraData: OPERATOR_TYPES,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "itemLayout",
    shouldBeSame: true,
    placeholder: "Item Layout",
    extraData: ITEM_LAYOUT_TYPES,
  },
  // {
  //   inputType: INPUT_TYPES.DROPDOWN,
  //   _key: "itemStyle",
  //   shouldBeSame: true,
  //   placeholder: "Item Style",
  //   extraData: ITEM_STYLE,
  //   isOptional: true,
  // },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "itemHeading",
    isOptional: true,
    placeholder: "Item Heading",
  },
];

export const ITEM_FIELDS = [
  {
    inputType: INPUT_TYPES.INPUT,
    isOptional: true,
    _key: "title",
    placeholder: "Title",
  },
  {
    md: 6,
    inputType: INPUT_TYPES.TEXT_AREA,
    _key: "description",
    isOptional: true,
    placeholder: "Description",
  },
  {
    md: 1.5,
    inputType: INPUT_TYPES.INPUT,
    _key: "value",
    shouldBeSame: true,
    regex: REGEX.DECIMALS,
    placeholder: "Value",
  },
  {
    md: 1.5,
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "slotShow",
    shouldBeSame: true,
    placeholder: "Show Slot",
    extraData: TRUE_FALSE,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "showUptoDate",
    shouldBeSame: true,
    placeholder: "Show Upto Date",
    extraData: TRUE_FALSE,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "showUptoTime",
    shouldBeSame: true,
    placeholder: "Show Upto Time",
    extraData: TRUE_FALSE,
  },
  {
    _key: "image",
    inputType: INPUT_TYPES.FILE,
    extraProps: { customMd: 12 },
  },
];
