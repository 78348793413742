import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { JOB_SERVICES } from "utils";

const dispatch = store.dispatch;

export const getJobsRequest = ({ query, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  JOB_SERVICES.GET_JOBS(query)
    .then(({ isSuccess, data }) => {
      if (isSuccess) {
        const { items = [], totalRecords } = data || {};
        onSuccess({
          totalRecords,
          response: items.map((item) => ({ ...item, id: item._id })),
        });
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getJobDetailsRequest = ({ id, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  JOB_SERVICES.GET_JOB_DETAILS(id)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getProviderAttendanceRequest = ({ jobId, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  JOB_SERVICES.GET_PROVIDER_ATTENDANCE(jobId)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const markProviderAttendanceRequest = ({ data, onSuccess }) => {
  dispatch(setLoading({ loading: true }));
  JOB_SERVICES.MARK_PROVIDER_ATTENDANCE(data)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
