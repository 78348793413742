import React, { useState } from "react";

import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { IconButton, Switch, Tooltip, Typography } from "@mui/material";
import { Edit, ExpandCircleDown } from "@mui/icons-material";

const LeftIcons = ({ active, color }) => {
  if (active) {
    return <IndeterminateCheckBoxIcon color={color} />;
  }
  return <AddBoxIcon color={color} />;
};
export default function FaqItem({
  onEditPress,
  onPositionUpdate,
  index,
  data = {},
  onToggleCallback,
  itemsLength,
  color = "secondary",
}) {
  const [showChildrenNodes, toggleChildrenNodes] = useState(false);
  const { isActive, faqsId, position, faqsQuestion, faqsAnswer } = data;
  const onEdit = (e) => {
    e?.stopPropagation && e.stopPropagation();
    onEditPress({ data });
  };
  const onMoveDown = (e) => {
    e?.stopPropagation && e.stopPropagation();
    onPositionUpdate({ position, faqsId, withItem: index + 1 });
  };
  const onMoveUp = (e) => {
    e?.stopPropagation && e.stopPropagation();
    onPositionUpdate({ position, faqsId, withItem: index - 1 });
  };
  const onToggleActiveStatus = (e) => {
    e?.stopPropagation && e.stopPropagation();
    onToggleCallback(faqsId);
  };
  return (
    <div
      style={{
        background: "#eff6ff",
        marginBottom: "8px",
        padding: "8px 4px",
      }}
    >
      <div
        className="hide-scrollbar"
        style={{
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
          width: "100%",
          position: "relative",
          overflow: "auto",
        }}
        onClick={() => toggleChildrenNodes(!showChildrenNodes)}
      >
        <LeftIcons color={color} active={showChildrenNodes} />
        <div
          style={{
            width: "100%",
            paddingLeft: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "500", whiteSpace: "nowrap" }}
            color={color}
          >
            {index + 1}. {faqsQuestion}
          </Typography>
          <Typography component={"div"} sx={{ display: "flex" }}>
            <IconButton
              onClick={onMoveUp}
              disabled={!index}
              sx={{ rotate: "180deg" }}
            >
              <Tooltip title="Move Up">
                <ExpandCircleDown color={index ? color : "disabled"} />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={onMoveDown}
              disabled={index === itemsLength - 1}
            >
              <Tooltip title="Move Down">
                <ExpandCircleDown
                  color={index === itemsLength - 1 ? "disabled" : color}
                />
              </Tooltip>
            </IconButton>
            <Tooltip title="Edit">
              <IconButton onClick={onEdit}>
                <Edit color={color} />
              </IconButton>
            </Tooltip>
            <Tooltip title={isActive ? "Disable" : "Enable"}>
              <Switch
                label="check"
                color={color}
                name="showParentTitle"
                checked={isActive}
                onClick={onToggleActiveStatus}
              />
            </Tooltip>
          </Typography>
        </div>
      </div>

      {showChildrenNodes && (
        <Typography
          sx={{ pl: "30px", pr: "10px" }}
          variant="caption"
          component={"div"}
        >
          <span>{faqsAnswer}</span>
        </Typography>
      )}
    </div>
  );
}
