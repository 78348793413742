import * as React from "react";
import Button from "@mui/material/Button";
import { Card, Divider, Typography } from "@mui/material";
import "../../index.css";
import { fetchLocalitiesRequest } from "./utils";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const borderColor = "rgba(0, 0, 0, 0.3)";
const background = "#f0fdf4";

const CustomList = ({
  v2,
  checked = [],
  handleToggle,
  data = [],
  headerTitle,
  width,
}) => (
  <Card
    sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      width: width ? width : "100%",
      boxShadow: "none",
    }}
  >
    <Typography
      textAlign={"center"}
      variant="h6"
      sx={{ fontSize: "16px", p: 1, background }}
      color={"primary"}
    >
      {headerTitle}
    </Typography>
    <Divider sx={{ bgcolor: borderColor }} />
    <Typography
      component="div"
      role="list"
      className="hide-scrollbar"
      sx={{ height: "100%", overflow: "auto" }}
    >
      {data.map((value) => {
        if (v2) {
          const { pincode, localities } = value;
          return (
            <Typography
              component={"p"}
              sx={{ py: 0.5, px: 1, fontSize: "16px" }}
              key={localities}
            >
              <Typography component={"p"}>{pincode}</Typography>
              <Typography component={"p"} sx={{ pl: 3 }}>
                {localities.map((item) => item.name + ",")}
              </Typography>
            </Typography>
          );
        }
        const isChecked = checked.indexOf(value) !== -1;
        return (
          <Typography
            key={value}
            component={"p"}
            button
            sx={{
              background: isChecked && "#f0fdf4",
              py: 1,
              px: 1,
              fontSize: "16px",
              cursor: "pointer",
            }}
            className="list-item"
            onClick={() => handleToggle(value)}
          >
            {value}
          </Typography>
        );
      })}
    </Typography>
  </Card>
);

export default function TransferList({
  enabledLocs: right = [],
  disabledLocs: left = [],
  onChangeState = () => {},
}) {
  const [checked, setChecked] = React.useState([]);
  const [leftLocalities, setLeftLocalities] = React.useState([]);
  const [rightLocalities, setRightLocalities] = React.useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const enabledPinCodes = rightChecked.join(",");
  const disabledPinCodes = leftChecked.join(",");

  React.useEffect(() => {
    if (enabledPinCodes) {
      fetchLocalitiesRequest({
        id: enabledPinCodes,
        onSuccess: setRightLocalities,
      });
    } else if (disabledPinCodes) {
      fetchLocalitiesRequest({
        id: disabledPinCodes,
        onSuccess: setLeftLocalities,
      });
    } else {
      setLeftLocalities([]);
      setRightLocalities([]);
    }
  }, [enabledPinCodes, disabledPinCodes]);

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    onChangeState({
      enabledLocs: right.concat(left),
      disabledLocs: [],
    });
    setChecked([]);
  };

  const handleCheckedRight = () => {
    onChangeState({
      enabledLocs: right.concat(leftChecked),
      disabledLocs: not(left, leftChecked),
    });
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    onChangeState({
      enabledLocs: not(right, rightChecked),
      disabledLocs: left.concat(rightChecked),
    });
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    onChangeState({
      enabledLocs: [],
      disabledLocs: left.concat(right),
    });
    setChecked([]);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        marginTop: "20px",
      }}
    >
      <div
        style={{
          border: `1px solid ${borderColor}`,
          borderRadius: "4px",
          position: "relative",
        }}
      >
        <Typography
          textAlign={"center"}
          variant="h6"
          sx={{
            fontSize: "13px",
            px: 0.5,
            background: "white",
            position: "absolute",
            top: -10,
            left: 8,
          }}
        >
          Disabled
        </Typography>
        {/* <Divider  /> */}
        <div
          style={{
            display: "flex",
            // marginBottom: "5px",
            // gap: 10,
            // border: "1px solid rgba(0, 0, 0, 0.12)",
            borderTop: "none",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            height: 400,
            overflow: "auto",
          }}
        >
          <CustomList
            handleToggle={handleToggle}
            checked={checked}
            data={left}
            headerTitle={"Pincodes"}
            width={"40%"}
          />
          <Divider orientation="vertical" sx={{ bgcolor: borderColor }} />
          <CustomList v2 data={leftLocalities} headerTitle={"Localities"} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: 15,
        }}
      >
        <Button
          sx={{ my: 0.5, py: 0.5, px: 2 }}
          variant="outlined"
          size="small"
          onClick={handleAllRight}
          disabled={left.length === 0}
          aria-label="move all right"
        >
          {/* ≫ */}
          Enable All
        </Button>
        <Button
          sx={{ my: 0.5, py: 0.5, px: 2 }}
          variant="outlined"
          size="small"
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0}
          aria-label="move selected right"
        >
          {/* &gt; */}
          Enable {leftChecked.length ? leftChecked.length : ""}
        </Button>
        <Button
          sx={{ my: 0.5, py: 0.5, px: 2 }}
          variant="outlined"
          size="small"
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0}
          aria-label="move selected left"
        >
          {/* &lt; */}
          Disable {rightChecked.length ? rightChecked.length : ""}
        </Button>
        <Button
          sx={{ my: 0.5, py: 0.5, px: 2 }}
          variant="outlined"
          size="small"
          onClick={handleAllLeft}
          disabled={right.length === 0}
          aria-label="move all left"
        >
          {/* ≪ */}
          Disable All
        </Button>
      </div>

      <div
        style={{
          border: `1px solid ${borderColor}`,
          borderRadius: "4px",
          position: "relative",
        }}
      >
        <Typography
          textAlign={"center"}
          variant="h6"
          sx={{
            fontSize: "13px",
            px: 0.5,
            background: "white",
            position: "absolute",
            top: -10,
            left: 8,
          }}
        >
          Enabled
        </Typography>
        <div
          style={{
            display: "flex",
            borderTop: "none",
            height: 400,
            overflow: "auto",
          }}
        >
          <CustomList
            handleToggle={handleToggle}
            checked={checked}
            data={right}
            headerTitle={"Pincodes"}
            width={"40%"}
          />
          <Divider orientation="vertical" sx={{ bgcolor: borderColor }} />
          <CustomList v2 data={rightLocalities} headerTitle={"Localities"} />
        </div>
      </div>

      {/* <CustomList
        handleToggle={handleToggle}
        checked={checked}
        data={right}
        headerTitle={"Enabled"}
        width={"30%"}
      />
      <CustomList v2 data={localities} headerTitle={"Localities"} /> */}
    </div>
  );
}
