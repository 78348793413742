import React, { useEffect, useState } from "react";
import { HeaderTitle } from "../../components/header";
import { HTTP_SERVICE } from "../../utils";
import DataGrid from "../../components/DataGrid";
import { useNavigate } from "react-router-dom";
import { getCategoryListColumns } from "./constants";
import { CreateCategoryModal } from "./components/CreateCategoryModal";
import { changeCategoryOrderRequest, getCategoryList } from "./utils";

const label = { inputProps: { "aria-label": "Category isActive" } };

export const CategoryList = () => {
  const navigate = useNavigate();
  const [categoryUniqueList, setCategoryUniqueList] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleStatusChange = async (categoryId) => {
    await HTTP_SERVICE.UPDATE_CATEGORY_STATUS(categoryId);
    loadCategoryList();
  };

  const onAddPress = () => {
    setShowModal(true);
  };

  const onUpdatePress = (row) => {
    navigate(`/category/${row.categoryId}`);
  };

  const moveCategoryUp = (categoryId) => {
    changeCategoryOrderRequest({
      payload: {
        id: categoryId,
        event: "decrement",
      },
      onSuccess: () => loadCategoryList(),
    });
  };

  const moveCategoryDown = (categoryId) => {
    changeCategoryOrderRequest({
      payload: {
        id: categoryId,
        event: "increment",
      },
      onSuccess: () => loadCategoryList(),
    });
  };

  const loadCategoryList = () => {
    getCategoryList({ onSuccess: setCategoryUniqueList });
  };

  useEffect(() => {
    loadCategoryList();
  }, []);

  return (
    <>
      <HeaderTitle
        heading="Category List"
        btn={true}
        btnTitle="Add Category"
        btnAction={onAddPress}
      />
      <DataGrid
        rows={categoryUniqueList?.map((item, index) => ({
          ...item,
          index: index,
        }))}
        columns={getCategoryListColumns({
          onUpdatePress,
          label,
          handleStatusChange,
          itemLength: categoryUniqueList?.length,
          moveCategoryUp,
          moveCategoryDown,
        })}
      />

      {showModal && (
        <CreateCategoryModal
          loadCategoryList={loadCategoryList}
          closeModal={() => setShowModal(false)}
        />
      )}
    </>
  );
};
