import cloneDeep from "lodash/cloneDeep";
import React, { useState } from "react";
import { RenderNodeData } from "screens/Services/ServiceDetails/ServiceNode/components/RenderNodeData";
import ModalWrapper from "../../../../components/ModalWrapper";
import { getErrorKey, getErrorText } from "../../../../components/CustomField";
import { validateFields } from "utils";
import { getPageData } from "../utils";

const getDefaultState = ({ data, availableLanguages = [] }) => {
  let languagesData = cloneDeep(availableLanguages);
  let activeIndex = 0;
  if (data?.languageId) {
    languagesData = languagesData
      .filter((lang) => lang.languageId === data?.languageId)
      .map((lang, index) => {
        if (lang.languageId === data.languageId) {
          activeIndex = index;
          return { ...lang, stateData: cloneDeep(data) };
        }
        return lang;
      });
  }
  return { activeIndex, languagesData };
};

export const NodeModal = ({
  availableLanguages = [],
  closeModal,
  modalData,
  onSubmitPress,
  customFields,
  id,
  pageType = "",
  appType = "",
}) => {
  const { data = {}, title } = modalData || {};
  const [state, setState] = useState(
    getDefaultState({ availableLanguages, data })
  );

  if (!modalData) {
    return null;
  }

  const { activeIndex, languagesData } = state;

  const { languageName, stateData = {} } = languagesData[activeIndex] || {};
  const length = availableLanguages.length;

  const onChange = ({ value, _key }) => {
    setState((_state) => {
      _state.languagesData[activeIndex].stateData = {
        ...(_state.languagesData[activeIndex].stateData || {}),
        [_key]: value,
        [getErrorKey(_key)]: false,
        [getErrorText(_key)]: "",
      };
      return { ..._state };
    });
  };

  const validateData = () => {
    let isValidData = true;

    const { isValid, _state } = validateFields({
      _state: stateData,
      fields: customFields,
    });
    setState((_stateRef) => {
      _stateRef.languagesData[activeIndex].stateData = { ..._state };
      return { ..._stateRef };
    });

    if (!isValid) {
      isValidData = false;
    }

    return isValidData;
  };

  const updateActiveLanguage = (goBack = false) => {
    setState((_state) => {
      const _stateData = _state.languagesData[_state.activeIndex].stateData;
      _state.languagesData = _state.languagesData.map((langData) => {
        if (!langData.stateData) {
          langData.stateData = {};
        }
        customFields.forEach(({ shouldBeSame, _key }) => {
          if (shouldBeSame) {
            langData.stateData[_key] = _stateData[_key];
          }
        });
        return { ...langData };
      });
      return {
        ..._state,
        activeIndex: _state.activeIndex + (goBack ? -1 : 1),
      };
    });
  };

  const onBackPress = () => updateActiveLanguage(true);

  const onNextPress = () => {
    if (!validateData()) {
      return;
    }

    if (activeIndex < length - 1) {
      updateActiveLanguage();
      return;
    }
    if (onSubmitPress) {
      onSubmitPress(state.languagesData);
      return;
    }

    const caller = getPageData({ pageType }).createVersionRequest;

    if (caller) {
      caller({
        onSuccess: closeModal,
        id,
        data: state.languagesData.map((item) => ({
          ...item,
          languageId: item.languageId.toString(),
        })),
        appType,
      });
    }
  };

  const getFooterButtons = () => {
    const buttons = [];

    buttons.push(
      {
        onClick: onBackPress,
        sx: { px: "20px", mx: "8px" },
        title: "Back",
        disabled: !activeIndex,
      },
      {
        onClick: onNextPress,
        sx: { px: "20px" },
        title: activeIndex < length - 1 ? "Next" : "Save",
      }
    );

    return buttons;
  };

  return (
    <ModalWrapper
      sx={{ width: "50%" }}
      footerButtons={getFooterButtons()}
      background={"#eff6ff"}
      childSx={{ p: 2, pl: 0 }}
      color={"primary"}
      closeModal={closeModal}
      title={`${title} (${languageName})`}
    >
      <RenderNodeData
        customFields={customFields}
        color={"primary"}
        data={stateData}
        hardDisable={!!activeIndex}
        isNode
        onChange={onChange}
      />
    </ModalWrapper>
  );
};
