import ModalWrapper from "components/ModalWrapper";
import React, { useCallback, useEffect, useState } from "react";
import { getBookingsListColumns } from "../constants";
import { useNavigate } from "react-router-dom";
import { getRelatedBookingsRequest } from "../utils";
import CustomDataGrid from "components/DataGrid";

export default function RelatedBookingsModal({ bookingId, closeModal }) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const onViewPress = ({ _id }) => {
    closeModal();
    navigate("/bookings/" + _id);
  };

  const getBookings = useCallback(() => {
    getRelatedBookingsRequest({
      id: bookingId,
      onSuccess: (res) => {
        setData(res);
      },
    });
  }, [bookingId]);

  useEffect(() => {
    getBookings();
  }, [bookingId, getBookings]);

  return (
    <ModalWrapper
      title={"Related Bookings"}
      closeModal={closeModal}
      sx={{ width: "70%" }}
      childSx={{ height: "82vh", mb: "0px !important", pt: 0 }}
    >
      <CustomDataGrid
        rows={data}
        columns={getBookingsListColumns({ onViewPress })}
      />
    </ModalWrapper>
  );
}
