import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import { fetchUserSpecificTickets } from "../utils";
import CustomDataGrid from "components/DataGrid";
import { getCsTicketsColumns } from "../constants";
import { queryString } from "utils";
import { AddEditTicketModal } from "./AddEditTicketModal";
import { useNavigate } from "react-router-dom";

export default function RenderUserSpecificTickets({ id, userData, type }) {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [totalCount, setTotalCount] = useState(1);
  const [modalData, setModalData] = useState(null);
  const navigate = useNavigate();

  const fetchTickets = ({ query }) => {
    fetchUserSpecificTickets({
      query,
      onSuccess: (data) => {
        setData(data.items);
        setTotalCount(data.totalRecords);
      },
    });
  };

  useEffect(() => {
    if (id && type) {
      fetchTickets({ query: queryString({ ...filters, id, type }) });
    }
  }, [id, type, filters]);

  const onPageChange = (pNo) =>
    setFilters((_filters) => ({ ..._filters, pageNo: pNo + 1 }));

  const onPageSizeChange = (pSize) =>
    setFilters((_filters) => ({ ..._filters, pageSize: pSize }));

  const onRaisePress = () => setModalData({ data: null });

  const onViewPress = (row) => {
    navigate(`/support/ticket/${row._id}`);
  };

  const closeModal = () => setModalData(null);

  const onSuccess = () => {
    closeModal();
    fetchTickets({ query: queryString({ ...filters, id, type }) });
  };

  return (
    <div>
      <Header
        skip
        title="Tickets"
        buttons={
          userData?.id && [{ title: "Raise Ticket", onClick: onRaisePress }]
        }
      />
      <CustomDataGrid
        rows={data}
        onPageChange={onPageChange}
        rowsPerPageOptions={[10, 25, 50, 100]}
        pageSize={Number(filters?.pageSize || 10)}
        page={(filters?.pageNo || 1) - 1}
        paginationMode="server"
        rowCount={totalCount}
        onPageSizeChange={onPageSizeChange}
        columns={getCsTicketsColumns({ onViewPress })}
      />
      {!!modalData && (
        <AddEditTicketModal
          data={modalData}
          id={id}
          type={type}
          userData={userData}
          closeModal={closeModal}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
}
