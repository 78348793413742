import CustomDataGrid from "components/DataGrid";
import { HeaderTitle } from "components/header";
import React, { useEffect, useState } from "react";
import { queryString } from "utils";
import { getPageData } from "./utils";
import { AddLanguageModal } from "./components/AddLanguageModal";
import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { EditViewByLanguage } from "./components/EditViewByLanguage";

const TermsAndConditionsList = () => {
  const pathName = window.location.pathname;
  const [userType, pageType, version] = pathName
    .slice(1, pathName.length)
    .split("/");
  const [showAddLanguageModal, setShowAddLanguageModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const {
    requestCaller,
    languageCaller,
    headerTitle,
    headerBtnTitle,
    getColumns,
    customFields,
    data,
  } = getPageData({ pageType, version }) || {};

  const { unavailableLanguages, availableLanguages } = languageCaller() || {};

  const handleStatusChange = () => {};

  const onViewPress = (row) => {
    setModalData({
      title: "View",
      isEdit: false,
      data: row,
    });
  };

  const onEditPress = (row) => {
    setModalData({
      title: "Edit",
      isEdit: true,
      data: row,
    });
  };

  const columns = [
    ...getColumns({
      onViewPress,
      handleStatusChange,
      availableLanguages,
    }).filter(
      (column) => column.field !== "version" && column.field !== "isActive"
    ),
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: ({ row }) => {
        const onEdit = (e) => {
          e.stopPropagation();
          onEditPress(row);
        };

        return (
          <>
            <IconButton onClick={onEdit}>
              <Edit color="primary" />
            </IconButton>
          </>
        );
      },
    },
  ];

  const closeAddLanguageModal = () => setShowAddLanguageModal(false);

  const onAddPress = () => {
    setShowAddLanguageModal(true);
  };

  useEffect(() => {
    requestCaller({
      query: queryString({ appType: userType, version: version }),
    });
  }, [userType, pageType, requestCaller, version]);

  return (
    <>
      <HeaderTitle
        heading={headerTitle}
        btn
        btnTitle={headerBtnTitle}
        btnAction={onAddPress}
      />
      <CustomDataGrid rows={data} columns={columns} />

      {!!modalData && (
        <EditViewByLanguage
          closeModal={() => setModalData(null)}
          customFields={customFields}
          modalData={modalData}
          pageType={pageType}
          appType={userType}
          version={version}
        />
      )}

      {!!showAddLanguageModal && (
        <AddLanguageModal
          serviceId={version}
          data={data}
          unavailableLanguages={unavailableLanguages}
          closeModal={closeAddLanguageModal}
          pageType={pageType}
          version={version}
          appType={userType}
        />
      )}
    </>
  );
};

export default TermsAndConditionsList;
