import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { ACCOUNT_SERVICES, CUSTOMER_SUPPORT, successToast } from "utils";

export const getCsTicketsList = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  CUSTOMER_SUPPORT.GET_CS_TICKETS_LIST(query)
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const searchUserCS = ({ search, userType, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  CUSTOMER_SUPPORT.SEARCH_PROFILE_CS({ search, userType })
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const fetchUserSpecificTickets = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  CUSTOMER_SUPPORT.FETCH_USER_SPECIFIC_TICKETS(query)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createTicketRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  CUSTOMER_SUPPORT.CREATE_TICKET_REQUEST(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        successToast("Ticket created successfully!");
        onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const fetchTicketTypesRequest = ({ onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  CUSTOMER_SUPPORT.FETCH_TICKET_TYPES_REQUEST()
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getTicketInfoById = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  CUSTOMER_SUPPORT.GET_TICKET_INFO(query)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const addCommentRequest = ({ payload, setIsLoading, onSuccess }) => {
  CUSTOMER_SUPPORT.ADD_COMMENT(payload)
    .then(({ isSuccess, data }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => setIsLoading(false));
};

export const getCommentsByTicketId = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  CUSTOMER_SUPPORT.GET_COMMENTS_BY_TICKET(query)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const changeTicketStatusRequest = ({ ticketId, payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  CUSTOMER_SUPPORT.CHANGE_TICKET_STATUS(ticketId, payload)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const changeAssigneeRequest = ({ ticketId, payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  CUSTOMER_SUPPORT.CHANGE_TICKET_ASSIGNEE(ticketId, payload)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const fetchUserSpecificPayments = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  ACCOUNT_SERVICES.GET_PAYMENTS(query)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
