import { setAuthToken, setLoading, setProfileData } from "redux/actions";
import { store } from "redux/store";
import { AUTH_SERVICES, decryptString } from "utils";

export const loginRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  AUTH_SERVICES.LOGIN(payload)
    .then(({ data, isSuccess }) => {
      if (isSuccess && onSuccess) {
        onSuccess();
        const userRole = decryptString(data.userRole);
        dispatch(setAuthToken(data.token));
        dispatch(
          setProfileData({ userRole, authorityType: data.authorityType })
        );
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
