import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setScreenTitle } from "redux/actions";
import DataGrid from "../../../components/DataGrid";
import { HeaderTitle } from "../../../components/header";
import { HTTP_SERVICE, customSortComparatorForNums } from "../../../utils";

export default function EnrollmentQuestions() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadEnrollmentQuestions = () => {
    setIsLoading(true);
    HTTP_SERVICE.GET_SERVICES()
      .then(({ data }) => {
        setData(data.map((item) => ({ ...item, id: item._id })));
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: true, hide: true },
    {
      field: "serviceId",
      headerName: "Id",
      width: 100,
      sortComparator: customSortComparatorForNums,
    },
    {
      field: "name",
      headerName: "Name",
      width: 400,
    },
    {
      field: "Update",
      headerName: "Update",
      width: 300,
      renderCell: ({ row }) => {
        return (
          <div>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              sx={{ mr: 1 }}
              onClick={() =>
                navigate(`/enrollment-questions-detail/${row?.serviceId}`)
              }
            >
              Add/Edit Questions
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    loadEnrollmentQuestions();
    dispatch(setScreenTitle("Enrollment Questions"));
  }, [dispatch]);

  return (
    <>
      <HeaderTitle heading="Enrollment Questions" />
      <DataGrid loading={isLoading} rows={data} columns={columns} />
    </>
  );
}
