import CustomDataGrid from "components/DataGrid";
import { HeaderTitle } from "components/header";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setScreenTitle } from "redux/actions";
import { queryString } from "utils";
import { PAGE_TYPES } from "../constants";
import { NodeModal } from "./components/NodeModal";
import { getPageData } from "./utils";

const TermsAndConditionsVersionList = () => {
  const pathName = window.location.pathname;
  const [userType, pageType] = pathName.slice(1, pathName.length).split("/");
  const [modalData, setModalData] = useState(null);
  const { type: pageUrl, title: pageTitle } =
    PAGE_TYPES.filter(({ type }) => type === pageType)[0] || {};
  const navigate = useNavigate();

  const {
    requestCaller,
    languageCaller,
    modalTitle,
    headerTitle,
    headerBtnTitle,
    getColumns,
    customFields,
    data,
    changeStatusRequest,
  } = getPageData({ pageType }) || {};

  const activeLanguages = useSelector(
    ({ common }) => common.allActiveLanguages
  );

  const { availableLanguages, defaultLanguageId } = languageCaller() || {};

  const handleStatusChange = (row) => {
    changeStatusRequest({
      appType: userType,
      isActive: row.isActive,
      version: row.version,
    });
  };
  const onViewPress = ({ version }) => {
    navigate(`/${userType}/${pageUrl}/${version}`);
  };

  const closeModal = () => setModalData(null);

  const onAddPress = () => {
    setModalData({
      title: modalTitle,
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenTitle(pageTitle));
    requestCaller({
      query: queryString({ appType: userType, languageId: defaultLanguageId }),
    });
  }, [
    userType,
    pageType,
    dispatch,
    pageTitle,
    defaultLanguageId,
    requestCaller,
  ]);

  return (
    <>
      <HeaderTitle
        heading={headerTitle}
        btn
        btnTitle={headerBtnTitle}
        btnAction={onAddPress}
      />
      <CustomDataGrid
        rows={data}
        columns={getColumns({
          onViewPress,
          handleStatusChange,
          availableLanguages,
        })}
      />

      {!!modalData && (
        <NodeModal
          closeModal={closeModal}
          customFields={customFields}
          modalData={modalData}
          availableLanguages={activeLanguages}
          pageType={pageType}
          appType={userType}
        />
      )}
    </>
  );
};

export default TermsAndConditionsVersionList;
