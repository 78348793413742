import ModalWrapper from "components/ModalWrapper";
import React, { useState } from "react";
import { endJobRequest, startJobRequest } from "./utils";
import {
  CustomField,
  INPUT_TYPES,
  getErrorKey,
  getErrorText,
} from "components";
import { successToast, validateFields } from "utils";
import { Grid, Typography } from "@mui/material";

const START_JOB_FIELDS = [
  {
    _key: "otp",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Enter Booking Otp",
  },
];

export default function JobOtpModal({
  providerJobId,
  closeModal,
  type,
  onSuccessCallback,
}) {
  const isStart = type === "start";
  const [state, setState] = useState({});

  const onChange = ({ _key, value }) => {
    setState((_state) => ({
      ..._state,
      [_key]: value,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
    }));
  };

  const validateData = () => {
    const { isValid, _state } = validateFields({
      _state: state,
      fields: START_JOB_FIELDS,
    });

    setState({ ..._state });

    return { isValid };
  };

  const onSubmitPress = () => {
    const { isValid } = validateData();

    if (!isValid) {
      return;
    }

    const caller = isStart ? startJobRequest : endJobRequest;
    caller({
      onSuccess: () => {
        successToast(`Job ${isStart ? "started" : "ended"} successfully`);
        onSuccessCallback();
      },
      payload: { jobId: providerJobId, otp: state.otp },
    });
  };

  return (
    <ModalWrapper
      title={`${isStart ? "Start" : "End"} Job`}
      closeModal={closeModal}
      sx={{ width: "40%" }}
      childSx={{ height: "40vh", p: 2 }}
      footerButtons={[
        {
          title: "Submit",
          onClick: onSubmitPress,
        },
      ]}
    >
      <Typography color={"red"} sx={{ fontSize: "13px", mb: 1 }}>
        *Please enter otp to {isStart ? "start" : "end"} the job*
      </Typography>
      <Grid container>
        {START_JOB_FIELDS.map((item) => {
          const { _key } = item;

          if (!_key) return null;

          return (
            <Grid item md={12} sm={12} xs={12}>
              <CustomField
                item={item}
                value={state[_key]}
                error={state[getErrorKey(_key)]}
                errorText={state[getErrorText(_key)]}
                onChange={onChange}
              />
            </Grid>
          );
        })}
      </Grid>
    </ModalWrapper>
  );
}
