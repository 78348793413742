import React, { useState } from "react";
import ModalWrapper from "components/ModalWrapper";
import { CustomField, getErrorKey, getErrorText } from "components";
import { Grid } from "@mui/material";
import { validateFields } from "utils";
import { toggleMarkAsEmployeeRequest } from "../utils";
import { MARK_AS_EMPLOYEE_FIELDS } from "../constants";

export const ToggleMarkAsEmployeeModal = ({
  employeeId,
  providerId,
  onSuccess,
  closeModal,
}) => {
  const [state, setState] = useState({ employeeId });

  const validateData = () => {
    let isValidData = true;
    const { isValid, _state } = validateFields({
      _state: state,
      fields: MARK_AS_EMPLOYEE_FIELDS,
    });
    setState({ ..._state });

    if (!isValid) {
      isValidData = false;
    }

    return isValidData;
  };

  const onChange = ({ _key, value }) => {
    setState((_state) => ({
      ...state,
      [getErrorKey(_key)]: false,
      [getErrorText(_key)]: "",
      [_key]: value,
    }));
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    toggleMarkAsEmployeeRequest({
      id: providerId,
      payload: { employeeId: state.employeeId },
      onSuccess,
    });
  };

  return (
    <ModalWrapper
      footerButtons={[
        {
          title: "Submit",
          onClick: onSubmitPress,
        },
      ]}
      title={(employeeId ? "Remove" : "Mark") + " as employee"}
      closeModal={closeModal}
      sx={{ width: "50%" }}
      childSx={{ height: "50vh", p: 2 }}
    >
      <div
        style={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <Grid container rowSpacing={2} spacing={1}>
          {MARK_AS_EMPLOYEE_FIELDS.map((item) => {
            const { _key } = item;

            if (!_key) {
              return null;
            }

            return (
              <Grid key={_key} item md={12} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={item}
                  errorText={state[getErrorText(_key)]}
                  error={state[getErrorKey(_key)]}
                  value={state[_key]}
                  onChange={onChange}
                  disabled={employeeId}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </ModalWrapper>
  );
};
