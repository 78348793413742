import React, { useState } from "react";
import ProfilePicPlaceholder from "assets/image/person.jpeg";

export default function ProfilePic({ uri, size }) {
  const [error, setError] = useState(false);

  const onError = () => setError(true);

  return (
    <img
      src={error ? ProfilePicPlaceholder : uri || ProfilePicPlaceholder}
      onError={onError}
      alt={"S"}
      style={{
        width: size,
        height: size,
        borderRadius: size,
        objectFit: "cover",
        border: `1px solid`,
      }}
    />
  );
}
