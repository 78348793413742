export const PAGE_TYPE_CONSTANTS = {
  TERMS: "terms-conditions",
  FAQ: "faq",
  PRIVACY: "privacy-policy",
  ABOUT: "about-us",
  CANCELLATION: "cancellation-policy",
  REFUND: "refund-policy",
};

export const PAGE_TYPES = [
  {
    type: PAGE_TYPE_CONSTANTS.TERMS,
    title: "Terms And Conditions",
  },
  {
    type: PAGE_TYPE_CONSTANTS.FAQ,
    title: "FAQ's",
  },
  {
    type: PAGE_TYPE_CONSTANTS.PRIVACY,
    title: "Privacy Policy",
  },
  {
    type: PAGE_TYPE_CONSTANTS.ABOUT,
    title: "About Us",
  },
  {
    type: PAGE_TYPE_CONSTANTS.CANCELLATION,
    title: "Cancellation Policy",
  },
  {
    type: PAGE_TYPE_CONSTANTS.REFUND,
    title: "Refund Policy",
  },
];
