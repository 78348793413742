export const UPDATE_CURRENT_TERMS_DATA = "UPDATE_CURRENT_TERMS_DATA";
export const UPDATE_CURRENT_PRIVACY_DATA = "UPDATE_CURRENT_PRIVACY_DATA";
export const UPDATE_CURRENT_ABOUT_DATA = "UPDATE_CURRENT_ABOUT_DATA";
export const UPDATE_CURRENT_CANCELLATION_POLICY_DATA =
  "UPDATE_CURRENT_CANCELLATION_POLICY_DATA";
export const UPDATE_CURRENT_REFUND_POLICY_DATA =
  "UPDATE_CURRENT_REFUND_POLICY_DATA";

export const updateCurrentTermsData = (payload) => ({
  type: UPDATE_CURRENT_TERMS_DATA,
  payload,
});

export const updateCurrentPrivacyData = (payload) => ({
  type: UPDATE_CURRENT_PRIVACY_DATA,
  payload,
});

export const updateCurrentAboutData = (payload) => ({
  type: UPDATE_CURRENT_ABOUT_DATA,
  payload,
});

export const updateCurrentCancellationPolicyData = (payload) => ({
  type: UPDATE_CURRENT_CANCELLATION_POLICY_DATA,
  payload,
});

export const updateCurrentRefundPolicyData = (payload) => ({
  type: UPDATE_CURRENT_REFUND_POLICY_DATA,
  payload,
});
