import { Box, Card, CardContent } from "@mui/material";
import CustomDataGrid from "components/DataGrid";
import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserInfoColumns } from "./constants";
import {
  getDashboardUsersRequest,
  updateDashboardUserStatusRequest,
} from "./utils";

export default function Users() {
  const [users, setUsersData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getDashboardUsersRequest({ onSuccess: setUsersData });
  }, []);

  const onEditPress = ({ _id }) => navigate("/users/" + _id);
  const onAddNewUser = () => navigate("/add-users");
  const handleStatusChange = ({ isActive, id }) => {
    updateDashboardUserStatusRequest({
      id,
      payload: { isActive: !isActive },
      onSuccess: () => getDashboardUsersRequest({ onSuccess: setUsersData }),
    });
  };

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header
            title={"Dashboard Users"}
            buttons={[{ title: "Add User", onClick: onAddNewUser }]}
          />
          <CustomDataGrid
            rows={users}
            columns={getUserInfoColumns({ onEditPress, handleStatusChange })}
          />
        </CardContent>
      </Card>
    </Box>
  );
}
