import { TextField } from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";

export default function CustomTimePicker({
  disabled,
  error,
  _key,
  errorText,
  onChange,
  color,
  extraProps = {},
  placeholder,
  value,
}) {
  const [state, setState] = useState(value);

  useEffect(() => {
    if (value) {
      const [hours, minutes] = value.split(":");
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      setState(date);
    }
  }, [value]);

  const onAccept = (val) => {
    const date = new Date(val);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    onChange({ value: `${hours}:${minutes}`, _key });
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileTimePicker
        label={placeholder}
        value={state || null}
        ampm={false}
        onAccept={onAccept}
        onClose={() => setState(value)}
        {...extraProps}
        onChange={setState}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={disabled}
            error={error}
            helperText={errorText}
            size="small"
            color={color}
            fullWidth
            {...extraProps}
          />
        )}
      />
    </LocalizationProvider>
  );
}
