import React, { useState } from "react";
import { Close, Fullscreen, Remove } from "@mui/icons-material";
import { Button, IconButton, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Draggable from "react-draggable";

export default function ModalWrapper({
  footerButtons,
  footerText = "",
  title = "",
  sx = {},
  childSx,
  color = "primary",
  background = "#f0fdf4",
  closeModal,
  children,
}) {
  const [isMinimized, setIsMinimized] = useState(false);

  if (isMinimized) {
    return (
      <Draggable>
        <div
          style={{
            position: "fixed",
            bottom: 8,
            right: 8,
            padding: "6px 14px",
            paddingRight: "2px",
            border: "1px solid black",
            background: "white",
            borderRadius: "8px",
            boxShadow: "4px 4px rgba(0, 0, 0, 0.4)",
            zIndex: "999999999",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 26,
            }}
          >
            <span>{title}</span>
            <div>
              <IconButton onClick={() => setIsMinimized(false)}>
                <Fullscreen color={color} />
              </IconButton>
              <IconButton onClick={closeModal}>
                <Close color={color} />
              </IconButton>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }

  return (
    <Modal
      open={!isMinimized}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ outline: "none" }}
    >
      <div>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: { xs: "90vw", sm: "40vw" },
            width: "60%",
            maxHeight: "95vh",
            bgcolor: "background.paper",
            boxShadow: 4,
            borderRadius: "8px",
            ...sx,
          }}
        >
          <Typography
            color={color}
            id="modal-modal-title"
            component="div"
            sx={{
              display: "flex",
              background,
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1.5px solid",
              p: 2,
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {title}
            </Typography>
            <div style={{ display: "flex" }}>
              <IconButton
                sx={{ display: { xs: "none", sm: "inline-flex" } }}
                onClick={() => setIsMinimized(true)}
              >
                <Remove color={color} />
              </IconButton>
              <IconButton onClick={closeModal}>
                <Close color={color} />
              </IconButton>
            </div>
          </Typography>
          <Typography
            id="modal-modal-description"
            component={"div"}
            style={{ marginBottom: !!footerButtons ? "60px" : 0 }}
            sx={{
              overflowY: "auto",
              height: { xs: "60vh", sm: "75vh" },
              p: 2,
              ...childSx,
            }}
          >
            {children}
          </Typography>
          {!!footerButtons && (
            <Typography
              id="modal-modal-footer"
              component="div"
              color={color}
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                zIndex: 111,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderTop: "1.5px solid",
                p: 2,
                background: "white",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              {!!footerText && (
                <Typography sx={{ color: "black", fontWeight: 600 }}>
                  {footerText}
                </Typography>
              )}
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                {footerButtons?.map((item, index) => (
                  <Button
                    key={index}
                    color={color}
                    variant="contained"
                    sx={{ px: "20px" }}
                    {...item}
                  >
                    {item?.title}
                  </Button>
                ))}
              </Typography>
            </Typography>
          )}
        </Box>
      </div>
    </Modal>
  );
}
