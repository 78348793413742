import { ConfirmationModal } from "components/ConfirmationModal";
import React, { useEffect, useState } from "react";
import { suspendBookingRequest } from "../utils";
import { getReasonsListRequest } from "screens/Services/ServiceDetails/Reasons/utils";
import { queryString } from "utils";
import { REASON_TYPES } from "screens/Services/ServiceDetails/Reasons/constants";

const SuspendBookingModal = ({
  bookingId,
  serviceId,
  onClose,
  onSuccessCallback,
}) => {
  const [reasons, setReasons] = useState([]);

  const onSuspendBooking = ({ reason }) => {
    suspendBookingRequest({
      payload: {
        id: bookingId,
        reasonId: reason,
      },
      onSuccess: () => {
        onSuccessCallback();
        onClose();
      },
    });
  };

  const modalData = {
    title: "Suspend Booking",
    description: "Are you sure you want to suspend this booking?",
    reasons,
    footerButtons: [
      {
        title: "Cancel",
        onClick: onClose,
        sx: { mr: 2 },
        color: "error",
        variant: "outlined",
      },
      {
        title: "Suspend Booking",
        color: "error",
        isRequired: true,
        onClick: onSuspendBooking,
      },
    ],
  };

  useEffect(() => {
    getReasonsListRequest({
      query: queryString({
        reasonApp: "both",
        type: REASON_TYPES.suspend,
        serviceId,
        languageId: 1,
        isActive: true,
      }),
      onSuccess: (data) => {
        setReasons(
          data.map((reason) => ({
            label: reason.reason,
            value: reason.reasonId,
          }))
        );
      },
    });
  }, [serviceId]);

  return (
    <ConfirmationModal
      modalData={modalData}
      closeModal={onClose}
      color={"error"}
    />
  );
};

export default SuspendBookingModal;
