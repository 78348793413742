export const USER_PRIVILEGE_TYPES = {
  providers: { label: "Providers", value: "providers" },
  services: { label: "Services", value: "services" },
  both: { label: "Both", value: "both" },
};

export const ACCESS_TYPES = {
  read: { value: "read", label: "Read" },
  write: { value: "write", label: "Read & Write" },
};

export const ROLE_TYPES = {
  "super-admin": { value: "super-admin", label: "Super Admin" },
  "country-admin": {
    accessCountry: true,
    value: "country-admin",
    label: "Operations Head - Country",
  },
  "state-admin": {
    accessCountry: true,
    accessState: true,
    value: "state-admin",
    label: "Operations Head - State",
  },
  "district-admin": {
    accessCountry: true,
    accessState: true,
    accessDistricts: true,
    value: "district-admin",
    label: "Operations Head - District",
  },
  "country-support-admin": {
    accessCountry: true,
    value: "country-support-admin",
    label: "Support Executive",
  },
  // "state-support-admin": {
  //   accessCountry: true,
  //   accessState: true,
  //   value: "state-support-admin",
  //   label: "Support Head - State",
  // },
  // "district-support-admin": {
  //   accessCountry: true,
  //   accessState: true,
  //   accessDistricts: true,
  //   value: "district-support-admin",
  //   label: "Support Head - District",
  // },
};

export const FILE_TYPES = {
  VIDEO: "video",
  IMAGE: "image",
};

export const COUNTRIES = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "INDIA",
    value: "INDIA",
  },
];

export const TRUE_FALSE = [
  { label: "true", value: "true" },
  { label: "false", value: "false" },
];
