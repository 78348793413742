import { ViewCarousel, Image } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { ImageUploaderModal } from "./ImageUploaderModal";

export const ImageUploader = ({
  color = "primary",
  customMd,
  _key,
  collection,
  onSuccessCallback,
  isNode,
  showImageUploader,
  data = {},
  placeholder,
}) => {
  const [uploaderData, setUploaderData] = useState(null);

  const onAddImages = (e) => {
    e && e.stopPropagation && e.stopPropagation();
    setUploaderData({
      title: `${
        isNode || showImageUploader ? "Upload Banners" : "Upload Image"
      } ${data.title ? `[${data.title}]` : ""}`,
    });
  };

  const closeUploader = () => setUploaderData(null);

  return (
    <Grid item md={customMd || 3} sm={12} xs={12}>
      <Typography
        color={showImageUploader ? "primary" : color}
        component={"div"}
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid rgba(0, 0, 0, 0.2)",
          "border:hover": "1px solid rgba(0, 0, 0, 0.87)",
          borderRadius: "4px",
          cursor: "pointer",
        }}
        onClick={onAddImages}
      >
        <IconButton>
          {isNode || showImageUploader ? <ViewCarousel /> : <Image />}
        </IconButton>
        <Typography>
          {placeholder || (isNode || showImageUploader ? "Banners" : "Image")}
          {!!data[_key] && ` (${isNode ? data[_key]?.length : 1})`}
        </Typography>
      </Typography>

      {!!uploaderData && (
        <ImageUploaderModal
          closeModal={closeUploader}
          multiple={isNode}
          _key={_key}
          isNode={isNode}
          onSuccessCallback={onSuccessCallback}
          collection={collection}
          modalData={uploaderData}
          showImageUploader={showImageUploader}
          data={data}
          placeholder={placeholder}
        />
      )}
    </Grid>
  );
};
