import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { INPUT_TYPES } from "components";
import moment from "moment";
import { customSortComparatorForNums } from "utils";

export const CONTACT_US_FIELDS = [
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "mobileNumber",
    placeholder: "Mobile Number",
    md: 12,
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "email",
    placeholder: "Email",
    md: 12,
  },
];

export const CONTACT_QUERY_FIELDS = [
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "mobileNumber",
    placeholder: "Mobile Number",
    md: 6,
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "createdAt",
    placeholder: "Created At",
    md: 6,
  },
  {
    inputType: INPUT_TYPES.TEXT_AREA,
    _key: "message",
    placeholder: "Message",
    md: 12,
  },
];

export const getQueryColumns = ({ onViewPress }) => [
  { field: "_id", headerName: "_id", width: 88, hide: true },
  {
    field: "id",
    headerName: "S.No",
    width: 100,
    sortable: true,
    sortComparator: customSortComparatorForNums,
  },
  {
    field: "mobileNumber",
    headerName: "Mobile Number",
    width: 250,
  },
  {
    field: "message",
    headerName: "Message",
    width: 300,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 250,
    renderCell: ({ row }) =>
      moment(new Date(row.createdAt)).format("DD MMM YYYY, H:mm"),
  },
  {
    field: "view",
    headerName: "View",
    width: 100,
    renderCell: ({ row }) => {
      const onView = (e) => {
        e.stopPropagation();
        onViewPress(row);
      };

      return (
        <>
          <IconButton onClick={onView}>
            <Visibility color="primary" />
          </IconButton>
        </>
      );
    },
  },
];
