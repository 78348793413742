import { setLoading } from "../../../../redux/actions";
import { store } from "../../../../redux/store";
import { HTTP_SERVICE } from "../../../../utils/apiServices";

export const getStatesRequest = ({ includeAllOption, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_STATES()
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        const _data = data.items.map((item) => item.name);
        onSuccess && onSuccess(includeAllOption ? ["ALL", ..._data] : _data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getDistrictsRequest = ({ state, onSuccess, includeAllOption }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_DISTRICTS({ state })
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        data = data.items.map((item) => item.name);
        onSuccess && onSuccess(includeAllOption ? ["ALL", ...data] : data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getPinCodesRequest = ({
  district,
  state,
  onSuccess,
  includeAllOption,
}) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_PIN_CODES({ district, state })
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        data = data.items.map((item) => item.pincode);

        onSuccess && onSuccess(includeAllOption ? ["ALL", ...data] : data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateEnabledLocsRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.UPDATE_ENABLED_LOCS(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getEnabledLocationsTreeRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_ENABLED_LOCATION_TREE(id)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess(data?.states || []);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const fetchLocalitiesRequest = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.FETCH_LOCALITIES(id)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess(data?.items || []);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getEnabledLocationsRequest = ({ serviceId, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_ENABLED_PIN_CODES(serviceId)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess(data.pincodes || []);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
