import { setLoading } from "redux/actions";
import { updateCurrentCategoryData } from "redux/actions/categories";
import { store } from "redux/store";
import { filterUniqueValues, HTTP_SERVICE } from "utils";

export const getCategoryList = ({ onSuccess }) => {
  const dispatch = store.dispatch;
  const languagesMap = store.getState().common?.languagesMap || [];
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_CATEGORY()
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        let newRow = data.map((item, index) => ({
          ...item,
          id: item._id,
          categoryLanguage: languagesMap[item.categoryLanguage].languageName,
        }));

        onSuccess &&
          onSuccess(
            filterUniqueValues(newRow, "categoryId", "categoryLanguage")
          );
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createCategoryRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.ADD_CATEGORY(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const getCategoryById = ({ id }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.GET_CATEGORY_BY_ID(id)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        dispatch(updateCurrentCategoryData(data));
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateCategoryRequest = ({
  payload,
  categoryId,
  languageId,
  onSuccess,
}) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.UPDATE_CATEGORY(payload, categoryId, languageId)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        onSuccess && onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const changeCategoryOrderRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;
  dispatch(setLoading({ loading: true }));
  HTTP_SERVICE.CHANGE_CATEGORY_LIST_ORDER(payload)
    .then(({ data, isSuccess }) => {
      isSuccess && onSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
