import { INPUT_TYPES } from "components";

const PUSH_NOTIFICATION_FIELDS = [
  {
    _key: "title",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Title",
    md: 6,
  },
  {
    _key: "image",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Image",
    isOptional: true,
    md: 6,
  },
  {
    _key: "body",
    inputType: INPUT_TYPES.TEXT_AREA,
    placeholder: "Body",
  },
];

const EMAIL_FIELDS = [
  {
    _key: "title",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Title",
    md: 6,
  },
  {
    _key: "image",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Image",
    isOptional: true,
    md: 6,
  },
  {
    _key: "body",
    inputType: INPUT_TYPES.TEXT_AREA,
    placeholder: "Body",
    extraProps: { minRows: 3 },
  },
];

const SMS_FIELDS = [
  {
    _key: "body",
    inputType: INPUT_TYPES.TEXT_AREA,
    placeholder: "Body",
    extraProps: { minRows: 3 },
  },
];

const WHATSAPP_FIELDS = [
  {
    _key: "image",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Image",
    isOptional: true,
  },
  {
    _key: "body",
    inputType: INPUT_TYPES.TEXT_AREA,
    placeholder: "Body",
    extraProps: { minRows: 3 },
  },
];

export const TEMPLATE_TYPE = [
  {
    label: "Push Notification",
    value: "pushnotification",
    fields: PUSH_NOTIFICATION_FIELDS,
  },
  { label: "Email", value: "email", fields: EMAIL_FIELDS },
  { label: "SMS", value: "sms", fields: SMS_FIELDS },
  { label: "WhatsApp", value: "whatsapp", fields: WHATSAPP_FIELDS },
];

export const TEMPLATE_INFO_FIELDS = [
  {
    _key: "templateName",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Template Name",
    extraProps: { sx: { my: "16px" } },
  },
];
