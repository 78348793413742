import { Box, Card, CardContent } from "@mui/material";
import Header from "components/HeaderV2";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomFilter from "components/CustomFilter";
import { queryString, useGetLanguageData } from "utils";
import CustomDataGrid from "components/DataGrid";
import qs from "query-string";
import { getBannersListRequest, updateBannerRequest } from "./utils";
import { BANNER_FILTER_FIELDS, getBannersColumns } from "./constants";
import { AddEditBannerModal } from "./components/AddEditBannerModal";

export default function Banners() {
  const [data, setData] = useState([]);
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState({});
  const selectedLanguage = 1;
  const [modalData, setModalData] = useState(null);
  const closeModal = () => setModalData(null);

  const navigate = useNavigate();

  const { allLanguages, languagesKeyValPair } = useGetLanguageData({
    data,
  });

  const fetchBannersList = useCallback(
    () =>
      getBannersListRequest({
        query: queryString(filters),
        onSuccess: (data) => {
          const tempData = data.map((item, index) => ({
            ...item,
            id: item._id,
            // index: index + 1,
            bannerLanguageDetail: item.bannerLangauageDetail.map((langItem) => {
              const langData = {
                ...langItem,
                bannerUrl: ``,
                bannerUrlfile: langItem.bannerUrl,
                languageName: languagesKeyValPair[langItem.languageId],
              };

              return langData;
            }),
          }));

          setData([...tempData]);
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, selectedLanguage]
  );

  const onCreatePress = () => setModalData({ data: null });

  const onViewPress = (row) => setModalData({ data: row, isReadOnly: true });

  const onEditPress = (row) => setModalData({ data: row });

  const handleStatusChange = (row) => {
    updateBannerRequest({
      bannerId: row?._id,
      payload: { isActive: !row.isActive },
      onSuccess: fetchBannersList,
    });
  };

  const setFiltersData = (_state) => {
    navigate(window.location.pathname + "?" + queryString(_state), {
      replace: true,
    });
    setFilters(_state);
  };

  useEffect(() => {
    const params = qs.parse(searchParams.toString());
    if (params.status) {
      params.status = params.status
        .split(",")
        .map((value) => ({ label: value, value }));
    }
    setFilters({ ...params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchBannersList();
  }, [fetchBannersList]);

  const languageData = data.map((item, index) => {
    return {
      ...item,
      ...item.couponLanguageDetail?.find(
        (langItem) => langItem.languageId === selectedLanguage
      ),
      index: index + 1,
    };
  });

  return (
    <Box sx={{ my: 1 }}>
      <Card>
        <CardContent>
          <Header
            title="Banners"
            buttons={[{ title: "Create Banner", onClick: onCreatePress }]}
          />
          <CustomFilter
            onChange={setFiltersData}
            filtersData={filters}
            fields={BANNER_FILTER_FIELDS}
            includeAllOption={true}
          />
          <CustomDataGrid
            rows={languageData}
            columns={getBannersColumns({
              onViewPress,
              onEditPress,
              handleStatusChange,
            })}
          />
        </CardContent>
      </Card>

      {!!modalData && (
        <AddEditBannerModal
          modalData={modalData}
          selectedLanguage={selectedLanguage}
          languages={allLanguages}
          onSuccessCallback={fetchBannersList}
          closeModal={closeModal}
          fetchBanners={fetchBannersList}
        />
      )}
    </Box>
  );
}
