import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { SPARE_PART_SERVICES } from "utils";

export const fetchSparePartsListRequest = ({
  query,
  onSuccess,
  languagesOrder,
}) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  SPARE_PART_SERVICES.GET(query)
    .then(({ data, isSuccess }) => {
      if (isSuccess) {
        let sorted = data.map((appliance) => {
          appliance.categories.sort((a, b) => +a.categoryId - +b.categoryId);
          return {
            ...appliance,
            categories: appliance.categories.map((categoryItem) => {
              categoryItem.spareParts.sort(
                (a, b) => +a.sparePartId - +b.sparePartId
              );
              return {
                ...categoryItem,
              };
            }),
          };
        });

        onSuccess(sorted);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createSparePartRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  SPARE_PART_SERVICES.SAVE(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateSparePartRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  SPARE_PART_SERVICES.UPDATE(payload)
    .then(({ isSuccess }) => {
      if (isSuccess) {
        onSuccess();
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const sortByLanguageOrder = ({ languageOrder, applianceData }) => {
  return applianceData.sort(
    (a, b) =>
      languageOrder.indexOf(a.languageId) - languageOrder.indexOf(b.languageId)
  );
};
