import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { ACCOUNT_SERVICES } from "utils";

export const fetchPayOrdersRequest = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  ACCOUNT_SERVICES.FETCH_PAYORDERS_LIST(query)
    .then(({ isSuccess, data }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const fetchPayOrderInfo = ({ id, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  ACCOUNT_SERVICES.GET_PAYORDER_INFO(id)
    .then(({ isSuccess, data }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createPayOrderRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  ACCOUNT_SERVICES.CREATE_PAYORDER(payload)
    .then(({ isSuccess, data }) => {
      if (isSuccess) {
        onSuccess(data);
      }
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
