import { setLoading } from "redux/actions";
import { store } from "redux/store";
import { PROMOTION_SERVICES } from "utils";

export const getBannersListRequest = ({ query, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  PROMOTION_SERVICES.FETCH_BANNERS_LIST(query)
    .then(({ isSuccess, data }) => {
      isSuccess && onSuccess(data);
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const createBannerRequest = ({ payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  PROMOTION_SERVICES.CREATE_BANNER(payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateBannerRequest = ({ bannerId, payload, onSuccess }) => {
  const dispatch = store.dispatch;

  dispatch(setLoading({ loading: true }));
  PROMOTION_SERVICES.UPDATE_BANNER(bannerId, payload)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};

export const updateBannerLanguageRequest = ({ bannerId, payload, onSuccess, fileKey }) => {
  const dispatch = store.dispatch;

  const formData = new FormData();

  Object.keys(payload).forEach((_key) => {
    if (_key === fileKey) {
      formData.append("files", payload[_key]);
    } else {
      formData.append(_key, payload[_key]);
    }
  });

  dispatch(setLoading({ loading: true }));
  PROMOTION_SERVICES.UPDATE_BANNER(bannerId, formData)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};


export const addBannerLanguageRequest = ({ payload, onSuccess, fileKey }) => {
  const dispatch = store.dispatch;

  const formData = new FormData();

  Object.keys(payload).forEach((_key) => {
    if (_key === fileKey) {
      formData.append("files", payload[_key]);
    } else {
      formData.append(_key, payload[_key]);
    }
  });

  dispatch(setLoading({ loading: true }));
  PROMOTION_SERVICES.ADD_BANNER_LANGUAGE(formData)
    .then(({ isSuccess }) => {
      isSuccess && onSuccess();
    })
    .finally(() => dispatch(setLoading({ loading: false })));
};
