import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { INPUT_TYPES, REGEX } from "components";
import moment from "moment";
import { TRUE_FALSE } from "screens/Services/ServiceDetails/ServiceNode/constants";
import { COUNTRIES } from "shared/constants";

export const CS_TICKET_USERS = {
  CUSTOMER: "customer",
  PROVIDER: "provider",
  ADMIN: "admin",
};

export const CS_TICKET_RELATED_TYPE = {
  BOOKING: "booking",
  JOB: "job",
  PAYMENT: "payment",
};

export const CS_TICKET_STATUSES = [
  {
    label: "initiated",
    value: "initiated",
  },
  {
    label: "open",
    value: "open",
  },
  {
    label: "reopen",
    value: "reopen",
  },
  {
    label: "closed",
    value: "closed",
  },
  {
    label: "assigned",
    value: "assigned",
  },
];

export const CS_TICKETS_FILTER = [
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "serviceId",
    placeholder: "Service",
    dataKey: "services",
    deleteValues: { serviceLabel: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "state",
    placeholder: "State",
    dataKey: "states",
    overRideValues: { district: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "district",
    placeholder: "District",
    dataKey: "districts",
    dependentKey: "state",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "typeId",
    placeholder: "Type",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "status",
    placeholder: "Status",
    extraData: CS_TICKET_STATUSES,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "assignedToMe",
    placeholder: "Assigned to me ?",
    extraData: TRUE_FALSE,
  },
];

export const CS_TICKET_FIELDS = [
  {
    _key: "title",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Title",
  },
  {
    _key: "description",
    inputType: INPUT_TYPES.TEXT_AREA,
    placeholder: "Description",
    extraProps: {
      minRows: 4,
    },
  },
  {
    _key: "type",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Type",
  },
  {
    _key: "createdByType",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Created By",
  },
  {
    _key: "raisedFor",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Raised For",
  },
  {
    _key: "relatedToType",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Related To",
  },
  {
    _key: "status",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Status",
  },
  {
    _key: "createdAt",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Raised On",
  },
];

export const getCsTicketsColumns = ({ onViewPress }) => [
  { field: "id", headerName: "Ticket Id", width: 90 },
  {
    field: "title",
    headerName: "Title",
    width: 200,
  },
  {
    field: "description",
    headerName: "Description",
    width: 200,
  },
  {
    field: "type",
    headerName: "Type",
    width: 200,
  },
  {
    field: "relatedToType",
    headerName: "Related To",
    width: 200,
  },
  {
    field: "raisedFor",
    headerName: "Raised For",
    width: 200,
  },
  {
    field: "createdByType",
    headerName: "Raised By",
    width: 200,
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
  },
  {
    field: "assignee",
    headerName: "Assignee",
    width: 200,
    renderCell: ({ row }) => row?.assignee?.name || "-",
  },
  {
    field: "view",
    headerName: "View",
    width: 100,
    renderCell: ({ row }) => {
      const onView = (e) => {
        e.stopPropagation();
        onViewPress(row);
      };
      return (
        <IconButton onClick={onView}>
          <Visibility color="primary" />
        </IconButton>
      );
    },
  },
];

export const ACCOUNT_TYPES = [
  { label: "Provider", value: "provider" },
  { label: "Customer", value: "customer" },
];

export const VOUCHER_TYPES = [
  { label: "Credit", value: "credit" },
  { label: "Debit", value: "debit" },
];

export const PAYMENT_MODES = [
  { label: "Cash", value: "cash" },
  { label: "Online", value: "online" },
];

export const PAYMENT_METHOD = [
  { label: "NET_BANKING", value: "Net Banking" },
  { label: "UPI", value: "upi" },
  { label: "DEBIT_CARD", value: "Debit Card" },
  { label: "CREDIT_CARD", value: "Credit Card" },
  { label: "CASH", value: "Cash" },
];

export const PAYMENT_TYPES = [
  { label: "Commission", value: "Commission" },
  { label: "Advance", value: "Advance" },
  { label: "Refund", value: "Refund" },
  { label: "Journal", value: "Journal" },
  { label: "Booking", value: "Booking" },
  { label: "Payback", value: "Payback" },
];

export const PAYMENT_STATUSES = [
  { label: "Success", value: "success" },
  { label: "Failure", value: "failure" },
];

export const PAYMENT_FIELDS = [
  {
    _key: "txnId",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Transaction ID",
  },
  {
    _key: "amount",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Amount",
    regex: REGEX.NUMBERS,
  },
  {
    _key: "accountType",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Account Holder Type",
    extraData: ACCOUNT_TYPES,
  },
  {
    _key: "customerId",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Account Holder Id",
  },
  {
    _key: "date",
    inputType: INPUT_TYPES.DATE,
    placeholder: "Date",
  },
  {
    _key: "type",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Type",
    extraData: PAYMENT_MODES,
  },
  {
    _key: "paymentMethod",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Payment Method",
    extraData: PAYMENT_METHOD,
  },
  {
    _key: "paymentType",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Payment Type",
    extraData: PAYMENT_TYPES,
  },
  {
    _key: "voucherType",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Voucher Type",
    extraData: VOUCHER_TYPES,
  },
  {
    _key: "status",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Status",
    extraData: PAYMENT_STATUSES,
  },
  {
    _key: "UtrNo",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "UTR No.",
  },
  {
    _key: "description",
    inputType: INPUT_TYPES.TEXT_AREA,
    placeholder: "Description",
  },
];

export const PAYMENTS_FILTER_FIELDS = [
  {
    _key: "isActive",
    inputType: INPUT_TYPES.DROPDOWN,
    placeholder: "Active",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "serviceId",
    placeholder: "Service",
    dataKey: "services",
    deleteValues: { serviceLabel: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "country",
    placeholder: "Country",
    overRideValues: { state: "" },
    extraData: COUNTRIES,
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "state",
    placeholder: "State",
    dataKey: "states",
    dependentKey: "country",
    overRideValues: { district: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "district",
    placeholder: "District",
    dataKey: "districts",
    dependentKey: "state",
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "pincode",
    placeholder: "Pincode",
    dataKey: "pincodes",
    dependentKey: "district",
  },
];

export const getPaymentsColumns = ({ onViewPress }) => [
  { field: "id", headerName: "ID", width: 90, hide: true },
  {
    field: "txnId",
    headerName: "Txn Id",
    width: 100,
  },
  {
    field: "type",
    headerName: "Type",
    width: 100,
  },
  // {
  //   field: "accountType",
  //   headerName: "Account Type",
  //   width: 150,
  // },
  // {
  //   field: "accountHolderId",
  //   headerName: "Account Holder",
  //   width: 150,
  // },
  {
    field: "amount",
    headerName: "Amount",
    width: 130,
    renderCell: ({ row }) => `₹${row.amount}`,
  },
  {
    field: "paymentMethod",
    headerName: "Payment Method",
    width: 160,
  },
  {
    field: "paymentType",
    headerName: "Payment Type",
    width: 150,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 180,
    renderCell: ({ row }) =>
      moment(row.createdAt).format("DD MMM YYYY, hh:mm A"),
  },
  {
    field: "UtrNo",
    headerName: "UTR No.",
    width: 150,
    renderCell: ({ row }) => row.UtrNo || "-",
  },
  // {
  //   field: "remarks",
  //   headerName: "Remarks",
  //   width: 150,
  //   renderCell: ({ row }) => row.remarks || "-",
  // },
  {
    field: "description",
    headerName: "Description",
    width: 200,
    renderCell: ({ row }) => row.description || "-",
  },
  {
    field: "view",
    headerName: "View",
    width: 100,
    renderCell: ({ row }) => {
      const onView = (e) => {
        e.stopPropagation();
        onViewPress(row);
      };
      return (
        <IconButton onClick={onView}>
          <Visibility color="primary" />
        </IconButton>
      );
    },
  },
];
