import React, { useState } from "react";
import ModalWrapper from "components/ModalWrapper";
import { CustomField, getErrorKey, getErrorText } from "components";
import { Grid } from "@mui/material";
import { validateFields } from "utils";
import { CHARGE_CONFIG_FIELDS } from "../constants";
import {
  addServiceChargeConfigRequest,
  updateServiceChargeConfigRequest,
} from "../utils";

export const AddEditChargesModal = ({
  data: _data,
  serviceId,
  onSuccessCallback = () => {},
  closeModal,
  chargeTypes,
}) => {
  const { data } = _data;
  const isEdit = !!data;
  const [state, setState] = useState(data || {});

  const validateData = () => {
    const { isValid, _state } = validateFields({
      _state: state,
      fields: CHARGE_CONFIG_FIELDS,
    });

    setState({ ..._state });

    return { isValid };
  };

  const onChange = ({ _key, value }) => {
    setState((_state) => ({ ..._state, [_key]: value }));
  };

  const onSubmitPress = () => {
    if (!validateData()) {
      return;
    }

    const payload = {
      serviceId: serviceId,
      chargeTypeId: state.chargeTypeId,
      isActive: isEdit ? state.isActive : true,
      isTaxApplicable: state.isTaxApplicable === "true",
      taxPercentage: state.taxPercentage || 0,
      isCommissionAble: state.isCommissionAble === "true",
      configType: state.configType,
    };

    if (isEdit) payload._id = data._id;

    const caller = isEdit
      ? updateServiceChargeConfigRequest
      : addServiceChargeConfigRequest;

    caller({
      payload,
      onSuccess: () => {
        onSuccessCallback();
      },
    });
    closeModal();
  };

  return (
    <ModalWrapper
      footerButtons={[
        {
          title: "Submit",
          onClick: onSubmitPress,
        },
      ]}
      title={`${isEdit ? "Edit" : "Add"} Charges`}
      closeModal={closeModal}
      sx={{ width: "60%" }}
      childSx={{ height: "60vh", p: 2, overflow: "auto" }}
    >
      <div
        style={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <Grid container rowSpacing={2} spacing={1}>
          {CHARGE_CONFIG_FIELDS.map((item) => {
            const { _key, dependentKey } = item;

            if (!_key) {
              return null;
            }

            if (
              dependentKey &&
              (!state[dependentKey] || state[dependentKey] === "false")
            ) {
              return null;
            }

            if (_key === "chargeTypeId") {
              item.extraData = chargeTypes;
            }

            return (
              <Grid key={_key} item md={6} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={item}
                  errorText={state[getErrorText(_key)]}
                  error={state[getErrorKey(_key)]}
                  value={state[_key]}
                  onChange={onChange}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </ModalWrapper>
  );
};
