import { REHYDRATE } from "redux-persist";
import { RESET } from "../actions";
import { UPDATE_CURRENT_CATEGORY_DATA } from "../actions/categories";

const initialState = {
  categoryInfo: [],
  cateoryLanguages: [],
};

const CategoriesReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_CATEGORY_DATA:
      const categoryInfo = action.payload || [];
      const categoryLanguages = [];
      categoryInfo.forEach((item) =>
        categoryLanguages.push(item.categoryLanguage)
      );
      return {
        ...state,
        categoryInfo,
        categoryLanguages,
      };

    case REHYDRATE:
      return {
        ...initialState,
        ...((action.payload || {}).common || {}),
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default CategoriesReducer;
