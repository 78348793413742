import { Edit, Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { INPUT_TYPES } from "components";
import { customSortComparatorForNums } from "utils";

export const CHARGES_FIELDS = [
  {
    _key: "chargeName",
    inputType: INPUT_TYPES.INPUT,
    placeholder: "Charge Name",
  },
  {
    _key: "description",
    inputType: INPUT_TYPES.TEXT_AREA,
    placeholder: "Description",
    extraProps: { minRows: 4 },
  },
];

export const getChargesListColumns = ({ onEditPress }) => {
  return [
    {
      field: "id",
      headerName: "Id",
      width: 60,
      hide: true,
    },
    {
      field: "index",
      headerName: "S. No.",
      width: 120,
      sortComparator: customSortComparatorForNums,
    },
    {
      field: "chargeName",
      headerName: "Charge Name",
      width: 250,
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 120,
      renderCell: ({ row }) => {
        const onEdit = (e) => {
          e?.stopPropagation && e?.stopPropagation();
          onEditPress(row);
        };

        return (
          <IconButton onClick={onEdit}>
            <Edit color="primary" />
          </IconButton>
        );
      },
    },
  ];
};
